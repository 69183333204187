import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { forEach } from 'jszip';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  allTour: any;
  allOrder: any = {};
  allUser: any = [];
  render: number;
  allPackageData: any;
  FinalBooking: boolean = false;
  dtOptions: DataTables.Settings = {};
  private isDtInitialized: boolean = false;
  totalUnpaidAddon = 0;
  public value: boolean = false;
  public selectFinancialyear: any;
  public getFinancialYear: Array<any> = [];
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  @ViewChild('dateSchedule') dateSchedule: ElementRef;
  @ViewChild('status') status: ElementRef;
  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    var yearsLength = 5;
    this.selectFinancialyear = "Select Year"
    var currentYear = new Date().getFullYear();
    for(var i = 0; i < 5; i++){
      var next = currentYear+1;
      var year = currentYear + '-' + next.toString().slice(-2);
      // $('#financialYear').append(new Option(year, year));
      this.getFinancialYear.unshift(year)
      currentYear--;
    }
     this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: true,
      pageLength: 10,
      dom: 'Bfrtip',
      //buttons: ['excel']
    };
    // this.render = 1;
    this.activeTour();
    this.getAllPackageData();
    this.userreport();
  }

  financialList(){
    console.log(this.value, this.selectFinancialyear)
    if(this.selectFinancialyear == 'Select Year'){
      Swal.fire({
        text: 'Please select financial year',
        icon: 'warning',
      });
      return
    }
    this.spinner.show();
    this.apiService.financialYearReportList({name: this.selectFinancialyear}).subscribe((res: any) => {
      this.spinner.hide();
      if(res.success){
        this.allUser = res.getData;
        console.log(res, this.allTour, this.allUser)
        for (let data of this.allUser) {
          data.totalAddon = 0;
          if (data.addons_id) {
            const totalAddon = data.addons_id.addons_data.reduce((total, value) => total + (value.price * value.quantity), 0);
            data.addons_id = totalAddon;
          } else {
            data.addons_id = 0;
          }
        }
        // console.log(data.totalAddon)
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.spinner.hide();
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
      } else {
        Swal.fire({
          text: res.message,
          icon: 'error',
        });
        this.spinner.hide();
      }
    })
  }

  getAllPackageData() {
    this.apiService.getAllPack().subscribe((res: any) => {
      if (res.success) {
        //console.log(res, "pack");
        this.allPackageData = res.data;
        // this.tourAllData.packageType_price = data;
      } else {
        console.error(res);
      }
    });
  }

  getPackage(id: string) {
    if (this.allPackageData?.length > 0 && id !== "") {
      return this.allPackageData.filter((pack: any) => pack._id === id)[0]?.package_type;
    } else {
      return "-"
    }
  }

  activeTour() {
    this.apiService.getTrip().subscribe((res: any) => {
      //console.log(res, 'heeeeeeee');
      this.allTour = res.data;
      // this.dtTrigger.next();
      // this.isDtInitialized = true;
          // this.render = 0;
    });
  }

   onCategorySelect(id) {
    let dataForForm = {
      id: id,
    };
    //console.log(dataForForm,'hiiiiiiiiii');
    this.apiService.orderID(dataForForm).subscribe((res: any) => {
      //console.log(res.getData, 'devvvvvvvvvvvvvvvvvv');
      this.allOrder = res.getData;
      this.allOrder.start_date = JSON.parse(this.allOrder.start_date);
      this.allOrder.end_date = JSON.parse(this.allOrder.end_date);
       this.allOrder.dateArr = [];
      for (let i = 0; i < this.allOrder.start_date.length; i++) {
       //if ((new Date()).getTime() < (new Date(this.allOrder.start_date[i])).getTime())
        this.allOrder.dateArr.push({
          startDate: this.allOrder.start_date[i],
          endDate: this.allOrder.end_date[i],
        });
      }
      this.allOrder.dateArr.sort(function(a: any,b: any){
        return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
      });
    });
  }
     ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

    userreport() {
    const dateSchedule = this.dateSchedule?.nativeElement?.value;
    const status = this.status?.nativeElement?.value;

    // console.log(packageType);

    // if (dateSchedule === '') {
    //   Swal.fire({
    //     text: 'Please select tour date',
    //     icon: 'warning',
    //   });
    //   return false;
    // }

    const orderDetails = {
    tour_id: this.allOrder._id ? this.allOrder._id : '',
    travel_date: dateSchedule ? `${(dateSchedule?.split('-')[0])?.split('/')[1]}/${(dateSchedule?.split('-')[0])?.split('/')[0]}/${(dateSchedule?.split('-')[0])?.split('/')[2]}` : '',
    payment_status: status ? status : '',
    };
    //console.log(orderDetails);
    this.spinner.show();
    this.apiService.report(orderDetails).subscribe((res: any) => {
      //console.log(res);
        if (res.success) {
          this.spinner.hide();
          this.allUser = res.userData;
          this.allUser.forEach(async (timeObj, i) => {
            // timeObj.totalAmount = timeObj.totalAmount?.toFixed(2);
            let totalAddon = 0;
            if(timeObj.addons_id){
              timeObj.addons_id.addons_data.forEach(async addons => {
                  totalAddon += addons.price*addons.quantity
                  console.log(totalAddon, addons);
                  timeObj.addons_id = totalAddon;
              })
            } else if(!timeObj.addons_id) {
              timeObj.addons_id = 0
            }
          })
          // for(let order of this.allUser) {
          //   if (order?.addons_id && order?.addons_id?.addons_data.length) {
          //     const addonData = order?.addons_id?.addons_data;
          //     for (let addon of addonData) {
          //       if (addon.status === 'unpaid') {
          //         this.totalUnpaidAddon += (addon.price * addon.quantity);
          //       }
          //     }
          //     order.addons_id = this.totalUnpaidAddon;
          //   }
          // }

          console.log(this.allUser,'heyyyyyy');
          if (this.isDtInitialized) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.isDtInitialized = true;
            this.dtTrigger.next();
          }
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
        } else {
          this.spinner.hide();
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
        }
    });
  }
}

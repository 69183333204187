<section class="content">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <table
              datatable
              [dtOptions]="dtOptions"
              [dtTrigger]="dtTrigger"
              class="row-border hover"
            >
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>User Name</th>
                  <th>Tour Name</th>
                  <th>Tour Amount</th>
                  <th>Amount Paid</th>
                  <th>Remaining Amount </th>
                  <th>Remaining Days </th>

                 <!-- <th>Company</th>
                  <th>Email</th>
                  <th>Mobile No.</th>
                  <th>Status</th>
                  <th>Action</th>  -->
                </tr>
              </thead>
              <tbody *ngIf="allData?.length != 0">
                <tr *ngFor="let user of allData; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ user.applyTour_id
.                    user_id.firstname | titlecase }} {{ user.applyTour_id
.    user_id.lastname | titlecase }}</td>
                  <td>{{user.applyTour_id.tour_id.title}}</td>
                  <td>{{user.applyTour_id.finalPrice + user.applyTour_id.addons_id}}</td>
                  <td>{{user.applyTour_id.amountPaid}}</td>
                  <td>{{user.applyTour_id.finalPrice - user.applyTour_id.amountPaid + user.applyTour_id.addons_id}}</td>
                  <td>{{timeDate[i]}}</td>
                  <!-- <td>{{ user.desigination }}</td>
                  <td>{{ user.company }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.mobile }}</td>
                  <td *ngIf="user.status === 'active'">Active</td>
                  <td *ngIf="user.status !== 'active'">Inactive</td>
                  <td>
                    <div class="btn-group">
                      <button
                        *ngIf="user.status === 'active'"
                        type="button"
                        class="btn btn-success"
                        (click)="changeStaus(user._id, user.status)"
                      ><i class="far fa-check-circle"></i></button>
                      <button
                        *ngIf="user.status !== 'active'"
                        type="button"
                        class="btn btn-danger"
                        (click)="changeStaus(user._id, user.status)"
                      ><i class="fas fa-ban"></i></button>
                    </div>
                  </td> -->
                </tr>
              </tbody>
              <tbody *ngIf="allData?.length == 0">
                <tr>
                 <td></td>
                 <td></td>
                 <td></td>
                 <td>No data!</td>
                 <td></td>
                 <td></td>
                 <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</section>

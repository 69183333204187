<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-primary">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <table
                      datatable
                      [dtOptions]="dtOptions"
                      [dtTrigger]="dtTrigger"
                      class="row-border hover"
                      style="width: 100%;"
                    >
                      <thead>
                        <tr>
                          <th>S. No.</th>
                          <th>Notification</th>
                          <!-- <th>Mobile</th>
                          <th>Tour&nbsp;Name</th>

                          <th>Total Amount</th>
                          <th>Amount Paid</th>
                          <th>Remaining Amount</th> -->
                          <th>Time</th>
                          <th>Order History</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="allNotifications?.length != 0">
                        <tr *ngFor="let notification of allNotifications; let i = index">
                          <td>{{ i + 1 }}</td>
                          <td class="notification-text">
                            {{notification.msg}}
                          </td>
                          <td>
                            {{notification.date.toLocaleString() | date: "dd/MM/yyyy, hh:mm a"}}
                          </td>
                          <!-- <td>{{ allMobile[i] }}</td>
                          <td>{{ allTourName[i] }}</td>
                          <td>{{this.getPackage(user.travel_packageType)}}
                                                                          </td>
                          <td>{{ notification.applyTour_id.payment_status }}</td>
                          <td>{{ notification.applyTour_id.travel_no_of_traveler }}</td>
                          <td>{{ notification.applyTour_id.finalPrice }}</td>
                          <td>{{ notification.applyTour_id.amountPaid }}</td> -->
<!--
                          <td
                            *ngIf="
                              this.notification.applyTour_id.finalPrice / this.notification.applyTour_id.partial_payment -
                                (this.notification.applyTour_id.finalPrice /
                                  this.notification.applyTour_id.partial_payment -
                                  notification.applyTour_id?.travel_no_of_traveler) ===
                                1 && notification.applyTour_id?.payment_status === 'Cancelled';
                              else Amt
                            "
                          >
                            0
                          </td>
                          <ng-template #Amt>
                            <td>{{ notification.applyTour_id.totalAmount - notification.applyTour_id.amountPaid }}</td>
                          </ng-template> -->
                          <td>
                            <div class="btn-group">
                              <button
                                type="button"
                                class="btn btn-info"
                                [routerLink]="[
                                  '/user-order-history/particular-order/' +
                                  notification.id
                                ]"
                              >
                                Order History
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tbody *ngIf="allNotifications?.length == 0">
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <!-- <td></td> -->
                          <!-- <td></td> -->
                          <td>No data!</td>
                          <!-- <td></td> -->
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="content">
      <div class="container-fluid">
            <div class="row">
                  <div class="col-md-12">
                        <div class="card card-primary">
                              <!-- form start -->
                              <form [formGroup]="addMakeTripTwoForm" (ngSubmit)="addMakeTourTwo(id)">
                                    <div class="card-body">

                                          <div class="row">
                                                <div class="col-md-12">
                                                      <div class="form-group">
                                                            <label for="class_name">Overview<span
                                                                        class="required"></span></label>
                                                            <ckeditor [config]="config_ck" formControlName="overview">

                                                            </ckeditor>
                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Region<span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control" placeholder="Region"
                                                                  formControlName="region">
                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">High Altitude<span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control"
                                                                  placeholder="High Altitude"
                                                                  formControlName="highAltitude">
                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Pick & Drop<span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control"
                                                                  placeholder="Pick & Drop"
                                                                  formControlName="pickAndDrop">
                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Trip/Trek <span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control"
                                                                  placeholder="Trip/Trek"
                                                                  formControlName="tripAndTrekType">
                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Name Of Places (Enter Places With
                                                                  ,)<span class="required"></span></label>
                                                            <input type="text" class="form-control"
                                                                  placeholder="Name Of Place"
                                                                  formControlName="nameOfPlace">
                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Number Of Places<span class="required"></span></label>
                                                            <input type="text" class="form-control"
                                                                  placeholder="Number Of Places"
                                                                  formControlName="no_of_place">
                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Difficulty<span class="required"></span></label>
                                                            <input type="text" class="form-control"
                                                                  placeholder="Difficulty"
                                                                  formControlName="difficulty">
                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Best Time<span class="required"></span></label>
                                                            <input type="text" class="form-control"
                                                                  placeholder="Best Time"
                                                                  formControlName="best_time">
                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Whatsapp Number</label>
                                                            <input type="text" class="form-control" (keypress)="onlyNumberKey($event)"
                                                                  placeholder="Whatsapp Number"
                                                                  formControlName="whatsapp_number">
                                                      </div>
                                                </div>
                                                <div class="col-md-6">
                                                      <div class="form-group">
                                                            <label for="class_name">Multiple Phone Number Number(When entering multiple numbers, put a comma separator after one number.)</label>
                                                            <input type="text" class="form-control" 
                                                                  placeholder="Multiple Phone Number Number Please comma Sepreter "
                                                                  formControlName="multiple_number">
                                                      </div>
                                                </div>
                                                <div class="form-group col-md-12" #div>
                                                </div>

                                                <div id="dynamic_fields"></div>

                                                <div class="row" style="margin-left: 1px;">
                                                      <div class="form-group col-md-12">
                                                            <button type="button" (click)="addElement()"
                                                                  class="btn btn-info">Add</button>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    <!-- <div class="card-body" style="margin-top: -42px;">
                                          <div class="row">
                                                <div class="col-md-4">

                                                      <div class="custom-control custom-checkbox">
                                                            <input id="male" type="checkbox"
                                                                  class="custom-control-input">
                                                            <label class="custom-control-label"
                                                                  for="male">Status</label>
                                                      </div>
                                                </div>
                                          </div>
                                    </div> -->



                                    <!-- /.card-body -->

                                    <div class="card-footer text-right">
                                          <app-button [type]="'submit'" [block]="false" [text]="'Next'"
                                                [loading]="isAuthLoading" [color]="'info'" [disabled]="isAuthDisabled">
                                          </app-button>

                                          <button type="submit" class="btn btn-info"
                                                [routerLink]="['/make-trip', tripId]" style="float:left;">Previous</button>
                                    </div>
                              </form>
                        </div>
                  </div>
            </div>
      </div>
</div>
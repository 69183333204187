import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-make-trip-pricing-list',
  templateUrl: './make-trip-pricing-list.component.html',
  styleUrls: ['./make-trip-pricing-list.component.scss'],
})
export class MakeTripPricingListComponent implements OnInit {
  addPricingForm: FormGroup;
  dtOptions: DataTables.Settings = {};
  public isAuthLoading = false;
  public isAuthDisabled = false;
  isShown: any = [];
  id: any;
  object: any;
  allData: any;
  pck: any;
  pck_strike: any;
  solo: any = [];
  share: any = [];
  rooms: any = [];
  boarding_point: any;
  allBoardData: any;
  allcoupon: any;
  allPackData: any;
  alldevData: any;
  addboard: any;
  board: any;
  tripId: any;
  boarding_point_type: any;
  private isDtInitialized: boolean = false;
  bpPrice: any;
  //comment: any;
  public strikingPrice: Array<any> = []

  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  constructor(
    private formBuilder: FormBuilder,
    private config: NgbDatepickerConfig,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService
  ) {}

  @ViewChild('importFestImg', { static: false }) importFestImg: ElementRef;
  @ViewChild('importFestImgTwo', { static: false })
  importFestImgTwo: ElementRef;

  ngOnInit(): void {
    this.object = this.route.snapshot.paramMap.get('objId');
    if (this.object != undefined && this.object != null && this.object != '') {
      this.edittripfive(this.object);
    }
    this.addPricingForm = new FormGroup({
      basicAmount: new FormControl(),
      strikingAmount: new FormControl(),
      pricingImage1: new FormControl(),
      pricingImage2: new FormControl(),
      bookPrice: new FormControl(),
      //coupon_code: new FormControl(),
      boarding_point_price: new FormControl(),
      // packageType_price: new FormControl(),
      // blog_image: new FormControl(),
      status: new FormControl(),
    });
    this.addPricingForm = this.formBuilder.group({
      // basicAmount: ['', [Validators.required]],
      // strikingAmount: ['', [Validators.required]],
      boarding_point_price: ['', [Validators.required]],
      bookPrice: ['', [Validators.required]],
      // blog_image: [''],
      //coupon_code: [''],
      pricingImage1: [''],
      pricingImage2: [''],
      status: ['', []],
      // ! here
      //comment: ['']
    });
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };

    this.getdetail(this.object);
    //this.coupon();
    this.addPricingForm.get('status').setValue('active');
  }

  // coupon() {
  //   this.apiService.activeCoupanList().subscribe((res: any) => {
  //     console.log(res);
  //     this.allcoupon = res.data;
  //   });
  // }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.addPricingForm.get('pricingImage1').setValue(file);
      this.importFestImg.nativeElement.innerText = event.target.files[0].name;
    }
  }

  onFileChangeTwo(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.addPricingForm.get('pricingImage2').setValue(file);
      this.importFestImgTwo.nativeElement.innerText =
        event.target.files[0].name;
    }
  }

  edittripfive(id) {
    let dataForForm = {
      id: id,
    };
    //console.log(this.object);
    this.apiService.tourUpdate(dataForForm).subscribe((result: any) => {
      console.log(result.getData);
      //console.log(result.getData.summary, 'hiiiiiiiiiiiiii');
      if (result.success) {
        let TripData: any = result.getData;
        this.tripId = TripData._id;
        // console.log(TripData);
        // TripData.pricingImage1[0].name = TripData.pricingImage1[0].orgName;
        // TripData.pricingImage2[0].name = TripData.pricingImage2[0].orgName;
        this.addPricingForm.patchValue({
          bookPrice: TripData.bookPrice,
          strikingAmount: TripData.strikingAmount,
          basicAmount: TripData.basicAmount,
          //coupon_code: TripData.coupon_code?._id,
          pricingImage1: TripData.pricingImage1[0],
          pricingImage2: TripData.pricingImage2[0],
        });

        if (TripData.packageType_price?.length) {
          TripData.packageType_price = JSON.parse(TripData.packageType_price);
          //console.log(TripData.packageType_price, 'hiiiiiiiiii');
          setTimeout(() => {
            TripData.packageType_price.forEach((key, i) => {
              this.addPricingForm.controls[key.packType].setValue(key.packPrice);
            });
          }, 1000);
        }

        // ! striking package price
        if(TripData.packageType_striking_price?.length){
          TripData.packageType_striking_price = JSON.parse(TripData.packageType_striking_price);
          setTimeout(() => {
            TripData.packageType_striking_price.forEach((key, i) => {
              this.addPricingForm.controls[key.packTypestriker].setValue(key.packPricestriker);
            });
          }, 1000);
        }

        // if (TripData.soloType_price?.length) {
        //   TripData.soloType_price = JSON.parse(TripData.soloType_price);
        //   console.log(TripData.soloType_price, 'hiiiiiiiiii');
        //   setTimeout(() => {
        //     TripData.soloType_price.forEach((key, i) => {
        //       this.addPricingForm.controls[key.soloType + '_solo'].setValue(key.soloPrice);
        //     });
        //   }, 1000);
        // }

        // if (TripData.shareType_price?.length) {
        //   TripData.shareType_price = JSON.parse(TripData.shareType_price);
        //   console.log(TripData.shareType_price, 'hiiiiiiiiii');
        //   setTimeout(() => {
        //     TripData.shareType_price.forEach((key, i) => {
        //       this.addPricingForm.controls[key.sharedType + '_share'].setValue(key.sharedPrice);
        //     });
        //   }, 1000);
        // }

        if (TripData.roomSharing_price?.length) {
          TripData.roomSharing_price = JSON.parse(TripData.roomSharing_price);
          console.log(TripData.roomSharing_price, 'hiiiiiiiiii');
          setTimeout(() => {
            TripData.roomSharing_price.forEach((key, i) => {
              this.addPricingForm.controls[key.roomType].setValue(key.roomPrice);
            });
          }, 1000);
        }

        if (TripData.boarding_point_price?.length) {
          TripData.boarding_point_price = JSON.parse(TripData.boarding_point_price);
          console.log(TripData.boarding_point_price, 'hiiiiiiiiii');
          // const isBpPriceExist = TripData.boarding_point_price.map(bpType => TripData.boarding_point.includes(bpType.bp));
          // console.log(isBpPriceExist);
          // if (isBpPriceExist.includes(true)) {
            setTimeout(() => {
            //  this.isShown = [];
             console.log(this.addPricingForm.value);
             TripData.boarding_point_price.forEach((key, i) => {
               // this.addPricingForm.controls[key.bp].setValue(key.bp_price);
               // console.log(key, 'heelo');
               if (TripData.boarding_point.includes(key.bp)) {
                 if (key.bp_price.length === 1) {
                   this.addPricingForm.get('boardplace' + i).setValue(key.bp_price[0].singleBoardPrice);
                   this.addPricingForm.get(key.bp).setValue('single'+i);
                  //  this.isShown.push('single' + i);
                 } else {
                   this.addPricingForm.get('Sleeper' + i).setValue(key.bp_price[0]['Sleeper']);
                   this.addPricingForm.get('3AC' + i).setValue(key.bp_price[1]['3AC']);
                   this.addPricingForm.get('2AC' + i).setValue(key.bp_price[2]['2AC']);
                   this.addPricingForm.get('1AC' + i).setValue(key.bp_price[3]['1AC']);
                   this.addPricingForm.get('Flight' + i).setValue(key.bp_price[4]['Flight']);
                   this.addPricingForm.get(key.bp).setValue('multi'+i);
                  if (this.isShown.includes('single' + i)) {
                    const index = this.isShown.indexOf('single' + i);
                    this.isShown.splice(index, 1);
                    this.isShown.push('multi' + i);
                  }
                 }
               }
             });
             console.log(this.isShown);
            }, 1000);
          // }
         if (TripData.status == 'active') {
           this.addPricingForm.get('status').setValue('active');
         }
         setTimeout(() => {
           this.importFestImg.nativeElement.innerText =
             TripData.pricingImage1[0]?.orgName;
         }, 1000);
         setTimeout(() => {
           this.importFestImgTwo.nativeElement.innerText =
             TripData.pricingImage2[0]?.orgName;
         }, 1000);
        }
      }
    });
  }

  getdetail(id) {
    let dataForForm = {
      id: id,
    };
    this.apiService.getTourId(dataForForm).subscribe((result: any) => {
      console.log(result.getData);
      //this.allData = result.getData;
      this.allData = result.getData;
      this.addboard = result.getData;
      //console.log(this.addboard);
      // this.pck = this.addboard.packageType.filter(pack => pack !== 'Solo bike' && pack !== 'Shared bike');
      // this.solo = this.addboard.soloBikeType;
      // this.share = this.addboard.sharedBikeType;
      this.pck = this.addboard.packageType;
      // console.log(this.addboard.packageType);
      this.addboard.packageType.forEach((elem)=> {
        this.strikingPrice.push(elem + " strike")
      })
      // console.log(this.strikingPrice);
      this.pck_strike = this.strikingPrice;
      // this.solo = this.addboard.soloBikeType;
      // this.share = this.addboard.sharedBikeType;
      this.rooms = this.addboard.roomSharing;
      this.board = this.allData.boarding_point;
      //console.log(this.board, 'hiiiiiiiiii');
      console.log(this.solo, this.share, 'hellllll', this.pck, this.addPricingForm);
      this.pck.forEach((packData, index) => {
        console.log(packData);

        this.addPricingForm.addControl(packData, new FormControl(''));
      });
      // console.log(this.pck);
      
      this.pck_strike.forEach((packStrikeData, index) => {
        console.log(packStrikeData);
        this.addPricingForm.addControl(packStrikeData, new FormControl(''));
      });
      // console.log(this.pck_strike, this.addPricingForm);
      if (this.solo.length) {
        this.solo.forEach((soloData, index) => {
          this.addPricingForm.addControl(soloData + '_solo', new FormControl(''));
        });
      }
      if (this.share.length) {
        this.share.forEach((shareData, index) => {
          this.addPricingForm.addControl(shareData + '_share', new FormControl(''));
        });
      }
      this.rooms.forEach((roomData, index) => {
        this.addPricingForm.addControl(roomData, new FormControl(''));
      });
      this.board.forEach((boardData, index) => {
        this.addPricingForm.addControl(
          boardData,
          new FormControl('single' + index)
        );
        this.addPricingForm.addControl(
          'boardplace' + index,
          new FormControl('')
        );
        this.addPricingForm.addControl('Sleeper' + index, new FormControl(''));
        this.addPricingForm.addControl('3AC' + index, new FormControl(''));
        this.addPricingForm.addControl('2AC' + index, new FormControl(''));
        this.addPricingForm.addControl('1AC' + index, new FormControl(''));
        this.addPricingForm.addControl('Flight' + index, new FormControl(''));
        this.isShown.push('single' + index);
      });
    });
  }

  toggleShow(getVal: string, idx: string) {
    const toggleVal = getVal === 'multi' + idx ? 'single' + idx : 'multi' + idx;
    const index = this.isShown.indexOf(toggleVal);
    if (index !== -1) {
      this.isShown[index] = getVal;
    } else {
      console.error(getVal);
    }
  }

  addPrice(id) {
    // if (
    //   this.addPricingForm.value.basicAmount == undefined ||
    //   this.addPricingForm.value.basicAmount == ''
    // ) {
    //   Swal.fire({
    //     text: 'Please enter basic amount',
    //     icon: 'warning',
    //   });
    //   return false;
    // }
    // if (
    //   this.addPricingForm.value.strikingAmount == undefined ||
    //   this.addPricingForm.value.strikingAmount == ''
    // ) {
    //   Swal.fire({
    //     text: 'Please enter striking amount',
    //     icon: 'warning',
    //   });
    //   return false;
    // }

    if (
      this.addPricingForm.value.bookPrice == undefined ||
      this.addPricingForm.value.bookPrice == ''
    ) {
      Swal.fire({
        text: 'Please enter booking amount',
        icon: 'warning',
      });
      return false;
    }

    if (this.addPricingForm.status == 'active') {
      this.addPricingForm.get('status').setValue('active');
    }

    for (let i=0; i<this.allPackData?.length; i++) {
      if (this.addPricingForm.value.packType == '' ||
        this.addPricingForm.value.packType == undefined
      ) {
        Swal.fire({
          text: 'Please enter all package price',
          icon: 'warning',
        });
        return false;
      }
    }


    let packagetData = [];
    let packagetStrikeData = [];
    let allSoloData = [];
    let allSharedData = [];
    let roomgetData = [];
    let boardingPointData = [];
    this.pck.forEach((packData, index) => {
      console.log(packData);

      packagetData.push({
        packType: packData,
        packPrice: this.addPricingForm.value[packData],
      });
      console.log(this.addPricingForm.value[packData]);
    });
    this.pck_strike.forEach((packStrikeData, index) => {
      console.log(packStrikeData);

      packagetStrikeData.push({
        packTypestriker: packStrikeData,
        packPricestriker: this.addPricingForm.value[packStrikeData],
      })
    })
    if (this.solo.length) {
      this.solo.forEach((soloData, index) => {
        allSoloData.push({
          soloType: soloData,
          soloPrice: this.addPricingForm.value[soloData + '_solo'],
        });
      });
    }
    if (this.share.length) {
      this.share.forEach((shareData, index) => {
        allSharedData.push({
          sharedType: shareData,
          sharedPrice: this.addPricingForm.value[shareData + '_share'],
        });
      });
    }
    this.rooms.forEach((roomData, index) => {
      roomgetData.push({
        roomType: roomData,
        roomPrice: this.addPricingForm.value[roomData],
      });
    });
    this.board.forEach((boardData, index) => {
      console.error(this.addPricingForm.value[boardData].slice(0, -1), 'yyyy');
      let bpPrice = [];
      if (this.addPricingForm.value[boardData] === `multi${index}`) {
        bpPrice = [
          { Sleeper: this.addPricingForm.value[`Sleeper${index}`] },
          { '3AC': this.addPricingForm.value[`3AC${index}`] },
          { '2AC': this.addPricingForm.value[`2AC${index}`] },
          { '1AC': this.addPricingForm.value[`1AC${index}`] },
          { Flight: this.addPricingForm.value[`Flight${index}`] },
        ];
      } else {
        bpPrice = [
          { singleBoardPrice: this.addPricingForm.value[`boardplace${index}`] },
        ];
      }

      boardingPointData.push({
        bp: boardData,
        bp_type: this.addPricingForm.value[boardData].slice(0, -1),
        bp_price: bpPrice,
      });
    });
    // console.error(packagetData, "kaal");
    // console.error(boardingPointData, "kaal");
    // return;

    let formData = new FormData();
    //formData.append('user_id', this._userId);
    // formData.append(
    //   'basicAmount',
    //   this.addPricingForm.get('basicAmount').value
    // );
    // formData.append(
    //   'strikingAmount',
    //   this.addPricingForm.get('strikingAmount').value
    // );
    formData.append('bookPrice', this.addPricingForm.get('bookPrice').value);
    formData.append(
      'pricingImage1',
      this.addPricingForm.get('pricingImage1').value
    );
    formData.append(
      'pricingImage2',
      this.addPricingForm.get('pricingImage2').value
    );
    //formData.append('coupon_code', this.addPricingForm.get('coupon_code').value );
    formData.append('boarding_point_price', JSON.stringify(boardingPointData));
    formData.append('packageType_price', JSON.stringify(packagetData));
    // console.log(packagetData);
    // console.log(packagetStrikeData);
    formData.append('packageType_striking_price', JSON.stringify(packagetStrikeData));
    // formData.append('soloType_price', JSON.stringify(allSoloData));
    // formData.append('shareType_price', JSON.stringify(allSharedData));
    formData.append('roomSharing_price', JSON.stringify(roomgetData));
    formData.append('boarding_point_type', 'NaN');
    formData.append('status', this.addPricingForm.get('status').value);
    console.log(this.addPricingForm.value);
    //return;
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    this.apiService
      .updateTourFive(formData, this.object)
      .subscribe((res: any) => {
        //console.log(res);
        if (res.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          this.router.navigateByUrl('/make-trip-list');
          //console.log(this.object);
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
        }
      });
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  onlyNumberKey(evt: KeyboardEvent) {
    // Only ASCII character in that range allowed
    let ASCIICode = evt.which ? evt.which : evt.keyCode;
    return ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57) ? false : true;
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { BlankComponent } from './views/blank/blank.component';
import { LoginComponent } from './pages/login/login.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ProfileComponent } from './views/profile/profile.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { AuthGuard } from './utils/guards/auth.guard';
import { NonAuthGuard } from './utils/guards/non-auth.guard';
import { BlogListComponent } from './views/blog-list/blog-list.component';
import { BlogComponent } from './views/blog/blog.component';
import { AdminListComponent } from './views/admin-list/admin-list.component';
import { UserListComponent } from './views/user-list/user-list.component';
import { RegisterComponent } from './pages/register/register.component';
import { BoardingPointComponent } from './views/boarding-point/boarding-point.component';
import { AdventureTypeComponent } from './views/adventure-type/adventure-type.component';
import { PackageTypeComponent } from './views/package-type/package-type.component';
import { DestinationCityComponent } from './views/destination-city/destination-city.component';
import { MakeTripComponent } from './views/make-trip/make-trip.component';
import { TestComponent } from './views/test/test.component';
import { BoardingPointListComponent } from './views/boarding-point-list/boarding-point-list.component';
import { PackageTypeListComponent } from './views/package-type-list/package-type-list.component';
import { DestinationCityListComponent } from './views/destination-city-list/destination-city-list.component';
import { AdventureTypeListComponent } from './views/adventure-type-list/adventure-type-list.component';
import { MakeTripItineraryComponent } from './views/make-trip-itinerary/make-trip-itinerary.component';
import { MakeTripInclusionComponent } from './views/make-trip-inclusion/make-trip-inclusion.component';
import { MakeTripOverviewComponent } from './views/make-trip-overview/make-trip-overview.component';
import { MakeTripPricingListComponent } from './views/make-trip-pricing-list/make-trip-pricing-list.component';
import { SuperAdminComponent } from './views/super-admin/super-admin.component';
import { SuperAdminListComponent } from './views/super-admin-list/super-admin-list.component';
import { AddMediaComponent } from './views/add-media/add-media.component';
import { AddMediaListComponent } from './views/add-media-list/add-media-list.component';
import { AddUserListComponent } from './views/add-user-list/add-user-list.component';
import { AddUserComponent } from './views/add-user/add-user.component';
import { AddVideoComponent } from './views/add-video/add-video.component';
import { AddVideoListComponent } from './views/add-video-list/add-video-list.component';
import { MakeTripListComponent } from './views/make-trip-list/make-trip-list.component';
import { CoupanCodeComponent } from './views/coupan-code/coupan-code.component';
import { AddCheckoutNotesComponent } from './views/add-checkout-notes/add-checkout-notes.component';
import { CoupanCodeListComponent } from './views/coupan-code-list/coupan-code-list.component';
import { CoupanAssignComponent } from './views/coupan-assign/coupan-assign.component';
import { AddCheckoutNotesListComponent } from './views/add-checkout-notes-list/add-checkout-notes-list.component';
import { UserHistoryComponent } from './views/user-history/user-history.component';
import { AddUserOrdersComponent } from './views/add-user-orders/add-user-orders.component';
import { SpecialTourComponent } from './views/special-tour/special-tour.component';
import { SpecialTourListComponent } from './views/special-tour-list/special-tour-list.component';
import { OtherChargesComponent } from './views/other-charges/other-charges.component';
import { OtherChargesListComponent } from './views/other-charges-list/other-charges-list.component';
import { UserOrderHistoryComponent } from './views/user-order-history/user-order-history.component';
import { ReportComponent } from './views/report/report.component';
import { AddRoomSharingComponent } from './views/add-room-sharing/add-room-sharing.component';
import { ListRoomSharingComponent } from './views/list-room-sharing/list-room-sharing.component';
import { BikeOptionsComponent } from './views/bike-options/bike-options.component';
import { BikeOptionsListComponent } from './views/bike-options-list/bike-options-list.component';
import { LandingReportComponent } from './views/landing-report/landing-report.component';
import { ViewLandingDetailComponent } from './views/view-landing-detail/view-landing-detail.component';
import { UserParticularOrderComponent } from './views/user-particular-order/user-particular-order.component';
import { TaxListComponent } from './views/tax-list/tax-list.component';
import { RefundCancelOrderComponent } from './views/refund-cancel-order/refund-cancel-order.component';
import { AddMenuComponent } from './views/add-menu/add-menu.component';
import { AddMenuListComponent } from './views/add-menu-list/add-menu-list.component';
import { RefundListComponent } from './views/refund-list/refund-list.component';
import { PayLinkOrdersComponent } from './views/pay-link-orders/pay-link-orders.component';
import { NotificationViewComponent } from './views/notification-view/notification-view.component';
import { UserReminderComponent } from './views/user-reminder/user-reminder.component';
import { AddVoucherComponent } from './views/add-voucher/add-voucher.component';
import { AddVoucherListComponent } from './views/add-voucher-list/add-voucher-list.component';
import { VoucherUserListComponent } from './views/voucher-user-list/voucher-user-list.component';
import { RefundListTaxComponent } from './views/refund-list-tax/refund-list-tax.component';
const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
        data: {
          title: 'Dashboard',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
          ],
        },
      },
      {
        path: 'report',
        component: ReportComponent,
        data: {
          title: 'Tour Report',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Tour Report',
              url: '/report',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'user-history/:id',
        component: UserHistoryComponent,
        data: {
          title: 'Book Tour',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'User Record',
              url: '/user-history',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
       {
        path: 'user-order-history/:id',
        component: UserOrderHistoryComponent,
        data: {
          title: 'Order History',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Other Charge',
              url: '/user-order-history',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'add-menu',
        component: AddMenuComponent,
        data: {
          title: 'Add Menu',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Add Menu',
              url: '/add-menu',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'add-menu-list',
        component: AddMenuListComponent,
        data: {
          title: 'Add Menu List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Add Menu List',
              url: '/add-menu-list',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'add-menu/edit-add-menu/:menu_id',
        component: AddMenuComponent,
        data: {
          title: 'Edit Add Menu',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            // {
            //   title: 'Blog',
            //   url: '/blog-list',
            // },
            {
              title: 'Edit Add Menu',
              url: '/add-menu/add-menu-chargess/:menu_id',
            },
          ],
        },
      },
      {
        path: 'other-charges',
        component: OtherChargesComponent,
        data: {
          title: 'Other Charge',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Other Charge',
              url: '/other-charges',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'other-charges-list',
        component: OtherChargesListComponent,
        data: {
          title: 'Other Charge List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Other Charge List',
              url: '/other-charges-list',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'other-charges/edit-other-chargess/:order_id',
        component: OtherChargesComponent,
        data: {
          title: 'Edit Order Charge',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            // {
            //   title: 'Blog',
            //   url: '/blog-list',
            // },
            {
              title: 'Edit Order Charge',
              url: '/other-charges/edit-other-chargess/:order_id',
            },
          ],
        },
      },
      {
        path: 'special-tour',
        component: SpecialTourComponent,
        data: {
          title: 'Special Tour',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Special Tour',
              url: '/special-tour',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'special-tour-list',
        component: SpecialTourListComponent,
        data: {
          title: 'Special Tour list',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Special Tour list',
              url: '/special-tour-list',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'special-tour/edit-special-tour/:image_id',
        component: SpecialTourComponent,
        data: {
          title: 'Edit Special Tour',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Edit Special Tour',
              url: '/special-tour/edit-special-tour/:image_id',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'add-user-orders',
        component: AddUserOrdersComponent,
        data: {
          title: 'Add Orders',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Add Orders',
              url: '/add-user-orders',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },

      {
        path: 'add-checkout-notes-list',
        component: AddCheckoutNotesListComponent,
        data: {
          title: ' Notes List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Notes List',
              url: '/add-checkout-notes-list',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'add-checkout-notes',
        component: AddCheckoutNotesComponent,
        data: {
          title: 'Add Notes',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Add Notes',
              url: '/add-checkout-notes',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'add-checkout-notes/edit-add-checkout-notes/:note_id',
        component: AddCheckoutNotesComponent,
        data: {
          title: 'Edit Notes',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            // {
            //   title: 'Blog',
            //   url: '/blog-list',
            // },
            {
              title: 'Edit Admin',
              url: '/add-checkout-notes/edit-add-checkout-notes/:note_id',
            },
          ],
        },
      },
      {
        path: 'coupan-code',
        component: CoupanCodeComponent,
        data: {
          title: 'Add Coupon ',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Add Coupan',
              url: '/coupan-code',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'coupan-code-list',
        component: CoupanCodeListComponent,
        data: {
          title: 'Coupon List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Coupan List',
              url: '/coupan-code-list',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'coupan-assign',
        component: CoupanAssignComponent,
        data: {
          title: 'Assign Coupon',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Coupan Assign',
              url: '/coupan-assign',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'voucher-user-list',
        component: VoucherUserListComponent,
        data: {
          title: 'Voucher User List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Voucher User List',
              url: '/voucher-user-list',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'add-user',
        component: AddUserComponent,
        data: {
          title: 'Add User',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Add User',
              url: '/add-user',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'add-user-list',
        component: AddUserListComponent,
        data: {
          title: 'Website User',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Website User',
              url: '/add-user-list',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'make-trip-list',
        component: MakeTripListComponent,
        data: {
          title: 'Trip List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Trip List',
              url: '/make-trip-list',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'add-media-list',
        component: AddMediaListComponent,
        data: {
          title: 'Admin Media List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Admin Media',
              url: '/add-media-list',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'add-media',
        component: AddMediaComponent,
        data: {
          title: 'Admin Media',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Admin Media',
              url: '/add-media',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'super-admin',
        component: SuperAdminComponent,
        data: {
          title: 'Admin Create',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Admin Create',
              url: '/super-admin',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'coupan-code/edit-coupan/:coupan_id',
        component: CoupanCodeComponent,
        data: {
          title: 'Edit Coupon',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Edit Coupan',
              url: '/coupan-code/edit-coupan/:coupan_id',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'add-voucher/edit-voucher/:voucher_id',
        component: AddVoucherComponent,
        data: {
          title: 'Edit Voucher',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Edit Voucher',
              url: '/add-voucher/edit-voucher/:voucher_id',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'super-admin/edit-super-admin/:admin_id',
        component: SuperAdminComponent,
        data: {
          title: 'Edit Admin',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            // {
            //   title: 'Blog',
            //   url: '/blog-list',
            // },
            {
              title: 'Edit Admin',
              url: '/super-admin/edit-super-admin/:admin_id',
            },
          ],
        },
      },
      {
        path: 'super-admin-list',
        component: SuperAdminListComponent,
        data: {
          title: 'Admin List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Admin List',
              url: '/super-admin-list',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'make-trip-inclusion/:objId',
        component: MakeTripInclusionComponent,
        data: {
          title: 'Make Trip Inclusion',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Make Trip Inclusion',
              url: '/make-trip-inclusion',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'make-trip-itinerary/:objId',
        component: MakeTripItineraryComponent,
        data: {
          title: 'Make Trip Itinerary',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Make Trip Itinerary',
              url: '/make-trip-itinerary',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'make-trip-overview/:objId',
        component: MakeTripOverviewComponent,
        data: {
          title: 'Make Trip Overview',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Make Trip Overview',
              url: '/make-trip-overview',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'make-trip-pricing-list/:objId',
        component: MakeTripPricingListComponent,
        data: {
          title: 'Make Trip Pricing',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Make Trip Pricing',
              url: '/make-trip-pricing-list',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'blog',
        component: BlogComponent,
        data: {
          title: 'Create Blog',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Blog',
              url: '/blog-list',
            },
            {
              title: 'Create Blog',
              url: '/blog',
            },
          ],
        },
      },
      {
        path: 'add-video',
        component: AddVideoComponent,
        data: {
          title: 'Create video',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Blog',
              url: '/add-video',
            },
            {
              title: 'Create Blog',
              url: '/add-video',
            },
          ],
        },
      },
      {
        path: 'add-video-list',
        component: AddVideoListComponent,
        data: {
          title: 'Create video list',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Blog',
              url: '/add-video-list',
            },
            {
              title: 'Create Blog',
              url: '/add-video-list',
            },
          ],
        },
      },
      {
        path: 'add-video/edit-add-video/:video_id',
        component: AddVideoComponent,
        data: {
          title: 'Edit video',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            // {
            //   title: 'Blog',
            //   url: '/blog-list',
            // },
            {
              title: 'Edit Blog',
              url: '/add-video/edit-add-video/:video_id',
            },
          ],
        },
      },
      {
        path: 'package-type-list',
        component: PackageTypeListComponent,
        data: {
          title: 'Package Type',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Package Type',
              url: '/package-type-list',
            },
            // {
            //   title: 'Custom List',
            //   url: '/custom-list'
            // },
          ],
        },
      },
      {
        path: 'destination-city-list',
        component: DestinationCityListComponent,
        data: {
          title: 'Destination City',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Destination City',
              url: '/destination-city-list',
            },
            // {
            //   title: 'Custom List',
            //   url: '/custom-list'
            // },
          ],
        },
      },
      {
        path: 'adventure-type-list',
        component: AdventureTypeListComponent,
        data: {
          title: 'Adventure Type',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Adventure Type',
              url: '/adventure-type-list',
            },
            // {
            //   title: 'Custom List',
            //   url: '/custom-list'
            // },
          ],
        },
      },

      {
        path: 'boarding-point-list',
        component: BoardingPointListComponent,
        data: {
          title: 'Bording List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Bording List',
              url: '/boarding-point-list',
            },
            // {
            //   title: 'Create Blog',
            //   url: '/blog',
            // },
          ],
        },
      },
      {
        path: 'test',
        component: TestComponent,
        data: {
          title: 'Create Blog',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'test',
              url: '/test',
            },
          ],
        },
      },
      {
        path: 'boarding-point',
        component: BoardingPointComponent,
        data: {
          title: 'Boarding Point',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Boarding Point',
              url: '/boarding-point',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'boarding-point/edit-boarding-point/:boarding_id',
        component: BoardingPointComponent,
        data: {
          title: 'Edit Blog',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            // {
            //   title: 'Blog',
            //   url: '/blog-list',
            // },
            {
              title: 'Edit Blog',
              url: '/boarding-point/edit-boarding-point/:boarding_id',
            },
          ],
        },
      },
      {
        path: 'package-type/edit-package-type/:package_id',
        component: PackageTypeComponent,
        data: {
          title: 'Edit Package',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            // {
            //   title: 'Blog',
            //   url: '/blog-list',
            // },
            {
              title: 'Edit Package',
              url: '/package-type/edit-package-type/:package_id',
            },
          ],
        },
      },
      {
        path: 'make-trip/:trip_id',
        component: MakeTripComponent,
        data: {
          title: ' Edit Make Trip',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: ' Edit Make Tip',
              url: '/make-trip/:trip_id',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'make-trip',
        component: MakeTripComponent,
        data: {
          title: 'Make Trip',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Make Tip',
              url: '/make-trip',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'adventure-type',
        component: AdventureTypeComponent,
        data: {
          title: 'Adventure Type',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Adventure Type',
              url: '/adventure-type',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'adventure-type/edit-adventure-type/:adventure_id',
        component: AdventureTypeComponent,
        data: {
          title: 'Edit Adventure',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            // {
            //   title: 'Blog',
            //   url: '/blog-list',
            // },
            {
              title: 'Edit Adventure',
              url: '/adventure-type/edit-adventure-type/:adventure_id',
            },
          ],
        },
      },
      {
        path: 'package-type',
        component: PackageTypeComponent,
        data: {
          title: 'Package Type',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Package Type',
              url: '/package-type',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'destination-city',
        component: DestinationCityComponent,
        data: {
          title: 'Destination',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Destination',
              url: '/destination-city',
            },
            // {
            //   title: 'Language ',
            //   url: '/language'
            // }
          ],
        },
      },
      {
        path: 'destination-city/edit-destination-city/:destination_id',
        component: DestinationCityComponent,
        data: {
          title: 'Edit Destination',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            // {
            //   title: 'Blog',
            //   url: '/blog-list',
            // },
            {
              title: 'Edit Destination',
              url: '/destination-city/edit-destination-city/:destination_id',
            },
          ],
        },
      },
      {
        path: 'blog-list',
        component: BlogListComponent,
        data: {
          title: 'Blog List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Blog List',
              url: '/blog-list',
            },
          ],
        },
      },
      {
        path: 'blog/edit-blog/:blog_id',
        component: BlogComponent,
        data: {
          title: 'Edit Blog',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Blog',
              url: '/blog-list',
            },
            {
              title: 'Edit Blog',
              url: '/blog/edit-blog/:blog_id',
            },
          ],
        },
      },

      {
        path: 'admin-list',
        component: AdminListComponent,
        data: {
          title: 'Admin Panel',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Admin Panel',
              url: '/admin-list',
            },
          ],
        },
      },
      {
        path: 'user-list',
        component: UserListComponent,
        data: {
          title: 'User Panel',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'User Panel',
              url: '/user-list',
            },
          ],
        },
      },
      {
        path: 'admin-list/register',
        component: RegisterComponent,
        data: {
          title: 'New Admin',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Admin Panel',
              url: '/admin-list',
            },
            {
              title: 'New Admin',
              url: '/admin-list/register',
            },
          ],
        },
      },
      {
        path: 'admin-list/edit-admin/:admin_id',
        component: RegisterComponent,
        data: {
          title: 'Edit Admin',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Admin Panel',
              url: '/admin-list',
            },
            {
              title: 'Edit Admin',
              url: '/admin-list/edit-admin/:admin_id',
            },
          ],
        },
      },
      {
        path: 'room-sharing',
        component: AddRoomSharingComponent,
        data: {
          title: 'Add Room Sharing',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Room Sharing',
              url: '/room-sharing-list',
            },
            {
              title: 'Add Room Sharing',
              url: '/room-sharing',
            },
          ],
        },
      },
      {
        path: 'room-sharing/:room_id',
        component: AddRoomSharingComponent,
        data: {
          title: 'Edit Room Sharing',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Room Sharing',
              url: '/room-sharing-list',
            },
            {
              title: 'Edit Room Sharing',
              url: '/room-sharing/:room_id',
            },
          ],
        },
      },
      {
        path: 'room-sharing-list',
        component: ListRoomSharingComponent,
        data: {
          title: 'Room Sharing',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Room Sharing',
              url: '/room-sharing-list',
            },
          ],
        },
      },
      {
        path: 'bike-options',
        component: BikeOptionsComponent,
        data: {
          title: 'Add Bike Options',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Bikes',
              url: '/bike-options-list',
            },
            {
              title: 'Add Bike Options',
              url: '/bike-options',
            },
          ],
        },
      },
      {
        path: 'bike-options/:bike_id',
        component: BikeOptionsComponent,
        data: {
          title: 'Edit Bike Options',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Bikes',
              url: '/bike-options-list',
            },
            {
              title: 'Edit Bike Options',
              url: '/bike-options/:bike_id',
            },
          ],
        },
      },
      {
        path: 'bike-options-list',
        component: BikeOptionsListComponent,
        data: {
          title: 'Bikes',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Bikes',
              url: '/bike-options-list',
            },
          ],
        },
      },
      {
        path: 'landing-page-details',
        component: LandingReportComponent,
        data: {
          title: 'Landing Page Details',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Landing Page Details',
              url: '/landing-page-details',
            },
          ],
        },
      },
      {
        path: 'landing-page-details/view-landing-detail/:landing_id',
        component: ViewLandingDetailComponent,
        data: {
          title: 'Landing Page Details',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Landing Page',
              url: '/landing-page-details',
            },
            {
              title: 'View Landing Detail',
              url: '/landing-page-details/view-landing-detail/:landing_id',
            },
          ],
        },
      },
      {
        path: 'user-order-history/particular-order/:orderId',
        component: UserParticularOrderComponent,
        data: {
          title: 'Order Details',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Order details',
              url: '/user-order-history/particular-order/:orderId',
            },
          ],
        },
      },
      {
        path: 'tax-list',
        component: TaxListComponent,
        data: {
          title: 'Tax Details',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Tax Details',
              url: '/tax-list',
            },
          ],
        },
      },
      {
        path: 'refund-canceled-order',
        component: RefundCancelOrderComponent,
        data: {
          title: 'Refund Cancelled Order',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Refund Cancelled Order',
              url: '/refund-canceled-order',
            },
          ],
        },
      },
      {
        path: 'refund-list',
        component: RefundListComponent,
        data: {
          title: 'Refund List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Refund List',
              url: '/refund-list',
            },
          ],
        },
      },
      {
        path: 'refund-tax-list',
        component: RefundListTaxComponent,
        data: {
          title: 'Refund Tax List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Refund Tax List',
              url: '/refund-tax-list',
            },
          ],
        },
      },
      {
        path: 'add-voucher',
        component: AddVoucherComponent,
        data: {
          title: 'Add Voucher',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Add Voucher',
              url: '/add-voucher',
            },
          ],
        },
      },
      {
        path: 'add-voucher-list',
        component: AddVoucherListComponent,
        data: {
          title: 'Voucher List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Voucher List',
              url: '/add-voucher-list',
            },
          ],
        },
      },
      {
        path: 'order-pay-link-list',
        component: PayLinkOrdersComponent,
        data: {
          title: 'Order With Pay Link List',
          breadcrumb: [
            {
              title: 'Dashboard',
              url: '/',
            },
            {
              title: 'Order With Pay Link',
              url: '/order-pay-link-list',
            },
          ],
        },
      },
      {
        path: 'notifications',
        component: NotificationViewComponent,
        data: {
          title: 'Notifications'
        }
      },
      {
        path: 'user-reminder',
        component: UserReminderComponent,
        data: {
          title: 'Reminder'
        }
      }
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'forgot-password',
    component: ForgetPasswordComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'reset-password/:emailAdd',
    component: ResetPasswordComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

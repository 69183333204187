<div class="container">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label for="class_name">Destination</label>
                    <select class="form-control" name="destination" [(ngModel)]="desti">
                        <option value="" selected>Select Destination
                        </option>
                        <option *ngFor="let item of alldesti; let i = index" value="{{item._id}}">
                            {{item.destination}} </option>
                    </select>

                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                        style="margin-top:47px; margin-left:17px;" value="tour" [checked]="true" [(ngModel)]="radio">
                    <label class="form-check-label" style="margin-top:38px;margin-left:40px;">
                        Only Tour
                    </label>
                </div>

            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                        style="margin-top:47px; margin-left:17px;" value="gallery" [checked]="true" [(ngModel)]="radio">
                    <label class="form-check-label" style="margin-top:38px;margin-left:40px;">
                        Only Gallery
                    </label>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                        style="margin-top:47px; margin-left:17px;" value="both" [checked]="true" [(ngModel)]="radio">
                    <label class="form-check-label" style="margin-top:38px;margin-left:40px;">
                        Both
                    </label>
                </div>

            </div>

        </div>

        <!-- <div class="row justify-content-center mb-3">
          <div class="col-md-3 d-flex justify-content-center">
              
              <div class="form-check mr-2" *ngIf="mainCat === 'Home' && allBusinessCategory.length === 0">
                  <input type="checkbox" class="form-check-input" [checked]="is_free" (change)="is_free = !is_free"
                      id="checkFree">
                  <label class="form-check-label" for="checkFree">Is Free</label>
              </div>
              <div class="form-check mr-2">
                  <input type="checkbox" class="form-check-input" [checked]="status" (change)="status = !status"
                      id="checkStatus">
                  <label class="form-check-label" for="checkStatus">Active</label>
              </div>
          </div>
      </div> -->
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="droparea" (dragover)="onDragOver($event)" (drop)="onDrop($event)"
                    (dragleave)="onDragLeave($event)" [class.active]="isActive">
                    <input type="file" name="userfiles" accept="image/*" class="browse-file"
                        (change)="onSelectedFile($event)" multiple />
                    <h4>Choose Images or <br /> Drag and Drop Images Here <br /> (.jpg, .jpeg, .png)</h4>
                </div>
                <div class="mt-2" *ngIf="uploadProgress?.progress > 0 && uploadProgress?.progress != 100">
                    <div class="progress" style="height: 20px;margin-bottom:20px;">
                        <div class="progress-bar progress-bar-striped bg-info" role="progressbar"
                            [style.width.%]="uploadProgress?.progress" aria-valuenow="25" aria-valuemin="0"
                            aria-valuemax="100">{{uploadProgress?.progress}}%</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3" *ngIf="getUploadData">
            <div class="col-md-2" *ngFor="let item of getUploadData">
                <div class="card">
                    <img class="card-img-top" height="171px" [src]="getImg(item.gallery_image[0]?.src)" alt="Card image">
                    <div class="card-body p-1 text-center">
                        <p style="margin-bottom:0px;" *ngIf="item.is_Both === 'true' ">Both</p>
                        <p style="margin-bottom:0px;" *ngIf="item.is_Gallery === 'true' ">Gallrey</p>
                        <p style="margin-bottom:0px;" *ngIf="item.is_Tour === 'true' ">Tour</p>
                        <!-- <small class="card-text">Type - {{ item.category_type }}</small><br />
                        <small class="card-text">Category - {{ item.category_name }}</small><br />
                        <small class="card-text">Language - {{ item.language_name }}</small><br />
                         <small class="card-text">Both - {{ item.is_Both === 'true' ? "Yes" : "No" }}</small><br /> 
                        -->

                        <small class="card-text">Status - {{ item.status | titlecase }}</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
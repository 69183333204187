import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/utils/services/api.service';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.scss']
})
export class BlogListComponent implements OnInit, OnDestroy {

  dtOptions: DataTables.Settings = {};
  allBlogs: any;
  render: number;
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;

  constructor(
    private apiService: ApiService, 
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    const that = this;

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };
    this.render = 1;
    this.showBlogs();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  async showBlogs() {
    const that = this;
    await this.apiService.getAllBlogs().subscribe((resp: any) => {
        that.allBlogs = resp.data;
      if(this.render === 1) {
        this.dtTrigger.next();
      }
      this.render = 0;
    });
  }

  
  async changeStaus(id, status) {
    if (id == undefined || id == '') {
      //this.toastr.error(' Id is Invalid');
      Swal.fire({
        text: 'Id is Invalid',
        icon: 'error',
      });
      return false;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure, you want to change status?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        let getStatus = {};
        getStatus =
          status === 'active' ? { status: 'inactive' } : { status: 'active' };
        this.apiService
          .changeStausById(id, JSON.stringify(getStatus))
          .subscribe((result: any) => {
            if (result.success) {
              Swal.fire({
                text: result.message,
                icon: 'success',
              });
              //this.toastr.success(result.message);
              this.showBlogs();
            } else {
              Swal.fire({
                text: result.message,
                icon: 'error',
              });
              //this.toastr.error(result.message);
            }
          });
      }
    });
  }

}

import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
//import { Router } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-other-charges',
  templateUrl: './other-charges.component.html',
  styleUrls: ['./other-charges.component.scss']
})
export class OtherChargesComponent implements OnInit {
  addOtherChargeForm: FormGroup;
  public cancelCatRoute: string;
  private orderid: any;
  getData: any;
  public isAuthLoading = false;
  public isAuthDisabled = false;
  private _userId: string = localStorage.getItem('id');

  constructor(
    private formBuilder: FormBuilder,
    //private router: Router,
    private apiService: ApiService,
    //private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.addOtherChargeForm = new FormGroup({
      cgst: new FormControl(),
      sgst: new FormControl(),
      igst: new FormControl(),
      transactional_charge: new FormControl(),
      status: new FormControl(),
      // user_id: new FormControl(),
      //display_sequence: new FormControl()
    });

    this.orderid = this.route.snapshot.paramMap.get('order_id');
    // console.log(this.news_id, this.news_type);
    if (
      this.orderid != undefined &&
      this.orderid != null &&
      this.orderid != ''
    ) {
      this.editCharge(this.orderid);
      console.log(this.orderid, 'hiiiiiiii');
      
    } else {
      this.addOtherChargeForm.get('status').setValue('active');
    }
  }

  editCharge(id) {
    let dataForForm = {
      id: id,
    };
    this.apiService.orderChargeID(dataForForm).subscribe((result: any) => {
      console.log(result.getData);
      if (result.success) {
        this.addOtherChargeForm.patchValue({
          cgst: result.getData.cgst,
          sgst: result.getData.sgst,
          igst: result.getData.igst,
          transactional_charge: result.getData.transactional_charge,
        });
        if (result.getData.status == 'active') {
          this.addOtherChargeForm.get('status').setValue('active');
        }
        //  if(blogData.status == "active") {
        //   this.addBlogForm.get('status').setValue('active');
        // }
      }
    });
  }

  addOrderCharge() {
  if (
      this.addOtherChargeForm.value.sgst == undefined ||
      this.addOtherChargeForm.value.sgst == ''
    ) {
      Swal.fire({
        text: 'Please enter CGST',
        icon: 'warning',
      });
      return false;
      }

    if (
      this.addOtherChargeForm.value.cgst == undefined ||
      this.addOtherChargeForm.value.cgst == ''
    ) {
      Swal.fire({
        text: 'Please enter SGST',
        icon: 'warning',
      });
      return false;
     }
     
     if (
      this.addOtherChargeForm.value.igst == undefined ||
      this.addOtherChargeForm.value.igst == ''
    ) {
      Swal.fire({
        text: 'Please enter IGST',
        icon: 'warning',
      });
      return false;
     }

       if (
      this.addOtherChargeForm.value.transactional_charge == undefined ||
      this.addOtherChargeForm.value.transactional_charge == ''
    ) {
      Swal.fire({
        text: 'Please enter transaction charge',
        icon: 'warning',
      });
      return false;
      }
    
    if (this.addOtherChargeForm.status == 'active') {
      this.addOtherChargeForm.get('status').setValue('active');
    }
    if (this.orderid != undefined && this.orderid != null && this.orderid != '') {
      this.updateOrd(this.orderid);
    } else {
      this.addOtherChargeForm.value.user_id = this._userId;
      this.isAuthLoading = true;
      this.isAuthDisabled = true;
      this.apiService
        .addorder(this.addOtherChargeForm.value)
        .subscribe((res: any) => {
          console.log(res);
          if (res.success) {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.success(res.message);
            Swal.fire({
              text: res.message,
              icon: 'success',
            });
            this.router.navigateByUrl('/other-charges-list');
          } else {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.error(res.message);
            Swal.fire({
              text: res.message,
              icon: 'error',
            });
          }
        });
    }
  }
    updateOrd(id) {
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    this.apiService
      .orderUpdate(this.addOtherChargeForm.value, id)
      .subscribe((res: any) => {
        if (res.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          //this.toastr.success(res.message);
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          this.router.navigateByUrl('/other-charges-list');
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
          //this.toastr.error(res.message);
        }
      });
  }
   onlyNumberKey(evt: KeyboardEvent) {
    // Only ASCII character in that range allowed
    let ASCIICode = (evt.which) ? evt.which : evt.keyCode;
    return (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) ? false : true;
  }
}

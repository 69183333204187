import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import * as bootstrap from "bootstrap";
import * as $AB from "jquery";

@Component({
  selector: 'app-user-history',
  templateUrl: './user-history.component.html',
  styleUrls: ['./user-history.component.scss'],
})
export class UserHistoryComponent implements OnInit {
  addTourForm: FormGroup;
  public SubCats: any;
  allData: any;
  allCoupan: any;
  allOrder: any = {};
  webID: any;
  allTour: any;
  getData: any;
  payment_status: string = '';
  //appliedCoupon: string = '';
  gstNumber: any;
  cusGSTNo: string = '';
  public cuscompanyName : string ='';
  public cuscompanyAddress : string = '';
  bookingNote: string = '';
  public allCheckoutTourData: any;
  public Object = Object;
  public multipleBoarded: any = [];
  public finalPrice: number = 0;
  //public tourAllData: any = {};
  public packages: any;
  public rooms: any;
  public boardings: any;
  public allTourData: any;
  public allOtherCharges: any;
  public readOnly: boolean = false;
  public isAuthLoading: boolean = false;
  public isAuthDisabled: boolean = false;
  public basicPrice: number = 0;
  public packPrice: number = 0;
  public roomPrice: number = 0;
  public boardPrice: number = 0;
  public no_of_traveller: number = 0;
  public totalPrice: number = 0;
  public amtWithTransactionalCharge: number;
  public isPartialPay: boolean = false;
  public amtWithCGST: number;
  public amtWithSGST: number;
  public amtWithIGST: number;
  public totalAmountToPaid: number;
  FinalBooking: boolean = false;
  couponmessage: boolean = false;
  public appliedCoupon: string = '';
  public discountedPriceByCoupon: number = 0;
  public isCouponApplied: boolean = false;
  public isCouponNotApplied: boolean = false;
  tourid: any;
  public bikeopt: any;
  public packTypeSelected: string;
  public packBikePrice: number = 0;
  public allPackData: any[] = [];
  public allRoomData: any[] = [];
  public totalTraveller: number = 0;

  public selectedTour: any = '';
  public ifTourNotSelected: any = false;

  public idForPackage: any;

  public couponRes: any;

//public appliedCoupon: string = '';
  @ViewChild('one') d1: ElementRef;
  @ViewChild('dateSchedule') dateSchedule: ElementRef;
  @ViewChild('package') package: ElementRef;
  @ViewChild('solo') solo: ElementRef;
  @ViewChild('share') share: ElementRef;
  @ViewChild('roomShare') roomShare: ElementRef;
  @ViewChild('boardingPt') boardingPt: ElementRef;
  @ViewChild('boardingMulti') boardingMulti: ElementRef;
  @ViewChild('numberOfTraveller') numberOfTraveller: ElementRef;
  private toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });



  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.addTourForm = new FormGroup({
      tour_id: new FormControl(),
      travel_date: new FormControl(),
      //travel_packageType: new FormControl(),
      //travel_boarding_point: new FormControl(),
      // travel_no_of_traveler: new FormControl(),
      // finalPrice: new FormControl(),
      status: new FormControl(),
    });
    this.addTourForm = this.formBuilder.group({
      tour_id: ['', [Validators.required]],
      travel_date: ['', [Validators.required]],
      //travel_packageType: ['', [Validators.required]],
      //travel_no_of_traveler: ['', [Validators.required]],
      //travel_boarding_point: ['', [Validators.required]],
      //finalPrice: ['', [Validators.required]],
      status: [false],
    });
    this.webID = this.route.snapshot.paramMap.get('id');
    this.editBoard(this.webID);
    //this.board();
    this.activeTour();
    this.appliedCharges();
    this.CoupanList();
  }

  appliedCharges() {
    this.apiService.orderList().subscribe((res: any) => {
      //console.log(res);
      if (res.success) {
        this.allOtherCharges = res.data[0];
        this.calculateAllExtraCharges();
      } else {
        console.error(res);
      }
    });
  }

  getBoardingPrice(getBoardingData: any) {
    const allBoadingDataWithPrices = JSON.parse(getBoardingData);
    const BoardPrice = allBoadingDataWithPrices.filter(
      (board: any) =>
        board.bp === this.allCheckoutTourData?.travel_boarding_point?.boarding
    )[0]?.bp_price;
    this.allCheckoutTourData.travel_boardingPrice =
      BoardPrice.length === 1
        ? BoardPrice[0].singleBoardPrice
        : Object.values(
            BoardPrice.filter(
              (boardPrice: any) =>
                Object.keys(boardPrice)[0] ===
                this.allCheckoutTourData?.boardingType
            )[0]
          )[0];
  }

  activeTour() {
    this.apiService.addcompleteActive().subscribe((res: any) => {
      //console.log(res, 'heeeeeeee');
      this.allTour = res.getData;
    });
  }

  CoupanList() {
    this.apiService.activeCoupanList().subscribe((res: any) => {
      console.log(res, 'heeeeeeee');
      this.allCoupan = res.data;

    });
  }

  getAllStates() {
    this.apiService.getStates().subscribe((res: any) => {
      //console.log(res);
      const getStateData = res.filter(data => data.iso2 === this.allData.state);
      //console.log(getStateData);
      [this.allData.stateData] = getStateData;
    });
  }

  getAllPackageData(data: any) {
    this.apiService.getAllPack().subscribe((res: any) => {
      if (res.success) {
        this.packages = res.data;
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < this.packages.length; j++) {
            if (data[i].packType === this.packages[j].package_type)
              data[i].id = this.packages[j]._id;
          }
        }
        // console.error(data);
        this.allOrder.packageType_price = data;
        const nonBikePack = data.filter((pack) => (pack.packPrice !== 'solo-bike' && pack.packPrice !== 'share-bike'));
        console.log(data,'dattaa', nonBikePack);
        if (nonBikePack.length)
          this.allPackData.push(...nonBikePack.map((pack) => {return {...pack, type: 'normal', persons: 0}}));
        this.reorderPackage(this.allPackData);
      } else {
        console.error(res);
      }
    });
  }

  getAllRoomShareData(data: any) {
    //console.log(data);
    this.apiService.AllRooms().subscribe((res: any) => {
      if (res.success) {
        this.rooms = res.data;
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < this.rooms.length; j++) {
            if (data[i].roomType === this.rooms[j].room_name)
              data[i].id = this.rooms[j]._id;
          }
        }
        this.allOrder.roomSharing_price = data;
        this.allRoomData.push(...data.map(room => {return {...room, persons: 0}}));
        this.allRoomData.sort((a,b) => (+a.roomPrice > +b.roomPrice) ? 1 : ((+b.roomPrice > +a.roomPrice) ? -1 : 0));
      } else {
        console.error(res);
      }
    });
  }

  getPackageData(data: string) {
    const id = { id: data };
    this.apiService.getPackId(id).subscribe((res: any) => {
      if (res.success) {
        //console.error(res,'All Package');
        this.allCheckoutTourData.travel_packageType = res.getData.package_type;

          //this.getAllBikePackageDataID(this.allCheckoutTourData.travel_packageType);

          let packageType_price = JSON.parse(this.allTourData.packageType_price);
          this.allCheckoutTourData.travel_packagePrice = packageType_price.filter(
            (pack: any) =>
              pack.packType === this.allCheckoutTourData.travel_packageType
          )[0]?.packPrice;

        // if(this.allCheckoutTourData.travel_packageType === 'Solo bike' || this.allCheckoutTourData.travel_packageType === 'Shared bike'){
        //   this.getAllBikePackageDataID(this.allCheckoutTourData.travel_packageType);
        // }else{
        //   let packageType_price = JSON.parse(this.allTourData.packageType_price);
        //   this.allCheckoutTourData.travel_packagePrice = packageType_price.filter(
        //     (pack: any) =>
        //       pack.packType === this.allCheckoutTourData.travel_packageType
        //   )[0]?.packPrice;
        // }
      } else {
        console.error(res);
      }
    });
  }

  getAllBikePackageDataID(type: string) {
    const bikeId = type === 'Solo bike' ? this.allCheckoutTourData.travel_soloPackageType : this.allCheckoutTourData.travel_sharedPackageType;
    const id = { id: bikeId };
    this.apiService.getBikeId(id).subscribe((res: any) => {
      //console.log(res);
      if (res.success) {
        this.allCheckoutTourData.travel_bikeType = res.getData.bike_name;
        let bikeType_price = type === 'Solo bike' ? JSON.parse(this.allTourData.soloType_price) : JSON.parse(this.allTourData.shareType_price);
          let bikeData = bikeType_price.filter(
            (bike: any) =>
              type === 'Solo bike' ? bike.soloType === this.allCheckoutTourData.travel_bikeType : bike.sharedType === this.allCheckoutTourData.travel_bikeType
          );
          this.allCheckoutTourData.travel_bikePrice = type === 'Solo bike' ? bikeData[0]?.soloPrice : bikeData[0]?.sharedPrice;
      } else {
        console.error(res);
      }
    });
  }

  getAllRoomData(data: string) {
    const id = { id: data };
    this.apiService.getRoomId(id).subscribe((res: any) => {
      if (res.success) {
        //console.log(this.allTourData, this.allCheckoutTourData);
        this.allCheckoutTourData.travel_roomSharing = res.getData.room_name;
        let roomName_price = JSON.parse(this.allTourData.roomSharing_price);
        this.allCheckoutTourData.travel_roomPrice = roomName_price.filter(
          (room: any) =>
            room.roomType === this.allCheckoutTourData.travel_roomSharing
        )[0]?.roomPrice;
        //console.log(this.allTourData, this.allCheckoutTourData)
      } else {
        console.error(res);
      }
    });
  }

  // getAllBikePackageData(data: any, type: string) {
  //   this.apiService.AllBikes().subscribe((res: any) => {
  //     console.log(res, data);

  //     if (res.success) {
  //       this.bikeopt = res.data;
  //       for (let i = 0; i < data.length; i++) {
  //         for (let j = 0; j < this.bikeopt.length; j++) {
  //           if (type === "solo" && data[i].soloType === this.bikeopt[j].bike_name) {
  //             data[i].id = this.bikeopt[j]._id;
  //           } else if (type === "shared" && data[i].sharedType === this.bikeopt[j].bike_name) {
  //             data[i].id = this.bikeopt[j]._id;
  //           }
  //         }
  //       }
  //       if (type === "solo") {
  //         this.allOrder.soloType_price = data;
  //         this.allPackData.push(...data.map(bike => {return {id: bike.id, packType: bike.soloType, type: 'Solo', packPrice: bike.soloPrice, persons: 0}}));
  //       } else if (type === "shared") {
  //         this.allOrder.shareType_price = data;
  //         this.allPackData.push(...data.map(bike => {return {id: bike.id, packType: bike.sharedType, type: 'Shared', packPrice: bike.sharedPrice, persons: 0}}));
  //       }
  //     } else {
  //       console.error(res);
  //     }
  //   });
  // }


  getAllBoardingData(data: any) {
    this.apiService.getAllBoarding().subscribe((res: any) => {
      if (res.success) {
        this.boardings = res.data;
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < this.boardings.length; j++) {
            if (data[i].bp === this.boardings[j].boarding)
              data[i].id = this.boardings[j]._id;
          }
        }
        // console.error(data);
        this.allOrder.boarding_point_price = data;
        return data;
      } else {
        console.error(res);
      }
    });
  }

  onCategorySelect(id) {
    let dataForForm = {
      id: id,
    };
    //console.log(dataForForm,'hiiiiiiiiii');
    // this.apiService.orderID(dataForForm).subscribe((res: any) => {
    //   console.log(res.getData, 'devvvvvvvvvvvvvvvvvv');
    //   this.allOrder = res.getData;
    //   this.allOrder.start_date = JSON.parse(this.allOrder.start_date);
    //   this.allOrder.end_date = JSON.parse(this.allOrder.end_date);
    //   this.allOrder.dateArr = [];
    //   for (let i = 0; i < this.allOrder.start_date.length; i++) {
    //    if ((new Date()).getTime() < (new Date(this.allOrder.start_date[i])).getTime())
    //     this.allOrder.dateArr.push({
    //       startDate: this.allOrder.start_date[i],
    //       endDate: this.allOrder.end_date[i],
    //     });
    //   }
    //   this.allOrder.dateArr.sort(function(a: any,b: any){
    //     return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    //   });
    //   if (this.allOrder.dateArr.length === 0) {
    //       Swal.fire({
    //         title: 'No schedule available for this tour',
    //         text: "Please contact us for more details",
    //         icon: 'error',
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Ok',
    //       });
    //     }
    //   this.allOrder.no_of_alltraveler = Array(+this.allOrder.no_of_traveler).fill(0).map((x, i) => (i + 1));
    //   this.totalPrice = this.basicPrice = +this.allOrder?.basicAmount;

    //   const allPackPriceData = JSON.parse(this.allOrder.packageType_price);
    //   if (this.allOrder.soloType_price.length && JSON.parse(this.allOrder.soloType_price)?.length) {
    //     allPackPriceData.unshift({ packType: 'Solo bike', packPrice: 'solo-bike' });
    //   }
    //   this.getAllBikePackageData(JSON.parse(this.allOrder.soloType_price), "solo");
    //   if (this.allOrder.shareType_price.length && JSON.parse(this.allOrder.shareType_price)?.length) {
    //     allPackPriceData.unshift({ packType: 'Shared bike', packPrice: 'share-bike' });
    //   }
    //   this.getAllBikePackageData(JSON.parse(this.allOrder.shareType_price), "shared");

    //   this.getAllPackageData(allPackPriceData);
    //   this.getAllRoomShareData(JSON.parse(this.allOrder.roomSharing_price));
    //   this.getAllBoardingData(JSON.parse(this.allOrder.boarding_point_price));

    //   // console.log(this.allOrder.roomSharing_price);
    //   // if (this.allOrder.packageType_price.length)
    //   //   this.getAllPackageData(JSON.parse(this.allOrder.packageType_price));
    //   // if (this.allOrder.roomSharing_price.length)
    //   //   this.getAllRoomShareData(JSON.parse(this.allOrder.roomSharing_price));
    //   // this.getAllBoardingData(JSON.parse(this.allOrder.boarding_point_price));
    // });
    this.idForPackage = dataForForm.id;
  }

  handleClick(){
    // console.log(this.ifTourNotSelected, this.selectedTour);
    //console.log(this.idForPackage, "IIIIIIIIDDDDDDDDDDDDDDDDDDD", this.selectedTour);
    if(this.selectedTour == ''){
      Swal.fire({
        text: "Please select a tour.",
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok',
      });
    } else {
      this.ifTourNotSelected = true;
      // this.onCategorySelect(this.idForPackage)
      this.apiService.orderID({id: this.idForPackage}).subscribe((res: any) => {
        console.log(res.getData, 'devvvvvvvvvvvvvvvvvv');
        this.allOrder = res.getData;
        this.allOrder.start_date = JSON.parse(this.allOrder.start_date);
        this.allOrder.end_date = JSON.parse(this.allOrder.end_date);
        this.allOrder.dateArr = [];
        for (let i = 0; i < this.allOrder.start_date.length; i++) {
         if ((new Date()).getTime() < (new Date(this.allOrder.start_date[i])).getTime())
          this.allOrder.dateArr.push({
            startDate: this.allOrder.start_date[i],
            endDate: this.allOrder.end_date[i],
          });
        }
        this.allOrder.dateArr.sort(function(a: any,b: any){
          return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
        });
        if (this.allOrder.dateArr.length === 0) {
            Swal.fire({
              title: 'No schedule available for this tour',
              text: "Please contact us for more details",
              icon: 'error',
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok',
            });
        }
        if(this.allOrder.dateArr.length > 0){
          ($("#staticBackdrop")as any).modal("show");
          this.allOrder.no_of_alltraveler = Array(+this.allOrder.no_of_traveler).fill(0).map((x, i) => (i + 1));
          this.totalPrice = this.basicPrice = +this.allOrder?.basicAmount;

          const allPackPriceData = JSON.parse(this.allOrder.packageType_price);
          this.getAllPackageData(allPackPriceData);
          this.getAllRoomShareData(JSON.parse(this.allOrder.roomSharing_price));
          this.getAllBoardingData(JSON.parse(this.allOrder.boarding_point_price));
        }
        // if (this.allOrder.soloType_price.length && JSON.parse(this.allOrder.soloType_price)?.length) {
        //   allPackPriceData.unshift({ packType: 'Solo bike', packPrice: 'solo-bike' });
        // }
        // this.getAllBikePackageData(JSON.parse(this.allOrder.soloType_price), "solo");
        // if (this.allOrder.shareType_price.length && JSON.parse(this.allOrder.shareType_price)?.length) {
        //   allPackPriceData.unshift({ packType: 'Shared bike', packPrice: 'share-bike' });
        // }
        // this.getAllBikePackageData(JSON.parse(this.allOrder.shareType_price), "shared");
        // console.log(this.allOrder.roomSharing_price);
        // if (this.allOrder.packageType_price.length)
        //   this.getAllPackageData(JSON.parse(this.allOrder.packageType_price));
        // if (this.allOrder.roomSharing_price.length)
        //   this.getAllRoomShareData(JSON.parse(this.allOrder.roomSharing_price));
        // this.getAllBoardingData(JSON.parse(this.allOrder.boarding_point_price));
      });
    }
  }

  handleClose(){
    // this.allOrder = '';
    // this.selectedTour == '';
    // this.ifTourNotSelected = false;
    // this.onCategorySelect('');
    console.log(this.allPackData, this.allRoomData);
    this.allPackData = [];
    this.allRoomData = [];
  }

  editBoard(id) {
    let dataForForm = {
      id: id,
    };
    this.apiService.addWebSiteID(dataForForm).subscribe((result: any) => {
      //console.log(result.getData);
      this.getAllStates();
      this.allData = result.getData;
    });
  }

  reorderPackage(packArr: any[]) {
    packArr.sort((a,b) => (+a.packPrice > +b.packPrice) ? 1 : ((+b.packPrice > +a.packPrice) ? -1 : 0));
    this.allPackData = packArr.map((pack, i)=>{
      if (i===0) return {...pack, persons: 0}
      else return {...pack, persons: 0}
    });
    this.calculatePrice();
  }

  addRemovePersons(obj: any, type: string) {
    if ("roomType" in obj && this.totalTraveller <= this.allRoomData.reduce((total, data) => total + data.persons, 0)) {
      this.toast.fire({
        icon: 'error',
        title: 'Add travellers to add rooms',
      });
      // return;
    }
    if ("packType" in obj) {
      this.allRoomData.map(room => room.persons = 0);
    }
    if (type === 'add' && this.totalTraveller > this.allRoomData.reduce((total, data) => total + data.persons, 0)) obj.persons++
    else if ("packType" in obj && type === 'add' && this.totalTraveller == 0 && this.allRoomData.reduce((total, data) => total + data.persons, 0) == 0) obj.persons++
    else if (type === 'minus' && obj.persons > 0) obj.persons--
    this.calculatePrice();
  }

  getSingleBoardPrice(value: string) {
    const boardPoint = this.allOrder.boarding_point_price.filter(
      (arrEle) => arrEle.bp === value
    );
    if (boardPoint[0].length !== 0 && boardPoint[0].bp_type === 'single')
     return `(₹${boardPoint[0].bp_price[0]?.singleBoardPrice}) Per Person`;
    else return '';
  }


  calculatePrice() {
    this.finalPrice = 0;
    const totalPriceByPackage = this.allPackData.reduce((total, data) => {
      return total + data.packPrice*data.persons
    }, 0);
    const totalPriceByRoom = this.allRoomData.reduce((total, data) => {
      return total + data.roomPrice*data.persons
    }, 0);
    this.totalTraveller = this.allPackData.reduce((total, data) => {
      return total + data.persons
    }, 0);
    this.finalPrice = totalPriceByPackage + totalPriceByRoom + this.totalTraveller*this.boardPrice;
  }


  onOptionSelect(value: any, type: string) {
    this.totalPrice = this.basicPrice;
    value =
      type !== 'traveller'
        ? value === ''
          ? '0'
          : value
        : value === ''
        ? '1'
        : value;
    //console.log(value);
    if (type === 'pack') {
      if (!isNaN(value)) {
        this.packPrice = +value;
        this.packBikePrice = 0;
        this.packTypeSelected = '';
      } else {
        this.packPrice = 0;
        this.packBikePrice = 0;
        this.packTypeSelected = value;
      }
    }
    if (this.packTypeSelected === "solo-bike" && type === "solo") {
      this.packBikePrice = +value;
    } else if (this.packTypeSelected === "share-bike" && type === "share") {
      this.packBikePrice = +value;
    }
    if (type === 'room') {
      this.roomPrice = +value;
    }
    if (type === 'traveller') {
      this.no_of_traveller = +value;
    }
    if (type === 'board') {
      if (value === '0') {
        this.boardPrice = 0;
        this.multipleBoarded = [];
      } else {
        this.multipleBoarded = this.allOrder.boarding_point_price.filter(
          (arrEle) => arrEle.bp === value
        );
        if (
          this.multipleBoarded.length !== 0 &&
          this.multipleBoarded[0]?.bp_type === 'single'
        ) {
          this.boardPrice =
            +this.multipleBoarded[0]?.bp_price[0]?.singleBoardPrice;
        } else {

          //console.log(this.multipleBoarded, this.multipleBoarded[0]);
          if (this.multipleBoarded[0]?.bp_price[4]?.Flight) {
            this.boardPrice = +this.multipleBoarded[0]?.bp_price[4]?.Flight;
          }
          if ((this.multipleBoarded[0]?.bp_price[3])['1AC']) {
            this.boardPrice = +(this.multipleBoarded[0]?.bp_price[3])['1AC'];
          }
          if ((this.multipleBoarded[0]?.bp_price[2])['2AC']) {
            this.boardPrice = +(this.multipleBoarded[0]?.bp_price[2])['2AC'];
          }
          if ((this.multipleBoarded[0]?.bp_price[1])['3AC']) {
            this.boardPrice = +(this.multipleBoarded[0]?.bp_price[1])['3AC'];
          }
          if (this.multipleBoarded[0]?.bp_price[0]?.Sleeper) {
            this.boardPrice = +this.multipleBoarded[0]?.bp_price[0]?.Sleeper;
          }
          //console.log(this.boardPrice);
        }
      }
    }
    if (type === 'boardMulti') {
      this.boardPrice = +value;
    }
   // console.log(this.packPrice, this.packBikePrice, this.roomPrice, this.boardPrice);
    this.totalPrice += this.packPrice + this.packBikePrice + this.roomPrice + this.boardPrice;
    this.totalPrice *= this.no_of_traveller;
    this.calculatePrice();
  }


  setChange(){
    const dateSchedule = this.dateSchedule?.nativeElement?.value;
    const boardingPt =
      this.boardingPt?.nativeElement?.options[
        this.boardingPt?.nativeElement?.selectedIndex
      ].dataset.getboardid;
    const boardingMulti =
      this.boardingMulti?.nativeElement?.options[
        this.boardingMulti?.nativeElement?.selectedIndex
      ].text;
    const packageDataWithTraveller = this.allPackData.filter(val => val.persons !== 0);
    const numberOfTraveller = this.totalTraveller;

    // if (this.allOrder.dateArr.length === 0) {
    //   Swal.fire({
    //     title: 'No schedule available for this tour',
    //     width: 400,
    //     text: 'Please contact us for more details',
    //     icon: 'error',
    //     confirmButtonText: 'Ok',
    //   });
    //   return;
    // }
    if (dateSchedule === '') {
      Swal.fire({
        text: 'Please select your tour date',
        icon: 'error',
      });
      // this.allPackData = [];
      // this.allRoomData = [];
      return;
    }
    if (packageDataWithTraveller.length === 0) {
      Swal.fire({
        text: 'Please add travellers to the package',
        icon: 'error',
      });
      // this.allPackData = [];
      // this.allRoomData = [];
      return;
    }
    if (boardingPt === undefined) {
      Swal.fire({
        text: 'Please select your boarding point',
        icon: 'warning',
      });
      // this.allPackData = [];
      // this.allRoomData = [];
      return;
    }
    if (numberOfTraveller === 0) {
      Swal.fire({
        text: 'Please add travellers',
        icon: 'error',
      });
      // this.allPackData = [];
      // this.allRoomData = [];
      return;
    }

    // this.spinner.show();
    const orderDetails = {
      user_id: this.webID,
      tour_id: this.allOrder._id,
      travel_date: dateSchedule,
      tourDate: `${dateSchedule.split('-')[0]?.split('/')[1]}/${
        dateSchedule.split('-')[0]?.split('/')[0]
      }/${dateSchedule.split('-')[0]?.split('/')[2]}`,
      // travel_packageType: packageType ? packageType : '',
      // travel_soloPackageType: packageSoloType ? packageSoloType : '',
      // travel_sharedPackageType: packageShareType ? packageShareType : '',
      // travel_roomSharing: roomName ? roomName : '',
      travel_packageType_data: this.allPackData,
      travel_roomSharing_data: this.allRoomData,
      travel_boarding_point: boardingPt,
      boardingType: boardingMulti ? boardingMulti.split(" ")[0] : '',
      travel_no_of_traveler: numberOfTraveller,
      old_travel_no_of_traveler: numberOfTraveller,
      finalPrice: this.finalPrice,
      status: 'active',
    };
    console.log(orderDetails);
    //return;
    // let notificationData = {
    //   applyTour_id: this.allOrder._id,
    //   last_amount_paid : this.isPartialPay
    //   ? +this.allTourData?.bookPrice *
    //     +this.allCheckoutTourData?.travel_no_of_traveler
    //   : this.totalAmountToPaid,
    //   is_new: true,
    //   status: "",
    //   payment_status: ""
    // }
    this.spinner.show();
      this.apiService.AdminTour(orderDetails).subscribe((res: any) => {
        if (res.success) {
          ($("#staticBackdrop")as any).modal("hide");

          this.spinner.hide();
          this.FinalBooking = true;
          this.getCheckoutTourDetails(res.objId);
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          // notificationData = {...notificationData, payment_status: "Paid"}
          //         console.log(notificationData, "NOTIFICATION!!!");

          // this.apiService.postNotification(notificationData).subscribe((res: any) => {
          //   if(res){
          //     console.log(res);
          //   }
          // })
        } else {
          this.spinner.hide();
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
        }
      });
  }



   ////////////////////////oldcodeforconfirmtour//////////////////////////////////////
  // setChange() {
  //   const dateSchedule = this.dateSchedule?.nativeElement?.value;
  //   let packageSoloType;
  //   let packageShareType;
  //   const packageType = this.package?.nativeElement?.options[
  //     this.package?.nativeElement?.selectedIndex
  //   ].dataset.getpackid;
  //   if (this.packTypeSelected === "solo-bike") {
  //     packageSoloType =
  //     this.solo?.nativeElement?.options[
  //       this.solo?.nativeElement?.selectedIndex
  //     ].dataset.getsoloid;
  //   } else if (this.packTypeSelected === "share-bike") {
  //     packageShareType =
  //     this.share?.nativeElement?.options[
  //       this.share?.nativeElement?.selectedIndex
  //     ].dataset.getsharedid;
  //   }
  //   const roomName = this.roomShare?.nativeElement?.options[
  //       this.roomShare?.nativeElement?.selectedIndex
  //   ].dataset.getroomid;
  //   const boardingPt = this.boardingPt?.nativeElement?.options[
  //     this.boardingPt?.nativeElement?.selectedIndex
  //   ].dataset.getboardid;
  //   const boardingMulti = this.boardingMulti?.nativeElement?.options[
  //     this.boardingMulti?.nativeElement?.selectedIndex
  //   ].text;
  //   const numberOfTraveller = this.numberOfTraveller?.nativeElement?.value;
  //   if (dateSchedule === '') {
  //     Swal.fire({
  //       text: 'Please select your tour date',
  //       icon: 'warning',
  //     });
  //     return false;
  //   }
  //   if (this.allOrder.packageType_price.length !== 0) {
  //     if (packageType === undefined) {
  //       Swal.fire({
  //         text: 'Please select your package type',
  //         icon: 'warning',
  //       });
  //       return false;
  //     }
  //   }
  //   if (this.packTypeSelected === "solo-bike") {
  //     if (packageSoloType === undefined) {
  //       Swal.fire({
  //         text: 'Please select your solo bike type',
  //         icon: 'warning'
  //       });
  //       return;
  //     }
  //   } else if (this.packTypeSelected === "share-bike") {
  //     if (packageShareType === undefined) {
  //       Swal.fire({
  //         text: 'Please select your shared bike type',
  //         icon: 'warning'
  //       });
  //       return;
  //     }
  //   }
  //   if (this.allOrder.roomSharing_price.length !== 0) {
  //     if (roomName === undefined) {
  //       Swal.fire({
  //         text: 'Please select your room name',
  //         icon: 'warning',
  //       });
  //       return;
  //     }
  //   }
  //   if (boardingPt === undefined) {
  //     Swal.fire({
  //       text: 'Please select your boarding point',
  //       icon: 'warning',
  //     });
  //     return false;
  //   }
  //   if (numberOfTraveller === '') {
  //     Swal.fire({
  //       text: 'Please select number of travellers',
  //       icon: 'warning',
  //     });
  //     return false;
  //   }
  //   const orderDetails = {
  //     user_id: this.webID,
  //     tour_id: this.allOrder._id,
  //     travel_date: dateSchedule,
  //     tourDate: `${(dateSchedule.split('-')[0])?.split('/')[1]}/${(dateSchedule.split('-')[0])?.split('/')[0]}/${(dateSchedule.split('-')[0])?.split('/')[2]}`,
  //     travel_packageType: packageType ? packageType : '',
  //     travel_soloPackageType: packageSoloType ? packageSoloType : '',
  //     travel_sharedPackageType: packageShareType ? packageShareType : '',
  //     travel_roomSharing: roomName ? roomName : '',
  //     travel_packageType_data: this.allPackData,
  //     travel_roomSharing_data: this.allRoomData,
  //     travel_boarding_point: boardingPt,
  //     boardingType: boardingMulti ? boardingMulti.split(" ")[0] : '',
  //     travel_no_of_traveler: numberOfTraveller,
  //     finalPrice: this.finalPrice,
  //     status: 'active',
  //   };
  //   this.spinner.show();
  //   this.apiService.AdminTour(orderDetails).subscribe((res: any) => {
  //     if (res.success) {
  //       this.spinner.hide();
  //       this.FinalBooking = true;
  //       this.getCheckoutTourDetails(res.objId);
  //       Swal.fire({
  //         text: res.message,
  //         icon: 'success',
  //       });
  //     } else {
  //       this.spinner.hide();
  //       Swal.fire({
  //         text: res.message,
  //         icon: 'error',
  //       });
  //     }
  //   });
  // }

  onCoupanSelect(couponcode) {
    const couponDetail = {
      user_id: this.webID,
      coupon: this.appliedCoupon,
      tour_price: this.allCheckoutTourData?.finalPrice,
      tour_id: this.allCheckoutTourData?.tour_id?._id,
    };
    //console.log(this.allCheckoutTourData?.finalPrice);
    this.finalPrice = +this.allCheckoutTourData?.finalPrice;
    //console.log(this.finalPrice );
    this.calculateAllExtraCharges();
    this.apiService.Coupan(couponDetail).subscribe((res: any) => {
      if (res.success) {
        Swal.fire({
          text: res.message,
          icon: 'success',
        });
        //this.couponmessage = true;
        //this.FinalBooking = true;
        console.log(res);
        this.couponRes = res;
        if (res?.discount_type === 'Fixed Amount') {
          if(res?.coupon_type == "Voucher"){
            this.discountedPriceByCoupon = res?.discountAmount
          } else {
            this.discountedPriceByCoupon = res?.discountAmount * (+this.allCheckoutTourData?.travel_no_of_traveler);
          }
        } else if (res?.discount_type === 'Percentage Discount') {
          this.discountedPriceByCoupon =
            res?.discountAmount;
        }
        // this.discountedPriceByCoupon = res?.discountAmount;
        this.finalPrice -= this.discountedPriceByCoupon;
        //console.log(this.discountedPriceByCoupon, 'hiiiiiiiiiii');

        this.calculateAllExtraCharges();
        this.isCouponNotApplied = false;
        this.isCouponApplied = true;
      } else {
        Swal.fire({
          text: res.message,
          icon: 'error',
        });
        this.isCouponNotApplied = true;
        this.isCouponApplied = false;
        this.discountedPriceByCoupon = 0;
        this.finalPrice = +this.allCheckoutTourData?.finalPrice;
        this.calculateAllExtraCharges();
        console.error(res);
      }
    });
  }

  discardCoupon() {
    this.isCouponNotApplied = false;
    this.isCouponApplied = false;
    this.appliedCoupon = '';
    this.discountedPriceByCoupon = 0;
    this.finalPrice = +this.allCheckoutTourData?.finalPrice;
    this.calculateAllExtraCharges();
  }

  booking(type: String) {
    if (this.payment_status === '') {
      Swal.fire({
        text: 'Please select payment status',
        icon: 'warning',
      });
      return;
    }
    if (this.cusGSTNo !== '' && this.cusGSTNo.length !== 15) {
      Swal.fire({
        title: 'Error!',
        width: 400,
        text: 'Please enter valid GST number',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }
    //console.log(this.cusGSTNo);
    if (this.cusGSTNo  && this.cuscompanyAddress === '') {
      //console.log(this.cusGSTNo);
      Swal.fire({
        title: 'Error!',
        width: 400,
        text: 'Please enter Company Address',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }
    if (this.cusGSTNo  && this.cuscompanyName === '') {
      //console.log(this.cusGSTNo);
      Swal.fire({
        title: 'Error!',
        width: 400,
        text: 'Please enter Company Name',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }
    const orderDetails: any = {
      username: this.allData?.firstname,
      useremail: this.allData?.email,
      user_id: this.webID,
      user_coupon: this.appliedCoupon,
      id: this.tourid,
      payment_status: type === 'booknow' ? this.payment_status : 'Pending',
      cgst: this.allOtherCharges?.cgst,
      sgst: this.allOtherCharges?.sgst,
      igst: this.allOtherCharges?.igst,
      isOuter: this.allData?.stateData?.iso2 === 'MP' ? false : true,
      transctionCharge: this.allOtherCharges?.transactional_charge,
      appliedCoupon: this.discountedPriceByCoupon,
      cusGSTNo: this.cusGSTNo,
      cuscompanyName: this.cuscompanyName,
      cuscompanyAddress: this.cuscompanyAddress,
      bookingNote: this.bookingNote,
      total_amount: this.totalAmountToPaid,
      amount_paid: 0,
      // partial_payment:(+this.basicPrice + (this.packPrice ? +this.packPrice : 0) +
      //     (this.packBikePrice ? +this.packBikePrice : 0) + (this.roomPrice ?
      //     +this.roomPrice : 0) +
      //     +this.boardPrice),
      partial_payment: this.allCheckoutTourData?.travel_packageType_data.reduce((total,pack) => {
        if (pack.persons !== 0) return total + Number(pack.packPrice);
        else return total;
      }, 0),
      bookingPrice:this.allTourData?.bookPrice,


      // receipt: 'Receipt #' + (Math.floor(Math.random() * 10000000) + 1),
    };

    if (type === 'booknow') {
      orderDetails.transction_Id = 'TI' + (Math.floor(Math.random() * 10000000) + 1);
      orderDetails.order_id = 'OI' + (Math.floor(Math.random() * 10000000) + 1);
      orderDetails.amount_paid = this.payment_status === 'Partial' ? (+this.allTourData?.bookPrice * +this.allCheckoutTourData?.travel_no_of_traveler) : this.totalAmountToPaid;
    } else {
      orderDetails.payLinkAmount = this.payment_status === 'Partial' ? (+this.allTourData?.bookPrice * +this.allCheckoutTourData?.travel_no_of_traveler) : this.totalAmountToPaid;
    }

     console.log(orderDetails);
    //return;
    this.spinner.show();
    this.apiService.booking(orderDetails).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        //console.log(res);
        Swal.fire({
          text: res.message,
          icon: 'success',
        });
        if (type === 'booknow') {
          this.router.navigateByUrl('/user-order-history/' + this.webID);
        } else  {
          this.router.navigateByUrl('/order-pay-link-list');
        }

        let notificationData = {
          applyTour_id: this.tourid,
          last_amount_paid : orderDetails.amount_paid,
          is_new: true,
          status: "",
          payment_status: "Paid"
        }

        // notificationData = {...notificationData, payment_status: "Paid"}
        console.log(notificationData, "NOTIFICATION!!!");

        this.apiService.postNotification(notificationData).subscribe((res: any) => {
          if(res){
            console.log(res);
          }
        })
      } else {
        this.spinner.hide();
        //this.spinner.hide();
        //console.log(res);
      }
    });
  }
  //[routerLink]="'/notification/' + data._id"

  getCheckoutTourDetails(checkoutId) {
    const getId = {
      id: checkoutId,
    };
    this.apiService.TourId(getId).subscribe((res: any) => {
      if (res.success) {
        //this.spinner.hide();
        console.log(res);
        this.allCheckoutTourData = res.getData;
        this.finalPrice = +this.allCheckoutTourData?.finalPrice;
        this.tourid = this.allCheckoutTourData._id;
        if (this.allCheckoutTourData.travel_packageType !== '')
          this.getPackageData(this.allCheckoutTourData.travel_packageType);
        if (this.allCheckoutTourData.travel_roomSharing !== '')
          this.getAllRoomData(this.allCheckoutTourData.travel_roomSharing);
        this.allTourData = res.getData.tour_id;
        this.getBoardingPrice(this.allTourData?.boarding_point_price);
        this.calculateAllExtraCharges();
      } else {
        //this.spinner.hide();
        console.log(res);
      }
    });
  }

  calculateAllExtraCharges() {
    if (
      this.allOtherCharges?.transactional_charge &&
      this.allOtherCharges?.cgst &&
      this.allOtherCharges?.sgst &&
      this.allOtherCharges?.igst
      ) {
      // this.amtWithTransactionalCharge = this.finalPrice * (+this.allOtherCharges?.transactional_charge * 0.01);

      if (this.allData?.stateData?.iso2 === 'MP') {
        // this.amtWithCGST = this.finalPrice * (+this.allOtherCharges?.cgst * 0.01);
        this.amtWithCGST = (this.allOtherCharges?.cgst * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.cgst * 0.01)+(this.allOtherCharges?.sgst * 0.01))))
        this.amtWithSGST = (this.allOtherCharges?.sgst * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.cgst * 0.01)+(this.allOtherCharges?.sgst * 0.01))))
        // this.amtWithSGST = this.finalPrice * (+this.allOtherCharges?.sgst * 0.01);
        // this.totalAmountToPaid =
        // +(this.finalPrice +
        // this.amtWithCGST +
        // this.amtWithSGST).toFixed(2);
        this.totalAmountToPaid = this.finalPrice;
      } else {
        // this.amtWithIGST = this.finalPrice * (+this.allOtherCharges?.igst * 0.01);
        this.amtWithIGST = (this.allOtherCharges?.igst * 0.01 * (+this.finalPrice / (1+(this.allOtherCharges?.igst * 0.01))))
        // this.totalAmountToPaid =
        // +(this.finalPrice +
        // this.amtWithIGST).toFixed(2);
        this.totalAmountToPaid = this.finalPrice;
      }
    }
    //console.log(this.totalAmountToPaid);
  }
}

import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import Swal from 'sweetalert2';
import { AppService } from '../../utils/services/app.service';
declare var $: any;
declare var paypal;
declare global {
  interface Date {
    addDays: (d) => any;
  }
}
Date.prototype.addDays = function (d) {
  this.setHours(this.getHours() + d * 24);
  return this;
};
@Component({
  selector: 'app-make-trip',
  templateUrl: './make-trip.component.html',
  styleUrls: ['./make-trip.component.scss'],
})
export class MakeTripComponent implements OnInit {
  addMakeTripForm: FormGroup;
  //public minDate = undefined;
  private tripid: any;
  isShown: boolean = false;
  public isAuthLoading: boolean = false;
  public isAuthDisabled: boolean = false;
  public SubCats: any;
  public allAdve: any;
  public allmenu: any;
  // startDate: any = [];
  // endDate: any = [];
  public alldesti: any;
  public allpackag: any;
  public packageSelected: any = [];
  public allrooms: any = [];
  public allsharedBike: any = [];
  public allsoloBike: any = [];
  banner_start_date: any;
  endDateBanner: any;
  banner_days = 0;
  sidebar_days = 0;
  poster_days = 7;
  vendor_days = 0;
  adventure_type: any;
  packageArr: any;
  data: any;
  todayDate: string;
  private _userId: string = localStorage.getItem('id');
  isAdd: boolean = false;
  isRemove: boolean = false;
  addedNew: boolean = false;
  minDate: string = '';
  index: number = 1;
  dateDiffer: number = 0;
  initStartDate: string = new Date().toISOString().slice(0, 10);
  @ViewChild('addDate', { static: false }) addDate: ElementRef;
  reminderDays: number[] = Array.from({length: 30}, (_, i) => i+1);

  config_ck = {
    uiColor: '#ffffff',
    toolbarGroups: [
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
      { name: 'links' },
      { name: 'insert' },
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
      { name: 'styles' },
      { name: 'colors' },
    ],
    // skin: 'kama',
    resize_enabled: false,
    removePlugins: 'elementspath,save,magicline',
    //extraPlugins: 'justify',
    colorButton_foreStyle: {
      element: 'font',
      attributes: { color: '#(color)' },
    },
    height: 188,
    removeDialogTabs: 'image:advanced;link:advanced',
    removeButtons: 'Subscript,Superscript,Anchor,Source,Table',
    format_tags: 'p;h1;h2;h3;pre;div',
    extraPlugins: 'uploadimage,justify',
   
 /*   uploadUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json',

    // Configure your file manager integration. This example uses CKFinder 3 for PHP.
    filebrowserBrowseUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html',
    filebrowserImageBrowseUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html?type=Images',
    filebrowserUploadUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files',
    filebrowserImageUploadUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Images',*/
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private apiService: ApiService,
    private appService: AppService,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private ngselectmodule: NgSelectModule,
    private route: ActivatedRoute
  ) {
    const current = new Date();
    // this.minDate = {
    //   year: current.getFullYear(),
    //   month: current.getMonth() + 1,
    //   day: current.getDate(),
    // };
  }
  @ViewChild('div') div: ElementRef;
  @ViewChild('importFestImg', { static: false }) importFestImg: ElementRef;
  @ViewChild('importPDFImg', { static: false }) importPDFImg: ElementRef;

  ngOnInit(): void {
    let getDateOnly = new Date().toISOString().split('T')[0];
    this.todayDate = getDateOnly;
    this.addMakeTripForm = new FormGroup({
      title: new FormControl(),
      tour_image: new FormControl(),
      tour_pdf: new FormControl(),
      // start_date: new FormControl(),
      start_date1: new FormControl(),
      // end_date: new FormControl(),
      end_date1: new FormControl(),
      show_date1: new FormControl(),
      boardingArr: new FormControl(),
      adventure_type: new FormControl(),
      destination: new FormControl(),
      packageArr: new FormControl(),
      soloBikeArr: new FormControl(),
      sharedBikeArr: new FormControl(),
      roomArr: new FormControl(),
      //no_of_traveler: new FormControl(),
      orderNote: new FormControl(),
      isHome: new FormControl(),
    });
    this.addMakeTripForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      tour_image: ['', [Validators.required]],
      tour_pdf: ['', [Validators.required]],
      // start_date: [null, Validators.required],
      start_date1: [null, Validators.required],
      // end_date: [null, Validators.required],
      end_date1: [null, Validators.required],
      show_date1: [true],
      boardingArr: ['', [Validators.required]],
      adventure_type: ['', [Validators.required]],
      destination: ['', [Validators.required]],
      packageArr: ['', []],
      soloBikeArr: ['', []],
      sharedBikeArr: ['', []],
      roomArr:['', []],
      orderNote: ['', []],
      isHome: [false],
      //no_of_traveler: ['', [Validators.required]],
      tripType: ['', [Validators.required]],
      lastReminderDay: [1, [Validators.required]]
    });
    this.board();
    this.adventure();
    this.destination();
    this.package();
    this.allActiveRooms();
    this.menulist();
    this.tripid = this.route.snapshot.paramMap.get('trip_id');
    if (this.tripid != undefined && this.tripid != null && this.tripid != '') {
      this.edittrip(this.tripid);
    } else {
      this.addMakeTripForm.get('isHome').setValue('true');
    }
  }

  addMinDate(dateToAdded, addDays) {
    console.log(dateToAdded, "lll");
    let initialStartDate = new Date(dateToAdded);
    initialStartDate.setDate(initialStartDate.getDate() + addDays);
    if (this.index !== 1) {
      this.getAddButton();
    }
    return initialStartDate.toISOString().slice(0, 10);
  }

  getAddButton() {
    this.isAdd = true;
  }

  addvalue() {
    this.index++;
    // this.minDate = this.addMinDate(this.addMakeTripForm.value.start_date1, 1);
    const p: HTMLParagraphElement = this.renderer.createElement('div');
    const val = `
    <div class="row" id="date-${this.index}" >
      <div class="col-md-4">
        <div class="form-group"  style="margin-left: 1px;">
          <input type="date" [min]="initStartDate" class="form-control" id="start_date${this.index}" (change)="addEndDate(${this.index})" formControlName="start_date${this.index}" >
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group" >
          <input type="date"  class="form-control" readonly id="end_date${this.index}" formControlName="end_date${this.index}" >
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="show_date${this.index}" formControlName="show_date${this.index}" checked >
        </div>
      </div>
    </div>
    `;
    p.innerHTML = val;
    this.renderer.appendChild(this.addDate.nativeElement, p);
    this.addMakeTripForm.addControl(
      'start_date' + this.index,
      new FormControl('')
    );
    this.addMakeTripForm.addControl(
      'end_date' + this.index,
      new FormControl('')
    );
    this.addMakeTripForm.addControl(
      'show_date' + this.index,
      new FormControl(true)
    );
    const i = this.index;
    // this.values.push({value: ""});
    this.renderer.listen(
      document.getElementById(`start_date${this.index}`),
      'change',
      (event) => {
        this.addEndDate(this.index);
      }
    );
    this.renderer.listen(
      document.getElementById(`show_date${this.index}`),
      'change',
      (event) => {
        // console.log(i, 'show');
        this.isShowDate(i);
      }
    );
    // this.isShowDate(this.index);
    document
      .getElementById(`start_date${this.index}`)
      .setAttribute('min', this.minDate);
    this.dateDiffer = this.calculateDiff(
      this.addMakeTripForm.value.start_date1,
      this.addMakeTripForm.value.end_date1
    );
    this.isRemove = true;
    this.addedNew = true;
    this.isAdd = false;
  }

  calculateDiff(startDate: string, endDate: string) {
    return Math.ceil(
      (new Date(endDate).getTime() - new Date(startDate).getTime()) /
      (1000 * 3600 * 24)
    );
  }

  isShowDate(i) {
    console.log(i);
    this.addMakeTripForm.get('show_date' + i).setValue(!this.addMakeTripForm.value['show_date' + i]);
  }

  addEndDate(i) {
    this.addMakeTripForm.controls['start_date' + i].setValue(
      (<HTMLInputElement>document.getElementById(`start_date${i}`)).value
    );
    const endDateVal = this.addMinDate(
      (<HTMLInputElement>document.getElementById(`start_date${i}`)).value,
      this.dateDiffer
    );
    this.addMakeTripForm.controls['end_date' + i].setValue(endDateVal);
    (<HTMLInputElement>(
      document.getElementById(`end_date${i}`)
    )).value = endDateVal;
  }

  removevalue(i) {
    this.addMakeTripForm.removeControl('start_date' + i);
    this.addMakeTripForm.removeControl('end_date' + i);
    this.renderer.removeChild(
      this.addDate.nativeElement,
      document.getElementById(`start_date${i}`)
    );
    this.renderer.removeChild(
      this.addDate.nativeElement,
      document.getElementById(`date-${i}`)
    );
    this.renderer.removeChild(
      this.addDate.nativeElement,
      document.getElementById(`end_date${i}`)
    );
    this.index--;
    console.log(i, 'i');
    console.log(this.index, 'index');
    if (this.index === 1) {
      this.isRemove = false;
      this.addedNew = false;
      this.isAdd = false;
      this.addMakeTripForm.get('end_date1').setValue('');
    }
  }

  edittrip(id) {
    let dataForForm = {
      id: id,
    };
    let start_date_1 = '';
    //console.log(this.tripid);
    this.apiService.tourUpdate(dataForForm).subscribe((result: any) => {
      console.log(result.getData);
      if (result.success) {
        let TripData: any = result.getData;
        let date = new Date(TripData.start_date);
        let dateend = new Date(TripData.end_date);
        const getDate = new Date(Date.parse(TripData.start_date));
        // console.log({
        //   day: getDate.getDate(),
        //   month: getDate.getMonth() + 1,
        //   year: getDate.getFullYear(),
        // });
        const getDateEnd = new Date(Date.parse(TripData.end_date));
        // console.log({
        //   day: getDateEnd.getDate(),
        //   month: getDateEnd.getMonth() + 1,
        //   year: getDateEnd.getFullYear(),
        // });
        this.addMakeTripForm.patchValue({
          title: TripData.title,
          orderNote: TripData.orderNote,
          tour_image: TripData.tour_image[0]?.src,
          tour_pdf: TripData.tour_pdf[0]?.src,
          //no_of_traveler: TripData.no_of_traveler,
          adventure_type: TripData.adventure_type._id,
          destination: TripData.destination._id,
          boardingArr: TripData.boarding_point,
          packageArr: TripData.packageType,
          soloBikeArr: TripData.soloBikeType,
          sharedBikeArr: TripData.sharedBikeType,
          roomArr: TripData.roomSharing
          // start_date: {
          //   day: getDate.getDate(),
          //   month: getDate.getMonth() + 1,
          //   year: getDate.getFullYear(),
          // },
          // end_date: {
          //   day: getDateEnd.getDate(),
          //   month: getDateEnd.getMonth() + 1,
          //   year: getDateEnd.getFullYear(),
          // },
        });

        this.addMakeTripForm.patchValue({
          tripType: result.getData.tripType,
        });
        this.addMakeTripForm.patchValue({
          lastReminderDay: result.getData.lastReminderDay,
        })
        //  TripData.start_date = JSON.parse(TripData.start_date);
        //  TripData.start_date.forEach((key, i) => {
        //      this.addElement();
        //     console.log(TripData.start_date);
        //     //this.addMakeTripTwoForm.controls['desc' + this.i]?.setValue(key.desc);
        //   });

        TripData.start_date = JSON.parse(TripData.start_date);
        TripData.end_date = JSON.parse(TripData.end_date);
        if (TripData.show_date?.length) 
          TripData.show_date = JSON.parse(TripData.show_date);
        TripData.start_date.forEach((key, i) => {
          if (i >= this.index) {
            this.addvalue();
          }
        });
        this.dateDiffer = this.calculateDiff(TripData.start_date[0], TripData.end_date[0]);
        this.getAddButton();
        setTimeout(() => {
          TripData.start_date.forEach((key, i) => {
            let yourdate = key.split('/').reverse();
            let tmp = yourdate[2];
            yourdate[2] = yourdate[1];
            yourdate[1] = tmp;
            yourdate = yourdate.join('-');
            start_date_1 = yourdate;
            if (i === 0) {
              this.initStartDate = yourdate;
              this.minDate = this.addMinDate(start_date_1, 1);
            }
            this.addMakeTripForm.controls['start_date' + (i + 1)].setValue(yourdate);
            (<HTMLInputElement>document.getElementById(`start_date${(i + 1)}`)).value = yourdate;
            document.getElementById(`start_date${(i + 1)}`).setAttribute('min', yourdate);
          });
        }, 1000);

        setTimeout(() => {
          TripData.end_date.forEach((key, i) => {
            let yourdate = key.split('/').reverse();
            let tmp = yourdate[2];
            yourdate[2] = yourdate[1];
            yourdate[1] = tmp;
            yourdate = yourdate.join('-');
            this.addMakeTripForm.controls['end_date' + (i + 1)].setValue(yourdate);
            (<HTMLInputElement>document.getElementById(`end_date${(i + 1)}`)).value = yourdate;
          });
        }, 1000);

        setTimeout(() => {
          TripData.show_date.forEach((key, i) => {
            console.log(key, i, "show date");
            this.addMakeTripForm.controls['show_date' + (i + 1)].setValue(key);
            (<HTMLInputElement>document.getElementById(`show_date${(i + 1)}`)).checked = key;
          });
        }, 1000);

        // if (TripData.tripType ==  1) {
        //   this.addMakeTripForm.get('tripType').setValue('1');
        // }
        if (TripData.isHome == true) {
          this.addMakeTripForm.get('isHome').setValue('true');
        }
        setTimeout(() => {
          this.importFestImg.nativeElement.innerText =
            TripData.tour_image[0]?.orgName;
        }, 1000);
        setTimeout(() => {
          this.importPDFImg.nativeElement.innerText =
            TripData.tour_pdf[0]?.orgName;
        }, 1000);

        this.packageSelected = TripData.packageType;
        if (this.packageSelected.includes('Solo bike')) {
          this.getBikesByOpt('solo');
        }
        if (this.packageSelected.includes('Shared bike')) {
          this.getBikesByOpt('shared');
        }
      }
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.addMakeTripForm.get('tour_image').setValue(file);
      this.importFestImg.nativeElement.innerText = event.target.files[0].name;
    }
  }

  onFileChangepdf(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.addMakeTripForm.get('tour_pdf').setValue(file);
      this.importPDFImg.nativeElement.innerText = event.target.files[0].name;
    }
  }

  toggleShow() {
    this.isShown = !this.isShown;
  }

  board() {
    this.apiService.activeBording().subscribe((res: any) => {
      //console.log(res);
      this.SubCats = [];
      console.log(this.SubCats);

      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].status === 'active') {
          this.SubCats.push(res.data[i]);
        }
      }
    });
  }
  menulist(){
    this.apiService.Activemenu().subscribe((res: any) => {
      console.log(res,'menuuu');
      this.allmenu = res.data;
      //console.log(res);
    });
  }

  adventure() {
    this.apiService.activeAdvent().subscribe((res: any) => {
      //console.log(res);
      this.allAdve = res.data;
      //console.log(res);
    });
  }
  destination() {
    this.apiService.activeDestination().subscribe((res: any) => {
      //console.log(res);
      this.alldesti = res.data;
    });
  }
  package() {
    this.apiService.activePackage().subscribe((res: any) => {
      //console.log(res);
      this.allpackag = [];
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].status === 'active') {
          this.allpackag.push(res.data[i]);
        }
      }
    });
  }
  selectedPackage(eve: any) {
    this.packageSelected = eve;
    // if (this.packageSelected.includes('Solo bike')) {
    //   if (this.packageSelected[this.packageSelected.length - 1] === 'Solo bike') {
    //     this.getBikesByOpt('solo');
    //   }
    // } else {
    //   this.allsoloBike = [];
    // }
    // if (this.packageSelected.includes('Shared bike')) {
    //   if (this.packageSelected[this.packageSelected.length - 1] === 'Shared bike') {
    //     this.getBikesByOpt('shared');
    //   }
    // } else {
    //   this.allsharedBike = [];
    // }
  }

  getBikesByOpt(bikeType: string) {
    this.apiService.activeBikesByOpt(bikeType).subscribe((res: any) => {
      if (res.success) {
        console.log(res.data);
        
        if (bikeType === "solo") {
          this.allsoloBike = res.data;
        } else if (bikeType === "shared") {
          this.allsharedBike = res.data;
        }
      } else {
        console.error(res);
      }
    });
  }

  allActiveRooms() {
    this.apiService.activeRooms().subscribe((res: any) => {
      if (res.success) {
        this.allrooms = res.data
      } else {
        console.error(res);
      }
    });
  }

  addtrip() {
    // console.log(this.addMakeTripForm.value, "show");
    // return;
    if (
      this.addMakeTripForm.value.title.trim() == undefined ||
      this.addMakeTripForm.value.title.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter title',
        icon: 'warning',
      });
      return false;
    }

    if (
      this.addMakeTripForm.value.boardingArr == undefined ||
      this.addMakeTripForm.value.boardingArr == ''
    ) {
      Swal.fire({
        text: 'Please select boarding point',
        icon: 'warning',
      });
      return false;
    }
    if (
      this.addMakeTripForm.value.adventure_type == undefined ||
      this.addMakeTripForm.value.adventure_type == ''
    ) {
      Swal.fire({
        text: 'Please select adventure type',
        icon: 'warning',
      });
      return false;
    }
    if (
      this.addMakeTripForm.value.destination == undefined ||
      this.addMakeTripForm.value.destination == ''
    ) {
      Swal.fire({
        text: 'Please select destination',
        icon: 'warning',
      });
      return false;
    }

    // if (this.packageSelected.includes('Shared bike')) {
    //   if (
    //     this.addMakeTripForm.value.sharedBikeArr == undefined ||
    //     this.addMakeTripForm.value.sharedBikeArr == ''
    //   ) {
    //     Swal.fire({
    //       text: 'Please select shared bike',
    //       icon: 'warning',
    //     });
    //     return false;
    //   }
    // }

    // if (this.packageSelected.includes('Solo bike')) {
    //   if (
    //     this.addMakeTripForm.value.soloBikeArr == undefined ||
    //     this.addMakeTripForm.value.soloBikeArr == ''
    //   ) {
    //     Swal.fire({
    //       text: 'Please select solo bike',
    //       icon: 'warning',
    //     });
    //     return false;
    //   }
    // }

    // if (
    //   this.addMakeTripForm.value.no_of_traveler == undefined ||
    //   this.addMakeTripForm.value.no_of_traveler == ''
    // ) {
    //   Swal.fire({
    //     text: 'Please select number of travellar',
    //     icon: 'warning',
    //   });
    //   return false;
    // }

    if (
      this.addMakeTripForm.value.tripType == undefined ||
      this.addMakeTripForm.value.tripType == ''
    ) {
      Swal.fire({
        text: 'Please select trip & trek',
        icon: 'warning',
      });
      return false;
    }

    if (
      this.addMakeTripForm.value.lastReminderDay == undefined ||
      this.addMakeTripForm.value.lastReminderDay == ''
    ) {
      Swal.fire({
        text: 'Please select last reminder day',
        icon: 'warning',
      });
      return false;
    }

    if (
      this.addMakeTripForm.value.start_date1 == undefined ||
      this.addMakeTripForm.value.start_date1 == '' ||
      this.addMakeTripForm.value.start_date1 == null
    ) {
      Swal.fire({
        text: 'Please select start date',
        icon: 'warning',
      });
      return false;
    }

    if (
      this.addMakeTripForm.value.end_date1 == undefined ||
      this.addMakeTripForm.value.end_date1 == '' ||
      this.addMakeTripForm.value.end_date1 == null
    ) {
      Swal.fire({
        text: 'Please select end date',
        icon: 'warning',
      });
      return false;
    }
    
    
    for (let i = 2; i <= this.index; i++) {
      //console.log(i);
      if (
        this.addMakeTripForm.value[`start_date${i}`] == null ||
        this.addMakeTripForm.value[`start_date${i}`] == '' ||
        this.addMakeTripForm.value[`start_date${i}`] == undefined
      ) {
        Swal.fire({
          text: 'Please select start date',
          icon: 'warning',
        });
        return false;
      }
    }

    // if (
    //   this.addMakeTripForm.value.start_date == undefined ||
    //   this.addMakeTripForm.value.start_date == '' ||
    //   this.addMakeTripForm.value.start_date == null
    // ) {
    //   Swal.fire({
    //     text: 'Please select start date',
    //     icon: 'warning',
    //   });
    //   return false;
    // }
    // if (
    //   this.addMakeTripForm.value.end_date == undefined ||
    //   this.addMakeTripForm.value.end_date == '' ||
    //   this.addMakeTripForm.value.end_date == null
    // ) {
    //   Swal.fire({
    //     text: 'Please select end date',
    //     icon: 'warning',
    //   });
    //   return false;
    // }

    if (this.tripid != undefined && this.tripid != '') {
      this.updateFirstTour();
    } else {
      if (
        this.addMakeTripForm.value.tour_image.name == undefined ||
        this.addMakeTripForm.value.tour_image.name == ''
      ) {
        Swal.fire({
          text: 'Please select image',
          icon: 'warning',
        });
        return false;
      }

      const obj = this.addMakeTripForm.value;
      const startDate = [];
      const endDate = [];
      const showDate = [];
      Object.entries(obj).forEach(([key, value], i) => {
        if (key.match(/start_date/))
        //console.log(obj[key],'dateeeeeeee');
          startDate.push(
            obj[key].split('-')[1] +
            '/' +
            obj[key].split('-')[2] +
            '/' +
            obj[key].split('-')[0]
          );
        if (key.match(/end_date/))
          endDate.push(
            obj[key].split('-')[1] +
            '/' +
            obj[key].split('-')[2] +
            '/' +
            obj[key].split('-')[0]
          );
        if (key.match(/show_date/))
          showDate.push(obj[key]);
      });

      // console.log(JSON.stringify(showDate));
      // return; // ------------------------------------------------------------------------------
      // let date = `${this.addMakeTripForm.value.start_date.month}/${this.addMakeTripForm.value.start_date.day}/${this.addMakeTripForm.value.start_date.year}`;

      // let enddate = `${this.addMakeTripForm.value.end_date.month}/${this.addMakeTripForm.value.end_date.day}/${this.addMakeTripForm.value.end_date.year}`;

      let formData = new FormData();
      formData.append('user_id', this._userId);
      formData.append('title', this.addMakeTripForm.get('title').value);
      formData.append(
        'tour_image',
        this.addMakeTripForm.get('tour_image').value
      );
      formData.append('tour_pdf', this.addMakeTripForm.get('tour_pdf').value);
      formData.append('isHome', this.addMakeTripForm.get('isHome').value);
      formData.append('orderNote', this.addMakeTripForm.get('orderNote').value);
      formData.append(
        'adventure_type',
        this.addMakeTripForm.get('adventure_type').value
      );
      formData.append(
        'destination',
        this.addMakeTripForm.get('destination').value
      );
      for (let i = 0; i < this.addMakeTripForm.value.boardingArr.length; i++) {
        formData.append(
          'boardingArr[]',
          this.addMakeTripForm.get('boardingArr').value[i]
        );
      }
      for (let i = 0; i < this.addMakeTripForm.value.packageArr.length; i++) {
        formData.append(
          'packageArr[]',
          this.addMakeTripForm.get('packageArr').value[i]
        );
      }
      for (let i = 0; i < this.addMakeTripForm.value.soloBikeArr.length; i++) {
        formData.append(
          'soloBikeArr[]',
          this.addMakeTripForm.get('soloBikeArr').value[i]
        );
      }
      for (let i = 0; i < this.addMakeTripForm.value.sharedBikeArr.length; i++) {
        formData.append(
          'sharedBikeArr[]',
          this.addMakeTripForm.get('sharedBikeArr').value[i]
        );
      }
      for (let i = 0; i < this.addMakeTripForm.value.roomArr.length; i++) {
        formData.append(
          'roomArr[]',
          this.addMakeTripForm.get('roomArr').value[i]
        );
      }

      formData.append('tripType', this.addMakeTripForm.get('tripType').value);
      formData.append('lastReminderDay', this.addMakeTripForm.get('lastReminderDay').value)
      // formData.append(
      //   'no_of_traveler',
      //   this.addMakeTripForm.get('no_of_traveler').value
      // );
      formData.append('start_date', JSON.stringify(startDate));
      formData.append('end_date', JSON.stringify(endDate));
      formData.append('show_date', JSON.stringify(showDate));
      // console.log(
      //   this.addMakeTripForm.value,
      //   'hiiiiiiiiiiii',
      //   startDate,
      //   endDate
      // );
      console.log(this.addMakeTripForm.value);
      //return;
      this.isAuthLoading = true;
      this.isAuthDisabled = true;
      this.apiService.addTrip(formData).subscribe((result: any) => {
        console.log(result);
        if (result.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          this.router.navigateByUrl('/make-trip-overview/' + result.objId);
          console.log(result.objId);
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: result.message,
            icon: 'error',
          });
        }
      });
    }
  }

  updateFirstTour() {
    // console.log(this.addMakeTripForm.value, "show");
    // return;
    // ==========
    let formData = new FormData();
    if (this.addMakeTripForm.value.tour_image?.name != undefined) {
      formData.append(
        'tour_image',
        this.addMakeTripForm.get('tour_image').value
      );
    }

    if (this.addMakeTripForm.value.tour_pdf?.name != undefined) {
      formData.append('tour_pdf', this.addMakeTripForm.get('tour_pdf').value);
    }

    // let date = `${this.addMakeTripForm.value.start_date.month}/${this.addMakeTripForm.value.start_date.day}/${this.addMakeTripForm.value.start_date.year}`;

    // let enddate = `${this.addMakeTripForm.value.end_date.month}/${this.addMakeTripForm.value.end_date.day}/${this.addMakeTripForm.value.end_date.year}`;

    const obj = this.addMakeTripForm.value;
    const startDate = [];
    const endDate = [];
    const showDate = [];
    Object.entries(obj).forEach(([key, value], i) => {
      if (key.match(/start_date/))
      //console.log(obj[key],'dateeeeeeee');
        startDate.push(
          obj[key].split('-')[1] +
          '/' +
          obj[key].split('-')[2] +
          '/' +
          obj[key].split('-')[0]
        );
      if (key.match(/end_date/))
        endDate.push(
          obj[key].split('-')[1] +
          '/' +
          obj[key].split('-')[2] +
          '/' +
          obj[key].split('-')[0]
        );
      if (key.match(/show_date/))
        showDate.push(obj[key]);
    });
    // Object.entries(obj).forEach(([key, value], i) => {
    //   if (key.substring(0, key.length - 1) === 'start_date')
    //     startDate.push(
    //       obj[key].split('-')[1] +
    //       '/' +
    //       obj[key].split('-')[2] +
    //       '/' +
    //       obj[key].split('-')[0]
    //     );
    //   if (key.substring(0, key.length - 1) === 'end_date')
    //     endDate.push(
    //       obj[key].split('-')[1] +
    //       '/' +
    //       obj[key].split('-')[2] +
    //       '/' +
    //       obj[key].split('-')[0]
    //     );
    // });


    formData.append(
      'isHome',
      this.addMakeTripForm.get('isHome').value == '' ||
        this.addMakeTripForm.get('isHome').value == 'false'
        ? 'false'
        : 'true'
    );

    formData.append('tripType', this.addMakeTripForm.get('tripType').value);
    formData.append('lastReminderDay', this.addMakeTripForm.get('lastReminderDay').value);

    // formData.append(
    //   'no_of_traveler',
    //   this.addMakeTripForm.get('no_of_traveler').value
    // );

    formData.append('title', this.addMakeTripForm.get('title').value.trim());
    formData.append(
      'orderNote',
      this.addMakeTripForm.get('orderNote').value.trim()
    );
    formData.append(
      'adventure_type',
      this.addMakeTripForm.get('adventure_type').value.trim()
    );
    formData.append(
      'destination',
      this.addMakeTripForm.get('destination').value.trim()
    );

    for (let i = 0; i < this.addMakeTripForm.value.boardingArr.length; i++) {
      formData.append(
        'boardingArr[]',
        this.addMakeTripForm.get('boardingArr').value[i]
      );
    }
    for (let i = 0; i < this.addMakeTripForm.value.packageArr.length; i++) {
      formData.append(
        'packageArr[]',
        this.addMakeTripForm.get('packageArr').value[i]
      );
    }
    for (let i = 0; i < this.addMakeTripForm.value.soloBikeArr.length; i++) {
      formData.append(
        'soloBikeArr[]',
        this.addMakeTripForm.get('soloBikeArr').value[i]
      );
    }
    for (let i = 0; i < this.addMakeTripForm.value.sharedBikeArr.length; i++) {
      formData.append(
        'sharedBikeArr[]',
        this.addMakeTripForm.get('sharedBikeArr').value[i]
      );
    }
    for (let i = 0; i < this.addMakeTripForm.value.roomArr.length; i++) {
      formData.append(
        'roomArr[]',
        this.addMakeTripForm.get('roomArr').value[i]
      );
    }

    formData.append('start_date', JSON.stringify(startDate));
    formData.append('end_date', JSON.stringify(endDate));
    formData.append('show_date', JSON.stringify(showDate));
    // formData.append('start_date', date);
    // formData.append('end_date', date);
    console.log(this.addMakeTripForm.value);
    ////return;/////////////////////////////////////////////////////////////////////////////////////////////
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    this.apiService
      .updateFirstTour(formData, this.tripid)
      .subscribe((result: any) => {
        if (result.success) {
          // Swal.fire({
          //   text: result.message,
          //   icon: 'success',
          // });
          //this.toastr.success(result.message);
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          this.router.navigateByUrl('/make-trip-overview/' + this.tripid);
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          //this.toastr.error(result.message);
          Swal.fire({
            text: result.message,
            icon: 'error',
          });
        }
      });
  }
}

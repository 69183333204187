
import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray
} from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import Swal from 'sweetalert2';
import { AppService } from '../../utils/services/app.service';
import { log } from 'console';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { setFlagsFromString } from 'v8';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';


declare var $: any;
declare var paypal;
declare global {
  interface Date {
    addDays: (d) => any;
  }
}
Date.prototype.addDays = function (d) {
  this.setHours(this.getHours() + d * 24);
  return this;
};
@Component({
  selector: 'app-make-trip',
  templateUrl: './make-trip.component.html',
  styleUrls: ['./make-trip.component.scss'],
})
export class MakeTripComponent implements OnInit {
  addMakeTripForm: FormGroup;
  tripId: any;
  public tripid: any;
  isShown: boolean = false;
  public isAuthLoading: boolean = false;
  public isAuthDisabled: boolean = false;
  public SubCats: any;
  public allAdve: any;
  public allBlog: any;
  public allTrip: any;
  public allLable: any;
  public allmenu: any;
  public alldesti: any;
  public allpackag: any;
  public packageSelected: any = [];
  public allrooms: any = [];
  public allsharedBike: any = [];
  public allsoloBike: any = [];
  banner_start_date: any;
  endDateBanner: any;
  banner_days = 0;
  sidebar_days = 0;
  poster_days = 7;
  vendor_days = 0;
  adventure_type: any;
  similar_blog: any;
  packageArr: any;
  data: any;
  images: any;
  multipleimagesnew:any;
  todayDate: string;
  showBar: any;
  private user_id: string = localStorage.getItem('id');
  isAdd: boolean = false;
  isRemove: boolean = false;
  addedNew: boolean = false;
  minDate: string = '';
  index: number = 1;
  dateDiffer: number = 0;
  initStartDate: string = new Date().toISOString().slice(0, 10);
  @ViewChild('addDate', { static: false }) addDate: ElementRef;
  reminderDays: number[] = Array.from({length: 30}, (_, i) => i+1);
  radio: string = 'normal';
  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];
  numberOfFiles: any;
  allMenu:any;
  previews: string[] = [];
  multipleImages:any;
  selectedID: any
  config_ck = {
    uiColor: '#ffffff',
    toolbarGroups: [
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
      { name: 'links' },
      { name: 'insert' },
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
      { name: 'styles' },
      { name: 'colors' },
    ],
    resize_enabled: false,
    removePlugins: 'elementspath,save,magicline',
    colorButton_foreStyle: {
      element: 'font',
      attributes: { color: '#(color)' },
    },
    height: 188,
    removeDialogTabs: 'image:advanced;link:advanced',
    removeButtons: 'Subscript,Superscript,Anchor,Source,Table',
    format_tags: 'p;h1;h2;h3;pre;div',
    extraPlugins: 'uploadimage,justify',
   
 
  };
 
  base64Image: string;
  advantureid: any =[];
  similarblogid: any=[];
  similartrip: any=[];
  show: any;
 
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private apiService: ApiService,
   private http: HttpClient,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
    const current = new Date();
   
  }
  @ViewChild('div') div: ElementRef;
  @ViewChild('importFestImg', { static: false }) importFestImg: ElementRef;
  @ViewChild('importBannerImg', { static: false }) importBannerImg: ElementRef;
  @ViewChild('importvideogif', { static: false }) importvideogif: ElementRef;
  @ViewChild('importPDFImg', { static: false }) importPDFImg: ElementRef;
 
  ngOnInit(): void {
 
    this.downloadImage()
    let getDateOnly = new Date().toISOString().split('T')[0];
    //console.log(this.user_id)
    this.todayDate = getDateOnly;
    this.addMakeTripForm = new FormGroup({
      menumaster_id: new FormControl(),
      title: new FormControl(),
      slug_url: new FormControl(),
      tour_image: new FormControl(),
      banner_image: new FormControl(),
      mobile_gif: new FormControl(),
      boardingArr: new FormControl(),
      adventure_type: new FormControl(),
      similar_blog: new FormControl(),
      similar_trip: new FormControl(),
      tagLable_id : new FormControl (),
      destination: new FormControl(),
      packageArr: new FormControl(),
      soloBikeArr: new FormControl(),
      sharedBikeArr: new FormControl(),
      roomArr: new FormControl(),
      orderNote: new FormControl(),
      domesticorInternational: new FormControl(),
      tourtype :new FormControl(),
      callexpertnumber: new FormControl(),
      infantfrom: new FormControl(),
      infantto: new FormControl(),
      childfrom: new FormControl(),
      childto: new FormControl(),
      tour_type: new FormControl(),
      isHome: new FormControl(),
      upcomingtour: new FormControl(),
      tour_pdf: new FormControl(),
      location: new FormControl(),
     
    });
    this.addMakeTripForm = this.formBuilder.group({
      menumaster_id: ['', [Validators.required]],
      title: ['', [Validators.required]],
      slug_url: ['', [Validators.required]],
      tour_image: ['', [Validators.required]],
      banner_image: ['', [Validators.required]],
      tour_pdf: ['', [Validators.required]],
      boardingArr: ['', [Validators.required]],
      adventure_type: ['', []],
      mobile_gif: ['',[]],
      similar_blog: ['', []],
      similar_trip: ['', []],
      tagLable_id: ['', []],
      destination: ['', [Validators.required]],
      packageArr: ['', []],
      soloBikeArr: ['', []],
      sharedBikeArr: ['', []],
      roomArr:['', []],
      orderNote: ['', []],
      domesticorInternational: ['', []],
      tourtype: ['', []],
      callexpertnumber: ['', []],
      infantfrom: ['', []],
      infantto: ['', []],
      childfrom: ['', []],
      childto: ['', []],
      location: ['', []],
      tour_type: ['normal', []],
      isHome: [false],
      upcomingtour: [false],
      tripType: ['', [Validators.required]],
      multiple_image: this.formBuilder.array([])
    });
    this.board();
    this.adventure();
    this.lable();
    this.destination();
    this.package();
    this.allActiveRooms();
    this.menulist();
    this.showMenu();
    this.tripid = this.route.snapshot.paramMap.get('trip_id');
    if (this.tripid != undefined && this.tripid != null && this.tripid != '') {
      this.edittrip(this.tripid);
    } else {
      this.addMakeTripForm.get('isHome').setValue('true');
      this.addMakeTripForm.get('upcomingtour').setValue('true');
    }
    this.spinner.show()
    setTimeout(() => {
      this.blog();
      this.trip();
    this.spinner.hide()
    }, 4000);
  }


  onChange(selectedValue: any){
    //console.log(selectedValue)
    this.selectedID = selectedValue
    if(selectedValue){
      this.apiService.getbolgsimilertrip(selectedValue).subscribe((res: any) => {
        //console.log(res)
        this.allBlog = res.data;
      });
      this.apiService.getsimilertrip(selectedValue).subscribe((res: any) => {
        console.log(res,"sam ")
        this.allTrip = res.data.filter((trip: any) => trip.status === 'active');
        console.log(this.allTrip,"sam ")
      });
    }else{
      this.allBlog = []
      this.allTrip = []
    }
  }
    
  trip() {
    this.spinner.show()
    this.apiService.getsimilertrip(this.selectedID).subscribe((res: any) => {
      //console.log(res)
      if(res.success == true){
        this.spinner.hide()
        this.allTrip = res.data;
      }else{
        this.spinner.hide()
      }
    });
  }

  blog() {
    this.apiService.getbolgsimilertrip(this.selectedID).subscribe((res: any) => {
      ///console.log(res)
      if(res.success == true){
        this.spinner.show();
        this.allBlog = res.data;
        this.spinner.hide();
      }else{
        this.spinner.hide();
      }
     
    });
  }
 
  get imagesFormArray() {
    return this.addMakeTripForm.get('multiple_image') as FormArray;
  }

 
  addImageControl(files: FileList) {
    if (this.imagesFormArray.length + files.length > 10) {
      Swal.fire({
        icon: 'warning',
        title: 'Image Limit Exceeded',
        text: 'You can only upload up to 10 images.',
        confirmButtonText: 'OK'
      });
      return;    
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const imageControl = this.formBuilder.control(file);
      this.imagesFormArray.push(imageControl);
 
      imageControl.valueChanges.subscribe((selectedFile: File) => {
        this.previewImage(selectedFile, imageControl);
      });
 
      this.previewImage(file, imageControl);
    }
  }
 
  removeImageControl(index: number) {
    this.imagesFormArray.removeAt(index);

  }

  previewImage(  file: any, control: any) {
    if (file) {
      //console.log(file.src)
      if(file.src){
        control.previewUrl = environment.API_HOST + file.src
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          control.previewUrl = reader.result as string;
        };
      }
    }
  }
 
  getBase64Image(file: File): void {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.base64Image = reader.result as string;
    };
    reader.readAsDataURL(file);
  }
  edittrip(id) {
    // console.log("hello");
    let imageData: any;
    let dataForForm = {
      id: id,
    };
    let start_date_1 = '';

    const apiUrl = 'your_api_url';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    // console.log(this.tripid);
    this.apiService.tourUpdate(dataForForm).subscribe((result: any) => {
      console.log(result.getData);
      if (result.success) {
        let TripData: any = result.getData;
        this.showBar = result?.getData?.bookPrice;
        this.selectedID = result.getData.menumaster_id
        this.initImages(TripData.multiple_image);
        this.images =  TripData.multiple_image
        for (let image of this.images) {
           //console.log(image.src,"path")
           this.multipleimagesnew = image.src
          // const imageData = {
          //   src: image.src,
          //   alt: image.alt // Add other properties if necessary
          // };
        }
        const selectedAdventureTypeIds = TripData.adventure_type.map(item => item._id);
        const selectedTagLabelIDTypeIds = TripData.tagLable_id[0]._id

        console.log(selectedTagLabelIDTypeIds);
        
        const selectedTagBlogIDTypeIds = TripData.similar_blog.map(item => item._id);
        const selectedTagSimilarTypeIds = TripData.similar_trip.map(item => item._id);
        this.addMakeTripForm.patchValue({
          title: TripData.title,
          slug_url: TripData.title,
          menumaster_id: TripData.menumaster_id,
          orderNote: TripData.orderNote,
          tour_image: TripData.tour_image[0]?.src,
          tour_pdf: TripData.tour_pdf[0]?.src,
          adventure_type: selectedAdventureTypeIds ,
          tagLable_id:   selectedTagLabelIDTypeIds,
          similar_blog: selectedTagBlogIDTypeIds,
          similar_trip :selectedTagSimilarTypeIds,
          destination: TripData.destination._id,
          boardingArr: TripData.boarding_point,
          packageArr: TripData.packageType,
          soloBikeArr: TripData.soloBikeType,
          sharedBikeArr: TripData.sharedBikeType,
          roomArr: TripData.roomSharing,
          callexpertnumber : TripData.callexpertnumber,
          infantfrom : TripData.infantfrom,
          infantto : TripData.infantto,
          childfrom : TripData.childfrom,
          childto :TripData.childto,
          location :TripData.location,
          tourtype : TripData.tourtype,
          banner_image:TripData.banner_image[0]?.src ,
          mobile_gif:TripData.mobile_gif[0]?.src  
        });
        this.addMakeTripForm.patchValue({
          tripType: result.getData.tripType,
        });
        this.addMakeTripForm.patchValue({
          domesticorInternational: result.getData.domesticorInternational,
        });
        this.addMakeTripForm.patchValue({
          tourtype: result.getData.tourtype,
        });
        if (TripData.isHome == true) {
          this.addMakeTripForm.get('isHome').setValue('true');
        }
        if (TripData.upcomingtour == true) {
          this.addMakeTripForm.get('upcomingtour').setValue('true');
        }
        setTimeout(() => {
          this.importFestImg.nativeElement.innerText =
            TripData.tour_image[0]?.orgName;
        }, 1000);
        setTimeout(() => {
          this.importPDFImg.nativeElement.innerText =
            TripData.tour_pdf[0]?.orgName;
        }, 1000);
        setTimeout(() => {
          this.importBannerImg.nativeElement.innerText =
            TripData.banner_image[0]?.orgName;
        }, 1000);
        setTimeout(() => {
          this.importvideogif.nativeElement.innerText =
            TripData.mobile_gif[0]?.orgName;
        }, 1000);
 
        this.packageSelected = TripData.packageArr;
     
      }
    });
  }
 
  initImages(images: any[]) {
    const controls = images.map(image => this.formBuilder.control(image));
    this.addMakeTripForm.setControl('multiple_image', this.formBuilder.array(controls));
     controls.forEach((control, index) => {
      this.previewImage(control.value, control);
    });
 
  }
 
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.addMakeTripForm.get('tour_image').setValue(file);
      this.importFestImg.nativeElement.innerText = event.target.files[0].name;
    }
  }
 
  onFileChangebanner(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.addMakeTripForm.get('banner_image').setValue(file);
      this.importBannerImg.nativeElement.innerText = event.target.files[0].name;
    }
  }

  onFilemobilegif(event) {
    if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.addMakeTripForm.get('mobile_gif').setValue(file);
        this.importvideogif.nativeElement.innerText = file.name;
    }
}
 
  onFileChangemultiple(event) {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i <= numberOfFiles -1;  i++) {
        this.numberOfFiles = event.target.files[i];
        let contect = [];
      contect.push({
        f: this.selectedFiles,
      });
      this.multipleImages = contect[0].f
      console.log(contect, this.multipleImages);
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };
        reader.readAsDataURL(this.selectedFiles[i]);
      }
    }
  }
 
 
 
  removeSelectedFile(index) {
   
    this.previews.splice(index, 1);
   }
 
  onFileChangepdf(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.addMakeTripForm.get('tour_pdf').setValue(file);
      this.importPDFImg.nativeElement.innerText = event.target.files[0].name;
    }
  }
 
  toggleShow() {
    this.isShown = !this.isShown;
  }
 
  showMenu() {
    const that = this;
    this.apiService.allMenuMatser().subscribe((resp: any) => {
      that.allMenu = resp.data;
   
    });
  }
 
  board() {
    this.apiService.activeBording().subscribe((res: any) => {
      this.SubCats = [];
      //console.log(this.SubCats);
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].status === 'active') {
          this.SubCats.push(res.data[i]);
        }
      }
    });
  }
  menulist(){
    this.apiService.Activemenu().subscribe((res: any) => {
      this.allmenu = res.data;
    });
  }
 
  adventure() {
    this.apiService.activeAdvent().subscribe((res: any) => {
      this.allAdve = res.data;
    });
  }
 
  
 
  lable() {
    this.apiService.getActiveLabelMaster().subscribe((res: any) => {
      this.allLable = res.data;
    });
  }
 
  destination() {
    this.apiService.activeDestination().subscribe((res: any) => {
      this.alldesti = res.data;
    });
  }
  package() {
    this.apiService.activePackage().subscribe((res: any) => {
      this.allpackag = [];
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].status === 'active') {
          this.allpackag.push(res.data[i]);
        }
      }
    });
  }
  selectedPackage(eve: any) {
    this.packageSelected = eve;
   
  }
 
  getBikesByOpt(bikeType: string) {
    this.apiService.activeBikesByOpt(bikeType).subscribe((res: any) => {
      if (res.success) {
        //console.log(res.data);
        if (bikeType === "solo") {
          this.allsoloBike = res.data;
        } else if (bikeType === "shared") {
          this.allsharedBike = res.data;
        }
      } else {
        console.error(res);
      }
    });
  }
 
  allActiveRooms() {
    this.apiService.activeRooms().subscribe((res: any) => {
      if (res.success) {
        this.allrooms = res.data
      } else {
        console.error(res);
      }
    });
  }
 
  addtrip() {
    if (
      this.addMakeTripForm.value.menumaster_id.trim() == undefined ||
      this.addMakeTripForm.value.menumaster_id.trim() == ''
    ) {
      Swal.fire({
        text: 'Please Select Menu Category',
        icon: 'warning',
      });
      return false;
    }
    if (
      this.addMakeTripForm.value.title.trim() == undefined ||
      this.addMakeTripForm.value.title.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter title',
        icon: 'warning',
      });
      return false;
    }

    if (this.tripid != undefined && this.tripid != '') {
      this.updateFirstTour();
    } else {
      if (
        this.addMakeTripForm.value.tour_image.name == undefined ||
        this.addMakeTripForm.value.tour_image.name == ''
      ) {
        Swal.fire({
          text: 'Please select image',
          icon: 'warning',
        });
        return false;
      }

    if (
      this.addMakeTripForm.value.banner_image == undefined ||
      this.addMakeTripForm.value.banner_image == ''
    ) {
      Swal.fire({
        text: 'Please select banner image',
        icon: 'warning',
      });
      return false;
    }

    // if (
    //   this.addMakeTripForm.value.mobile_gif == undefined ||
    //   this.addMakeTripForm.value.mobile_gif == ''
    // ) {
    //   Swal.fire({
    //     text: 'Please select Mobile Gif',
    //     icon: 'warning',
    //   });
    //   return false;
    // }
 
   
    if (
      this.addMakeTripForm.value.boardingArr == undefined ||
      this.addMakeTripForm.value.boardingArr == ''
    ) {
      Swal.fire({
        text: 'Please select boarding point',
        icon: 'warning',
      });
      return false;
    }
    if (
      this.addMakeTripForm.value.adventure_type == undefined ||
      this.addMakeTripForm.value.adventure_type == ''
    ) {
      Swal.fire({
        text: 'Please select adventure type',
        icon: 'warning',
      });
      return false;
    }
    // if (
    //   this.addMakeTripForm.value.tagLable_id == undefined ||
    //   this.addMakeTripForm.value.tagLable_id == ''
    // ) {
    //   Swal.fire({
    //     text: 'Please select Lable',
    //     icon: 'warning',
    //   });
    //   return false;
    // }

    if (
      this.addMakeTripForm.value.packageArr == undefined ||
      this.addMakeTripForm.value.packageArr == ''
    ) {
      Swal.fire({
        text: 'Please select Package Type',
        icon: 'warning',
      });
      return false;
    }

    // if (
    //   this.addMakeTripForm.value.destination == undefined ||
    //   this.addMakeTripForm.value.destination == ''
    // ) {
    //   Swal.fire({
    //     text: 'Please select destination',
    //     icon: 'warning',
    //   });
    //   return false;
    // }
  //  if (
  //     this.addMakeTripForm.value.tripType == undefined ||
  //     this.addMakeTripForm.value.tripType == ''
  //   ) {
  //     Swal.fire({
  //       text: 'Please select trip & trek',
  //       icon: 'warning',
  //     });
  //     return false;
  //   } 
    // if (
    //   this.addMakeTripForm.value.tour_type.trim() == undefined ||
    //   this.addMakeTripForm.value.tour_type.trim() == ''
    // ) {
    //   Swal.fire({
    //     text: 'Please enter tour_type',
    //     icon: 'warning',
    //   });
    //   return false;
    // }
    if (
      this.addMakeTripForm.value.domesticorInternational == undefined ||
      this.addMakeTripForm.value.domesticorInternational == ''
    ) {
      Swal.fire({
        text: 'Please select domestic/international trip',
        icon: 'warning',
      });
      return false;
    }
    if (
      this.addMakeTripForm.value.tourtype == undefined ||
      this.addMakeTripForm.value.tourtype == ''
    ) {
      Swal.fire({
        text: 'Please select tourtype',
        icon: 'warning',
      });
      return false;
    }

    if (
      this.addMakeTripForm.value.location == undefined ||
      this.addMakeTripForm.value.location == ''
    ) {
      Swal.fire({
        text: 'Please enter location',
        icon: 'warning',
      });
      return false;
    }
      let formData = new FormData();
      formData.append('menumaster_id', this.addMakeTripForm.get('menumaster_id').value);
      formData.append('title', this.addMakeTripForm.get('title').value);
      formData.append('slug_url', this.addMakeTripForm.get('title').value);
      formData.append('tour_type', this.addMakeTripForm.get('tour_type').value);
      formData.append('childto', this.addMakeTripForm.get('childto').value);
      formData.append('childfrom', this.addMakeTripForm.get('childfrom').value);
      formData.append('infantto', this.addMakeTripForm.get('infantto').value);
      formData.append('infantfrom', this.addMakeTripForm.get('infantfrom').value);
      formData.append('callexpertnumber', this.addMakeTripForm.get('callexpertnumber').value);
      formData.append('location', this.addMakeTripForm.get('location').value);
      formData.append(
        'tour_image',
        this.addMakeTripForm.get('tour_image').value
      );
      formData.append(
        'banner_image',
        this.addMakeTripForm.get('banner_image').value
      );
      formData.append(
        'mobile_gif',
        this.addMakeTripForm.get('mobile_gif').value
      );
      formData.append('tour_pdf', this.addMakeTripForm.get('tour_pdf').value);
      formData.append('isHome', this.addMakeTripForm.get('isHome').value);
      formData.append('upcomingtour', this.addMakeTripForm.get('upcomingtour').value);
      formData.append('orderNote', this.addMakeTripForm.get('orderNote').value);
      formData.append(
        'adventure_type',
        this.addMakeTripForm.get( 'adventure_type',).value
      )
      formData.append(
        'similar_blog',
        this.addMakeTripForm.get( 'similar_blog',).value
      )
      formData.append(
        'similar_trip',
        this.addMakeTripForm.get( 'similar_trip',).value
      );
      formData.append(
        'tagLable_id',
        this.addMakeTripForm.get('tagLable_id').value
      );
      formData.append(
        'destination',
        this.addMakeTripForm.get('destination').value
      );
      for (let i = 0; i < this.addMakeTripForm.value.boardingArr.length; i++) {
        formData.append(
          'boardingArr[]',
          this.addMakeTripForm.get('boardingArr').value[i]
        );
      }
      for (let i = 0; i < this.addMakeTripForm.value.packageArr.length; i++) {
        formData.append(
          'packageArr[]',
          this.addMakeTripForm.get('packageArr').value[i]
        );
      }
      for (let i = 0; i < this.addMakeTripForm.value.soloBikeArr.length; i++) {
        formData.append(
          'soloBikeArr[]',
          this.addMakeTripForm.get('soloBikeArr').value[i]
        );
      }
      for (let i = 0; i < this.addMakeTripForm.value.sharedBikeArr.length; i++) {
        formData.append(
          'sharedBikeArr[]',
          this.addMakeTripForm.get('sharedBikeArr').value[i]
        );
      }
      for (let i = 0; i < this.addMakeTripForm.value.roomArr.length; i++) {
        formData.append(
          'roomArr[]',
          this.addMakeTripForm.get('roomArr').value[i]
        );
      }
 
      formData.append('tripType', this.addMakeTripForm.get('tripType').value);
      formData.append('domesticorInternational', this.addMakeTripForm.get('domesticorInternational').value);
      formData.append('tourtype', this.addMakeTripForm.get('tourtype').value);
      formData.append('user_id', this.user_id);
      this.imagesFormArray.controls.forEach((control, index) => {
        if (control.value) {
          formData.append('multiple_image', control.value, `image${index + 1}.jpg`);
        }
      });
      console.log(this.addMakeTripForm.value);
      console.log(formData);
      //return
      this.isAuthLoading = true;
      this.isAuthDisabled = true;
      this.apiService.addTrip(formData).subscribe((result: any) => {
        console.log(result);
        if (result.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          this.router.navigateByUrl('/make-trip-date/' + result.objId);
          console.log(result.objId);
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: result.message,
            icon: 'error',
          });
        }
      });
    }
  }

  parentcategory(parentcategory: any, arg1: string) {
    throw new Error('Method not implemented.');
  }
 
 
  downloadImage() {
    const img = 'https://images.pexels.com/photos/3039036/pexels-photo-3039036.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
   
    this.http.get(img, { responseType: 'blob' }).subscribe(
      (imageBlob) => {
      //console.log(imageBlob);
      },
      (error) => {
        console.error('Error downloading image', error);
      }
    );
  }
 
  updateFirstTour() {
    let formData = new FormData();
    if (this.addMakeTripForm.value.tour_image?.name != undefined) {
      formData.append(
        'tour_image',
        this.addMakeTripForm.get('tour_image').value
      );
    }
    if (this.addMakeTripForm.value.banner_image?.name != undefined) {
      formData.append(
        'banner_image',
        this.addMakeTripForm.get('banner_image').value
      );
    }
    if (this.addMakeTripForm.value.mobile_gif?.name != undefined) {
      formData.append(
        'mobile_gif',
        this.addMakeTripForm.get('mobile_gif').value
      );
    }
    if (this.addMakeTripForm.value.tour_pdf?.name != undefined) {
      formData.append('tour_pdf', this.addMakeTripForm.get('tour_pdf').value);
    }
    formData.append(
      'isHome',
      this.addMakeTripForm.get('isHome').value == '' ||
        this.addMakeTripForm.get('isHome').value == 'false'
        ? 'false'
        : 'true'
    );
    formData.append(
      'upcomingtour',
      this.addMakeTripForm.get('upcomingtour').value == '' ||
        this.addMakeTripForm.get('upcomingtour').value == 'false'
        ? 'false'
        : 'true'
    );
 
    formData.append('tripType', this.addMakeTripForm.get('tripType').value);
    formData.append('domesticorInternational', this.addMakeTripForm.get('domesticorInternational').value);
    formData.append('tourtype', this.addMakeTripForm.get('tourtype').value);
    formData.append('title', this.addMakeTripForm.get('title').value.trim());
    formData.append('slug_url', this.addMakeTripForm.get('title').value.trim());
    formData.append('menumaster_id', this.addMakeTripForm.get('menumaster_id').value.trim());
    formData.append('tour_type', this.addMakeTripForm.get('tour_type').value.trim());
    formData.append('childto', this.addMakeTripForm.get('childto').value);
    formData.append('childfrom', this.addMakeTripForm.get('childfrom').value);
    formData.append('infantto', this.addMakeTripForm.get('infantto').value);
    formData.append('infantfrom', this.addMakeTripForm.get('infantfrom').value);
    formData.append('callexpertnumber', this.addMakeTripForm.get('callexpertnumber').value);
    formData.append('location', this.addMakeTripForm.get('location').value);
    formData.append('user_id', this.user_id);
    
    var preview_multiple_images:any = [];
    this.imagesFormArray.controls.forEach((control, index) => {
      const file: any = control.value;
      if (file instanceof File) {
          formData.append('multiple_image', file, `new_image${index + 1}.jpg`);
      } else {
        preview_multiple_images.push(control.value);
      } 
    });
    formData.append('preview_multiple_images',JSON.stringify(preview_multiple_images));       

        formData.append(
          'orderNote',
          this.addMakeTripForm.get('orderNote').value.trim()
        );
        formData.append(
          'adventure_type',
          this.addMakeTripForm.get( 'adventure_type').value.join(',')
        )
        formData.append(
          'similar_blog',
          this.addMakeTripForm.get( 'similar_blog').value.join(',')
        )
        formData.append(
          'similar_trip',
          this.addMakeTripForm.get( 'similar_trip').value.join(',')
        );
        formData.append(
          'tagLable_id',
          this.addMakeTripForm.get('tagLable_id').value.trim()
        );
        formData.append(
          'destination',
          this.addMakeTripForm.get('destination').value.trim()
        );
    
        for (let i = 0; i < this.addMakeTripForm.value.boardingArr.length; i++) {
          formData.append(
            'boardingArr[]',
            this.addMakeTripForm.get('boardingArr').value[i]
          );
        }
        for (let i = 0; i < this.addMakeTripForm.value.packageArr.length; i++) {
          formData.append(
            'packageArr[]',
            this.addMakeTripForm.get('packageArr').value[i]
          );
        }
        for (let i = 0; i < this.addMakeTripForm.value.soloBikeArr.length; i++) {
          formData.append(
            'soloBikeArr[]',
            this.addMakeTripForm.get('soloBikeArr').value[i]
          );
        }
        for (let i = 0; i < this.addMakeTripForm.value.sharedBikeArr.length; i++) {
          formData.append(
            'sharedBikeArr[]',
            this.addMakeTripForm.get('sharedBikeArr').value[i]
          );
        }
        for (let i = 0; i < this.addMakeTripForm.value.roomArr.length; i++) {
          formData.append(
            'roomArr[]',
            this.addMakeTripForm.get('roomArr').value[i]
          );
        }
        this.isAuthLoading = true;
        this.isAuthDisabled = true;
        this.apiService
      .updateFirstTour(formData, this.tripid )
      .subscribe((result: any) => {
        if (result.success) {
          console.log(this.imagesFormArray,"imagesFormArray");
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          this.router.navigateByUrl('/make-trip-date/' + this.tripid);
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: result.message,
            icon: 'error',
          });
        }
      });
  }
  
  onlyNumberKey(evt: KeyboardEvent) {
    // Only ASCII character in that range allowed
    let ASCIICode = (evt.which) ? evt.which : evt.keyCode;
    return (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57) && ASCIICode !== 46) ? false : true;
  }


}
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import htmlToPdfmake from 'html-to-pdfmake';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-user-order-history',
  templateUrl: './user-order-history.component.html',
  styleUrls: ['./user-order-history.component.scss'],
})
export class UserOrderHistoryComponent implements OnInit {
  webID: any;
  tourId: any;
  orderID: any;
  allData: any;
  public allOrders: any = [];
  private logoImgInDateURL: string = '';
  public allPackageData: any;
  public allBikeData: any;
  public allRoomData: any;
  public assignedVoucher: Array<any> = [];
  private swalWithCustomButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn primary-grd m-1',
      cancelButton: 'btn btn-danger m-1 b-10',
    },
    buttonsStyling: false,
  });
  public getUserById: any = {
    firstname: '',
    lastname: '',
    mobile: '',
    email: '',
    address: '',
    gender: '',
    DOB: '',
    city: '',
    state: '',
  };

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.webID = this.route.snapshot.paramMap.get('id');
    this.getAllOrder();
    this.getAllPackageData();
    this.getAllBikeData();
    this.getAllRoomData();
    this.invoiceLogoImgToDataURL();
    this.editBoard(this.webID);
    this.getUserByID(this.webID)
    this.VoucherList();
  }

  getAllPackageData() {
    this.apiService.getAllPack().subscribe((res: any) => {
      if (res.success) {
        //console.error(res, "pack");
        this.allPackageData = res.data;
      } else {
        console.error(res);
      }
    });
  }

  getAllBikeData() {
    this.apiService.AllBikes().subscribe((res: any) => {
      if (res.success) {
        console.error(res, "bike");
        this.allBikeData = res.data;
        // this.tourAllData.packageType_price = data;
      } else {
        console.error(res);
      }
    });
  }

  getAllRoomData() {
    this.apiService.AllRooms().subscribe((res: any) => {
      if (res.success) {
        console.error(res, "room");
        this.allRoomData = res.data;
        // this.tourAllData.packageType_price = data;
      } else {
        console.error(res);
      }
    });
  }

  getPackage(id: string) {
    if (this.allPackageData?.length > 0 && id !== undefined) {
      return this.allPackageData.filter((pack: any) => pack._id === id)[0]
        ?.package_type;
    } else {
      return '-';
    }
  }

  getBikePackage(id: string) {
    if (this.allBikeData?.length > 0 && id !== "") {
      return this.allBikeData.filter((pack: any) => pack._id === id)[0].bike_name;
    } else {
      return "-";
    }
  }

  getRoom(id: string) {
    if (this.allRoomData?.length > 0 && id !== "") {
      return this.allRoomData.filter((room: any) => room._id === id)[0].room_name;
    } else {
      return "-"
    }
  }

  editBoard(id) {
    let dataForForm = {
      id: id,
    };
    //console.log(dataForForm);
    this.apiService.addWebSiteID(dataForForm).subscribe((result: any) => {
      //console.log(result.getData, 'hiiiiiiiiii');
      this.allData = result.getData;
    });
  }

  getAllOrder() {
    this.spinner.show();
    const userId = {
      user_id: this.webID,
    };
    this.apiService.webUserOrder(userId).subscribe((res: any) => {
      console.log(res, 'invoiceeeee');
      if (res.success) {
        this.spinner.hide();
        this.allOrders = res.getData;
        // this.orderID =  this.allOrders.order_id
        // console.log(this.allOrders,'hjiiiiiiiiii');

        // this.tourId = this.allOrders[0]._id
        console.log(this.tourId, 'heloooooo');
      } else {
        this.spinner.hide();
        Swal.fire({
          icon: 'error',
          title: res.message,
        });
      }
    });
  }

  getUserByID(id: any){
    let dataForForm = {
      id: id
    }
    this.apiService.addWebSiteID(dataForForm).subscribe((res: any) => {
      console.log(res)
      this.getUserById = res.getData;
      this.getState(res.getData.state)
    })
  }

  getState(state: any){
    this.apiService.getStates().subscribe((res: any) => {
      res.map((elem) => {
        if(elem.iso2 == state){
          this.getUserById.state = elem.name
        }
      })
    })
  }

  // completeOrder(tourId, totalAmount, appliedCoupon, cgst, sgst, igst, isOuter, transctionCharge, cusGSTNo, bookingNote) {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: 'Did the amount fully paid?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes',
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       const orderDetails = {
  //         username: this.allData?.firstname,
  //         useremail: this.allData?.email,
  //         user_id: this.webID,
  //         id: tourId,
  //         payment_status: 'Complete',
  //         total_amount: totalAmount,
  //         amount_paid: totalAmount,
  //         order_id: 'OI' + (Math.floor(Math.random() * 1000000000000) + 1),
  //         transction_Id: 'TI' + (Math.floor(Math.random() * 1000000000000) + 1),
  //         receipt: 'Receipt #' + (Math.floor(Math.random() * 10000000) + 1),
  //         cgst: cgst,
  //         sgst: sgst,
  //         igst: igst,
  //         isOuter: isOuter,
  //         transctionCharge: transctionCharge,
  //         appliedCoupon: appliedCoupon,
  //         cusGSTNo: cusGSTNo,
  //         bookingNote: bookingNote,
  //       };

  //       this.apiService.booking(orderDetails).subscribe((res: any) => {
  //         if (res.success) {
  //           console.log(res);
  //           Swal.fire({
  //             text: res.message,
  //             icon: 'success',
  //           });
  //           this.router.navigateByUrl('/add-user-list');
  //           //this.router.navigateByUrl('/user-order-history/' + this.webID);
  //         } else {
  //           //this.spinner.hide();
  //           console.log(res);
  //         }
  //       });
  //     }
  //   });
  // }

  // cancelOrder(tourId: any) {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: 'Are you sure want to cancel your tour?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes',
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       const cancelStatus = {
  //         payment_status: 'Cancelled',
  //         username: this.allData?.firstname,
  //         useremail: this.allData?.email
  //       };
  //       // console.log(cancelStatus);
  //       // return;
  //       this.apiService
  //         .putCheckoutStatusUpdate(cancelStatus, tourId)
  //         .subscribe((res: any) => {
  //           if (res.success) {
  //             Swal.fire({
  //               title: 'Success',
  //               text: 'Tour cancelled successfully',
  //               icon: 'success',
  //             });
  //             this.router.navigateByUrl('/add-user-list');
  //           } else {
  //             Swal.fire({
  //               text: res.message,
  //               icon: 'error',
  //             });
  //           }
  //         });
  //     }
  //   });
  // }

  getPackageDataPrice(packageTypePrice: string, packageType: string) {
    let packageType_price = JSON.parse(packageTypePrice);
    return packageType_price.filter(
      (pack: any) => pack.packType === packageType
    )[0]?.packPrice;
  }

  getBikeDataPrice(bikeTypePrice: string, bikeName: string, bikeType: string) {
    console.log(bikeTypePrice, bikeName, bikeType);
    let bikeType_price = JSON.parse(bikeTypePrice);
    if (bikeType === "Solo bike") {
      return bikeType_price.filter((bike: any) => bike.soloType === bikeName)[0]?.soloPrice;
    } else {
      return bikeType_price.filter((bike: any) => bike.sharedType === bikeName)[0]?.sharedPrice;
    }
  }

  getRoomDataPrice(roomTypePrice: string, roomType: string) {
    let roomType_price = JSON.parse(roomTypePrice);
    return roomType_price.filter((room: any) => room.roomType === roomType)[0]?.roomPrice;
  }

  dateFormatter(travelDates: string) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let dateParts1 = travelDates.split('-')[0].split("/");
    let dateParts2 = travelDates.split('-')[1].split("/");
    let dateObject1 = new Date(+dateParts1[2], +dateParts1[1] - 1, +dateParts1[0]);
    let dateObject2 = new Date(+dateParts2[2], +dateParts2[1] - 1, +dateParts2[0]);
    // console.log();
    return `${monthNames[dateObject1.getMonth()]} ${dateObject1.getDate()}, ${dateObject1.getFullYear()} - ${monthNames[dateObject2.getMonth()]} ${dateObject2.getDate()}, ${dateObject2.getFullYear()}`;
  }

  invoiceLogoImgToDataURL() {
    this.apiService
      .imgToData64('../../../assets/img/fdf-01 (1).png')
      .subscribe((blob) => {
        const reader = new FileReader();
        const binaryString = reader.readAsDataURL(blob);
        reader.onload = (event: any) => {
          this.logoImgInDateURL = event.target.result;
        };
        reader.onerror = (event: any) => {
          //console.error('File could not be read: ' + event.target.error.code);
        };
      });
  }

//   sendDataToGenerateInvoice(order: any) {
//     // console.error(order)
//     let myInvoice = `
//     <!DOCTYPE html>
// <html>
// <head>
// </head>
// <body>

//     <header class="clearfix" style="content: ''; display: table; clear: both;">
//       <div id="logo" style=""float: left; margin-top: 8px;>
//       <img src="${this.logoImgInDateURL}" style="width: 300px; height: 75px" />
//       </div>

//       <div id="company" style="float: right; text-align: right;">
//         <h2 class="name" style="color:#dc1b5e;">${order?.payment_status === 'Partial' ? 'Payment Receipt' : 'Invoice'}</h2>
//         <h6 class="name"  >GLABOL EXPEDITION PVT. LTD.</h6>
//         <div>1881 D Sudama Nagar, Indore (m.p) <br/>
//         +91 89622 45021 | info@glabol.com<br/>
//         </div>
//         <div>GST No. ___________________</div>
//       </div>

//     </header>
//     <br>
//     <main>
//     <br>
//     <table border="0" cellspacing="0" cellpadding="0" style="width: 100%">
//      <tbody style="width: 100%">
// <tr style=" width: 33.33%">
//     		<td rowspan="3">

//             	<h5>Billed To <br> ${order?.user_id?.firstname} ${order?.user_id?.lastname }</h5>
//           <span>${order?.user_id?.mobile}</span>
//           <span>${order?.user_id?.email ? order?.user_id?.email : ''}</span>
//           <p>GST No.: ${order?.cusGSTNo !== '' ? order?.cusGSTNo : '-'}</p>
//             </td>
//         	<td style="text-align: center;">
//             	<h5> Order ID </h5> ${order?.order_id}
//             </td>
//             <td rowspan="3" style="text-align: center;"><br><br><br>

//             	Total Amount <h5 style= "color:#dc1b5e;"> ₹${order?.totalAmount.toLocaleString(
//               'en-IN'
//             )}</h5>

//             </td>

//         </tr>
//     	<tr style=" width: 33.33%">
//         	<td style="text-align: center;">
//             <h5>	Transaction ID </h5>${order?.transction_Id}
//             </td>
//         </tr>
//         <tr style=" width: 33.33%">
//         	<td style="text-align: center;">
//           <h5>	Date of Booking</h5> ${new Date(order?.created_at)
//             .toISOString()
//             .split('T')[0]
//             .split('-')
//             .reverse()
//             .join('-')}

//             </td>
//         </tr>
//     </tbody>
// </table>

//       <br>
//       <table border="0" cellspacing="0" cellpadding="0" style="width: 100%">
//         <thead style="width: 100%">
//           <tr style="color:#dc1b5e; width: 20%">
//             <th class="no">S.No.</th>
//             <th class="desc">Descripiton</th>
//             <th class="unit">Basic Price</th>
//             <th class="unit">No. Of Travellers</th>
//             <th class="total">Total</th>
//           </tr>
//         </thead>
//         <tbody style="width: 100%">
//           <tr style="width: 20%">
//             <td class="no">01</td>
//             <td class="desc"><h5> ${order?.tour_id?.title} Trip</h5>
//               <p>Batch: ${order?.travel_date } </p>`
//               if(order?.travel_packageType) {
//                 if (this.getPackage(order?.travel_packageType) !== 'Solo bike' && this.getPackage(order?.travel_packageType) !== 'Shared bike') {
//                   myInvoice +=
//                   `<span>Package: ${this.getPackage(order?.travel_packageType) } </span>`
//                 } else {
//                   myInvoice +=
//                   `<span>Package: ${this.getBikePackage(order?.travel_soloPackageType ? order?.travel_soloPackageType : order?.travel_sharedPackageType)} (${this.getPackage(order?.travel_packageType)})</span>`
//                 }
//               }
//               if(order?.travel_roomSharing) {
//                 myInvoice +=
//                 `<span>Room: ${this.getRoom(order?.travel_roomSharing) } </span>`
//               }
//               myInvoice +=
//               `<span>Boarding Point: ${order?.travel_boarding_point?.boarding } </span>`;
//               if(order?.boardingType) {
//               myInvoice +=  `<span>Boarding Type: ${order?.boardingType } </span>`
//               }
//             myInvoice += `</td>
//             <td class="unit" style="vertical-align:top!important;">
//             <p>${order?.tour_id?.basicAmount}</p>
//             <p>&nbsp;</p><p>&nbsp;</p>`
//             if(order?.travel_packageType) {
//               if(this.getPackage(order?.travel_packageType) === 'Solo bike' || this.getPackage(order?.travel_packageType) === 'Shared bike') {
//                 myInvoice +=
//                 `<span>
//                 ${+(this.getBikePackage(order?.travel_soloPackageType ? order?.travel_soloPackageType : order?.travel_sharedPackageType) !== '-' ? '' + this.getBikeDataPrice(order?.travel_soloPackageType ? order?.tour_id?.soloType_price : order?.tour_id?.shareType_price, this.getBikePackage(order?.travel_soloPackageType ? order?.travel_soloPackageType : order?.travel_sharedPackageType), this.getPackage(order?.travel_packageType)) : '0')}
//                 </span><br>`
//               } else {
//                 myInvoice +=
//                 `<span> ${+(this.getPackage(order?.travel_packageType) !== '-'
//                 ? '' +
//                   this.getPackageDataPrice(
//                     order?.tour_id?.packageType_price,
//                     this.getPackage(order?.travel_packageType)
//                   )
//                 : '0')} </span><br>`
//               }
//             }
//             if(order?.travel_roomSharing) {
//               myInvoice +=
//               `<span> ${+(this.getRoom(order?.travel_roomSharing) !== '-'
//               ? '' +
//                 this.getRoomDataPrice(
//                   order?.tour_id?.roomSharing_price,
//                   this.getRoom(order?.travel_roomSharing)
//                 )
//               : '0')} </span><br>`
//             }

//             myInvoice += `
//             <span>${+(this.getBoardingPrice(
//               order?.tour_id?.boarding_point_price,
//               order
//             )
//               ? '' +
//                 this.getBoardingPrice(
//                   order?.tour_id?.boarding_point_price,
//                   order
//                 )
//               : '0')}</span>
//             </td>
//             <td style="text-align: center;">${order?.travel_no_of_traveler}</td>
//             <td class="total">₹${order?.finalPrice.toLocaleString('en-IN')}</td>
//           </tr>
//         </tbody>
//         <tfoot style="width:100%">
//         `;
//     if (+order?.appliedCoupon !== 0) {
//       myInvoice += `
//         <tr style="width:20%">
//           <td colspan="2"></td>
//           <td colspan="2">Coupon Discount</td>
//           <td>₹${(+order?.appliedCoupon).toLocaleString('en-IN')}</td>
//         </tr>
//       `;
//     }
//     myInvoice += `
//           <tr style="width:20%">
//             <td colspan="2"></td>
//             <td colspan="2">Total Package Cost</td>
//             <td>₹${(order?.finalPrice - +order?.appliedCoupon).toLocaleString(
//               'en-IN'
//             )}</td>
//           </tr>`;
//           // `<tr style="width:20%">
//           //   <td colspan="2"></td>
//           //   <td colspan="2">Transction Charge (${
//           //     order?.transctionCharge
//           //   }%)</td>
//           //   <td>₹${(
//           //     +order?.transctionCharge *
//           //     0.01 *
//           //     (order?.finalPrice - +order?.appliedCoupon)
//           //   ).toLocaleString('en-IN')}</td>
//           // </tr>`;

//     if (order?.isOuter) {
//       myInvoice += `<tr style="width:20%">
//             <td colspan="2"></td>
//             <td colspan="2">IGST  @ ${order?.igst}% </td>
//             <td>₹${(
//               +order?.igst *
//                 0.01 *
//                 (order?.finalPrice - +order?.appliedCoupon)
//             ).toLocaleString('en-IN')}</td>
//           </tr>`;
//     } else {
//       myInvoice += `<tr style="width:20%">
//             <td colspan="2"></td>
//             <td colspan="2">CGST  @ ${order?.cgst}% </td>
//             <td>₹${(
//               +order?.cgst *
//                 0.01 *
//                 (order?.finalPrice - +order?.appliedCoupon)
//             ).toLocaleString('en-IN')}</td>
//           </tr>
//           <tr style="width:20%">
//             <td colspan="2"></td>
//             <td colspan="2">SGST  @ ${order?.sgst}%</td>
//             <td>₹${(
//               +order?.sgst * 0.01 * (order?.finalPrice - +order?.appliedCoupon)
//             ).toLocaleString('en-IN')}</td>
//           </tr>`;
//     }

//     myInvoice += `<tr style="width:20%">
//             <td colspan="2"></td>
//             <td colspan="2" style="font-weight: bold">Grand Total</td>
//             <td style="font-weight: bold">₹${order?.totalAmount.toLocaleString(
//               'en-IN'
//             )}</td>
//           </tr>
//           <tr style="width:20%">
//             <td colspan="2"></td>
//             <td colspan="2">Total Amount to Pay</td>
//             <td>₹${order?.amountPaid.toLocaleString('en-IN')}</td>
//           </tr>
//         </tfoot>
//       </table>
//       <br>
//        <table border="0" cellspacing="0" cellpadding="0" style="width:100%">
//         <thead style="width:100%">
//           <tr style="color:#dc1b5e; width: 33.33%">
//             <th class="desc">Payment Status</th>
//             <th class="unit">Amount Already Paid</th>
//             <th class="unit">Remaining Amount To Pay</th>
//           </tr>
//         </thead>
//         <tbody style="width:100%">
//           <tr style="text-align: center; width: 33.33%">
//             <td class="desc"><h6> ${order?.payment_status} </h6></td>
//             <td class="unit"><h6> ₹${order?.amountPaid} </h6></td>
//             <td class="unit"><h6> ₹${(order?.totalAmount - order?.amountPaid).toLocaleString(
//                 'en-IN'
//               )} </h6>
//             </td>
//           </tr>

//           </tbody>
//           </table>
//       <br />
//       <div id="thanks" style="text-align: center;">Thank you!</div>
//       <br />
//       <div id="notices">`;
//       if (order?.bookingNote) {
//         myInvoice += `
//           <div>NOTE</div>
//           <div class="notice" style="white-space: pre-wrap;">${order?.bookingNote}</div>
//           <br />`;
//       }
//       myInvoice += `
//         <div>TERMS & CONDITIONS:</div>
//           <div class="notice">
//             1. All disputes are subjected to Indore Jurisdiction only.
//           </div>

//       </div>
//     </main>
//   </body>
// </html>

//     `;
//     console.log(myInvoice);
//     //html to pdf format
//     var html = htmlToPdfmake(myInvoice, { tableAutoSize: true });
//     // console.error(html);
//     const documentDefinition = { content: html };
//     pdfMake.createPdf(documentDefinition).open();
//   }


  getBoardingPrice(getBoardingData: any, order: any) {
    const allBoadingDataWithPrices = JSON.parse(getBoardingData);
    const BoardPrice = allBoadingDataWithPrices.filter(
      (board: any) => board.bp === order?.travel_boarding_point?.boarding
    )[0]?.bp_price;
    return BoardPrice?.length === 1
      ? BoardPrice[0].singleBoardPrice
      : Object.values(
          BoardPrice.filter(
            (boardPrice: any) =>
              Object.keys(boardPrice)[0] === order?.boardingType
          )[0]
        )[0];
  }

  VoucherList(){
    this.apiService.voucherList().subscribe((resp: any) => {
      console.log(resp.data,"responnnnnn", this.webID);
      resp.data.filter((elem) => {
        // console.log(elem);
        if(elem.user_assign.length > 0){
          elem.user_assign.forEach((item) => {
            console.log(item)
            if(item._id == this.webID && elem.status == 'active'){
              console.log(elem)
              this.assignedVoucher.push(elem)
            }
          })
        }
      })
    });
  }
}

<div class="content">
      <div class="container-fluid">
            <div class="row">
                  <div class="col-md-12">
                        <div class="card card-primary">
                              <form [formGroup]="addTourForm">
                                    <div class="card-body">
                                          <div class="row">
                                                <div class="form-group col-md-4">
                                                      <label for="title">First Name<span
                                                                  class="required"></span></label>
                                                      <input type="text" class="form-control" readonly
                                                            value={{allData?.firstname}}
                                                            placeholder="Enter First Name" />
                                                </div>
                                                <div class="form-group col-md-4">
                                                      <label>Last Name<span class="required"></span></label>
                                                      <input type="text" class="form-control" readonly
                                                            value={{allData?.lastname}} placeholder="Enter Last Name">
                                                </div>
                                                <div class="form-group col-md-4">
                                                      <label>Mobile Number<span class="required"></span></label>
                                                      <input type="text" class="form-control" readonly
                                                            value={{allData?.mobile}} placeholder="Mobile Number">

                                                </div>
                                                <div class="form-group col-md-4">
                                                      <label>Email<span class="required"></span></label>
                                                      <input type="email" class="form-control" placeholder="Email"
                                                            readonly value={{allData?.email}}>

                                                </div>
                                                <div class="form-group col-md-4">
                                                      <label>State<span class="required"></span></label>
                                                      <input type="text" class="form-control" placeholder="State"
                                                            readonly value={{allData?.stateData?.name}}>

                                                </div>
                                          </div>
                                    </div>
                              </form>

                              <div class="card-body">
                                    <div class="row">
                                          <div class="form-group col-md-4">
                                                <label for="class_name">Tour Name <span class="required"></span></label>
                                                <select class="form-control" #cat
                                                      (change)="onCategorySelect($event.target.value);"
                                                      [disabled]="FinalBooking" [(ngModel)]="selectedTour">
                                                      <option value="" selected>Plan Name</option>
                                                      <option *ngFor="let item of allTour; let i = index"
                                                            value="{{item._id }}">
                                                            {{ item.title }}</option>
                                                </select>
                                          </div>
                                          <div class="col-md-4" *ngIf="this.FinalBooking">
                                                <div class="form-group" >
                                                      <label for="class_name">Select Coupon/Voucher </label>
                                                      <select class="form-control"
                                                            (change)="onCoupanSelect($event.target.value);"
                                                            [(ngModel)]="appliedCoupon">
                                                            <option value="" selected>Select Coupon Code</option>
                                                            <option  *ngFor="let item of allCoupan; let i = index" [ngClass]="{
                                                                  'positive' : item.isShow === 'Voucher'
                                                               }"
                                                                  value="{{ item.coupon_code }}" >
                                                                     {{ item.coupon_code }}
                                                            </option>
                                                      </select>
                                                </div>
                                          </div>
                                          <!-- <div class="form-group col-md-4" *ngIf="allOrder?.start_date">
                                                <div class="form-group">
                                                      <label class="title-label">Date <span
                                                                  class="required"></span></label>
                                                      <select class="form-control" id="slct" #dateSchedule
                                                            [disabled]="FinalBooking">
                                                            <option value="" selected="selected">Select option
                                                            </option>
                                                            <option
                                                                  value="{{ date.startDate | date: 'dd/MM/yyyy' }}-{{ date.endDate | date: 'dd/MM/yyyy' }}"
                                                                  *ngFor="let date of allOrder?.dateArr; index as i">
                                                                  Batch {{
                                                                  i+1
                                                                  }} : {{ date.startDate
                                                                  | date:
                                                                  'dd/MM/yy' }} - {{ date.endDate | date: 'dd/MM/yy' }}
                                                                  &nbsp;&nbsp;

                                                            </option>
                                                      </select>
                                                </div>
                                          </div> -->
                                          <!-- <div class="col-md-4" *ngIf="allOrder?.boarding_point">
                                                <div class="form-group">
                                                      <label for="class_name">Boarding Point <span
                                                                  class="required"></span></label>

                                                      <select class="form-control" id="slct" #boardingPt
                                                            (change)="onOptionSelect($event.target.value, 'board');"
                                                            [disabled]="FinalBooking">
                                                            <option value="" [attr.data-getboardid]="" selected>
                                                                  Select Boarding Point</option>

                                                            <option value="{{ boardData?.bp }}"
                                                                  [attr.data-getboardid]="boardData?.id"
                                                                  *ngFor="let boardData of allOrder?.boarding_point_price">
                                                                  {{
                                                                  boardData?.bp }}
                                                            </option>
                                                      </select>
                                                </div>
                                          </div> -->
                                          <!-- <div class="col-md-4"
                                                *ngIf="multipleBoarded?.length !== 0 && multipleBoarded[0]?.bp_type === 'multi'">
                                                <div class="form-group">
                                                      <label for="class_name">Boarding Type<span
                                                                  class="required"></span></label>

                                                      <select class="form-control" id="slct" #boardingMulti
                                                            (change)="onOptionSelect($event.target.value, 'boardMulti');"
                                                            [disabled]="FinalBooking">

                                                            <ng-container
                                                                  *ngFor="let multiboardData of multipleBoarded[0]?.bp_price">

                                                                  <option value="{{ Object.values(multiboardData)[0] }}"
                                                                        *ngIf="Object.values(multiboardData)[0]">
                                                                        {{ Object.keys(multiboardData) }}
                                                                  </option>
                                                            </ng-container>
                                                      </select>
                                                </div>
                                          </div> -->
                                          <!-- <div class="col-md-4" *ngIf="allOrder?.packageType?.length > 0">
                                                <div class="form-group">
                                                      <label for="class_name">Package Type<span
                                                                  class="required"></span></label>

                                                      <select class="form-control" id="slct" #package
                                                            (change)="onOptionSelect($event.target.value, 'pack');"
                                                            [disabled]="FinalBooking">
                                                            <option value="" [attr.data-getpackid]="" selected>
                                                                  Select option</option>

                                                            <option value="{{ packData?.packPrice }}"
                                                                  [attr.data-getpackid]="packData?.id"
                                                                  *ngFor="let packData of allOrder?.packageType_price">
                                                                  {{
                                                                  packData?.packType }} {{packData?._id}}
                                                            </option>
                                                      </select>


                                                </div>
                                          </div>

                                          <div class="col-md-4"
                                                *ngIf="allOrder?.soloBikeType?.length > 0 && packTypeSelected === 'solo-bike'">
                                                <div class="form-group">
                                                      <label for="class_name">Solo Bike<span
                                                                  class="required"></span></label>
                                                      <select class="form-control" id="slct" #solo
                                                            (change)="onOptionSelect($event.target.value, 'solo');"
                                                            [disabled]="FinalBooking">
                                                            <option value="" [attr.data-getsoloid]="" selected>
                                                                  Select option</option>
                                                            <option value="{{ soloData?.soloPrice }}"
                                                                  [attr.data-getsoloid]="soloData?.id"
                                                                  *ngFor="let soloData of allOrder?.soloType_price">
                                                                  {{ soloData?.soloType }}
                                                            </option>
                                                      </select>
                                                </div>
                                          </div>

                                          <div class="col-md-4"
                                                *ngIf="allOrder?.sharedBikeType?.length > 0 && packTypeSelected === 'share-bike'">
                                                <div class="form-group">
                                                      <label for="class_name">Shared Bike<span
                                                                  class="required"></span></label>
                                                      <select class="form-control" id="slct" #share
                                                            (change)="onOptionSelect($event.target.value, 'share');"
                                                            [disabled]="FinalBooking">
                                                            <option value="" [attr.data-getsharedid]="" selected>
                                                                  Select option</option>
                                                            <option value="{{ sharedData?.sharedPrice }}"
                                                                  [attr.data-getsharedid]="sharedData?.id"
                                                                  *ngFor="let sharedData of allOrder?.shareType_price">
                                                                  {{ sharedData?.sharedType }}
                                                            </option>
                                                      </select>
                                                </div>
                                          </div>

                                          <div class="col-md-4" *ngIf="allOrder?.roomSharing?.length > 0">
                                                <div class="form-group">
                                                      <label for="class_name">Room Name<span
                                                                  class="required"></span></label>

                                                      <select class="form-control" id="slct" #roomShare
                                                            (change)="onOptionSelect($event.target.value, 'room');"
                                                            [disabled]="FinalBooking">
                                                            <option value="" [attr.data-getroomid]="" selected>
                                                                  Select option</option>

                                                            <option value="{{ roomData?.roomPrice }}"
                                                                  [attr.data-getroomid]="roomData?.id"
                                                                  *ngFor="let roomData of allOrder?.roomSharing_price">
                                                                  {{ roomData?.roomType }} {{roomData?._id}}
                                                            </option>
                                                      </select>


                                                </div>
                                          </div>

                                          <div class="col-md-4" *ngIf="allOrder?.no_of_traveler">
                                                <div class="form-group">
                                                      <label for="class_name">Number Of Travellar<span
                                                                  class="required"></span></label>
                                                      <select class="form-control" id="slct" #numberOfTraveller
                                                            (change)="onOptionSelect($event.target.value, 'traveller');"
                                                            [disabled]="FinalBooking">
                                                            <option value="" selected="selected">Select option
                                                            </option>
                                                            <option value="{{ traveler }}"
                                                                  *ngFor="let traveler of allOrder?.no_of_alltraveler">
                                                                  {{ traveler }}
                                                            </option>
                                                      </select>

                                                </div>
                                          </div> -->

                                          <div class="form-group col-md-4">
                                                <button type="button" class="btn btn-info mt-4" *ngIf="!this.FinalBooking"
                                                      (click)="handleClick()">
                                                      Confirm
                                                </button>
                                                <!-- <button type="button" class="btn btn-info mt-4" data-toggle="modal"
                                                      data-target="#staticBackdrop" *ngIf="!this.FinalBooking"
                                                      (click)="handleClick()">
                                                      Confirm
                                                </button> -->
                                          </div>
                                          <!-- Modal -->
                                          <div class="modal fade" id="staticBackdrop" data-backdrop="static"
                                                data-keyboard="false" tabindex="-1"
                                                aria-labelledby="staticBackdropLabel" aria-hidden="true"
                                                *ngIf="this.ifTourNotSelected && this.selectedTour != ''">
                                                <div class="modal-dialog modal-lg">
                                                      <div class="modal-content">
                                                            <div class="modal-header">
                                                                  <h5 class="modal-title" id="staticBackdropLabel">Tour
                                                                        Details</h5>
                                                                  <button type="button" class="close"
                                                                        data-dismiss="modal" aria-label="Close"
                                                                        (click)="handleClose()">
                                                                        <span aria-hidden="true">&times;</span>
                                                                  </button>
                                                            </div>
                                                            <div class="modal-body">
                                                                  <ng-container *ngIf="(allOrder | keyvalue)?.length">
                                                                        <div class="row mx-2">
                                                                              <div class="col-12 mb-2">
                                                                                    <h5 class="mb-0 fw-bold">Tour Date
                                                                                    </h5>
                                                                              </div>
                                                                              <div class="col-12">
                                                                                    <div class="inline"
                                                                                          *ngIf="allOrder?.start_date.length > 0">
                                                                                          <!-- <label class="select" for="slct"> -->
                                                                                          <select id="slct"
                                                                                                class="form-control"
                                                                                                required="required"
                                                                                                #dateSchedule>
                                                                                                <option value=""
                                                                                                      selected="selected">
                                                                                                      Select option
                                                                                                </option>
                                                                                                <option
                                                                                                      value="{{ date.startDate | date: 'dd/MM/yyyy' }}-{{ date.endDate | date: 'dd/MM/yyyy' }}"
                                                                                                      *ngFor="let date of allOrder?.dateArr; index as i">
                                                                                                      Batch - {{ i+1 }}:
                                                                                                      {{ date.startDate
                                                                                                      | date: 'dd MMM
                                                                                                      yyyy'
                                                                                                      }} - {{
                                                                                                      date.endDate |
                                                                                                      date:
                                                                                                      'dd MMM yyyy' }}
                                                                                                </option>
                                                                                          </select>
                                                                                          <!-- <svg class="sprites"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  width="24" height="24"
                                                                  viewBox="0 0 24 24">
                                                                  <path d="M12 21l-12-18h24z" />
                                                            </svg> -->
                                                                                          <!-- </label> -->
                                                                                    </div>
                                                                              </div>

                                                                              <ng-container
                                                                                    *ngIf="allPackData?.length !== 0">
                                                                                    <div class="col-12">
                                                                                          <hr>
                                                                                    </div>
                                                                                    <div class="col-12">
                                                                                          <h5 class="mb-0 fw-bold">
                                                                                                Package Type</h5>
                                                                                    </div>
                                                                                    <div class="col-6">Type</div>
                                                                                    <div class="col-4">Price (per
                                                                                          person)</div>
                                                                                    <div class="col-2 text-center"><i
                                                                                                class="fa fa-user"
                                                                                                aria-hidden="true"
                                                                                                style="font-size: x-large;"></i>
                                                                                    </div>

                                                                                    <ng-container
                                                                                          *ngFor="let packageData of allPackData">
                                                                                          <div class="col-6">
                                                                                                {{packageData.type !==
                                                                                                'normal' ?
                                                                                                packageData.type+' X' :
                                                                                                ''}}
                                                                                                {{packageData.packType}}
                                                                                          </div>
                                                                                          <div class="col-4">₹
                                                                                                {{packageData.packPrice}}
                                                                                          </div>
                                                                                          <div class="col-2">
                                                                                                <div
                                                                                                      class="w-auto d-flex justify-content-center align-items-center">
                                                                                                      <button
                                                                                                            class="btn btn-light"
                                                                                                            (click)="addRemovePersons(packageData, 'minus')"
                                                                                                            data-field="quantity">-</button>
                                                                                                      <input type="text"
                                                                                                            value="{{packageData.persons}}"
                                                                                                            readonly
                                                                                                            name="quantity"
                                                                                                            class="quantity-field border-0 text-center w-25 fw-bold">
                                                                                                      <button
                                                                                                            class="btn btn-light"
                                                                                                            (click)="addRemovePersons(packageData, 'add')"
                                                                                                            data-field="quantity">+</button>
                                                                                                </div>
                                                                                          </div>
                                                                                    </ng-container>
                                                                              </ng-container>

                                                                              <div class="col-12">
                                                                                    <hr>
                                                                              </div>
                                                                              <ng-container *ngIf="totalTraveller <= 1">
                                                                                    <div
                                                                                          class="col-12 d-flex align-items-center justify-content-between">
                                                                                          <div>
                                                                                                Total Number Of Traveler
                                                                                          </div>
                                                                                          <div
                                                                                                style="margin-right: 7%;">
                                                                                                {{totalTraveller}}
                                                                                          </div>
                                                                                    </div>
                                                                              </ng-container>
                                                                              <ng-container *ngIf="totalTraveller > 1">
                                                                                    <div
                                                                                          class="col-12 d-flex align-items-center justify-content-between">
                                                                                          <div>
                                                                                                Total Number of
                                                                                                travelers
                                                                                          </div>
                                                                                          <div
                                                                                                style="margin-right: 7%;">
                                                                                                {{totalTraveller}}
                                                                                          </div>
                                                                                    </div>
                                                                              </ng-container>

                                                                              <!-- <div class="col-6">Re Himalayan (Solo)</div>
        <div class="col-4">₹ 39,999/-</div>
        <div class="col-2">
            <div class="w-auto d-flex justify-content-center align-items-center">
                <button class="button-minus icon-shape icon-sm w-25 btn-inc-dec fw-bold" data-field="quantity">-</button>
                <input type="text" value="1" readonly name="quantity" class="quantity-field border-0 text-center w-25 fw-bold">
                <button class="button-plus icon-shape icon-sm w-25 btn-inc-dec fw-bold" data-field="quantity">+</button>
            </div>
        </div>
        <div class="col-6">Re Himalayan (With Pillion)</div>
        <div class="col-4">₹ 31,999/-</div>
        <div class="col-2">
            <div class="w-auto d-flex justify-content-center align-items-center">
                <button class="button-minus icon-shape icon-sm w-25 btn-inc-dec fw-bold" data-field="quantity">-</button>
                <input type="text" value="0" readonly name="quantity" class="quantity-field border-0 text-center w-25 fw-bold">
                <button class="button-plus icon-shape icon-sm w-25 btn-inc-dec fw-bold" data-field="quantity">+</button>
            </div>
        </div> -->

                                                                              <ng-container
                                                                                    *ngIf="allRoomData?.length !== 0">
                                                                                    <div class="col-12">
                                                                                          <hr>
                                                                                    </div>
                                                                                    <div class="col-12">
                                                                                          <h5 class="mb-0 fw-bold">Room
                                                                                                Sharing</h5>
                                                                                    </div>
                                                                                    <div class="col-6">Type</div>
                                                                                    <div class="col-4">Price (per
                                                                                          person)</div>
                                                                                    <div class="col-2 text-center"><i
                                                                                                class="fa fa-user"
                                                                                                aria-hidden="true"
                                                                                                style="font-size: x-large;"></i>
                                                                                    </div>

                                                                                    <ng-container
                                                                                          *ngFor="let roomData of allRoomData">
                                                                                          <div class="col-6">
                                                                                                {{roomData.roomType}}
                                                                                          </div>
                                                                                          <div class="col-4">₹
                                                                                                {{roomData.roomPrice}}
                                                                                          </div>
                                                                                          <div class="col-2">
                                                                                                <div
                                                                                                      class="w-auto d-flex justify-content-center align-items-center">
                                                                                                      <button
                                                                                                            class="btn btn-light"
                                                                                                            (click)="addRemovePersons(roomData, 'minus')"
                                                                                                            data-field="quantity">-</button>
                                                                                                      <input type="text"
                                                                                                            value="{{roomData.persons}}"
                                                                                                            readonly
                                                                                                            name="quantity"
                                                                                                            class="quantity-field border-0 text-center w-25 fw-bold">
                                                                                                      <button
                                                                                                            class="btn btn-light"
                                                                                                            (click)="addRemovePersons(roomData, 'add')"
                                                                                                            data-field="quantity">+</button>
                                                                                                </div>
                                                                                          </div>
                                                                                    </ng-container>
                                                                              </ng-container>

                                                                              <div class="col-12">
                                                                                    <hr>
                                                                              </div>
                                                                              <div class="col-12 mb-2">
                                                                                    <h5 class="mb-0 fw-bold">Boarding
                                                                                          Point</h5>
                                                                              </div>
                                                                              <div class="col-12">
                                                                                    <div class="inline"
                                                                                          *ngIf="allOrder?.boarding_point.length > 0">
                                                                                          <!-- <label class="title-label">BOARDING POINT</label> -->
                                                                                          <!-- <label class="select" for="slct"> -->
                                                                                          <select id="slct"
                                                                                                class="form-control"
                                                                                                #boardingPt
                                                                                                (change)="onOptionSelect($event.target.value, 'board');">
                                                                                                <option value=""
                                                                                                      [attr.data-getboardid]=""
                                                                                                      selected="selected">
                                                                                                      Select option
                                                                                                </option>
                                                                                                <option
                                                                                                      value="{{ boardData?.bp }}"
                                                                                                      [attr.data-getboardid]="boardData?.id"
                                                                                                      *ngFor="let boardData of allOrder?.boarding_point_price">
                                                                                                      {{ boardData?.bp
                                                                                                      }} &nbsp;&nbsp;{{
                                                                                                      getSingleBoardPrice(boardData?.bp)
                                                                                                      }}
                                                                                                </option>
                                                                                          </select>
                                                                                          <!-- <svg class="sprites"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  width="24" height="24"
                                                                  viewBox="0 0 24 24">
                                                                  <path d="M12 21l-12-18h24z" />
                                                            </svg> -->
                                                                                          <!-- </label> -->
                                                                                    </div>
                                                                              </div>
                                                                              &nbsp;
                                                                              <div class="col-12 mb-2">
                                                                                    <div class="inline"
                                                                                          *ngIf="multipleBoarded?.length !== 0 && multipleBoarded[0]?.bp_type === 'multi' ">
                                                                                          <!-- <label class="title-label">BOARDING TYPE</label> -->
                                                                                          <!-- <label class="select" for="slct"> -->
                                                                                          <select id="slct"
                                                                                                class="form-control"
                                                                                                (change)="onOptionSelect($event.target.value, 'boardMulti');"
                                                                                                #boardingMulti>
                                                                                                <ng-container
                                                                                                      *ngFor="let multiboardData of multipleBoarded[0]?.bp_price">

                                                                                                      <option
                                                                                                            value="{{ Object.values(multiboardData)[0] }}"
                                                                                                            *ngIf="Object.values(multiboardData)[0]  != ''">
                                                                                                            {{
                                                                                                            Object.keys(multiboardData)
                                                                                                            }}
                                                                                                            &nbsp;&nbsp;(₹{{
                                                                                                            Object.values(multiboardData)
                                                                                                            }} Per
                                                                                                            Person)
                                                                                                      </option>
                                                                                                </ng-container>
                                                                                          </select>
                                                                                          <!-- <svg class="sprites"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  width="24" height="24"
                                                                  viewBox="0 0 24 24">
                                                                  <path d="M12 21l-12-18h24z" />
                                                            </svg> -->
                                                                                          <!-- </label> -->
                                                                                    </div>
                                                                              </div>


                                                                        </div>
                                                                  </ng-container>
                                                            </div>
                                                            <div class="modal-footer justify-content-between">
                                                                  <h3 class="fw-bold">Total: ₹{{finalPrice}}</h3>
                                                                  <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                                                                  <button type="button" class="btn btn-primary"
                                                                        [disabled]="totalTraveller === 0"
                                                                        (click)="setChange()"
                                                                        *ngIf="!this.FinalBooking">Book Tour</button>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>

                                    </div>
                              </div>
                              <div class="card-body" *ngIf="this.FinalBooking">
                                    <div class="row">
                                          <div class="col-md-4">
                                                <div class="card py-3"
                                                      *ngIf="(allCheckoutTourData | keyvalue)?.length || (allTourData | keyvalue)?.length">
                                                      <ng-container
                                                            *ngIf="allCheckoutTourData.travel_packageType_data?.length !== 0">
                                                            <h6 class="d-flex align-items-center mx-3 mb-0 fw-bold"
                                                                  style="font-weight: 800;">Package Type :</h6>
                                                            <span class="d-flex align-items-center mx-3"
                                                                  *ngFor="let pack of allCheckoutTourData.travel_packageType_data">
                                                                  <ng-container *ngIf="pack.persons !== 0 ">
                                                                        <h6 class="text-muted fw-bolder mb-0">
                                                                              {{pack.type === 'normal' ? '' :
                                                                              pack.type+' X '}}{{pack.packType}}
                                                                        </h6>
                                                                        <hr>
                                                                        <h5 class="ml-auto fw-bolder mb-0 fontmamber">₹
                                                                              {{pack.packPrice?.toLocaleString('en-IN')
                                                                              }} x
                                                                              {{pack.persons}} = ₹ {{(pack.packPrice *
                                                                              pack.persons)?.toLocaleString('en-IN')}}
                                                                        </h5>
                                                                  </ng-container>
                                                            </span>
                                                      </ng-container>

                                                      <hr />
                                                      <ng-container
                                                            *ngIf="allCheckoutTourData.travel_roomSharing_data?.length !== 0">
                                                            <h6 class="d-flex align-items-center mx-3 mb-0 fw-bold"
                                                                  style="font-weight: 800;">Room Sharing :</h6>
                                                            <span class="d-flex align-items-center mx-3"
                                                                  *ngFor="let room of allCheckoutTourData.travel_roomSharing_data">
                                                                  <ng-container *ngIf="room.persons !== 0">
                                                                        <h6 class="text-muted fw-bolder mb-0">
                                                                              {{room.roomType}}</h6>
                                                                        <hr>
                                                                        <h5 class="ml-auto fw-bolder mb-0 fontmamber">₹
                                                                              {{room.roomPrice?.toLocaleString('en-IN')
                                                                              }} x {{room.persons}} = ₹
                                                                              {{(room.roomPrice *
                                                                              room.persons)?.toLocaleString('en-IN')}}
                                                                        </h5>
                                                                  </ng-container>
                                                            </span>
                                                      </ng-container>
                                                      <hr>
                                                      <h6 class="d-flex align-items-center mx-3 mb-0 fw-bold"
                                                            style="font-weight: 800;">Boarding Point :</h6>
                                                      <span class="d-flex align-items-center mx-3">
                                                            <h6 class="text-muted fw-bolder mb-0">{{
                                                                  allCheckoutTourData?.travel_boarding_point?.boarding
                                                                  }} &nbsp;<br>
                                                                  <small *ngIf="allCheckoutTourData?.boardingType"
                                                                        class="fw-bolder"><b>Boarding Type
                                                                              :</b></small><br>
                                                                  {{ allCheckoutTourData?.boardingType ? "(" +
                                                                  allCheckoutTourData?.boardingType + ")" : "" }}
                                                            </h6>
                                                            <h5 class="ml-auto fw-bolder mb-0 fontmamber">₹ {{
                                                                  allCheckoutTourData?.travel_boardingPrice?.toLocaleString('en-IN')
                                                                  }} x {{allCheckoutTourData?.travel_no_of_traveler}} =
                                                                  ₹ {{ (allCheckoutTourData?.travel_no_of_traveler *
                                                                  allCheckoutTourData?.travel_boardingPrice)?.toLocaleString('en-IN')}}
                                                            </h5>
                                                      </span>
                                                      <hr>
                                                      <div *ngIf="this.FinalBooking">
                                                            <span class="d-flex align-items-center mx-3">
                                                                  <h6 class="text-muted fw-bolder mb-0">Total Member
                                                                  </h6>
                                                                  <h5 class="ml-auto fw-bolder mb-0 fontmamber">{{
                                                                        allCheckoutTourData?.travel_no_of_traveler }}
                                                                  </h5>
                                                            </span>
                                                      </div>


                                                      <div *ngIf="isCouponApplied">
                                                            <hr>
                                                            <span class="d-flex align-items-center mx-3">
                                                                  <h6 class="text-muted fw-bolder mb-0">Success Coupon
                                                                        applied
                                                                  </h6>
                                                                  <!-- <h5 class="ml-auto fw-bolder mb-0 font">{{
                                                                        finalPrice?.toLocaleString('en-IN')
                                                                        }}<small class="text-muted ms-auto"
                                                                              *ngIf="discountedPriceByCoupon !== 0"
                                                                              style="font-size: small;"> - ₹{{
                                                                              discountedPriceByCoupon?.toLocaleString('en-IN')
                                                                            }}
                                                                            <span *ngIf="couponRes.coupon_type == 'Voucher'; else notVoucher">(By voucher)</span>
                                                                            <ng-template #notVoucher>
                                                                                  <span>(By coupon)</span>
                                                                            </ng-template>
                                                                            </small></h5>
                                                                  <small *ngIf="couponmessage">Invalid
                                                                        Coupon</small> -->
                                                                  <h5 class="ml-auto fw-bolder mb-0 fontmamber"
                                                                        *ngIf="discountedPriceByCoupon !== 0">- ₹{{
                                                                        discountedPriceByCoupon?.toLocaleString('en-IN')
                                                                      }}
                                                                      <span *ngIf="couponRes.coupon_type == 'Voucher'; else notVoucher">(By voucher)</span>
                                                                      <ng-template #notVoucher>
                                                                            <span>(By coupon)</span>
                                                                      </ng-template>
                                                                  </h5>
                                                                  <small *ngIf="couponmessage">Invalid
                                                                        Coupon</small>

                                                            </span>
                                                      </div>
                                                      <div *ngIf="this.FinalBooking">
                                                            <hr />
                                                            <span class="d-flex align-items-center mx-3">
                                                                  <h6 class="text-muted fw-bolder mb-0"> Total Amount
                                                                  </h6>
                                                                  <h4 class="ml-auto fw-bolder mb-0">{{
                                                                        totalAmountToPaid?.toLocaleString('en-IN')
                                                                        }}</h4>
                                                            </span>

                                                      </div>
                                                </div>
                                                <div class="form-group" *ngIf="this.FinalBooking">
                                                      <label for="class_name">Payment
                                                            Type<span class="required"></span></label>
                                                      <select class="form-control pay_type"
                                                            [(ngModel)]="payment_status">
                                                            <option value="" selected>Payment Type</option>
                                                            <option value="Complete">Completed</option>
                                                            <option value="Partial">Partial</option>
                                                      </select>
                                                </div>
                                                <div class="form-group" *ngIf="this.FinalBooking">
                                                      <label>Note</label>

                                                      <textarea [(ngModel)]="bookingNote" class="form-control" rows="4"
                                                            cols="50"></textarea>
                                                </div>
                                          </div>
                                          <!-- <div class="col-md-2">
                                                <button type="submit" class="btn btn-info" (click)="setChange()"
                                                      style="margin-top: 12px;"
                                                      *ngIf="!this.FinalBooking">Confirm</button>
                                          </div> -->
                                          <div class="col-md-5" *ngIf="this.FinalBooking">
                                                <div class="card">
                                                      <div *ngIf="(allOtherCharges | keyvalue)?.length" class="mt-3">

                                                            <div *ngIf="allData?.stateData?.iso2 === 'MP'; else outer">
                                                                  <span class="d-flex align-items-center mx-3">
                                                                        <h6 class="text-muted fw-bolder mb-0">Sub Total
                                                                        </h6>
                                                                        <h5 class="ml-auto fw-bolder mb-0">₹{{
                                                                              (finalPrice-(amtWithSGST*2))?.toLocaleString('en-IN')
                                                                              }}
                                                                        </h5>
                                                                  </span>

                                                            </div>
                                                            <ng-template #outer>
                                                                  <!-- <span>gfdgfdfg hgfgh</span> -->
                                                                  <span class="d-flex align-items-center mx-3">
                                                                        <h6 class="text-muted fw-bolder mb-0">Sub Total
                                                                        </h6>
                                                                        <h6 class="ml-auto fw-bolder mb-0">₹{{
                                                                              (finalPrice-(amtWithIGST))?.toLocaleString('en-IN')
                                                                              }}
                                                                        </h6>
                                                                  </span>
                                                            </ng-template>
                                                            <hr />
                                                            <div
                                                                  *ngIf="allData?.stateData?.iso2 === 'MP'; else outerUser">
                                                                  <span class="d-flex align-items-center mx-3">
                                                                        <small class="text-muted mb-0">SGST ({{
                                                                              allOtherCharges?.sgst }}%)</small>
                                                                        <small class="ml-auto fw-bolder mb-0">{{
                                                                              amtWithSGST?.toLocaleString('en-IN')
                                                                              }}</small>
                                                                  </span>
                                                                  <hr />
                                                                  <span class="d-flex align-items-center mx-3">
                                                                        <small class="text-muted mb-0">CGST ({{
                                                                              allOtherCharges?.cgst }}%)</small>
                                                                        <small class="ml-auto fw-bolder mb-0">{{
                                                                              amtWithCGST?.toLocaleString('en-IN')
                                                                              }}</small>
                                                                  </span>
                                                            </div>
                                                            <ng-template #outerUser>
                                                                  <span class="d-flex align-items-center mx-3">
                                                                        <small class="text-muted mb-0">IGST ({{
                                                                              allOtherCharges?.igst }}%)</small>
                                                                        <small class="ml-auto fw-bolder mb-0">{{
                                                                              amtWithIGST?.toLocaleString('en-IN')
                                                                              }}</small>
                                                                  </span>
                                                            </ng-template>
                                                            <hr />

                                                      </div>
                                                      <span class="d-flex align-items-center mx-3">
                                                            <small class="text-muted mb-0"
                                                                  style="font-size: 25px;">Total
                                                                  Amount </small>
                                                            <small class="ml-auto fw-bolder mb-0"
                                                                  style="font-size: 31px;">{{
                                                                  totalAmountToPaid?.toLocaleString('en-IN')
                                                                  }}</small>
                                                      </span>

                                                      <div class="py-2 text-center">
                                                            <label class="form-check-label"
                                                                  style="color: gray; margin-bottom: 13px;     margin-left: -13px;"
                                                                  for="partial">
                                                                  Booking Amount - {{
                                                                  allTourData?.bookPrice?.toLocaleString('en-IN')
                                                                  }}/per-person
                                                            </label>
                                                      </div>

                                                </div>
                                                <div class="row text-center">
                                                      <div class="col-12">
                                                            <button type="submit" class="btn btn-info"
                                                                  (click)="booking('booknow')">Booking</button>
                                                            <button type="submit" class="btn btn-warning ml-2"
                                                                  (click)="booking('payonlink')">Booking by
                                                                  link</button>
                                                            <button type="submit" class="btn btn-secondary ml-2"
                                                                  [routerLink]="['/add-user-list']">Cancel</button>
                                                      </div>
                                                </div>

                                          </div>
                                    </div>
                              </div>
                              <div class="row">

                              </div>
                              <div class="card-footer text-right">
                                    <!-- <app-button [type]="'submit'" [block]="false" [text]="'Booking'"
                                                [loading]="isAuthLoading"  [disabled]="isAuthDisabled">
                                          </app-button> -->
                                    <!-- <button type="button" [disabled]="isAuthDisabled"
                                                class="btn btn-secondary ml-2"
                                                [routerLink]="['/add-user-list']">Cancel</button> -->
                              </div>

                        </div>
                  </div>
            </div>

      </div>
</div>

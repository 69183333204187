<table datatable [dtOptions]="dtOptions" class="row-border hover">
  <thead>
    <tr>
      <th>ID</th>
      <th>First name</th>
      <th>Last name</th>
    </tr>
  </thead>
  <tbody *ngIf="persons?.length != 0">
    <tr *ngFor="let person of persons">
      <td>{{ person.id }}</td>
      <td>{{ person.firstName }}</td>
      <td>{{ person.lastName }}</td>
    </tr>
  </tbody>
  <tbody *ngIf="persons?.length == 0">
    <tr>
      <td colspan="3" class="no-data-available">No data!</td>
    </tr>
  </tbody>
</table>


<form [formGroup]="tourForm" >
  <div formArrayName="dateRanges">
    <div *ngFor="let dateRange of dateRanges.controls; let i = index" [formGroupName]="i">
      <div>
        <label>Start Date:</label>
        <input type="date" formControlName="startDate">
        <label>End Date:</label>
        <input type="date" formControlName="endDate">
        <button type="button" (click)="removeDateRange(i)">Remove</button>
      </div>
    </div>
  </div>
  <button type="button" >Add Date Range</button>
  <button type="submit">Submit</button>
</form>


import { Component, OnInit,  OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
//import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-voucher-user-list',
  templateUrl: './voucher-user-list.component.html',
  styleUrls: ['./voucher-user-list.component.scss']
})
export class VoucherUserListComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  private isDtInitialized: boolean = false;
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  public allData: any;
  public editCatRoute: string;
  public addLanName: string;
  public addLanRoute: string;
  id: any;

  constructor(private router: Router,
    private apiService: ApiService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };
    this.UserList()
  }

  UserList(){
    this.apiService.voucherList().subscribe((res: any) => {
      console.log(res)
      if(res.success){
        this.allData = res.data
      }
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    })
  }

  videolist(one, two){

  }

  deletecoupon(id){

  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}

<section>
    <div class="container-fluid">
        <div class="row">
            <div class="px-0 border-right bgimg">
                <div class="p-3">
                    <div class="container">
                        <div class="d-flex justify-content-center row">
                            <div class="col-md-12">
                                <div class="row p-md-3">
                                    <div class="container">

                                        <div class="p-md-5 p-3 bg-white rounded-card shadow">
                                            <ul id="myTab" role="tablist"
                                                class="nav nav-tabs nav-pills flex-column flex-sm-row text-center bg-light border-0 rounded-nav">
                                                <li class="nav-item flex-sm-fill" role="presentation">
                                                    <a id="general-tab" data-toggle="pill" href="#general" type="button"
                                                        role="tab" aria-controls="general" aria-selected="true"
                                                        class="nav-link border-0 text-uppercase font-weight-bold w-100 active">general</a>
                                                </li>
                                                <li class="nav-item flex-sm-fill" role="presentation">
                                                    <a id="traveller-details-tab" data-toggle="pill"
                                                        href="#traveller-details" type="button" role="tab"
                                                        aria-controls="traveller-details" aria-selected="false"
                                                        class="nav-link border-0 text-uppercase font-weight-bold w-100">traveller
                                                        details</a>
                                                </li>
                                                <li class="nav-item flex-sm-fill" role="presentation">
                                                    <a id="add-on-tab" data-toggle="pill" href="#add-on" type="button"
                                                        role="tab" aria-controls="add-on" aria-selected="false"
                                                        class="nav-link border-0 text-uppercase font-weight-bold w-100">add-on</a>
                                                </li>
                                            </ul>
                                            <div id="myTabContent" class="tab-content">
                                                <div id="general" role="tabpanel" aria-labelledby="general-tab"
                                                    class="tab-pane fade px-md-4 show active">
                                                    <div class="card-body pb-0 row">
                                                        <div class="col-md-3">
                                                            <div class="py-1">
                                                                <div class="">First Name</div>
                                                                <h6 class="font-weight-bold">{{
                                                                    order?.user_id?.firstname }}
                                                                </h6>
                                                            </div>
                                                            <div class="py-1">
                                                                <div class="">Boarding Point</div>
                                                                <h6 class="font-weight-bold">{{
                                                                    order?.travel_boarding_point?.boarding }}</h6>
                                                            </div>
                                                            <!-- <div class="py-1">
                                                                <div class="">Total Amount</div>
                                                                <h6 class="font-weight-bold">₹{{
                                                                    order?.finalPrice?.toLocaleString('en-IN',{
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2}) }}
                                                                </h6>
                                                            </div> -->
                                                            <div class="py-1"
                                                                *ngIf="order?.amountPaid > order?.totalAmount;else AmtPaid">
                                                                <div class="">Amount Paid (Tour)</div>
                                                                <h6 class="font-weight-bold">₹{{
                                                                    order?.totalAmount.toLocaleString('en-IN',{
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                    }) }}
                                                                </h6>
                                                            </div>
                                                            <ng-template #AmtPaid>
                                                                <div class="py-1">
                                                                    <div class="">Amount Paid (Tour)</div>
                                                                    <h6 class="font-weight-bold">₹{{
                                                                        order?.amountPaid.toLocaleString('en-IN',{
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2
                                                                        }) }}
                                                                    </h6>
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <div class="py-1">
                                                                <div class="">Last Name</div>
                                                                <h6 class="font-weight-bold">{{ order?.user_id?.lastname
                                                                    }}
                                                                </h6>
                                                            </div>
                                                            <!-- <div class="py-1"
                                                                *ngIf="getPackage(order?.travel_packageType) !== 'Solo bike' && getPackage(order?.travel_packageType) !== 'Shared bike'; else bikeOpt">
                                                                <div class="">Package Type</div>
                                                                <h6 class="font-weight-bold">{{
                                                                    getPackage(order?.travel_packageType) }}</h6>
                                                            </div> -->
                                                            <div class="py-1">
                                                                <div class="">Date
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        style="height: 14px; margin-left: 8px; margin-bottom: 4px;"
                                                                        viewBox="0 0 512 512" (click)="handleClick()"
                                                                        *ngIf="!dateEdit && order?.payment_status != 'Cancelled' && dateDropDown.length != 0"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                                                        <path
                                                                            d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
                                                                    </svg>
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        style="height: 14px; margin-left: 10px; margin-bottom: 4px;"
                                                                        viewBox="0 0 448 512" (click)="handleSaveDate()"
                                                                        *ngIf="dateEdit"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                                                        <path
                                                                            d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                                                                    </svg>
                                                                </div>
                                                                <div *ngIf="dateEdit">
                                                                    <select *ngIf="dateDropDown.length > 0"
                                                                        [(ngModel)]="selectedDate"
                                                                        [value]="selectedDate" class="selectStyle">
                                                                        <option value="">Select Date</option>
                                                                        <option
                                                                            *ngFor="let date of dateDropDown; index as i"
                                                                            value="{{date}}">{{date}}</option>
                                                                    </select>
                                                                    <!-- <select *ngIf="dateDropDown.length == 0">
                                                                        <option value="No date available">No date available</option>
                                                                    </select> -->
                                                                </div>
                                                                <div *ngIf="!dateEdit">
                                                                    <!-- <h6 class="font-weight-bold">{{ order?.travel_date }}
                                                                    </h6> -->
                                                                    <h6 class="font-weight-bold">{{ selectedDate }}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <ng-template #bikeOpt>
                                                                <div class="py-1">
                                                                    <div class="">Package Type</div>
                                                                    <h6 class="font-weight-bold">{{
                                                                        getBikePackage(order?.travel_soloPackageType ?
                                                                        order?.travel_soloPackageType :
                                                                        order?.travel_sharedPackageType) }} ({{
                                                                        getPackage(order?.travel_packageType) }})</h6>
                                                                </div>
                                                            </ng-template>

                                                            <!-- <div class="py-1"
                                                                *ngIf="this.n-(this.n-order?.travel_no_of_traveler)===1 ;else Amt1">
                                                                <div class="">Pending Amount</div>
                                                                <h6 class="font-weight-bold">₹ 0.00</h6>
                                                            </div> -->
                                                            <div class="py-1"
                                                                *ngIf="this.n-(this.n-order?.travel_no_of_traveler)===1 && order?.payment_status==='Cancelled';else Amt1">
                                                                <div class="">Pending Amount</div>
                                                                <h6 class="font-weight-bold">₹ 0.00</h6>
                                                            </div>
                                                            <ng-template #Amt1>
                                                                <div class="py-1"
                                                                    *ngIf="(order?.amountPaid > order?.finalPrice ||( order?.totalAmount< order?.amountPaid));else AmtPaid1">
                                                                    <div class="">Pending Amount</div>
                                                                    <h6 class="font-weight-bold">₹ 0.00</h6>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template #AmtPaid1>
                                                                <div class="py-1">
                                                                    <div class="">Pending Amount</div>
                                                                    <h6 class="font-weight-bold">₹{{
                                                                        (order?.totalAmount-
                                                                        order?.amountPaid).toLocaleString('en-IN',{
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2}) }}
                                                                    </h6>
                                                                </div>
                                                            </ng-template>
                                                            <!-- <div class="py-1"
                                                                *ngIf="order?.amountPaid > order?.totalAmount;else AmtPaid">
                                                                <div class="">Amount Paid (Tour)</div>
                                                                <h6 class="font-weight-bold">₹{{
                                                                    order?.totalAmount.toLocaleString('en-IN',{
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                    }) }}
                                                                </h6>
                                                            </div>
                                                            <ng-template #AmtPaid>
                                                                <div class="py-1">
                                                                    <div class="">Amount Paid (Tour)</div>
                                                                    <h6 class="font-weight-bold">₹{{
                                                                        order?.amountPaid.toLocaleString('en-IN',{
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2
                                                                        }) }}
                                                                    </h6>
                                                                </div>
                                                            </ng-template> -->
                                                        </div>
                                                        <div class="col-md-3">
                                                            <div class="py-1">
                                                                <div class="">Order ID</div>
                                                                <h6 class="font-weight-bold ellipsify">{{
                                                                    order?.order_id }}</h6>
                                                            </div>
                                                            <!-- <div class="py-1">
                                                                <div class="">Date</div>
                                                                <h6 class="font-weight-bold">{{ order?.travel_date }}
                                                                </h6>
                                                            </div> -->
                                                            <div class="py-1">
                                                                <div class="">Payment Status</div>
                                                                <h6 class="font-weight-bold"
                                                                    [ngClass]="[order?.payment_status==='Complete' ? 'text-success' : '', order?.payment_status==='Partial' ? 'text-warning' : '', order?.payment_status==='Cancelled' ? 'text-danger' : '']">
                                                                    {{ order?.payment_status }}</h6>
                                                            </div>

                                                            <div class="py-1"
                                                                *ngIf="order?.travel_no_of_traveler != order?.old_travel_no_of_traveler; else showDiscount">
                                                                <div class="">Final Amount</div>
                                                                <h6 class="font-weight-bold">₹{{
                                                                    order?.totalAmount.toLocaleString('en-IN',{
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2}) }}
                                                                </h6>
                                                            </div>
                                                            <ng-template #showDiscount>
                                                                <div class="py-1"
                                                                    *ngIf="order?.appliedCoupon != '0'; else showActualDis">
                                                                    <div class="">Discount Amount</div>
                                                                    <h6 class="font-weight-bold">₹{{
                                                                        order?.totalAmount?.toLocaleString('en-IN',{
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2}) }}
                                                                    </h6>
                                                                </div>
                                                                <ng-template #showActualDis>
                                                                    <div class="py-1">
                                                                        <div class=""></div>
                                                                        <h6 class="font-weight-bold">
                                                                        </h6>
                                                                    </div>
                                                                </ng-template>
                                                            </ng-template>

                                                            <!-- <div class="py-1"
                                                                *ngIf="this.n-(this.n-order?.travel_no_of_traveler)===1 && order?.payment_status==='Cancelled';else Amt1">
                                                                <div class="">Pending Amount</div>
                                                                <h6 class="font-weight-bold">₹ 0.00</h6>
                                                            </div>
                                                            <ng-template #Amt1>
                                                                <div class="py-1"
                                                                    *ngIf="(order?.amountPaid > order?.finalPrice ||( order?.totalAmount< order?.amountPaid));else AmtPaid1">
                                                                    <div class="">Pending Amount</div>
                                                                    <h6 class="font-weight-bold">₹ 0.00</h6>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template #AmtPaid1>
                                                                <div class="py-1">
                                                                    <div class="">Pending Amount</div>
                                                                    <h6 class="font-weight-bold">₹{{ (order?.totalAmount-
                                                                        order?.amountPaid).toLocaleString('en-IN',{
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2}) }}
                                                                    </h6>
                                                                </div>
                                                            </ng-template> -->

                                                        </div>
                                                        <div class="col-md-3">

                                                            <div class="py-1"
                                                                *ngIf="this.n!== order?.travel_no_of_traveler && order?.payment_status!=='Cancelled' ;else Mem">
                                                                <div class="">Members</div>

                                                                <h6 class="fw-bolder">{{ order?.travel_no_of_traveler }}
                                                                    Members ({{this.n-order?.travel_no_of_traveler}}
                                                                    Member Cancelled)</h6>
                                                            </div>
                                                            <ng-template #Mem>
                                                                <div class="py-1"
                                                                    *ngIf="this.n-(this.n-order?.travel_no_of_traveler)===1 && order?.payment_status==='Cancelled';else Mem1">
                                                                    <div class="">Members</div>

                                                                    <h6 class="fw-bolder">All
                                                                        Members Cancelled</h6>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template #Mem1>
                                                                <div class="py-1"
                                                                    *ngIf="this.n-(this.n-order?.travel_no_of_traveler)!==1 && order?.payment_status==='Cancelled';else Mem2">
                                                                    <div class="">Members</div>

                                                                    <h6 class="fw-bolder">{{
                                                                        order?.travel_no_of_traveler }}
                                                                        Members</h6>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template #Mem2>
                                                                <div class="py-1">
                                                                    <div class="">Members</div>

                                                                    <h6 class="fw-bolder">{{
                                                                        order?.travel_no_of_traveler }}
                                                                        Members</h6>
                                                                </div>
                                                            </ng-template>
                                                            <div class="py-1">
                                                                <div class="">Total Amount</div>
                                                                <h6 class="font-weight-bold">₹{{
                                                                    order?.finalPrice?.toLocaleString('en-IN',{
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2}) }}
                                                                </h6>
                                                            </div>

                                                            <!-- <div class="py-1" *ngIf="order?.travel_no_of_traveler != order?.old_travel_no_of_traveler && order?.appliedCoupon != '0'; else discountTravler">
                                                              <div class="">Discount Amount</div>
                                                              <h6 class="font-weight-bold">₹{{
                                                                  order?.totalAmount?.toLocaleString('en-IN',{
                                                                  minimumFractionDigits: 2,
                                                                  maximumFractionDigits: 2}) }}
                                                              </h6>
                                                            </div>
                                                            <ng-template #discountTravler>
                                                              <div class="py-1">
                                                                <div class=""></div>
                                                                <h6 class="font-weight-bold">
                                                                </h6>
                                                              </div>
                                                            </ng-template> -->
                                                            <!-- <div class="py-1" *ngIf="order?.appliedCoupon!=0; else dothis">
                                                                    <div class=""> Coupoan Discount Amount</div>
                                                                    <h6 class="font-weight-bold ellipsify">₹{{
                                                                        order?.finalPrice - order?.appliedCoupon}}</h6>
                                                                </div>
                                                                <ng-template #dothis>
                                                                    <div class="py-1" style="height:33%;">
                                                                        <div class=""></div>
                                                                        <h6 class="font-weight-bold"
                                                                            >
                                                                            </h6>
                                                                    </div>
                                                                </ng-template> -->

                                                            <!-- <div class="py-1">
                                                                <div class="">Payment Status</div>
                                                                <h6 class="font-weight-bold"
                                                                    [ngClass]="[order?.payment_status==='Complete' ? 'text-success' : '', order?.payment_status==='Partial' ? 'text-warning' : '', order?.payment_status==='Cancelled' ? 'text-danger' : '']">
                                                                    {{ order?.payment_status }}</h6>
                                                            </div> -->

                                                            <div *ngIf="!order?.isRefundedOnCancel" class="py-1">
                                                                <button class="btn btn-curve my-2"
                                                                    [ngClass]="[order?.payment_status==='Complete' ? 'complete-green' : '', order?.payment_status==='Partial' ? 'progress-blue' : '', order?.payment_status==='Cancelled' ? 'cancel-violet' : '']"
                                                                    (click)="sendDataToGenerateInvoice(order)"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    title="INVOICE">INVOICE <i class="far fa-file-pdf"
                                                                        aria-hidden="true"></i>
                                                                </button>
                                                            </div>
                                                            <div *ngIf="order?.isRefundedOnCancel" class="py-1">
                                                                <button class="btn btn-curve my-2"
                                                                    [ngClass]="[order?.payment_status==='Complete' ? 'complete-green' : '', order?.payment_status==='Partial' ? 'progress-blue' : '', order?.payment_status==='Cancelled' ? 'cancel-violet' : '']"
                                                                    (click)="sendDataToGenerateInvoicerefund(order)"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    title="INVOICE">INVOICE<i class="far fa-file-pdf"
                                                                        aria-hidden="true"></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <!-- <div class="mb-0 col-12 d-flex justify-content-end">
                                                            <div class="col-3">
                                                              <button class="btn btn-curve m-1"
                                                                      [ngClass]="[order?.payment_status==='Complete' ? 'complete-green' : '', order?.payment_status==='Partial' ? 'progress-blue' : '', order?.payment_status==='Cancelled' ? 'cancel-violet' : '']"
                                                                      (click)="sendDataToGenerateInvoice(order)"
                                                                      data-bs-toggle="tooltip" data-bs-placement="top"
                                                                      title="INVOICE">INVOICE <i class="far fa-file-pdf"
                                                                          aria-hidden="true"></i>
                                                              </button>
                                                            </div>
                                                        </div> -->
                                                        <div *ngIf="order?.tour_id?.orderNote" style="color: #ff0000;"
                                                            class="mb-0 innerNote d-flex"
                                                            innerHTML="<span class='pe-1'>Note:&nbsp;</span> {{ order?.tour_id?.orderNote }}">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="traveller-details" role="tabpanel"
                                                    aria-labelledby="traveller-details-tab" class="tab-pane fade px-4">
                                                    <div class="contact-form card-body pb-0"
                                                        *ngIf="order?.travellers_id; else noTravellerDetails">
                                                        <div class="border rounded row mb-3"
                                                            *ngFor="let travellers of order?.travellers_id?.traveller_data; let i = index">
                                                            <div class="small legend-title col-md-12">
                                                                <span class="bg-white"> #Traveller {{ i+1 }} </span>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="pl-2">
                                                                    <div class="">Name</div>
                                                                    <h6 class="font-weight-bold">{{ travellers?.name }}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="pl-2">
                                                                    <div class="">Age</div>
                                                                    <h6 class="font-weight-bold">{{ travellers?.age }}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="pl-2">
                                                                    <div class="">Gender</div>
                                                                    <h6 class="font-weight-bold">{{ travellers?.gender |
                                                                        titlecase }}</h6>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="pl-2">
                                                                    <div class="">Email</div>
                                                                    <h6 class="font-weight-bold">{{ travellers?.email }}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="pl-2">
                                                                    <div class="">Phone</div>
                                                                    <h6 class="font-weight-bold">{{ travellers?.phone }}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ng-template #noTravellerDetails>
                                                        <div class="card-body pb-0">
                                                            <h3 class="text-center">No traveller details</h3>
                                                        </div>
                                                    </ng-template>
                                                </div>

                                                <div id="add-on" role="tabpanel" aria-labelledby="add-on-tab"
                                                    class="tab-pane fade px-4">
                                                    <div class="contact-form card-body pb-0">
                                                        <div class="form-group">
                                                            <label for="exampleFormControlTextarea1">Notes</label>
                                                            <textarea class="form-control"
                                                                id="exampleFormControlTextarea1" rows="3"
                                                                [(ngModel)]="addon_notes">{{order?.addons_id?.addon_notes}}</textarea>
                                                            <button class="btn btn-curve m-1 btn-info"
                                                                title="Save & Update" (click)="addaddonnotes()">Save &
                                                                Update
                                                            </button>

                                                        </div>

                                                        <ng-container
                                                            *ngIf="isCancelRemove(order?.travel_date ? order?.travel_date?.split('-')[0] : '')">
                                                            <div class="text-center mb-3"
                                                                *ngIf="order?.payment_status!=='Cancelled'">
                                                                <button class="btn btn-dark" (click)="insertAddOn()"><i
                                                                        class="fas fa-plus-square"></i>&nbsp;&nbsp;Add-on</button>
                                                            </div>
                                                        </ng-container>
                                                        <form [formGroup]="addOnForm">
                                                            <ng-container formArrayName="addOns">
                                                                <ng-container
                                                                    *ngFor="let addOn of addOns.controls; let i = index">
                                                                    <div class="py-0 row" [formGroup]="addOn">
                                                                        <div class="mb-3 col-md-7">
                                                                            <input id="service" class="form-control"
                                                                                placeholder="Service" type="text"
                                                                                name="service"
                                                                                formControlName="service">
                                                                        </div>
                                                                        <div class="mb-3 col-md-2">
                                                                            <input id="price"
                                                                                (keypress)="onlyNumberKey($event)"
                                                                                class="form-control"
                                                                                placeholder="Price/item" type="text"
                                                                                name="price" formControlName="price">
                                                                        </div>
                                                                        <div class="mb-3 col-md-2">
                                                                            <input id="quantity"
                                                                                (keypress)="onlyNumberKey($event)"
                                                                                class="form-control"
                                                                                placeholder="Quantity" type="text"
                                                                                name="quantity"
                                                                                formControlName="quantity">
                                                                        </div>
                                                                        <div
                                                                            class="mb-3 col-md-1 justify-content-center align-items-center d-flex p-0">
                                                                            <i *ngIf="addOn.enabled; else showStatus"
                                                                                class="fas fa-minus-square fa-2x"
                                                                                role="button"
                                                                                (click)="removeAddOn(i)"></i>
                                                                            <ng-template #showStatus>
                                                                                <span>{{ addOn.value.status === 'paid' ?
                                                                                    'Paid' : 'Not Paid' }}</span>
                                                                            </ng-template>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                            <div class="py-0 row">
                                                                <div class="col-md-12 text-center">
                                                                    <ng-container *ngIf="addOns.controls.length">
                                                                        <div class="addon-note">* Add-ons remaining
                                                                            amount includes {{ order?.isOuter ?
                                                                            (order?.igst + '% IGST') : (order?.cgst + '% CGST and ' + order?.sgst + '% SGST') }}
                                                                        </div>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="addOns.controls.length && addOnForm.enabled">
                                                                        <button class="btn btn-curve m-1 btn-info"
                                                                            (click)="submitAddOn()">SUBMIT</button>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="addOns.controls.length && addOnForm.disabled">
                                                                        <button class="btn btn-curve m-1"
                                                                            [ngClass]="[order?.payment_status==='Complete' ? 'complete-green' : '', order?.payment_status==='Partial' ? 'progress-blue' : '', order?.payment_status==='Cancelled' ? 'cancel-violet' : '']"
                                                                            (click)="sendDataToGenerateAddonInvoice(order)"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title="ADD-ON INVOICE">ADD-ON INVOICE <i
                                                                                class="far fa-file-pdf"
                                                                                aria-hidden="true"></i>
                                                                        </button>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="card-body py-0 row">
                                                <div class="col-md-12">
                                                    <hr class=""
                                                        [ngClass]="[order?.payment_status==='Complete' ? 'complete-green-hr' : '', order?.payment_status==='Partial' ? 'progress-blue-hr' : '', order?.payment_status==='Cancelled' ? 'cancel-violet-hr' : '']" />
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="py-1">
                                                        <div class="">Add-ons Amount</div>
                                                        <h6 class="font-weight-bold">₹{{
                                                            this.totalAddonAmt?.toLocaleString('en-IN',{
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2}) }}
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="py-1"
                                                        *ngIf="( order?.totalAmount>= order?.amountPaid) ;else RemAddOn">

                                                        <div class="">Amount Paid (Add-ons)</div>
                                                        <h6 class="font-weight-bold">₹ 0.00
                                                        </h6>
                                                    </div>
                                                    <ng-template #RemAddOn>
                                                        <div class="py-1">
                                                            <div class="">Amount Paid (Add-ons)</div>
                                                            <h6 class="font-weight-bold">₹ {{
                                                                (order?.amountPaid-order?.totalAmount).toLocaleString('en-IN',{
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2}) }}
                                                            </h6>
                                                        </div>
                                                    </ng-template>
                                                    <!--  <ng-template #RemAddOn1>
                                                        <div class="py-1">
                                                            <div class="">Amount Paid (Add-ons)</div>
                                                            <h6 class="font-weight-bold">₹ 0.00
                                                            </h6>
                                                        </div>
                                                    </ng-template>
                                                      <div class="py-1"  *ngIf="this.n-(this.n-order?.travel_no_of_traveler)===1 && order?.payment_status==='Cancelled';else Amt">
                                                        <div class="">Total Remaining Amount</div>
                                                        <h6 class="font-weight-bold">₹0.00
                                                        </h6>
                                                    </div>
                                                   <ng-template #Amt>
                                                    <div class="py-1">
                                                        <div class="">Total Remaining Amount</div>
                                                        <h6 class="font-weight-bold">₹{{ (order?.totalAmount -
                                                            order?.amountPaid)?.toLocaleString('en-IN',{
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2}) }}
                                                        </h6>
                                                    </div>
                                                    </ng-template>-->

                                                </div>

                                                <div class="col-md-2">
                                                    <div class="py-1"
                                                        *ngIf="isCancelled; else Amt2">
                                                        <div class="">Total Remaining Amount</div>
                                                        <h6 class="font-weight-bold">₹{{(0+
                                                            totalUnpaidAddon)?.toLocaleString('en-IN',{
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2}) }}
                                                        </h6>
                                                    </div>
                                                    <ng-template #Amt2>
                                                        <div class="py-1">
                                                            <div class="">Total Remaining Amount</div>
                                                            <h6 class="font-weight-bold">₹{{
                                                                ((order?.totalAmount+this.totalAddonAmt)-
                                                                order?.amountPaid )?.toLocaleString('en-IN',{
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2}) }}
                                                            </h6>
                                                        </div>
                                                    </ng-template>

                                                </div>
                                                <div
                                                    class="col-md-4 d-flex align-items-center justify-content-around flex-wrap">
                                                    <ng-container
                                                        *ngIf="isCancelRemove(order?.travel_date ? order?.travel_date?.split('-')[0] : '')">
                                                        <button class="btn p-1 btn-curve px-3"
                                                            [ngClass]="[order?.payment_status==='Complete' ? 'complete-green' : '', order?.payment_status==='Partial' ? 'progress-blue' : '', order?.payment_status==='Cancelled' ? 'cancel-violet' : '']"
                                                            *ngIf="order?.payment_status!=='Cancelled'"
                                                            (click)="cancelOrder(order?._id)">CANCEL</button>
                                                        <button class="btn p-1 btn-curve px-3"
                                                            [ngClass]="[order?.payment_status==='Complete' ? 'complete-green' : '', order?.payment_status==='Partial' ? 'progress-blue' : '', order?.payment_status==='Cancelled' ? 'cancel-violet' : '']"
                                                            *ngIf="order?.payment_status==='Partial' || order?.payment_status==='Cancelled'"
                                                            data-toggle="modal" data-target="#pay-link">
                                                            LINK PAY 
                                                        </button>
                                                        <button class="btn p-1 btn-curve px-3 progress-blue"
                                                            data-toggle="modal" data-target="#admin-pay-link"
                                                            *ngIf="order?.payment_status==='Partial' || order?.payment_status==='Cancelled'">ADMIN PAY
                                                        </button>
                                                    </ng-container>
                                                    <button class="btn p-1 btn-curve px-3"
                                                        [ngClass]="[order?.payment_status==='Complete' ? 'complete-green' : '', order?.payment_status==='Partial' ? 'progress-blue' : '', order?.payment_status==='Cancelled' ? 'cancel-violet' : '']"
                                                        
                                                        (click)="completeOrder(order?._id, order?.totalAmount, order?.appliedCoupon, order?.cgst, order?.sgst, order?.igst, order?.isOuter, order?.transctionCharge, order?.cusGSTNo, order?.cuscompanyName, order?.cuscompanyAddress, order?.bookingNote, 'payNow', 0)">PAY NOW
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="cancel-members" role="dialog">
        <div class="modal-dialog modal-lg modal_div">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body cancel-members" *ngIf="order?.travellers_id; else noTravellerName">
                    <div class="border rounded row mb-3 cancelMemDiv"
                        *ngFor="let travellers of order?.travellers_id?.traveller_data; let i = index">
                        <div class="small legend-title">
                            <span class="font-weight-bold"> Member {{ i+1 }} </span>
                        </div>
                        <div class="shadow-lg bg-white rounded memDiv1">

                            <div class="divName">Name</div>
                            <h6 class="font-weight-bold nameHeader">{{ travellers?.name }}
                            </h6>
                            <button class="btn btn-danger cancelMemButton"
                                (click)="cancelMembersByName(order?.travellers_id?.traveller_data[i],order?.travellers_id,order?.travel_no_of_traveler)">Cancel</button>

                        </div>
                    </div>
                </div>
                <ng-template #noTravellerName>
                    <div class="border rounded row mb-3 cancelMemdiv" *ngFor="let item of 'x'.repeat(order?.travel_no_of_traveler).split('');
                 let i = index">
                        <div class="shadow-lg bg-white rounded memDiv">
                            <h6 class="font-weight-bold my-3 nameHeader"> Member {{ i+1 }} </h6>
                            <button class="btn btn-danger cancelMemButton2"
                                (click)="cancelMembers(order?._id)">Cancel</button>
                        </div>

                    </div>
                </ng-template>
            </div>
        </div>
    </div>

    <div class="modal fade" id="pay-link" role="dialog">
        <div class="modal-dialog modal-lg modal_div">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">PAY BY LINK</h5>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="linkAmount">Link amount</label>
                                <input id="linkAmount" class="form-control" placeholder="Link amount" type="number"
                                    name="linkAmount" [(ngModel)]="linkAmount">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="linkValidTill">Link valid till</label>
                                <select id="linkValidTill" class="form-control" name="linkValidTill"
                                    [(ngModel)]="linkValidTill">
                                    <option *ngFor="let val of validity" [selected]="val===1" value="{{val}}">{{val}}
                                        hour</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12">
                            <button class="btn m-1 progress-blue btn-block" (click)="sendLink()">Send link</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="admin-pay-link" role="dialog">
        <div class="modal-dialog modal-lg modal_div">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">ADMIN PAY</h5>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="linkAmount">Enter amount</label>
                                <input id="linkAmount" class="form-control" placeholder="Enter amount" type="number"
                                    name="linkAmount" [(ngModel)]="adminAmount">
                            </div>
                        </div>
                        <div class="col-12">
                            <button class="btn m-1 progress-blue btn-block"
                                (click)="completeOrder(order?._id, order?.totalAmount, order?.appliedCoupon, order?.cgst, order?.sgst, order?.igst, order?.isOuter, order?.transctionCharge, order?.cusGSTNo, order?.cuscompanyName, order?.cuscompanyAddress, order?.bookingNote, 'payAdmin', adminAmount)">Pay</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
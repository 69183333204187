import { Component, OnInit, OnDestroy, ViewChild  } from '@angular/core';
import { Subject } from 'rxjs';
//import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-destination-city-list',
  templateUrl: './destination-city-list.component.html',
  styleUrls: ['./destination-city-list.component.scss']
})
export class DestinationCityListComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  private category: string;
  public allData: any;
  public editCatRoute: string;
  public addLanName: string;
  public addLanRoute: string;
  id: any;

  private isDtInitialized: boolean = false;

  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.category = this.router.url.split('/')[1];
    if (this.category === 'destination-city-list') {
      this.addLanName = 'Add Destination ';
      this.addLanRoute = '/destination-city';
      // this.editCatRoute = '/news/edit-news';
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };

    this.destination();
  }

   destination() {
    // console.log(this.addLanguageListForm.value)
    this.apiService.getDesti().subscribe((resp: any) => {
      console.log(resp);
      this.allData = resp.data;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }
    
    async destinationlist(id, status) {
    if (id == undefined || id == '') {
      //this.toastr.error(' Id is Invalid');
      Swal.fire({
        text: 'Id is Invalid',
        icon: 'error',
      });
      return false;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure, you want to change status?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        let getStatus = {};
        getStatus =
          status === 'active' ? { status: 'inactive' } : { status: 'active' };
        this.apiService
          .changeDestiStatus(id, JSON.stringify(getStatus))
          .subscribe((result: any) => {
            if (result.success) {
              Swal.fire({
                text: result.message,
                icon: 'success',
              });
              //this.toastr.success(result.message);
              this.destination();
            } else {
              Swal.fire({
                text: result.message,
                icon: 'error',
              });
              //this.toastr.error(result.message);
            }
          });
      }
    });
  }



    ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}

import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder, FormControl, FormGroup, Validators, FormArray
} from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
declare const window: any;
@Component({
  selector: 'app-make-trip-overview',
  templateUrl: './make-trip-overview.component.html',
  styleUrls: ['./make-trip-overview.component.scss'],
})
export class MakeTripOverviewComponent implements OnInit {
  addMakeTripTwoForm: FormGroup =  this.formBuilder.group({
    overview: ['', [Validators.required]],
    overview_title: ['', [Validators.required]],
    region: ['', [Validators.required]],
    highAltitude: ['', [Validators.required]],
    pickAndDrop: ['', [Validators.required]],
    tripAndTrekType: ['', [Validators.required]],
    nameOfPlace: ['', [Validators.required]],
    no_of_place: ['', [Validators.required]],
    difficulty: ['', [Validators.required]],
    best_time: ['', [Validators.required]],
    whatsapp_number: ['', [Validators.required]],
    multiple_number: ['', [Validators.required]],
    overviewNote: ['', [Validators.required]],
    multiple_overviewdesc: this.formBuilder.array([this.addAdditionalInfoGroup()])
  });
  // addMakeTripTwoForm: FormGroup =  this.formBuilder.group({
  //   inculssion: ['', [Validators.required]],
  //   summary: ['', [Validators.required]],
  //   exculssion: ['', [Validators.required]],
  //   summaryExc: ['', [Validators.required]],
  //   //main_menu: ['', [Validators.required]],
  //   multiple_url: this.formBuilder.array([this.addAdditionalInfoGroup()])
  //   // blog_image: [''],
  //   // status: ['', []],
  // });
  public dynamicDesc: any = [];
  //@ViewChild('div') div: ElementRef;
  @ViewChild('div', { static: false }) div: ElementRef;
  //private tripInfo = JSON.parse(localStorage.getItem("tripInfo") || '{}');
  public minDate = undefined;
  public isAuthLoading: boolean = false;
  public isAuthDisabled: boolean = false;
  id: any;
  object: any;
  show: any;
  tripId: any;
  tripsId:any;
  public i: number = 1;
  pricingid: any;
  private addAdditionalInfoGroup(): FormGroup {
    return this.formBuilder.group({
      overview_title: [],
      overview_desc: []
    });
  }
  //Add Fields

  //Add Fields
  addAdditionalInfo(): void {
    this.additionalInfo.push(this.addAdditionalInfoGroup());
  }

  //Remove Fields
  removeAdditionalInfo(index: number): void {
    this.additionalInfo.removeAt(index);
  }

  //Fields Array
  get additionalInfo(): FormArray {
    return <FormArray>this.addMakeTripTwoForm.get('multiple_overviewdesc');
  }

  //name : any;
  config_ck = {
    uiColor: '#ffffff',
    toolbarGroups: [
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
      { name: 'links' },
      { name: 'insert' },
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
      { name: 'styles' },
      { name: 'colors' },
    ],
    // skin: 'kama',
    resize_enabled: false,
    removePlugins: 'elementspath,save,magicline',
    //extraPlugins: 'uploadimage,justify',
    colorButton_foreStyle: {
      element: 'font',
      attributes: { color: '#(color)' },
    },
    height: 188,
    removeDialogTabs: 'image:advanced;link:advanced',
    removeButtons: 'Subscript,Superscript,Anchor,Source,Table',
    format_tags: 'p;h1;h2;h3;pre;div',
    extraPlugins: 'uploadimage,justify',
    uploadUrl: 
      'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json',

    // Configure your file manager integration. This example uses CKFinder 3 for PHP.
    filebrowserBrowseUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html',
    filebrowserImageBrowseUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html?type=Images',
    filebrowserUploadUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files',
    filebrowserImageUploadUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Images',
  };

  constructor(
    private formBuilder: FormBuilder,
    //private router: Router,
    private apiService: ApiService,
    //private toastr: ToastrService,
    private router: Router,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {}

  //@ViewChild('div') div: ElementRef;

  ngOnInit(): void {
    //this.object = this.route.snapshot.paramMap.get('objId');
    this.object = this.route.snapshot.paramMap.get('objId');
    console.log(this.object)
    if (this.object != undefined && this.object != null && this.object != '') {
      this.edittriptwo(this.object);
    }
    //console.log(this.object);
  }

  addElement() {
    const p: HTMLParagraphElement = this.renderer.createElement('div');
    p.innerHTML = `
      <tr id="row${this.i}"><td><label class="col-md-3 control-label">Description</label><textarea class="ckeditor form-control" formControlName="desc${this.i}" id="desc${this.i}" rows="6" style="width:50%;" value=""></textarea></td></tr>
    `;
// let inputElement: HTMLElement = this.fileInput.nativeElement as HTMLElement;
//  inputElement.click();
    this.renderer.appendChild(this.div.nativeElement, p);
    window.CKEDITOR.replace('desc' + this.i);
    this.addMakeTripTwoForm.addControl(
      `desc${this.i}`,
      new FormControl('', Validators.required)
    );
    this.i++;
  }

  edittriptwo(id) {
    let dataForForm = {
      id: id,
    };
    //console.log(this.object);
    this.spinner.show()
    this.apiService.tourUpdate(dataForForm).subscribe((result: any) => {
      console.log(result.getData);
      if (result.success) {
        this.spinner.hide();
        this.pricingid = result?.getData?.bookPrice;
        //this.show = TripData.overview
        let TripData: any = result.getData;
        this.show = TripData.
        multiple_overviewdesc
        console.log(this.show)
        this.tripId = TripData._id;
        this.addMakeTripTwoForm.patchValue({
          //title: TripData.title,
          overview_title: TripData.overview_title,
          overview: TripData.overview,
          region: TripData.region,
          highAltitude: TripData.highAltitude,
          tripAndTrekType: TripData.tripAndTrekType,
          nameOfPlace: TripData.nameOfPlace,
          pickAndDrop: TripData.pickAndDrop,
          no_of_place: TripData.no_of_place,
          difficulty: TripData.difficulty,
          best_time: TripData.best_time,
          whatsapp_number: TripData.whatsapp_number,
          multiple_number: TripData.multiple_number,
          overviewNote : TripData.overviewNote,
          //Overwiew1: TripData.Overwiew1[0].desc,
       });
         // Clear existing form array
      while (this.additionalInfo.length !== 0) {
        this.additionalInfo.removeAt(0);
      }
       const control: any = <FormArray>this.addMakeTripTwoForm.controls['multiple_overviewdesc'];
       // Iterate through the 'multiple_overviewdesc' array and add form groups to the form array
       TripData.multiple_overviewdesc.forEach((element: any) => {
         let fg = this.formBuilder.group({
           overview_desc: [element.overview_desc, [Validators.required]],
           overview_title: [element.overview_title, [Validators.required]],
         });
         control.push(fg);
       });
      }else{
          this.spinner.hide();
      }
    });
  }

  addMakeTourTwo(id) {
    if (
      this.addMakeTripTwoForm.value.overview == undefined ||
      this.addMakeTripTwoForm.value.overview.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter overview',
        icon: 'warning',
      });
      return false;
    }
    if (
      this.addMakeTripTwoForm.value.region == undefined ||
      this.addMakeTripTwoForm.value.region.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter region',
        icon: 'warning',
      });
      return false;
    }

    if (
      this.addMakeTripTwoForm.value.highAltitude == undefined ||
      this.addMakeTripTwoForm.value.highAltitude.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter high altitude',
        icon: 'warning',
      });
      return false;
    }

    if (
      this.addMakeTripTwoForm.value.pickAndDrop == undefined ||
      this.addMakeTripTwoForm.value.pickAndDrop== ''
    ) {
      Swal.fire({
        text: 'Please enter pick & drop',
        icon: 'warning',
      });
      return false;
    }

    if (
      this.addMakeTripTwoForm.value.tripAndTrekType == undefined ||
      this.addMakeTripTwoForm.value.tripAndTrekType.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter trip/trek',
        icon: 'warning',
      });
      return false;
    }

    if (
      this.addMakeTripTwoForm.value.nameOfPlace == undefined ||
      this.addMakeTripTwoForm.value.nameOfPlace.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter name of places',
        icon: 'warning',
      });
      return false;
    }
     if (
      this.addMakeTripTwoForm.value.no_of_place == undefined ||
      this.addMakeTripTwoForm.value.no_of_place.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter nomber of places',
        icon: 'warning',
      });
      return false;
    }
    if (
      this.addMakeTripTwoForm.value.difficulty == undefined ||
      this.addMakeTripTwoForm.value.difficulty.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter difficulty',
        icon: 'warning',
      });
      return false;
    }
    if (
      this.addMakeTripTwoForm.value.best_time == undefined ||
      this.addMakeTripTwoForm.value.best_time.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter best time',
        icon: 'warning',
      });
      return false;
    }

    if (
      this.addMakeTripTwoForm.value.overviewNote == undefined ||
      this.addMakeTripTwoForm.value.overviewNote.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter Over View Note',
        icon: 'warning',
      });
      return false;
    }
    console.log(this.addMakeTripTwoForm.value);
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    //return;
    this.apiService
      .updateTour(this.addMakeTripTwoForm.value, this.object)
      .subscribe((res: any) => {
        //console.log(this.tripInfo._id)
        //return;
        console.log(res);
        if (res.success) {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            this.router.navigateByUrl('/make-trip-itinerary/' + this.object);
            console.log(this.object);
        } else {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
          //this.toastr.error(res.message);
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
        }
      });
  }
}

import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/utils/services/api.service';
import { AppService } from 'src/app/utils/services/app.service';
import Swal from 'sweetalert2';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { log } from 'console';

import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
//import { log } from 'console';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-user-particular-order',
  templateUrl: './user-particular-order.component.html',
  styleUrls: ['./user-particular-order.component.scss']
})
export class UserParticularOrderComponent implements OnInit {
  fornviewID: string[] = [];
  backviewID: string[] = [];
  apiHost = environment.API_HOST;
  minDate = { year: 1900, month: 1, day: 1 };
  private userData = JSON.parse(localStorage.getItem("userToken") || '{}');
  // @ViewChild('appendAddOn') appendAddOn:ElementRef;
  public addOnForm: FormGroup;
  addUserForm: FormGroup;
  private orderId: any;
  public order: any;
  addon_notes: any;
  aboutData: any;
  public travellerArr: any = [];
  public allPackageData: any;


  public selectedTour: any = '';
  public ifTourNotSelected: any = false;
  public idForPackage: any;
  tourID: any = {};
  selectebatchID: any;
  allTourdate: any;
  allData: any;
  allDates: any;
  allCoupan: any;
  allOrder: any = {};
  webID: any;
  allTour: any;
  getData: any;
  selectebatchPrice: any;
  payment_status: string = '';
  tourid: any;
  childprice: any;
  infantprice: any;
  public allPackData: any[] = [];
  public allRoomData: any[] = [];
  public bikeopt: any;
  public packTypeSelected: string;
  public packBikePrice: number = 0;
  public totalTraveller: number = 0;
  public totalTravellercustomized: number = 0;
  public finaltotalTraveller: number = 0;
  public numberofchild: number = 0;
  public numberofinfant: number = 0;
  public customizedpackageprice: number = 0;
  public customizedroomprice: number = 0;
  public toltalchidprice: number = 0;
  public toltalinfantprice: number = 0;

  public discountedPriceByCoupon: number = 0;
  public isCouponApplied: boolean = false;
  public isCouponNotApplied: boolean = false;
  bookingNote: string = '';
  public couponRes: any;
  startDate: string; // You can use Date type if you prefer
  endDate: string;   // You can use Date type if you prefer
  minStartDate: string;
  packageValues: number[] = new Array(this.allPackData.length).fill(0);
  roomValues: number[] = new Array(this.allRoomData.length).fill(0);
  numbercustomizedpackage: number[] = [];
  numbercustomizedroom: number[] = [];
  public packages: any;
  public rooms: any;
  public boardings: any;
  public allTourData: any;
  public allOtherCharges: any;
  public finalPrice: number = 0;
  customizedpackagetotaluser: any;
  tourtype: any;
  FinalBooking: boolean = false;
  couponmessage: boolean = false;
  public packPrice: number = 0;
  public roomPrice: number = 0;
  public boardPrice: number = 0;
  public no_of_traveller: number = 0;
  public totalPrice: number = 0;
  public multipleBoarded: any = [];
  public Object = Object;
  dataArray: any[] = [];
  dataArrayroom: any[] = [];
  boardingPointPricesEntered = {
    singal: false,
    sleeper: false,
    ac3: false,
    ac2: false,
    ac1: false,
    flight: false
  };
  boardingPointPrices: number = 0;
  boardingPointType: any
  enteredPrices: { boardingPoint: string, price: number }[] = [];
  priceEntered: any;
  public allCheckoutTourData: any;
  private user_id: string = localStorage.getItem('id');
  public allBikeData: any;
  // public allRoomData: any;
  private logoImgInDateURL: string = '';
  public openProfile: boolean = true;
  public maxDate = new Date().toISOString().slice(0, 10);
  public submitted: boolean = false;
  public totalUnpaidAddon: number = 0;
  public totalAddonAmt: number = 0;
  private _userId: string = localStorage.getItem('id');
  addondata: any;
  count: number = 0;
  addOnData1: any = [];
  validity: number[] = Array.from({ length: 24 }, (_, i) => i + 1);
  linkAmount: number | undefined;
  linkValidTill: number = 1;
  dateEdit: any = false;
  dateDropDown: any = [];
  selectedDate: any;
  adminAmount: number | undefined;
  public basicPrice: number = 0;
  @ViewChild('dateSchedule') dateSchedule: ElementRef;
  @ViewChild('boardingPt') boardingPt: ElementRef;
  @ViewChild('boardingMulti') boardingMulti: ElementRef;
  singlachildprice: any;
  singlainfentprice: any;
  public appliedCoupon: string = '';
  public amtWithCGST: number;
  public amtWithSGST: number;
  public amtWithIGST: number;
  public totalAmountToPaid: number;
  packagePrices: number[] = [];
  kidsValue: string = '';
  infantValue: string = '';
  customizedroomuser: any
  roomPrices: number[] = [];
  singalboardingprice: number = 0;
  ac3Price: number = 0;
  ac2Price: number = 0;
  ac1Price: number = 0;
  flightPrice: number = 0;
  sleeperPrice: number = 0;
  cusGSTNo: string = '';
  public cuscompanyAddress: string = '';
  public cuscompanyName: string = '';
  orderbatchBYID: any;





  private toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  amt: number;
  n: any;
  totalpaidAddon: number = 0;
  totalPayAmt: any;
  id: any;
  tour_type: any;
  pdf: any;
  tour: any;
  traveller: any;
  tourType: any;
  domesticorInternational: any;
  batchNumber: any;
  country: any;
  roomdata: any;
  myUserId: any;
  myData: any;
  boardingdata: any;
  mytourids: any;
  applyTourID: any;
  travellerID: [];
  selectedTab: any;
  oldtrip: any;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private apiService: ApiService,
    private appService: AppService,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
  ) {
    this.addOnForm = new FormGroup({
      addOns: this.fb.array([])
    });

  }

  @ViewChild('idNumberInput', { static: false }) idNumberInput!: ElementRef;

  ngOnInit(): void {
    this.selectedDate = '';
    this.route.params.subscribe(data => {
      this.orderId = data?.orderId;
      this.getAllPackageData();
      this.getAllBikeData();
      this.getAllRoomData();
      this.addUserForm = this.fb.group({
        travelers: this.fb.array([])
      });

      this.getAllOrder();
      this.invoiceLogoImgToDataURL();

      // setTimeout(() => {
      //   this.getalladdonnote(this.order._id);
      // }, 2000);
    });
    // setTimeout(() => {
    //   this.spinner.show();
    //   JSON.parse(this.order?.tour_id?.start_date)?.filter((elem, i) => {
    //     if (new Date(elem).getTime() > Date.now()) {
    //       // console.log(elem.split('/')[1], elem.split('/')[0], elem.split('/')[2], JSON.parse(this.order.tour_id.end_date)[i].split('/')[1], JSON.parse(this.order.tour_id.end_date)[i].split('/')[0], JSON.parse(this.order.tour_id.end_date)[i].split('/')[2])
    //       let startDatePart = `${elem.split('/')[1]}/${elem.split('/')[0]}/${elem.split('/')[2]}`
    //       let endDatePart = `${JSON.parse(this.order.tour_id.end_date)[i].split('/')[1]}/${JSON.parse(this.order.tour_id.end_date)[i].split('/')[0]}/${JSON.parse(this.order.tour_id.end_date)[i].split('/')[2]}`
    //       // console.log(startDatePart, endDatePart)
    //       this.dateDropDown.push(`${startDatePart} - ${endDatePart}`)
    //       this.spinner.hide();
    //     }
    //   })
    // }, 2000)


  }

  isEditModalOpen: boolean = false;


  openEditModal() {
    this.isEditModalOpen = true;

  }

  closeEditModal() {
    this.isEditModalOpen = false;
  }

  isButtonDisabled = false;
  handleClickk() {
    // if (this.selectedTour) {
    //   Swal.fire({
    //     text: "Please select a tour.",
    //     icon: 'warning',
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Ok',
    //   });
    //   return; 
    // }
    this.handleClose()
    this.isButtonDisabled = true;
    this.ifTourNotSelected = true;
    console.log(this.id);

    this.apiService.orderID({ id: this.id }).subscribe((res: any) => {
      console.log(res.getData, 'devvvvvvvvvvvvvvvvvv');
      console.log(this.getAllRoomData, ">>>>>>>>>>>");

      this.tourID = res.getData._id;
      this.allTourdate = res.getData;
      this.selectebatchID = res.getData.select_batch[0]._id;
      console.log(this.selectebatchID);
      this.allOrder = res.getData.select_batch[0];
      this.childprice = res.getData.select_batch[0].child_price;
      this.infantprice = res.getData.select_batch[0].infant_price;
      console.log(this.childprice, this.infantprice);
      this.allDates = res.Datedata;
      this.allDates.start_date = JSON.parse(this.allDates.start_date);
      this.allDates.end_date = JSON.parse(this.allDates.end_date);
      this.allDates.dateArr = [];
      this.tourType = res.getData.tourtype

      console.log(this.tourType, ">>>>>>>>>>");

      this.domesticorInternational = res.getData.domesticorInternational
      for (let i = 0; i < this.allDates.start_date.length; i++) {
        if ((new Date()).getTime() < (new Date(this.allDates.start_date[i])).getTime()) {
          this.allDates.dateArr.push({
            startDate: this.allDates.start_date[i],
            endDate: this.allDates.end_date[i],
          });
        }
      }

      this.allDates.dateArr.sort((a: any, b: any) => {
        return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
      });

      if (this.allDates.dateArr.length === 0) {
        Swal.fire({
          title: 'No schedule available for this tour',
          text: "Please contact us for more details",
          icon: 'error',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok',
        });
      } else {

        this.allOrder.no_of_alltraveler = Array(+this.allOrder.no_of_traveler).fill(0).map((x, i) => (i + 1));
        this.totalPrice = this.basicPrice = +this.allOrder?.bookPrice;
        const allPackPriceData = JSON.parse(this.allOrder.packageType_price);
        this.getAllPackageDataa(allPackPriceData);
        this.getAllRoomShareData(JSON.parse(this.allOrder.roomSharing_price));
        this.getAllBoardingData(JSON.parse(this.allOrder.boarding_point_price));

        ($("#staticBackdrop") as any).modal("show");
      }

      this.isButtonDisabled = false;
    }, (error) => {
      this.isButtonDisabled = false;
      console.error('Error fetching order data:', error);
    });
  }


  onChange(selectedValue: any) {
    console.log(selectedValue);
    this.allPackData = [];
    this.allRoomData = [];
    this.numberofchild = 0
    this.toltalchidprice = 0
    this.numberofinfant = 0
    this.toltalinfantprice = 0
    // Handle the logic based on the selected value
    console.log('Selected value:', selectedValue);
    let dataForForm = {
      batch_id: selectedValue,
    };
    this.spinner.show();
    this.apiService.getbatchlistBy(dataForForm, this.tourID).subscribe((result: any) => {
      console.log(result.
        select_batch
      );
      this.selectebatchPrice = result.select_batch
      this.batchNumber = result.select_batch.batchNumber
      console.log(this.batchNumber, ">>>>>>>>");

      //console.log(result.getData.summary, 'hiiiiiiiiiiiiii');
      if (result.success) {
        this.childprice = result.
          select_batch.child_price
        this.infantprice = result.
          select_batch.infant_price
        console.log(this.childprice, this.infantprice)
        this.selectebatchPrice.no_of_alltraveler = Array(+this.selectebatchPrice.no_of_traveler).fill(0).map((x, i) => (i + 1));
        this.totalPrice = this.basicPrice = +this.selectebatchPrice?.bookPrice;
        const allPackPriceData = JSON.parse(this.selectebatchPrice.packageType_price);
        this.getAllPackageDataa(allPackPriceData);
        this.getAllRoomShareData(JSON.parse(this.selectebatchPrice.roomSharing_price));
        this.getAllBoardingData(JSON.parse(this.selectebatchPrice.boarding_point_price));
        this.spinner.hide();
      }
    });
  }

  handleClose() {
    // this.allOrder = '';
    // this.selectedTour == '';
    // this.ifTourNotSelected = false;
    // this.onCategorySelect('');
    console.log(this.allPackData, this.allRoomData);
    this.allPackData = [];
    this.allRoomData = [];
    this.numberofchild = 0
    this.toltalchidprice = 0
    this.numberofinfant = 0
    this.toltalinfantprice = 0
    this.startDate = '';
    this.endDate = '';
    this.packageValues = []
    this.roomValues = []
    this.numbercustomizedpackage = [];
    this.numbercustomizedroom = [];
    this.customizedpackageprice = 0;
    this.customizedroomprice = 0;
    // this.sleeperPrice = 0;
    // this.ac3Price = 0;
    // this.ac2Price = 0;
    // this.ac1Price = 0;
    // this.flightPrice = 0;
    // this.singalboardingprice = 0;
    //this.calculatePrice(); 
    //this.totalTravellercustomized = 0;

  }


  getAllPackageDataa(data: any) {
    this.apiService.getAllPack().subscribe((res: any) => {
      if (res.success) {
        this.packages = res.data;
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < this.packages.length; j++) {
            if (data[i].packType === this.packages[j].package_type)
              data[i].id = this.packages[j]._id;
          }
        }
        // console.error(data);
        this.allOrder.packageType_price = data;
        const nonBikePack = data.filter((pack) => (pack.packPrice !== 'solo-bike' && pack.packPrice !== 'share-bike'));
        console.log(data, 'dattaa', nonBikePack);
        if (nonBikePack.length)
          this.allPackData.push(...nonBikePack.map((pack) => { return { ...pack, type: 'normal', persons: 0 } }));
        for (let data of this.allPackData) {
          this.numbercustomizedpackage.push(0);
        }
        this.reorderPackage(this.allPackData);
        console.log(this.allPackData)

      } else {
        console.error(res);
      }
    });
  }

  getAllRoomShareData(data: any) {
    //console.log(data);
    this.apiService.AllRooms().subscribe((res: any) => {
      if (res.success) {
        this.rooms = res.data;
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < this.rooms.length; j++) {
            if (data[i].roomType === this.rooms[j].room_name)
              data[i].id = this.rooms[j]._id;
          }
        }
        this.allOrder.roomSharing_price = data;
        this.allRoomData.push(...data.map(room => { return { ...room, persons: 0 } }));
        for (let data of this.allRoomData) {
          this.numbercustomizedroom.push(0);
        }
        this.allRoomData.sort((a, b) => (+a.roomPrice > +b.roomPrice) ? 1 : ((+b.roomPrice > +a.roomPrice) ? -1 : 0));
      } else {
        console.error(res);
      }
    });
  }

  getAllBoardingData(data: any) {
    this.apiService.getAllBoarding().subscribe((res: any) => {
      if (res.success) {
        this.boardings = res.data;
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < this.boardings.length; j++) {
            if (data[i].bp === this.boardings[j].boarding)
              data[i].id = this.boardings[j]._id;
          }
        }
        // console.error(data);
        this.allOrder.boarding_point_price = data;
        return data;
      } else {
        console.error(res);
      }
    });
  }

  reorderPackage(packArr: any[]) {
    packArr.sort((a, b) => (+a.packPrice > +b.packPrice) ? 1 : ((+b.packPrice > +a.packPrice) ? -1 : 0));
    this.allPackData = packArr.map((pack, i) => {
      if (i === 0) return { ...pack, persons: 0 }
      else return { ...pack, persons: 0 }
    });
    this.calculatePrice();
  }

  calculatePrice() {
    this.finalPrice = 0;
    const totalPriceByPackage = this.allPackData.reduce((total, data) => {
      return total + data.packPrice * data.persons
    }, 0);
    //console.log(totalPriceByPackage)
    const totalPriceByRoom = this.allRoomData.reduce((total, data) => {
      return total + data.roomPrice * data.persons
    }, 0);
    this.totalTraveller = this.allPackData.reduce((total, data) => {
      return total + data.persons
    }, 0);
    this.finaltotalTraveller = this.numberofchild + this.totalTraveller + this.numberofinfant
    this.totalTravellercustomized = this.getTotalSelectedPackages() + this.numberofchild + this.numberofinfant
    console.log(this.totalTravellercustomized, 'll')
    if (this.tourtype == 'customized') {
      //this.finalPrice = 0;
      this.finalPrice = this.customizedpackageprice + this.customizedroomprice + this.toltalchidprice + this.toltalinfantprice + this.totalTravellercustomized * this.boardingPointPrices;
      console.log(this.customizedpackageprice + this.customizedroomprice + this.toltalchidprice + this.toltalinfantprice + this.totalTravellercustomized * this.boardingPointPrices)
    } else {
      this.finalPrice = totalPriceByPackage + totalPriceByRoom + this.toltalchidprice + this.toltalinfantprice + this.finaltotalTraveller * this.boardPrice;
    }

  }
  getTotalSelectedPackages(): number {
    let total = 0;
    for (let count of this.numbercustomizedpackage) {
      total += count;
    }
    this.customizedpackagetotaluser = total
    //this.calculatePrice()
    //console.log(this.customizedpackagetotaluser)
    return total;
  }

  addRemovePersons(obj: any, type: string) {
    // console.log(obj, type )
    // return
    if ("roomType" in obj && this.totalTraveller <= this.allRoomData.reduce((total, data) => total + data.persons, 0)) {
      this.toast.fire({
        icon: 'error',
        title: 'Add travellers to add rooms',
      });
      // return;
    }
    if ("packType" in obj) {
      this.allRoomData.map(room => room.persons = 0);
    }
    if (type === 'add' && this.totalTraveller > this.allRoomData.reduce((total, data) => total + data.persons, 0)) obj.persons++
    else if ("packType" in obj && type === 'add' && this.totalTraveller == 0 && this.allRoomData.reduce((total, data) => total + data.persons, 0) == 0) obj.persons++
    else if (type === 'minus' && obj.persons > 0) obj.persons--
    this.calculatePrice();
  }

  addremovechild(obj: any, type: string) {
    console.log(obj, type)
    if (type === 'add') {
      this.numberofchild++
      this.toltalchidprice = obj * this.numberofchild
      console.log(this.toltalchidprice)
    } else if (type === 'minus' && this.numberofchild > 0) {
      this.numberofchild--
      this.toltalchidprice = this.toltalchidprice - obj
      console.log(this.toltalchidprice)
    }
    this.calculatePrice()
  }
  addremovecinfant(obj: any, type: string) {
    console.log(obj, type)
    if (type === 'add') {
      this.numberofinfant++
      this.toltalinfantprice = obj * this.numberofinfant
      console.log(this.toltalinfantprice)
    } else if (type === 'minus' && this.numberofinfant > 0) {
      this.numberofinfant--
      this.toltalinfantprice = this.toltalinfantprice - obj
      console.log(this.toltalinfantprice)
    }
    this.calculatePrice()
  }

  onOptionSelect(value: any, type: string) {
    console.log(value, type)
    this.totalPrice = this.basicPrice;
    value =
      type !== 'traveller'
        ? value === ''
          ? '0'
          : value
        : value === ''
          ? '1'
          : value;
    //console.log(value);
    if (type === 'pack') {
      if (!isNaN(value)) {
        this.packPrice = +value;
        this.packBikePrice = 0;
        this.packTypeSelected = '';
      } else {
        this.packPrice = 0;
        this.packBikePrice = 0;
        this.packTypeSelected = value;
      }
    }
    if (this.packTypeSelected === "solo-bike" && type === "solo") {
      this.packBikePrice = +value;
    } else if (this.packTypeSelected === "share-bike" && type === "share") {
      this.packBikePrice = +value;
    }
    if (type === 'room') {
      this.roomPrice = +value;
    }
    if (type === 'traveller') {
      this.no_of_traveller = +value;
    }
    if (type === 'board') {
      if (value === '0') {
        this.boardPrice = 0;
        this.multipleBoarded = [];
      } else {
        this.multipleBoarded = this.allOrder.boarding_point_price.filter(
          (arrEle) => arrEle.bp === value
        );
        if (
          this.multipleBoarded.length !== 0 &&
          this.multipleBoarded[0]?.bp_type === 'single'
        ) {
          this.boardPrice =
            +this.multipleBoarded[0]?.bp_price[0]?.singleBoardPrice;
        } else {

          //console.log(this.multipleBoarded, this.multipleBoarded[0]);
          if (this.multipleBoarded[0]?.bp_price[4]?.Flight) {
            this.boardPrice = +this.multipleBoarded[0]?.bp_price[4]?.Flight;
          }
          if ((this.multipleBoarded[0]?.bp_price[3])['1AC']) {
            this.boardPrice = +(this.multipleBoarded[0]?.bp_price[3])['1AC'];
          }
          if ((this.multipleBoarded[0]?.bp_price[2])['2AC']) {
            this.boardPrice = +(this.multipleBoarded[0]?.bp_price[2])['2AC'];
          }
          if ((this.multipleBoarded[0]?.bp_price[1])['3AC']) {
            this.boardPrice = +(this.multipleBoarded[0]?.bp_price[1])['3AC'];
          }
          if (this.multipleBoarded[0]?.bp_price[0]?.Sleeper) {
            this.boardPrice = +this.multipleBoarded[0]?.bp_price[0]?.Sleeper;
          }
          //console.log(this.boardPrice);
        }
      }
    }
    if (type === 'boardMulti') {
      this.boardPrice = +value;
    }
    // console.log(this.packPrice, this.packBikePrice, this.roomPrice, this.boardPrice);
    this.totalPrice += this.packPrice + this.packBikePrice + this.roomPrice + this.boardPrice;
    this.totalPrice *= this.no_of_traveller;
    this.calculatePrice();
  }


  getSingleBoardPrice(value: string) {
    const boardPoint = this.allOrder.boarding_point_price.filter(
      (arrEle) => arrEle.bp === value
    );
    if (boardPoint[0].length !== 0 && boardPoint[0].bp_type === 'single')
      return `(₹${boardPoint[0].bp_price[0]?.singleBoardPrice}) Per Person`;
    else return '';
  }


  setChange() {
    const dateSchedule = this.dateSchedule?.nativeElement?.value;
    // console.log(dateSchedule)
    // //return
    const boardingPt =
      this.boardingPt?.nativeElement?.options[
        this.boardingPt?.nativeElement?.selectedIndex
      ].dataset.getboardid;
    const boardingMulti =
      this.boardingMulti?.nativeElement?.options[
        this.boardingMulti?.nativeElement?.selectedIndex
      ].text;
    const packageDataWithTraveller = this.allPackData.filter(val => val.persons !== 0);
    const numberOfTraveller = this.totalTraveller;

    // if (this.allOrder.dateArr.length === 0) {
    //   Swal.fire({
    //     title: 'No schedule available for this tour',
    //     width: 400,
    //     text: 'Please contact us for more details',
    //     icon: 'error',
    //     confirmButtonText: 'Ok',
    //   });
    //   return;
    // }
    if (dateSchedule === '') {
      Swal.fire({
        text: 'Please select your tour date',
        icon: 'error',
      });
      // this.allPackData = [];
      // this.allRoomData = [];
      return;
    }
    if (packageDataWithTraveller.length === 0) {
      Swal.fire({
        text: 'Please add travellers to the package',
        icon: 'error',
      });
      // this.allPackData = [];
      // this.allRoomData = [];
      return;
    }
    if (boardingPt === undefined) {
      Swal.fire({
        text: 'Please select your boarding point',
        icon: 'warning',
      });
      // this.allPackData = [];
      // this.allRoomData = [];
      return;
    }
    if (numberOfTraveller === 0) {
      Swal.fire({
        text: 'Please add travellers',
        icon: 'error',
      });
      // this.allPackData = [];
      // this.allRoomData = [];
      return;
    }

    console.log(this.webID, "userID");

    // this.spinner.show();
    const orderDetails = {
      id:this.order._id, 
      user_id: this.webID,
      tour_id: this.tourID,
      travel_date: dateSchedule,
      tourDate: dateSchedule,
      travel_packageType_data: this.allPackData,
      travel_roomSharing_data: this.allRoomData,
      travel_boarding_point: boardingPt,
      boardingType: boardingMulti ? boardingMulti.split(" ")[0] : '',
      travel_no_of_traveler: this.finaltotalTraveller,
      old_travel_no_of_traveler: this.finaltotalTraveller,
      infant_price: this.toltalinfantprice,
      child_price: this.toltalchidprice,
      finalPrice: this.finalPrice,
      child_count: this.numberofchild,
      infant_count: this.numberofinfant,
      status: 'active',
      tour_type: this.tourType,
      domesticorinternational: this.domesticorInternational,
      batch_number: this.batchNumber,
      bordingprice: this.boardPrice,
      bookedBy: this.allTourdate.bookedBy,

      transctionCharge:  this.order.transctionCharge,
      sgst: this.order.sgst,
      igst: this.order.igst,
      transction_Id: this.order.transction_Id,
      tcs: this.order.tcs,
      payLinkValidTill: this.order.payLinkValidTill,
      payLinkTotalAmount: this.order.payLinkTotalAmount,
      payLinkAmount: this.order.payLinkAmount,
      payLink: this.order.payLink,
      partial_payment: this.order.partial_payment,
      cgst: this.order.cgst,
      appliedCoupon: this.order.appliedCoupon,
      bookingNote: this.order.bookingNote,
      isOuter: this.order.isOuter,
      userCoupon: this.order.userCoupon,
      isComplete: this.order.isComplete,
      order_id: this.order.order_id,

      



    };
    console.log(orderDetails);
    this.spinner.show();
    this.apiService.ModifiedTour(orderDetails).subscribe((res: any) => {
      if (res.success) {
        ($("#staticBackdrop") as any).modal("hide");

        this.spinner.hide();
        this.FinalBooking = true;
        this.getCheckoutTourDetails(res.modifiedTourId);
        Swal.fire({
          text: res.message,
          icon: 'success',
        });
      } else {
        this.spinner.hide();
        Swal.fire({
          text: res.message,
          icon: 'error',
        });
      }
    });
  }


  setChangecustomized() {
    const dateSchedule = this.startDate
    const enddateSchedule = this.endDate
    console.log(enddateSchedule)
    const packageDataWithTraveller = this.dataArray.filter(item => item.value !== undefined && item.persons !== 0);
    const roomDataWithTraveller = this.dataArrayroom.filter(item => item.value !== undefined && item.persons !== 0);
    const boardingPt =
      this.boardingPt?.nativeElement?.options[
        this.boardingPt?.nativeElement?.selectedIndex
      ].dataset.getboardid;
    const boardingMulti =
      this.boardingMulti?.nativeElement?.options[
        this.boardingMulti?.nativeElement?.selectedIndex
      ].text;
    console.log(boardingPt)
    const numberOfTraveller = this.customizedpackagetotaluser + this.numberofchild + this.numberofinfant
    console.log(numberOfTraveller)
    if (dateSchedule === '') {
      Swal.fire({
        text: 'Please select your tour start date',
        icon: 'error',
      });
      return;
    }
    if (enddateSchedule === '') {
      Swal.fire({
        text: 'Please select your tour end date',
        icon: 'error',
      });
      return;
    }
    if (packageDataWithTraveller.length === 0) {
      Swal.fire({
        text: 'Please add travellers to the package',
        icon: 'error',
      });
      return;
    }
    if (roomDataWithTraveller.length === 0) {
      Swal.fire({
        text: 'Please add travellers to the room',
        icon: 'error',
      });
      return;
    }
    if (boardingPt === undefined) {
      Swal.fire({
        text: 'Please select your boarding point',
        icon: 'warning',
      });
      return;
    }
    if (numberOfTraveller === 0) {
      Swal.fire({
        text: 'Please add travellers',
        icon: 'error',
      });
      return;
    }
    console.log('hello')
    //return
    const orderDetails = {
      user_id: this.webID,
      tour_id: this.tourID,
      travel_date: dateSchedule,
      tourDate: enddateSchedule,
      travel_packageType_data: this.dataArray,
      travel_roomSharing_data: this.dataArrayroom,
      travel_boarding_point: this.boardingPointPrices,
      boardingType: this.boardingPointType.boardingPoint,
      travel_no_of_traveler: this.totalTravellercustomized,
      old_travel_no_of_traveler: this.totalTravellercustomized,
      infant_price: this.toltalinfantprice,
      child_price: this.toltalchidprice,
      finalPrice: this.finalPrice,
      child_count: this.numberofchild,
      infant_count: this.numberofinfant,
      tour_type: this.tourType,
      domesticorinternational: this.domesticorInternational,
      batch_number: this.batchNumber,
      bordingprice: this.boardPrice,
      status: 'active',
    };
    console.log(orderDetails);
    //return;
    this.spinner.show();
    this.apiService.AdminTour(orderDetails).subscribe((res: any) => {
      if (res.success) {
        ($("#staticBackdrop") as any).modal("hide");

        this.spinner.hide();
        this.FinalBooking = true;
        this.getCheckoutTourDetails(res.objId);
        Swal.fire({
          text: res.message,
          icon: 'success',
        });
      } else {
        this.spinner.hide();
        Swal.fire({
          text: res.message,
          icon: 'error',
        });
      }
    });
  }


  getCheckoutTourDetails(checkoutId) {
    const getId = {
      id: checkoutId,
    };
    this.apiService.TourId(getId).subscribe((res: any) => {
      if (res.success) {
        //this.spinner.hide();
        console.log(res);
        console.log(this.oldtrip);
        
        const oldtrip = res.allGetDataArray

        this.oldtrip = oldtrip?.slice(1);
 console.log(this.oldtrip,">..................bhai 7n");
 


        this.allCheckoutTourData = res.getData;
        this.singlachildprice = this.allCheckoutTourData?.child_price / this.allCheckoutTourData?.child_count
        this.singlainfentprice = this.allCheckoutTourData?.infant_price / this.allCheckoutTourData?.infant_count
        //console.log(childprice)
        this.finalPrice = +this.allCheckoutTourData?.finalPrice;
        this.tourid = this.allCheckoutTourData._id;
        if (this.allCheckoutTourData.travel_packageType !== '')
          this.getPackageData(this.allCheckoutTourData.travel_packageType);
        if (this.allCheckoutTourData.travel_roomSharing !== '')
          // this.getAllRoomData(this.allCheckoutTourData.travel_roomSharing);
          this.allTourData = res.getData.tour_id.select_batch[0];
        console.log(this.allTourData)
        // this.getBoardingPrice(this.allTourData?.boarding_point_price);
        this.calculateAllExtraCharges();
      } else {
        //this.spinner.hide();
        console.log(res);
      }
    });
  }


  onCategorySelect(id) {
    let dataForForm = {
      id: id,
    };

    this.idForPackage = dataForForm.id;
  }


  onCoupanSelect(couponcode) {
    const couponDetail = {
      user_id: this.webID,
      coupon: this.appliedCoupon,
      tour_price: this.allCheckoutTourData?.finalPrice,
      tour_id: this.allCheckoutTourData?.tour_id?._id,
    };
    //console.log(this.allCheckoutTourData?.finalPrice);
    this.finalPrice = +this.allCheckoutTourData?.finalPrice;
    console.log(this.finalPrice);
    this.calculateAllExtraCharges();
    this.apiService.Coupan(couponDetail).subscribe((res: any) => {
      if (res.success) {
        Swal.fire({
          text: res.message,
          icon: 'success',
        });
        //this.couponmessage = true;
        //this.FinalBooking = true;
        console.log(res);
        this.couponRes = res;
        if (res?.discount_type === 'Fixed Amount') {
          if (res?.coupon_type == "Voucher") {
            this.discountedPriceByCoupon = res?.discountAmount
          } else {
            this.discountedPriceByCoupon = res?.discountAmount * (+this.allCheckoutTourData?.travel_no_of_traveler);
          }
        } else if (res?.discount_type === 'Percentage Discount') {
          this.discountedPriceByCoupon =
            res?.discountAmount;
        }
        // this.discountedPriceByCoupon = res?.discountAmount;
        this.finalPrice -= this.discountedPriceByCoupon;
        //console.log(this.discountedPriceByCoupon, 'hiiiiiiiiiii');

        this.calculateAllExtraCharges();
        this.isCouponNotApplied = false;
        this.isCouponApplied = true;
      } else {
        Swal.fire({
          text: res.message,
          icon: 'error',
        });
        this.isCouponNotApplied = true;
        this.isCouponApplied = false;
        this.discountedPriceByCoupon = 0;
        this.finalPrice = +this.allCheckoutTourData?.finalPrice;
        this.calculateAllExtraCharges();
        console.error(res);
      }
    });
  }

  calculateAllExtraCharges() {
    if (
      this.allOtherCharges?.transactional_charge &&
      this.allOtherCharges?.cgst &&
      this.allOtherCharges?.sgst &&
      this.allOtherCharges?.igst
    ) {
      if (this.allData?.stateData?.iso2 === 'MP') {
        // this.amtWithCGST = this.finalPrice * (+this.allOtherCharges?.cgst * 0.01);
        this.amtWithCGST = (this.allOtherCharges?.cgst * 0.01 * (+this.finalPrice / (1 + (this.allOtherCharges?.cgst * 0.01) + (this.allOtherCharges?.sgst * 0.01))))
        this.amtWithSGST = (this.allOtherCharges?.sgst * 0.01 * (+this.finalPrice / (1 + (this.allOtherCharges?.cgst * 0.01) + (this.allOtherCharges?.sgst * 0.01))))

        this.totalAmountToPaid = this.finalPrice;
      } else {
        // this.amtWithIGST = this.finalPrice * (+this.allOtherCharges?.igst * 0.01);
        this.amtWithIGST = (this.allOtherCharges?.igst * 0.01 * (+this.finalPrice / (1 + (this.allOtherCharges?.igst * 0.01))))
        this.totalAmountToPaid = this.finalPrice;
      }
    }
    //console.log(this.totalAmountToPaid);
  }


  getPackageData(data: string) {
    const id = { id: data };
    this.apiService.getPackId(id).subscribe((res: any) => {
      if (res.success) {
        //console.error(res,'All Package');
        this.allCheckoutTourData.travel_packageType = res.getData.package_type;
        //this.getAllBikePackageDataID(this.allCheckoutTourData.travel_packageType);
        let packageType_price = JSON.parse(this.allTourData.packageType_price);
        this.allCheckoutTourData.travel_packagePrice = packageType_price.filter(
          (pack: any) =>
            pack.packType === this.allCheckoutTourData.travel_packageType
        )[0]?.packPrice;
      } else {
        console.error(res);
      }
    });
  }


  addRemovePersonsPackage(index: number, packageData: any, action: string) {
    console.log(packageData, action, index);
    //return
    const packageType = this.allPackData[index].packType;
    const price = this.allPackData[index].price;
    const value = this.packageValues[index];
    const currentQuantity = this.numbercustomizedpackage[index];
    console.log("Current Quantity:", currentQuantity);
    console.log(packageType, value)
    if (action === 'add') {
      this.numbercustomizedpackage[index]++;
      console.log("Increased Quantity:", this.numbercustomizedpackage[index]);
      this.customizedpackageprice = value * this.numbercustomizedpackage[index];
      //console.log(this.customizedpackageprice)
    } else if (action === 'minus' && this.numbercustomizedpackage[index] > 0) {
      this.numbercustomizedpackage[index]--;
      console.log("Decreased Quantity:", this.numbercustomizedpackage[index]);
      this.customizedpackageprice = value * this.numbercustomizedpackage[index];
    }
    const setprice = this.packageValues[index] * this.numbercustomizedpackage[index];
    this.packagePrices[index] = setprice;
    this.customizedpackageprice = this.packagePrices.reduce((total, current) => total + (current || 0), 0);
    let dataObject = this.dataArray[index];
    if (!dataObject) {
      // If not, create a new object
      dataObject = {
        packageType: packageType,
        value: value,
        persons: this.numbercustomizedpackage[index]
      };
    } else {
      // If it exists, update its properties
      dataObject.packageType = packageType;
      dataObject.value = value;
      dataObject.persons = this.numbercustomizedpackage[index];
    }
    // Update the data in dataArray
    this.dataArray[index] = dataObject;
    //dataArray[index] = dataObject;
    console.log("Data Array:", this.dataArray);
    this.calculatePrice();
    //Perform operations based on packageType, price, and value
  }

  addRemovePersonsRoom(index: number, roomData: any, action: string) {
    console.log(roomData, action, index, this.numbercustomizedroom);
    //return
    const roomType = this.allRoomData[index].roomType;
    const price = this.allRoomData[index].price;
    const value = this.roomValues[index];
    const currentQuantity = this.numbercustomizedroom[index];
    console.log("Current Quantity:", currentQuantity);
    console.log(roomType, value)
    if (action === 'add' && this.customizedroomuser < this.customizedpackagetotaluser) {
      //  this.toast.fire({
      //   icon: 'error',
      //   title: 'Add travellers to add rooms',
      // });
      this.numbercustomizedroom[index]++;
      console.log("Increased Quantity:", this.numbercustomizedroom[index]);
      this.customizedroomprice = value * this.numbercustomizedroom[index];
      //console.log(this.customizedpackageprice)
    } else if (action === 'minus' && this.numbercustomizedroom[index] > 0) {
      this.numbercustomizedroom[index]--;
      console.log("Decreased Quantity:", this.numbercustomizedroom[index]);
      this.customizedroomprice = value * this.numbercustomizedroom[index];
    }
    this.getTotalSelectedRoom()
    const setprice = this.roomValues[index] * this.numbercustomizedroom[index];
    this.roomPrices[index] = setprice;
    this.customizedroomprice = this.roomPrices.reduce((total, current) => total + (current || 0), 0);
    let dataObject = this.dataArrayroom[index];
    if (!dataObject) {
      // If not, create a new object
      dataObject = {
        roomType: roomType,
        value: value,
        persons: this.numbercustomizedroom[index]
      };
    } else {
      // If it exists, update its properties
      dataObject.roomType = roomType;
      dataObject.value = value;
      dataObject.persons = this.numbercustomizedroom[index];
    }
    // Update the data in dataArray
    this.dataArrayroom[index] = dataObject;
    //dataArray[index] = dataObject;
    console.log("Data Array:", this.dataArrayroom, this.singalboardingprice);
    this.calculatePrice();
    //Perform operations based on packageType, price, and value
  }

  getTotalSelectedRoom(): number {
    let totalroom = 0;
    for (let count of this.numbercustomizedroom) {
      totalroom += count;
    }
    this.customizedroomuser = totalroom
    //console.log(this.customizedroomuser)
    //this.calculatePrice()
    return totalroom;
  }

  updatePriceEntered(boardingPoint: string, price: number): void {
    // Update price entry status for the corresponding boarding point
    switch (boardingPoint) {
      case 'singal':
        this.singalboardingprice = price;
        this.boardingPointPricesEntered.singal = !!this.singalboardingprice;
        break;
      case 'sleeper':
        this.sleeperPrice = price;
        this.boardingPointPricesEntered.sleeper = !!this.sleeperPrice;
        //this.boardingPointPrices.sleeper = price; // Store price for sleeper
        break;
      case 'ac3':
        this.ac3Price = price;
        this.boardingPointPricesEntered.ac3 = !!this.ac3Price;
        //this.boardingPointPrices.ac3 = price; // Store price for ac3
        break;
      case 'ac2':
        this.ac2Price = price;
        this.boardingPointPricesEntered.ac2 = !!this.ac2Price;
        //this.boardingPointPrices.ac2 = price; // Store price for ac2
        break;
      case 'ac1':
        this.ac1Price = price;
        this.boardingPointPricesEntered.ac1 = !!this.ac1Price;
        //this.boardingPointPrices.ac1 = price; // Store price for ac1
        break;
      case 'flight':
        this.flightPrice = price;
        this.boardingPointPricesEntered.flight = !!this.flightPrice;
        //this.boardingPointPrices.flight = price; // Store price for flight
        break;
      default:
        break;
    }

    // Check if any price has been entered among all boarding points
    this.priceEntered = Object.values(this.boardingPointPricesEntered).some(value => value);
    console.log(`Price entered for ${boardingPoint}:`, price);
    this.boardingPointPrices = price
    this.boardingPointType = { boardingPoint }
    // console.log('Any price entered?', this.priceEntered);
    console.log('Boarding point prices:', this.boardingPointPrices, this.boardingPointType.boardingPoint
    );
    //this.enteredPrices.push({ boardingPoint: boardingPoint, price: price });
    //console.log('Any price entered?', this.priceEntered);
    //console.log('Entered prices:', this.enteredPrices);
    this.calculatePrice()
  }

  booking(type: String) {
    if (this.payment_status === '') {
      Swal.fire({
        text: 'Please select payment status',
        icon: 'warning',
      });
      return;
    }
    if (this.cusGSTNo !== '' && this.cusGSTNo.length !== 15) {
      Swal.fire({
        title: 'Error!',
        width: 400,
        text: 'Please enter valid GST number',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }
    //console.log(this.cusGSTNo);
    if (this.cusGSTNo && this.cuscompanyAddress === '') {
      //console.log(this.cusGSTNo);
      Swal.fire({
        title: 'Error!',
        width: 400,
        text: 'Please enter Company Address',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }
    if (this.cusGSTNo && this.cuscompanyName === '') {
      //console.log(this.cusGSTNo);
      Swal.fire({
        title: 'Error!',
        width: 400,
        text: 'Please enter Company Name',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }
    const orderDetails: any = {
      username: this.allData?.firstname,
      useremail: this.allData?.email,
      user_id: this.webID,
      user_coupon: this.appliedCoupon,
      id: this.tourid,
      payment_status: type === 'booknow' ? this.payment_status : 'Pending',
      cgst: this.allOtherCharges?.cgst,
      sgst: this.allOtherCharges?.sgst,
      igst: this.allOtherCharges?.igst,
      isOuter: this.allData?.stateData?.iso2 === 'MP' ? false : true,
      transctionCharge: this.allOtherCharges?.transactional_charge,
      appliedCoupon: this.discountedPriceByCoupon,
      cusGSTNo: this.cusGSTNo,
      cuscompanyName: this.cuscompanyName,
      cuscompanyAddress: this.cuscompanyAddress,
      bookingNote: this.bookingNote,
      total_amount: this.totalAmountToPaid,
      amount_paid: 0,
      // partial_payment:(+this.basicPrice + (this.packPrice ? +this.packPrice : 0) +
      //     (this.packBikePrice ? +this.packBikePrice : 0) + (this.roomPrice ?
      //     +this.roomPrice : 0) +
      //     +this.boardPrice),
      partial_payment: this.allCheckoutTourData?.travel_packageType_data.reduce((total, pack) => {
        if (pack.persons !== 0) return total + Number(pack.packPrice);
        else return total;
      }, 0),
      bookingPrice: this.allTourData?.bookPrice,


      // receipt: 'Receipt #' + (Math.floor(Math.random() * 10000000) + 1),
    };

    if (type === 'booknow') {
      orderDetails.transction_Id = 'TI' + (Math.floor(Math.random() * 10000000) + 1);
      orderDetails.order_id = 'OI' + (Math.floor(Math.random() * 10000000) + 1);
      orderDetails.amount_paid = this.payment_status === 'Partial' ? (+this.allTourData?.bookPrice * +this.allCheckoutTourData?.travel_no_of_traveler) : this.totalAmountToPaid;
    } else {
      orderDetails.payLinkAmount = this.payment_status === 'Partial' ? (+this.allTourData?.bookPrice * +this.allCheckoutTourData?.travel_no_of_traveler) : this.totalAmountToPaid;
    }

    console.log(orderDetails);
    //  return;

    this.spinner.show();
    this.apiService.booking(orderDetails).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        //console.log(res);
        Swal.fire({
          text: res.message,
          icon: 'success',
        });
        if (type === 'booknow') {
          this.router.navigateByUrl('/user-order-history/' + this.webID);
        } else {
          this.router.navigateByUrl('/order-pay-link-list');
        }

        let notificationData = {
          applyTour_id: this.tourid,
          last_amount_paid: orderDetails.amount_paid,
          is_new: true,
          status: "",
          payment_status: "Paid"
        }

        // notificationData = {...notificationData, payment_status: "Paid"}
        console.log(notificationData, "NOTIFICATION!!!");

        this.apiService.postNotification(notificationData).subscribe((res: any) => {
          if (res) {
            console.log(res);
          }
        })
      } else {
        this.spinner.hide();
        //this.spinner.hide();
        //console.log(res);
      }
    });
  }



  getalladdonnote(id) {
    this.spinner.show();
    this.apiService.getaddon(id).subscribe((res: any) => {
      //console.log(res, 'devvv', this.totalUnpaidAddon);
      this.addOnData1 = res.data;
      if (res.success) {
        this.addon_notes = res.data[0]?.addon_notes;
        this.spinner.hide();
        //console.log(this.addon_notes)
      } else {
        this.spinner.hide();
      }
    });
  }

  addaddonnotes() {
    const notes = {
      //user_id: this._userId,
      applyTour_id: this.order._id,
      addon_notes: this.addon_notes,
      addon_id: this.order.addons_id ? this.order.addons_id._id : ''
    };
    //console.log(notes);
    //return;
    this.spinner.show();
    this.apiService.addnotesaddon(notes).subscribe((res: any) => {
      if (res.success) {

        Swal.fire({
          title: 'Success',
          text: res.message,
          icon: 'success',
        });
        this.spinner.hide();
        setTimeout(() => {                           //<<<---using ()=> syntax
          this.getalladdonnote(this.order._id);
        }, 2000);
        //this.router.navigateByUrl('/add-user-list');
      } else {
        Swal.fire({
          text: res.message,
          icon: 'error',
        });
        this.spinner.hide();
      }
    });
  }
  handleClick() {
    this.selectedDate = '';
    // console.log("WORKED", JSON.parse(this.order.tour_id.start_date), JSON.parse(this.order.tour_id.end_date), Date.now(), new Date(),new Date().toLocaleDateString() )
    // JSON.parse(this.order.tour_id.start_date).filter((elem, i) => {
    //   if(new Date(elem).getTime() > Date.now()){
    //     console.log(elem, JSON.parse(this.order.tour_id.end_date)[i])
    //     this.dateDropDown.push(`${elem} - ${JSON.parse(this.order.tour_id.end_date)[i]}`)
    //   }
    // })
    this.dateEdit = true;
  }
  handleSaveDate() {
    this.dateEdit = false;
    if (this.selectedDate) {
      // console.log(this.selectedDate, this.orderId, this.selectedDate.split(" ")[0].split("/")[2], new Date(`${this.selectedDate.split(" ")[0].split("/")[2]}/${this.selectedDate.split(" ")[0].split("/")[1]}/${this.selectedDate.split(" ")[0].split("/")[0]}`).getTime(), new Date(new Date(`${this.selectedDate.split(" ")[0].split("/")[2]}/${this.selectedDate.split(" ")[0].split("/")[1]}/${this.selectedDate.split(" ")[0].split("/")[0]}`).getTime()).toISOString())
      let fewDaysAgoDate = new Date(`${this.selectedDate.split(" ")[0].split("/")[2]}/${this.selectedDate.split(" ")[0].split("/")[1]}/${this.selectedDate.split(" ")[0].split("/")[0]}`);
      new Date(fewDaysAgoDate.setDate(fewDaysAgoDate.getDate() - +this.order.tour_id.lastReminderDay))
      let fiveDaysAgoDate = new Date(fewDaysAgoDate)
      new Date(fiveDaysAgoDate.setDate(fiveDaysAgoDate.getDate() - 5))
      // console.log(+this.order.tour_id.lastReminderDay, this.selectedDate, fewDaysAgoDate, fiveDaysAgoDate, new Date(`${this.selectedDate.split(" ")[0].split("/")[2]}/${this.selectedDate.split(" ")[0].split("/")[1]}/${this.selectedDate.split(" ")[0].split("/")[0]}`))

      let travelDate = { travel_date: this.selectedDate, tourDate2: `${this.selectedDate.split(" ")[0].split("/")[1]}/${this.selectedDate.split(" ")[0].split("/")[0]}/${this.selectedDate.split(" ")[0].split("/")[2]}`, tourDate: new Date(`${this.selectedDate.split(" ")[0].split("/")[2]}/${this.selectedDate.split(" ")[0].split("/")[1]}/${this.selectedDate.split(" ")[0].split("/")[0]}`).getTime(), tourReminderStartDate: fiveDaysAgoDate, tourReminderEndDate: fewDaysAgoDate }
      // return
      this.apiService.updateTravelDate(this.orderId, JSON.stringify(travelDate)).subscribe((res: any) => {
        // console.log(res)
        location.reload();
      })
    } else if (this.selectedDate == "No date available") {
      this.dateEdit = false;
    } else {
      Swal.fire({
        text: 'Please select a tour date.',
        icon: 'warning',
      });
      this.dateEdit = true;
      return;
    }
  }

  getAllOrder() {
    const orderId = {
      id: this.orderId
    };
    this.spinner.show();
    this.apiService.TourId(orderId).subscribe((res: any) => {
      console.log(res)
      if (res.success) {
        console.log(this.oldtrip);
        
        // this.oldtrip = res.allGetDataArray
        const oldtrip = res.allGetDataArray

        if(oldtrip?.length > 1){
          this.oldtrip = oldtrip?.slice(1)
        }

        console.log(this.oldtrip,'>');
        
        this.order = res.getData;
        console.log(res.getData, ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");

        this.travellerID = res.getData;
        console.log(this.travellerID);

        // this.traveller=res.userTourDetail[0].travelers
        // console.log(this.traveller,">>>>>>>>>>>><<<<<<<<<<<<<<");


        console.log(this.order);

        this.tour = res.tourData
        console.log(this.order._id)
        this.orderbatchBYID = res?.applyTourbatchId[0]
        this.selectedDate = this.order.travel_date


        this.id = this.order?.tour_id?._id
        console.log(this.id);
        this.webID = this.order?.user_id?._id
        this.tour_type = this.order?.tour_type
        this.pdf = this.order?.pdf_url
        console.log(this.webID, ">>");


        this.apiService.getaddon(this.order._id).subscribe((res: any) => {
          this.addOnData1 = res.data;
          this.spinner.hide();
        })
        this.n = 0;
        const totalPerson = this.order?.travel_packageType_data;
        this.n = this.n + this.order?.old_travel_no_of_traveler;
        this.totalPayAmt = 0
        const totalPaylinkamt = this.order?.payLinkTotalAmount;
        for (let person of totalPaylinkamt) {
          this.totalPayAmt = this.totalPayAmt + person;
        }
        this.addondata = this.order?.addons_id?.addon_notes
        this.travellerArr = Array(this.order?.travel_no_of_traveler).fill('travel');
        if (this.order?.addons_id && this.order?.addons_id?.addons_data.length) {
          this.totalUnpaidAddon = 0;
          this.totalpaidAddon = 0;
          this.totalAddonAmt = 0;
          const addonData = this.order?.addons_id?.addons_data;
          for (let addon of addonData) {
            if (addon.status === 'unpaid') {
              this.totalUnpaidAddon += (addon.price * addon.quantity);
            }
            if (addon.status === 'paid') {
              this.totalpaidAddon += (addon.price * addon.quantity);
            }

            this.totalAddonAmt += (addon.price * addon.quantity);
            this.insertAddOn(addon.service, addon.price, addon.quantity, addon.status)
          }
          if (!this.order?.isOuter) {
            //this.totalUnpaidAddon += (+this.order?.cgst + +this.order?.sgst) * this.totalUnpaidAddon * 0.01;
            //this.totalAddonAmt += (+this.order?.cgst + +this.order?.sgst) * this.totalAddonAmt * 0.01;
          } else {
            //this.totalUnpaidAddon += +this.order?.igst * this.totalUnpaidAddon * 0.01;
            //this.totalAddonAmt += +this.order?.igst * this.totalAddonAmt * 0.01;
          }
          this.addOnForm.disable();
        }
        this.openProfile = true;
        //console.log(this.order, "hala");
        // this.n= this.order.finalPrice/(this.order.partial_payment);
        //console.log(this.n, this.totalUnpaidAddon, this.totalpaidAddon, "paidAdd")

      } else {
        this.toast.fire({
          icon: 'error',
          title: res.message
        });
        this.spinner.hide();
      }
    });
  }

  getAllPackageData() {
    this.spinner.show();
    this.apiService.getAllPack().subscribe((res: any) => {
      if (res.success) {
        // console.log(res, "pack");
        this.allPackageData = res.data;
        this.spinner.hide();
      } else {
        console.error(res);
        this.spinner.hide();
      }
    });
  }

  getAllBikeData() {
    this.spinner.show();
    this.apiService.AllBikes().subscribe((res: any) => {
      if (res.success) {
        // console.log(res, "bike");
        this.allBikeData = res.data;
        this.spinner.hide();
        // this.tourAllData.packageType_price = data;
      } else {
        console.error(res);
        this.spinner.hide();
      }
    });
  }

  getAllRoomData() {
    this.spinner.show();
    this.apiService.AllRooms().subscribe((res: any) => {
      if (res.success) {
        //console.error(res, "room");
        this.allRoomData = res.data;
        this.spinner.hide();
        // this.tourAllData.packageType_price = data;
      } else {
        console.error(res);
        this.spinner.hide();
      }
    });
  }

  getPackage(id: string) {
    if (this.allPackageData?.length > 0 && id !== "") {
      return this.allPackageData.filter((pack: any) => pack._id === id)[0]?.package_type;
    } else {
      return "-";
    }
  }

  getBikePackage(id: string) {
    if (this.allBikeData?.length > 0 && id !== "") {
      return this.allBikeData.filter((pack: any) => pack._id === id)[0].bike_name;
    } else {
      return "-";
    }
  }

  get addOns() {
    return this.addOnForm.controls['addOns'] as FormArray;
  }

  insertAddOn(serviceEdit = '', priceEdit = undefined, quantityEdit = undefined, statusEdit = 'unpaid') {
    let addOnData = this.fb.group({
      service: [serviceEdit, Validators.required],
      price: [priceEdit, Validators.required],
      quantity: [quantityEdit, Validators.required],
      status: [statusEdit, Validators.required]
    });
    this.addOns.push(addOnData);
  }

  removeAddOn(removeId: number) {
    this.addOns.removeAt(removeId);
  }

  clearAddOn() {
    while (this.addOns.length !== 0) {
      this.addOns.removeAt(0)
    }
  }

  submitAddOn() {
    for (let addOn of this.addOns.controls) {
      if (!addOn.value.service.trim() || !addOn.value.price || !addOn.value.quantity) {
        this.toast.fire({
          icon: 'error',
          title: 'Please enter valid data'
        });
        return;
      }
    }
    this.addOnForm.enable();
    this.addOnForm.value.addOns = this.addOnForm.value?.addOns.map(addon => {
      addon.price = +addon.price;
      addon.quantity = +addon.quantity;
      return addon;
    });
    // console.log(this.addOnForm.value.addOns);
    // return;
    const allData = {
      addons_data: this.addOnForm.value.addOns,
      applyTour_id: this.order._id,
      id: this.order.addons_id ? this.order.addons_id._id : ''
    };
    this.spinner.show();
    this.apiService.setAddonsData(allData).subscribe((res: any) => {
      //console.log(res);
      if (res.success) {
        this.clearAddOn();
        this.getAllOrder();
        this.spinner.hide();
      } else {
        this.clearAddOn();
        this.getAllOrder();
        this.toast.fire({
          icon: 'error',
          title: res.message
        });
        this.spinner.hide();
      }
    });
  }

  isCancelRemove(tourStartDate: string) {
    if (tourStartDate) {
      const [month, date, year] = tourStartDate.split('/');
      return (new Date(`${month}/${date}/${year}`).getTime() >= new Date().getTime());
    }
  }

  cancelOrder(tourId: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure want to cancel your tour?',
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Cancel Tour',
      cancelButtonText: 'Cancel Members'
    }).then((result) => {
      if (result.isConfirmed) {
        const cancelStatus = {
          payment_status: 'Cancelled',
          username: this.order?.user_id?.firstname,
          useremail: this.order?.user_id?.email
        };
        // console.log(cancelStatus);
        // return;
        this.spinner.hide();
        this.apiService
          .putCheckoutStatusUpdate(cancelStatus, tourId)
          .subscribe((res: any) => {
            if (res.success) {
              Swal.fire({
                title: 'Success',
                text: 'Tour cancelled successfully',
                icon: 'success',
              });
              this.spinner.hide();
              //this.router.navigateByUrl(`/user-order-history/particular-order/${this.orderId}`);
              this.router.navigateByUrl('/add-user-list');
            } else {
              Swal.fire({
                text: res.message,
                icon: 'error',
              });
              this.spinner.hide();
            }
          });
      }
      if (result.isDismissed) {

        (<any>$('#cancel-members')).modal('show');

      }
    });
  }
  cancelMembers(id: string) {
    let e = id
    this.orderId = e;
    //console.log(this.order)
    if (this.order.travel_no_of_traveler !== 1) {

      //.log(this.order.travel_no_of_traveler)
      // console.log(e, "valuee")

      let pending_amt = this.order.totalAmount - this.order.amountPaid;
      //console.log(pending_amt)
      this.amt = pending_amt / this.order.travel_no_of_traveler;
      this.order.totalAmount = this.order.totalAmount - this.amt;
      this.order.travel_no_of_traveler = this.order.travel_no_of_traveler - 1;
      this.count = this.count + 1;
      var data1 = {
        orderId: this.orderId,
        noOfTraveler: this.order.travel_no_of_traveler,
        totalAmt: this.order.totalAmount
      }
      //console.log(data1);
      this.spinner.show();
      this.apiService.updatecancelMembers(data1).subscribe((res: any) => {
        if (res.success) {
          console.log("successfully")
          if (res.success) {
            Swal.fire({
              title: 'Success',
              text: 'Member cancelled successfully',
              icon: 'success',
            });
            this.spinner.hide();
            this.router.navigateByUrl(`/user-order-history/particular-order/${this.orderId}`);
            (<any>$('#cancel-members')).modal('hide');
          } else {
            Swal.fire({
              text: res.message,
              icon: 'error',
            });
            this.spinner.hide();
          }

        }
      })
    }
    else {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to cancel the Tour?',
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.isConfirmed) {
          const cancelStatus = {
            payment_status: 'Cancelled',
            username: this.order?.user_id?.firstname,
            useremail: this.order?.user_id?.email
          };
          // console.log(cancelStatus);
          // return;
          this.spinner.show();
          this.apiService
            .putCheckoutStatusUpdate(cancelStatus, id)
            .subscribe((res: any) => {
              if (res.success) {
                Swal.fire({
                  title: 'Success',
                  text: 'Tour cancelled successfully',
                  icon: 'success',
                });
                this.spinner.hide();
                //this.router.navigateByUrl(`/user-order-history/particular-order/${this.orderId}`);
                this.router.navigateByUrl('/add-user-list');
              } else {
                Swal.fire({
                  text: res.message,
                  icon: 'error',
                });
                this.spinner.hide();
              }
            });
        }
      })
    }


  }
  cancelMembersByName(record, id, num: number) {
    //console.log(record.name, id.applyTour_id)
    if (this.order.travel_no_of_traveler !== 1) {

      let pending_amt = this.order.totalAmount - this.order.amountPaid;
      //console.log(pending_amt)
      this.amt = pending_amt / this.order.travel_no_of_traveler;
      this.order.totalAmount = this.order.totalAmount - this.amt;
      this.order.travel_no_of_traveler = this.order.travel_no_of_traveler - 1;
      //console.log(this.order.totalAmount)
      var data1 = {
        applyTour_id: id.applyTour_id,
        name: record.name,
        noOfTraveler: num - 1,
        totalAmt: this.order.totalAmount
      }
      console.log(record,
        this.order?.travellers_id?.traveller_data)
      //this.order.travellers_id.traveller_data =this.order.travellers_id.traveller_data.filter(this.order?.travellers_id?.traveller_data[record.name])
      this.order.travellers_id.traveller_data.splice(this.order.travellers_id.traveller_data.indexOf(record), 1)

      //  user.splice(user.indexOf(recToRemove),1)
      console.log(
        this.order?.travellers_id?.traveller_data)
      this.apiService.updatecancelMembersByName(data1).subscribe((res: any) => {
        if (res.success) {
          Swal.fire({
            title: 'Success',
            text: 'Member cancelled successfully',
            icon: 'success',
          });
          this.router.navigateByUrl(`/user-order-history/particular-order/${this.orderId}`);

          (<any>$('#cancel-members')).modal('hide');
        } else {
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
        }

      })
    } else {
      console.log(this.orderId)
      Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to cancel the Tour?',
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.isConfirmed) {
          const cancelStatus = {
            payment_status: 'Cancelled',
            username: this.order?.user_id?.firstname,
            useremail: this.order?.user_id?.email
          };
          // console.log(cancelStatus);
          // return;
          this.apiService
            .putCheckoutStatusUpdate(cancelStatus, this.orderId)
            .subscribe((res: any) => {
              if (res.success) {
                Swal.fire({
                  title: 'Success',
                  text: 'Tour cancelled successfully',
                  icon: 'success',
                });
                this.router.navigateByUrl(`/user-order-history/particular-order/${this.orderId}`);
                //this.router.navigateByUrl('/add-user-list');
              } else {
                Swal.fire({
                  text: res.message,
                  icon: 'error',
                });
              }
            });
        }
      })
    }

  }
  completeOrder(
    id, totalAmount, appliedCoupon, cgst, sgst, igst, isOuter, transctionCharge,
    cusGSTNo, cuscompanyName, cuscompanyAddress, bookingNote, payText, adminPay,
    tour_type
  ) {
    console.log(totalAmount, appliedCoupon, "coupon", this.order.isComplete, payText);
    console.log(totalAmount, this.order?.totalAmount, this.order?.amountPaid, this.totalAddonAmt, adminPay);
    console.log(this.order?.totalAmount - this.order?.amountPaid + this.totalAddonAmt - adminPay);

    if (payText === 'payAdmin') {
      if (!this.adminAmount || !adminPay) {
        this.toast.fire({
          icon: 'error',
          title: 'Please enter amount payable against the remaining amount'
        });
        return;
      }
      if (this.adminAmount <= 0 || adminPay <= 0) {
        this.toast.fire({
          icon: 'error',
          title: 'Please enter a valid amount'
        });
        return;
      }
      const remainingAmount = Math.ceil((this.order?.totalAmount - this.order?.amountPaid + this.totalAddonAmt));
      if (this.adminAmount > remainingAmount || adminPay > remainingAmount) {
        this.toast.fire({
          icon: 'error',
          title: 'Payable amount cannot be greater than remaining amount'
        });
        return;
      }

      const orderDetails = {
        username: this.order?.user_id?.firstname,
        useremail: this.order?.user_id?.email,
        user_id: this.order?.user_id?._id,
        id: this.order?.checkout_id ? this.order?._id : this.order?.checkout_id,
        payment_status: (remainingAmount - adminPay) === 0 ? 'Complete' : 'Partial',
        total_amount: totalAmount,
        amount_paid: payText === 'payAdmin' ? adminPay + this.order?.amountPaid : totalAmount + this.totalAddonAmt,
        order_id: 'OI' + (Math.floor(Math.random() * 1000000000000) + 1),
        transction_Id: 'TI' + (Math.floor(Math.random() * 1000000000000) + 1),
        receipt: 'Receipt #' + (Math.floor(Math.random() * 10000000) + 1),
        cgst: cgst,
        sgst: sgst,
        igst: igst,
        isOuter: isOuter,
        appliedCoupon: appliedCoupon,
        cusGSTNo: cusGSTNo,
        cuscompanyName: cuscompanyName,
        cuscompanyAddress: cuscompanyAddress,
        bookingNote: bookingNote,
        partial_payment: this.order.partial_payment + adminPay,
        bookingPrice: this.order.tour_id.bookPrice,
        isComplete: this.order.isComplete + 1,
        bookedBy: this.user_id,
        currentAmount: adminPay
      };

      this.spinner.show();

      const apiCall = tour_type === 'customized'
        ? this.apiService.bookingPerorderCustomized(orderDetails)
        : this.apiService.bookingPerorder(orderDetails);

      apiCall.subscribe((res: any) => {
        this.handleApiResponse(res, adminPay, orderDetails);
      }, error => {
        console.error('API call error:', error);
        this.spinner.hide();
      });
    } else if (payText === 'payNow') {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Did the amount fully paid?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          const orderDetails = {
            username: this.order?.user_id?.firstname,
            useremail: this.order?.user_id?.email,
            user_id: this.order?.user_id?._id,
            id: this.order?.checkout_id,
            payment_status: 'Complete',
            total_amount: totalAmount,
            amount_paid: totalAmount + this.totalAddonAmt,
            order_id: 'OI' + (Math.floor(Math.random() * 1000000000000) + 1),
            transction_Id: 'TI' + (Math.floor(Math.random() * 1000000000000) + 1),
            receipt: 'Receipt #' + (Math.floor(Math.random() * 10000000) + 1),
            cgst: cgst,
            sgst: sgst,
            igst: igst,
            isOuter: isOuter,
            transctionCharge: transctionCharge,
            appliedCoupon: appliedCoupon,
            cusGSTNo: cusGSTNo,
            cuscompanyName: cuscompanyName,
            cuscompanyAddress: cuscompanyAddress,
            bookingNote: bookingNote,
            partial_payment: this.order.partial_payment,
            bookingPrice: this.order.tour_id.bookPrice,
            isComplete: this.order.isComplete + 1,
            currentAmount: totalAmount + this.totalAddonAmt
          };

          this.spinner.show();

          const apiCall = tour_type === 'customized'
            ? this.apiService.bookingPerorderCustomized(orderDetails)
            : this.apiService.bookingPerorder(orderDetails);

          apiCall.subscribe((res: any) => {
            this.handleApiResponse(res, totalAmount + this.totalAddonAmt, orderDetails);
          }, error => {
            console.error('API call error:', error);
            this.spinner.hide();
          });
        }
      });
    }
  }

  handleApiResponse(res: any, lastAmountPaid: number, orderDetails: any) {
    (<any>$('#admin-pay-link')).modal('hide');
    this.spinner.hide();
    if (res.success) {
      Swal.fire({
        text: res.message,
        icon: 'success',
      }).then(() => {
        let notificationData = {
          applyTour_id: this.order?._id,
          last_amount_paid: lastAmountPaid,
          is_new: true,
          status: "",
          payment_status: orderDetails?.payment_status
        };
        this.apiService.postNotification(notificationData).subscribe((res: any) => {
          if (res) {
            console.log(res);
          }
        });

        this.router.navigateByUrl('/add-user-list');
      });
    } else {
      console.error('API response error:', res);
      this.spinner.hide();
    }
  }


  getRoom(id: string) {
    if (this.allRoomData?.length > 0 && id !== "") {
      return this.allRoomData.filter((room: any) => room._id === id)[0].room_name;
    } else {
      return "-"
    }
  }

  getPackageDataPrice(packageTypePrice: string, packageType: string) {
    let packageType_price = JSON.parse(packageTypePrice);
    return packageType_price.filter(
      (pack: any) => pack.packType === packageType
    )[0]?.packPrice;
  }

  getRoomDataPrice(roomTypePrice: string, roomType: string) {
    let roomType_price = JSON.parse(roomTypePrice);
    return roomType_price.filter((room: any) => room.roomType === roomType)[0]?.roomPrice;
  }

  getBikeDataPrice(bikeTypePrice: string, bikeName: string, bikeType: string) {
    //console.log(bikeTypePrice, bikeName, bikeType);
    let bikeType_price = JSON.parse(bikeTypePrice);
    if (bikeType === "Solo bike") {
      return bikeType_price.filter((bike: any) => bike.soloType === bikeName)[0]?.soloPrice;
    } else {
      return bikeType_price.filter((bike: any) => bike.sharedType === bikeName)[0]?.sharedPrice;
    }
  }

  getBoardingPrice(getBoardingData: any, order: any) {
    const allBoadingDataWithPrices = JSON.parse(getBoardingData);
    const BoardPrice = allBoadingDataWithPrices.filter(
      (board: any) => board.bp === order?.travel_boarding_point?.boarding

    )[0]?.bp_price;

    return BoardPrice?.length === 1
      ? BoardPrice[0].singleBoardPrice
      : Object.values(
        BoardPrice.filter(
          (boardPrice: any) =>
            Object.keys(boardPrice)[0] === order?.boardingType
          // Object.keys(boardPrice)[0] === this.allCheckoutTourData?.boardingType
        )[0]

      )[0];
  }

  invoiceLogoImgToDataURL() {
    this.apiService
      .imgToData64('../../../assets/img/fdf-01 (1).png')
      .subscribe((blob) => {
        const reader = new FileReader();
        const binaryString = reader.readAsDataURL(blob);
        reader.onload = (event: any) => {
          this.logoImgInDateURL = event.target.result;
        };
        reader.onerror = (event: any) => {
          //console.error('File could not be read: ' + event.target.error.code);
        };
      });
  }

  sendDataToGenerateInvoicerefund(order) {
    //console.log(order)
    var couponAmount = (+order?.appliedCoupon) ? order?.appliedCoupon : 0;
    //var bpPriceArr = JSON.parse(order?.tour_id.boarding_point_price[0]);
    var bpPriceArr = JSON.parse(this.orderbatchBYID?.boarding_point_price[0]);
    let boardingPoint = bpPriceArr.find(o => o.bp === order?.travel_boarding_point.boarding);
    if (boardingPoint.bp_type == 'single') {
      var boardingPointPrice = +boardingPoint.bp_price[0].singleBoardPrice;
    }
    else {
      //let boardingPointPriceArr = boardingPoint.bp_price.find(o => order?.boarding_point_type in o);
      let boardingPointPriceArr = boardingPoint.bp_price.find(o => Object.keys(o)[0] == order?.boardingType);
      //console.log(boardingPoint, order, boardingPointPriceArr)
      //console.log(boardingPointPriceArr,Object.keys(boardingPointPriceArr)[0]);
      var boardingPointPrice = Number(boardingPointPriceArr[Object.keys(boardingPointPriceArr)[0]]);
    }
    let ref_amt = (order?.finalPrice - (this.n * this.order.tour_id.bookPrice)) / this.n;
    let remin_amt = (order?.finalPrice - (this.n * this.order.tour_id.bookPrice));
    let alredy_paid = ((order?.amountPaid) - (this.n * this.order.tour_id.bookPrice));
    //console.log(this.order.tour_id.bookPrice, this.order.partial_payment)
    let myInvoice = `
    <!DOCTYPE html>
<html>
<head>
<style>
  thead tr
  {
      background:#eee!important;
  }
  thead tr th
  {
    background: #eee;
    font-size: 16px
  }
  h5,h6
  {
    font-weight: bold;
    font-size: 16px;
    padding-left: 5px;
  }
  p,span,td
  {
    font-size:16px;
    margin-top:2px!important;
    margin-bottom: 2px!important;
    padding-left: 5px;
  }
  </style>
</head>
<body>

    <table style="border: none;" border="0" cellspacing="0" cellpadding="0">
      <tbody style="border: none;">
        <tr style="width: 50.5%;border: none;">
          <td>
          <div id="logo" style="margin-top: 8px;">
            <img src="${this.logoImgInDateURL}" style="width: 225px; height: 60px;"/>
          </div>
          </td>
          <td>
            <div id="company" style="text-align: right;margin-top:-100px;position: absolute;right: 0%;top: 0%;">
              <h3 class="name" style="color:#dc1b5e;">${order?.payment_status == "Complete" || order?.payment_status == "Cancelled" ? "Sales Return Tax Invoice" : "Proforma Invoice"}</h3>
              <h6 class="name"  >GLABOL EXPEDITION PVT. LTD.</h6>
              <div>1881 D Sudama Nagar, Indore (m.p)<br/>
               www.glabol.com | info@glabol.com<br/>
              </div>
              <div>GST No.: 23AAJCG2630E1Z3</div>
              <div>SAC Code: 998555</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <header style="display: flex; justify-content: center; align-items: center">
      <img src="${this.logoImgInDateURL}" style="width: 125px; height: 30px;"/>
      <span>THIS</span>
    </header> -->
    <main>
    <table border="0" cellspacing="0" cellpadding="0" style="width: 100%; margin-bottom: 10px;">
     <tbody style="width: 100%">
<tr style=" width: 33.33%">
    		<td rowspan="3">
            
          <h5>Billed To <br> ${order?.user_id?.firstname} ${order?.user_id?.lastname}</h5>
          <span>${order?.user_id?.mobile}</span>
          <span>${order?.user_id?.email ? order?.user_id?.email : ''}</span>`;

    if (order?.cusGSTNo) {
      myInvoice += ` <p>GST No.: ${order?.cusGSTNo}</p>`
    }

    if (order?.cuscompanyName) {
      myInvoice += `<p>Company Name: ${order?.cuscompanyName}</p>`
    }
    if (order?.cuscompanyAddress) {
      myInvoice += `<p>Company Address: ${order?.cuscompanyAddress}</p>`
    }

    let reminderDate = order?.tourReminderEndDate
    //console.log(new Date(reminderDate).toISOString().split('T')[0], reminderDate)

    myInvoice += `
    <h6> Date of Booking - ${new Date(order?.created_at)
        .toISOString()
        .split('T')[0]
        .split('-')
        .reverse()
        .join('-')}</h6>
    <h6> Date of Invoice - ${new Date(order?.updated_at)
        .toISOString()
        .split('T')[0]
        .split('-')
        .reverse()
        .join('-')}</h6></td>
        	<td style="text-align: center;">
            	<h5> Order ID </h5> ${order?.order_id}
            </td>
            <td rowspan="3" style="text-align: center;"><br><br><br>
            	Total Amount <h5 style= "color:#dc1b5e;"> ₹${order?.refundAmount.toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</h5>
            </td>
        </tr>
    	<tr style=" width: 33.33%">
        	<td style="text-align: center;">
            <h5>	Transaction ID </h5>${order?.transction_Id}
            </td>
        </tr>
        <tr style=" width: 33.33%">
        	<td style="text-align: center;">
          <h5> ${order?.payment_status == "Complete" || order?.payment_status == "Cancelled" ? "Tax Invoice ID" : "Proforma Invoice ID"} </h5>`

    if (order?.payment_status == "Complete") {
      myInvoice += `${order?.complete_booking_id ? order?.complete_booking_id : order?._id}`
    } else if (order?.payment_status == "Cancelled") {
      myInvoice += `${order?.cancel_booking_id ? order?.cancel_booking_id : ''}<br><span>(Reference ID: ${order?.complete_booking_id ? order?.complete_booking_id : order?.booking_id})</span>`
    } else {
      myInvoice += `${order?.booking_id ? order?.booking_id : order?._id}`
    }

    myInvoice += `<!-- ${order?.payment_status == "Complete" || order?.payment_status == "Cancelled" ? order?.complete_booking_id ? order?.complete_booking_id : order?._id : order?.booking_id ? order?.booking_id : order?._id} 
          ${order?.booking_id ? order?.booking_id : order?._id} -->  
            </td>
        </tr>
    </tbody>
</table>
      
      <table border="0" cellspacing="0" cellpadding="0" style="width: 100%; margin-bottom: 10px;">
        <thead style="width: 100%">
          <tr style="color:#dc1b5e; width: 31%">
          <th class="desc" style="width: ${order?.payment_status == "Partial" ? '45%' : '100%'}">Trip Details</th>
         `

    myInvoice += `</tr>
        </thead>
        <tbody style="width: 100%">
          <tr>
            <td><h5> ${order?.tour_id?.title} Trip</h5>
            <p>Batch: ${order?.travel_date} </p></td>
          `

    myInvoice += `
      </td>
  </tr>
</tbody>
</table>

<table border="0" cellspacing="0" cellpadding="0" style="width: 100%; margin-bottom: 10px;">
  <thead style="width: 100%">
    <tr style="color:#dc1b5e; width: 30%">
    <th class="desc" style="width:70%">Descrption</th>
    <th style="text-align: center;" class="unit" style="width:25%">Total</th>`

    myInvoice += `</tr>
  </thead>
  <tbody style="width: 100%">
    <tr>
      <td>  Refund Amount
      </td>
      <td style="text-align: center;">₹${order?.refundAmount.toLocaleString('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}</td>
    <tr>
      <td style="font-weight: bold" > Total Amount Refund (*Tax Included in Amount)
      </td>
      <td style="text-align: center;">₹${order?.refundAmount.toLocaleString('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}</td>`

    myInvoice += `
        </h6>
            </td>
          </tr>
          
          </tbody>
          </table>
      <br />
      <div id="thanks" style="text-align: center;">Thank you!</div>
      <br />
      <div id="notices">`;

    if (order?.bookingNote) {
      myInvoice += `
          <div>NOTE</div>
          <div class="notice" style="white-space: pre-wrap;">${order?.bookingNote}</div>
          <br />`;
    }
    myInvoice += `
        <div>TERMS & CONDITIONS:</div>
          <div class="notice">
            1. All disputes are subjected to Indore Jurisdiction only.
          </div>
        
      </div>
    </main>
  </body>
</html>
    `;
    // console.log(myInvoice);
    //html to pdf format
    var html = htmlToPdfmake(myInvoice, { tableAutoSize: true });
    // console.error(html);
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).open();

  }

  sendDataToGenerateInvoice(order: any) {
    if (this.pdf) {
      window.open(this.apiHost + this.pdf,);
    } else {
      this.sendDataaToGenerateInvoice(order)
    }
  }


  sendDataaToGenerateInvoice(order) {
    console.log(order)
    var couponAmount = (+order?.appliedCoupon) ? order?.appliedCoupon : 0;
    if (this.order.tour_type == 'normal') {
      var bpPriceArr = JSON.parse(this.orderbatchBYID?.boarding_point_price[0]);
      let boardingPoint = bpPriceArr.find(o => o.bp === order?.travel_boarding_point.boarding);
      if (boardingPoint.bp_type == 'single') {
        var boardingPointPrice = +boardingPoint.bp_price[0].singleBoardPrice;
      }
      else {
        //let boardingPointPriceArr = boardingPoint.bp_price.find(o => order?.boarding_point_type in o);
        let boardingPointPriceArr = boardingPoint.bp_price.find(o => Object.keys(o)[0] == order?.boardingType);
        //console.log(boardingPoint, order, boardingPointPriceArr)
        //console.log(boardingPointPriceArr,Object.keys(boardingPointPriceArr)[0]);
        var boardingPointPrice = Number(boardingPointPriceArr[Object.keys(boardingPointPriceArr)[0]]);
      }
    } else if (this.order.tour_type == 'customized') {
      let boardingPointPrice = order?.boardingTypepricecustomized;
    }
    // let boardingPointPrices = this.order?.boardingTypepricecustomized
    // var boardingPointPrice =  boardingPointPrices


    // for new format
    // <br>
    // <table border="0" cellspacing="0" cellpadding="0" style="width: 100%">
    // <thead style="width: 100%">
    //       <tr style="color:#dc1b5e; width: 20%">
    //         <th class="desc" style="width: 33.33%">Descripiton</th>
    //         <th class="unit" style="width: 33.33%">No. Of Travellers</th>
    //         <th class="total" style="width: 33.33%">Reminder Date</th>
    //       </tr>
    //     </thead>
    //  <tbody>
    //   <tr style=" width: 33.33%">
    //     <td>
    //       <h5>
    //        ${order?.tour_id?.title}&nbsp;
    //       </h5>
    //       <p>Date: ${order?.travel_date}</p>
    //     </td>
    //     <td>
    //     <br>
    //       <h5 style="text-align: center;">
    //        ${order?.travel_no_of_traveler}
    //       </h5>
    //     </td>
    //     <td>
    //       <h3>
    //        ${order?.tourReminderEndDate}
    //       </h3>
    //     </td>
    //   </tr>
    //  </tbody>
    // </table>



    let ref_amt = (order?.finalPrice - (this.n * this.order.tour_id.bookPrice)) / this.n;
    let remin_amt = (order?.finalPrice - (this.n * this.order.tour_id.bookPrice));
    let alredy_paid = ((order?.amountPaid) - (this.n * this.order.tour_id.bookPrice));
    //console.log(this.order.tour_id.bookPrice, this.order.partial_payment)
    let myInvoice = `
    <!DOCTYPE html>
<html>
<head>
<style>
  thead tr
  {
      background:#eee!important;
  }
  thead tr th
  {
    background: #eee;
    font-size: 16px
  }
  h5,h6
  {
    font-weight: bold;
    font-size: 16px;
    padding-left: 5px;
  }
  p,span,td
  {
    font-size:16px;
    margin-top:2px!important;
    margin-bottom: 2px!important;
    padding-left: 5px;
  }
  </style>
</head>
<body>
    
    <!-- <header class="clearfix" style="display: flex; align-items: flex-start; justify-content: center; position: relative;">
      <div id="logo" style="margin-top: 8px;">
        <img src="${this.logoImgInDateURL}" style="width: 280px; height: 75px;"/>
      </div>
      <div id="company" style="text-align: right;margin-top:-100px;position: absolute;right: 0%;top: 0%;">
        <h2 class="name" style="color:#dc1b5e;">${order?.payment_status == "Complete" || order?.payment_status == "Cancelled" ? "Tax Invoice" : "Proforma Invoice"}</h2>
        <h6 class="name"  >GLABOL EXPEDITION PVT. LTD.</h6>
        <div>1881 D Sudama Nagar, Indore (m.p) <br/>
        +91 89622 45021 | info@glabol.com<br/>
        </div>
        <div>GST No.: 23AAJCG2630E1Z3</div>
        <div>SAC Code: 998555</div>
      </div>
    </header> -->
    <table style="border: none;" border="0" cellspacing="0" cellpadding="0">
      <tbody style="border: none;">
        <tr style="width: 50.5%;border: none;">
          <td>
          <div id="logo" style="margin-top: 8px;">
            <img src="${this.logoImgInDateURL}" style="width: 225px; height: 60px;"/>
          </div>
          </td>
          <td>
            <div id="company" style="text-align: right;margin-top:-100px;position: absolute;right: 0%;top: 0%;">
              <h2 class="name" style="color:#dc1b5e;">${order?.payment_status == "Complete" || order?.payment_status == "Cancelled" ? "Tax Invoice" : "Proforma Invoice"}</h2>
              <h6 class="name"  >GLABOL EXPEDITION PVT. LTD.</h6>
              <div>1881 D Sudama Nagar, Indore (m.p)<br/>
               www.glabol.com | info@glabol.com<br/>
              </div>
              <div>GST No.: 23AAJCG2630E1Z3</div>
              <div>SAC Code: 998555</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <header style="display: flex; justify-content: center; align-items: center">
      <img src="${this.logoImgInDateURL}" style="width: 125px; height: 30px;"/>
      <span>THIS</span>
    </header> -->
    <main>
    <table border="0" cellspacing="0" cellpadding="0" style="width: 100%; margin-bottom: 10px;">
     <tbody style="width: 100%">
<tr style=" width: 33.33%">
    		<td rowspan="3">
            
          <h5>Billed To <br> ${order?.user_id?.firstname} ${order?.user_id?.lastname}</h5>
          <span>${order?.user_id?.mobile}</span>
          <span>${order?.user_id?.email ? order?.user_id?.email : ''}</span>`;

    if (order?.cusGSTNo) {
      myInvoice += ` <p>GST No.: ${order?.cusGSTNo}</p>`
    }

    if (order?.cuscompanyName) {
      myInvoice += `<p>Company Name: ${order?.cuscompanyName}</p>`
    }
    if (order?.cuscompanyAddress) {
      myInvoice += `<p>Company Address: ${order?.cuscompanyAddress}</p>`
    }

    let reminderDate = order?.tourReminderEndDate
    //console.log(new Date(reminderDate).toISOString().split('T')[0], reminderDate)

    myInvoice += `
    <h6> Date of Booking - ${new Date(order?.created_at)
        .toISOString()
        .split('T')[0]
        .split('-')
        .reverse()
        .join('-')}</h6>
    <h6> Date of Invoice - ${new Date(order?.updated_at)
        .toISOString()
        .split('T')[0]
        .split('-')
        .reverse()
        .join('-')}</h6></td>
        	<td style="text-align: center;">
            	<h5> Order ID </h5> ${order?.order_id}
            </td>
            <td rowspan="3" style="text-align: center;"><br><br><br>
            	Total Amount <h5 style= "color:#dc1b5e;"> ₹${(+order?.finalPrice - +couponAmount).toLocaleString(
          'en-IN'
        )}</h5>
            </td>
        </tr>
    	<tr style=" width: 33.33%">
        	<td style="text-align: center;">
            <h5>	Transaction ID </h5>${order?.transction_Id}
            </td>
        </tr>
        <tr style=" width: 33.33%">
        	<td style="text-align: center;">
          <h5> ${order?.payment_status == "Complete" || order?.payment_status == "Cancelled" ? "Tax Invoice ID" : "Proforma Invoice ID"} </h5>`

    if (order?.payment_status == "Complete") {
      myInvoice += `${order?.complete_booking_id ? order?.complete_booking_id : order?._id}`
    } else if (order?.payment_status == "Cancelled") {
      myInvoice += `${order?.cancel_booking_id ? order?.cancel_booking_id : ''}<br><span>(Reference ID: ${order?.complete_booking_id ? order?.complete_booking_id : order?.booking_id})</span>`
    } else {
      myInvoice += `${order?.booking_id ? order?.booking_id : order?._id}`
    }

    myInvoice += `<!-- ${order?.payment_status == "Complete" || order?.payment_status == "Cancelled" ? order?.complete_booking_id ? order?.complete_booking_id : order?._id : order?.booking_id ? order?.booking_id : order?._id} 
          ${order?.booking_id ? order?.booking_id : order?._id} -->  
            </td>
        </tr>
    </tbody>
</table>
      
      <table border="0" cellspacing="0" cellpadding="0" style="width: 100%; margin-bottom: 10px;">
        <thead style="width: 100%">
          <tr style="color:#dc1b5e; width: 31%">
          <th class="desc" style="width: ${order?.payment_status == "Partial" ? '45%' : '67.5%'}">Trip Details</th>
          <th class="unit" style="width: ${order?.payment_status == "Partial" ? '25%' : '32.5%'};">No. Of Travellers</th>`
    if (order?.payment_status == "Partial") {
      myInvoice += `<th class="total" style="width: 30%">Due Date</th>`
    }

    myInvoice += `</tr>
        </thead>
        <tbody style="width: 100%">
          <tr>
            <td><h5> ${order?.tour_id?.title} Trip</h5>
            <p>Batch: ${order?.travel_date} </p></td>
            <td style="text-align: center;"><br>${order?.travel_no_of_traveler}</td>`
    if (order?.payment_status == "Partial") {
      myInvoice += `<td style="text-align: center;"><br><span>${new Date(order?.tourReminderEndDate).toLocaleDateString().split("/")[1]}/${new Date(order?.tourReminderEndDate).toLocaleDateString().split("/")[0]}/${new Date(order?.tourReminderEndDate).toLocaleDateString().split("/")[2]}</span></td>`
    }

    myInvoice += `</tr>
        </tbody>
      </table>
      <table border="0" cellspacing="0" cellpadding="0" style="width: 100%; margin-bottom: 10px;">
                    <thead style="width: 100%">
                        <tr style="color:#dc1b5e; width: 31%">
                          <!-- <th class="no" style="width: 7%">S.No.</th> -->
                          <th class="desc" style="width: 40%">Descripiton</th>
                          <!-- <th class="unit" style="width: 15%"></th> -->
                          <th class="unit" style="width: 15%;">Quantity</th>
                          <th class="total" style="width: 15%">Price</th>
                          <th class="total" style="width: 30%">Total</th>
                        </tr>
                      </thead>
                      <tbody style="width: 100%">
                        <!-- <tr>
                          <td><h5> ${order?.tour_id?.title} Trip</h5>
                          <p>Batch: ${order?.travel_date} </p></td>
                          <td style="text-align: center;"><br>${order?.travel_no_of_traveler}</td>
                          <td></td>
                        </tr>
                        <tr style="color:#dc1b5e; width: 31%">
                          <td class="desc" style="width: 45%"></td>
                          <td class="unit" style="width: 25%; font-weight: bold;">Quantity</td>
                          <td class="total" style="width: 30%; font-weight: bold;">Price</td>
                        </tr> -->
                        
                        <tr style="width: 20%">
                          <!-- <td class="no" style="width: 7%">01</td> -->
                          <td class="desc" style="width: 38%;line-spacing:0.5px;">
                            <!-- <h5> ${order?.tour_id?.title} Trip</h5>
                            <p>Batch: ${order?.travel_date} </p> -->`
    if (order?.travel_packageType_data?.length !== 0) {
      myInvoice += `<h6 style="margin: 0px;"><u>Package Type</u></h6>`

      for (let packData of order?.travel_packageType_data) {
        if (packData.persons !== 0) {

          myInvoice += `<span>`;
          if (packData?.type !== 'normal') {
            myInvoice += `${packData.type} X `;
          }
          myInvoice += `${packData.packType}`;
          myInvoice += `</span>`;
        }
      }
    }
    if (order?.travel_roomSharing_data?.length !== 0) {
      // myInvoice += `<h6 style="margin: 0px; margin-top: 5px;">Room Sharing Type</h6>`

      function RoomSharing() {
        for (let roomData of order?.travel_roomSharing_data) {
          if (roomData.persons !== 0) {
            return myInvoice += `<h6 style="margin: 0px; margin-top: 5px;"><u>Room Sharing Type</u></h6>`;
          }
        }
      }
      RoomSharing();
      for (let roomData of order?.travel_roomSharing_data) {
        if (roomData.persons !== 0) {
          myInvoice += `<span>`;
          myInvoice += `${roomData.roomType}`;
          myInvoice += `</span>`;
        }
      }
    }
    if (order?.tour_type == 'normal') {
      myInvoice += `
        <h6 style="margin: 0px; margin-top: 5px;"><u>Boarding Point</u></h6>
          <span>${order?.travel_boarding_point.boarding} </span>`

    } else if (order?.tour_type == 'customized') {
      myInvoice += `
        <h6 style="margin: 0px; margin-top: 5px;"><u>Boarding Point</u></h6>
        <span>${order?.boardingpointustomized} </span>`
    }

    if (order?.boardingType) {
      myInvoice += `
                            <h6 style="margin: 0px; margin-top: 5px;"><u>Boarding Point Type</u></h6>
                              <span>${order?.boardingType}</span>`
    }

    myInvoice += `
                          </td>
                          <!-- <td style="text-align: center;margin-top:5px">${order?.travel_no_of_traveler}</td> -->
                          <td class="unit" style="text-align: center;">
                          <!-- <p>&nbsp;</p>
                          <p>&nbsp;</p> -->
                          <br>
                          `;

    if (order?.travel_packageType_data?.length !== 0) {
      // myInvoice += `<br>`

      for (let packData of order?.travel_packageType_data) {
        if (packData.persons !== 0) {

          // myInvoice += `₹${packData.packPrice} x ${packData.persons}`;
          myInvoice += `<div>${packData.persons}<div>`;
          // myInvoice += `<br>`;
        }
      }
    }
    let forStyle = false;
    if (order?.travel_roomSharing_data?.length !== 0) {
      myInvoice += `<br>`
      for (let roomData of order?.travel_roomSharing_data) {
        if (roomData.persons !== 0) {

          // myInvoice += `₹${roomData.roomPrice} x ${roomData.persons}`;
          myInvoice += `<div>${roomData.persons}</div>`;
          // myInvoice += `<br> <br>`;
        }
      }
      function forBr() {
        for (let roomData of order?.travel_roomSharing_data) {
          if (roomData.persons !== 0) {
            return myInvoice += `<div>&nbsp;</div>`;
          }
        }
      }
      forBr()
      // myInvoice += `<br>`;
    }
    myInvoice += `         
                        <!-- <br>
                        <span>₹${+boardingPointPrice} x ${order?.travel_no_of_traveler}<br> -->
                        <span>${order?.old_travel_no_of_traveler}<br>
                        </td>
                        <td>
                        <br>
                        <!-- <p>&nbsp;</p>
                        <p>&nbsp;</p> -->`;
    if (order?.travel_packageType_data?.length !== 0) {
      for (let packData of order?.travel_packageType_data) {
        if (packData.persons !== 0) {

          // myInvoice += `₹${Number(packData.packPrice) * Number(packData.persons)}`;
          myInvoice += `<div>₹${packData.packPrice} x ${packData.persons}</div>`;
          // myInvoice += `<br>`;
        }
      }
    }
    if (order?.travel_roomSharing_data?.length !== 0) {
      myInvoice += `<br>`

      for (let roomData of order?.travel_roomSharing_data) {
        if (roomData.persons !== 0) {

          // myInvoice += `₹${Number(roomData.roomPrice) * Number(roomData.persons)}`;
          myInvoice += `<div>₹${roomData.roomPrice} x ${roomData.persons}</div>`;
          // myInvoice += `<br>`;
        }
      }
      function forBr() {
        for (let roomData of order?.travel_roomSharing_data) {
          if (roomData.persons !== 0) {
            return myInvoice += `<br><div>&nbsp;</div>`;
          }
        }
      }
      forBr()
      // myInvoice += `<br><br>`
    }
    // myInvoice += `<br><span>₹${Number(boardingPointPrice) * Number(order?.travel_no_of_traveler)}<br>`;
    if (order?.tour_type == 'normal') {
      myInvoice += `<div>₹${+boardingPointPrice} x ${order?.old_travel_no_of_traveler}</div>
      </td>`;
    } else if (order?.tour_type == 'customized') {
      myInvoice += `<div>₹${order?.boardingTypepricecustomized} x ${order?.old_travel_no_of_traveler}</div>
      </td>`;

    }

    myInvoice += `
                          <td>
                          <br>
                          <!-- <p>&nbsp;</p>
                          <p>&nbsp;</p> -->`;

    if (order?.travel_packageType_data?.length !== 0) {
      for (let packData of order?.travel_packageType_data) {
        if (packData.persons !== 0) {

          // myInvoice += `₹${Number(packData.packPrice) * Number(packData.persons)}`;
          myInvoice += `<div>₹${+packData.packPrice * +packData.persons}</div>`;
          // myInvoice += `<br>`;
        }
      }
    }
    if (order?.travel_roomSharing_data?.length !== 0) {
      myInvoice += `<br>`

      for (let roomData of order?.travel_roomSharing_data) {
        if (roomData.persons !== 0) {

          // myInvoice += `₹${Number(roomData.roomPrice) * Number(roomData.persons)}`;
          myInvoice += `<div>₹${+roomData.roomPrice * +roomData.persons}</div>`;
        }
      }
      function forBr() {
        for (let roomData of order?.travel_roomSharing_data) {
          if (roomData.persons !== 0) {
            return myInvoice += `<br><div>&nbsp;</div>`;
          }
        }
      }
      forBr()
      // myInvoice += `<br><br>`;
    }
    // myInvoice += `<br><span>₹${Number(boardingPointPrice) * Number(order?.travel_no_of_traveler)}<br>`;
    if (order?.tour_type == 'normal') {
      myInvoice += `<div>₹${+boardingPointPrice * +order?.old_travel_no_of_traveler}</div>
      </td>`;
    } else if (order?.tour_type == 'customized') {
      myInvoice += `<div>₹${order?.boardingTypepricecustomized * +order?.old_travel_no_of_traveler}</div>
      </td>`;

    }
    myInvoice += `
                      </tr>
                    </tbody>
                    <tfoot style="width:100%">
                    
                    <tr style="width:20%">
                      <td></td>
                      <td colspan="2">Final Price</td>
                      <td class="total">₹${order?.finalPrice.toLocaleString('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}</td>
                    </tr>
                    <tr style="width:20%">
                      <td></td>
                      <td colspan="2">Coupon Discount</td>
                      <td>₹${+couponAmount}</td>
                    </tr>
                  
                      <tr style="width:20%">
                        <td></td>
                        <td colspan="2">Total Package Cost</td>
                        <td>₹${(+order?.finalPrice - +couponAmount).toFixed(2)}</td>
                      </tr>`;


    //   if (order?.isOuter){
    //     myInvoice += `
    //     <tr style="width:20%">
    //       <td colspan="2"></td>
    //       <td colspan="2">Tour cost</td>
    //       <td>₹${((order?.finalPrice - +order?.appliedCoupon) / (1 + (order?.igst * 0.01))).toFixed(
    //   2
    // )}</td>
    //     </tr>
    //     `;

    //   }else{
    //     myInvoice += `
    //     <tr style="width:20%">
    //       <td colspan="2"></td>
    //       <td colspan="2">Tour cost</td>
    //       <td>₹${((order?.finalPrice - +order?.appliedCoupon) / (1 + (order?.cgst * 0.01) + (order?.sgst * 0.01))).toFixed(
    //   2
    // )}</td>
    //     </tr>
    //     `;

    //   }

    // `<tr style="width:20%">
    //   <td colspan="2"></td>
    //   <td colspan="2">Transction Charge (${
    //     order?.transctionCharge
    //   }%)</td>
    //   <td>₹${(
    //     +order?.transctionCharge *
    //     0.01 *
    //     (order?.finalPrice - +order?.appliedCoupon)
    //   ).toLocaleString('en-IN')}</td>
    // </tr>`;

    // if (order?.isOuter) {
    //   myInvoice += `<tr style="width:20%">
    //         <td colspan="2"></td>
    //         <td colspan="2">IGST  @ ${order?.igst}% </td>
    //         <td>₹${(
    //           +order?.igst *
    //           0.01 *
    //           (order?.finalPrice - +order?.appliedCoupon) / (1 + (order?.igst *
    //             0.01))
    //         ).toFixed(2)}</td>
    //       </tr>`;
    // } else {
    //   myInvoice += `<tr style="width:20%">
    //         <td colspan="2"></td>
    //         <td colspan="2">CGST  @ ${order?.cgst}% </td>
    //         <td>₹${(
    //           order?.cgst * 0.01 * ((+order?.finalPrice - +order?.appliedCoupon) / (1 + (order?.cgst * 0.01) + (order?.sgst * 0.01)))
    //         ).toFixed(2)}</td>
    //       </tr>
    //       <tr style="width:20%">
    //         <td colspan="2"></td>
    //         <td colspan="2">SGST  @ ${order?.sgst}%</td>
    //         <td>₹${(
    //           order?.sgst * 0.01 * ((+order?.finalPrice - +order?.appliedCoupon) / (1 + (order?.cgst * 0.01) + (order?.sgst * 0.01)))
    //         ).toFixed(2)}</td>
    //       </tr>`;
    // }



    //console.log(ref_amt * (this.n - order?.travel_no_of_traveler), remin_amt - alredy_paid)
    // ref_amt!==(remin_amt-alredy_paid)){
    let traveler = Math.round((remin_amt - alredy_paid) / ref_amt);
    // console.log(traveler, this.n - traveler, "---", this.n - order?.travel_no_of_traveler)
    // console.log(this.n, this.order.tour_id.bookPrice, this.order.finalPrice, this.totalPayAmt)
    //console.log(order?.travel_no_of_traveler !== this.n, "&&", order?.amountPaid, this.totalpaidAddon, "&& ", order?.isComplete <= 1)
    if ((order?.travel_no_of_traveler !== this.n) && ((order?.amountPaid - this.totalpaidAddon) < order?.totalAmount) && (order?.isComplete <= 1)) {

      myInvoice += `<tr style="width:20%">
                          <td>*Tax Included in Amount</td>
                        
                          <td style="font-weight: bold" colspan="2">Grand Total</td>
                          <td style="font-weight: bold">₹${(order?.finalPrice - +couponAmount).toLocaleString(
        'en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
      )}</td>
                        </tr>`
      if (order?.amountPaid === this.totalPayAmt) {
        myInvoice += `
                          <tr style="width:20%">
                          <td>*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
                          <td colspan="2">Amount after Member Cancel</td>
                          <td>₹${(order?.totalAmount).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td>
                        </tr>
                          <!-- <tr style="width:20%">
                          <td></td>
                          <td colspan="2">Amount Paid</td>
                          <td>₹${(order?.totalAmount).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td>
                        </tr> -->
                        `;
        /*
          <tr style="width:20%">
          <td colspan="2"></td>
          <td colspan="2">Remaining Amount</td>
          <td>₹${((order?.finalPrice - +couponAmount) - ( this.totalPayAmt-this.totalpaidAddon)).toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}</td>
        </tr>`;
    /* if ((ref_amt * (this.n - order?.travel_no_of_traveler)) !== (remin_amt - alredy_paid)) {
        let traveler = Math.round((remin_amt - alredy_paid) / ref_amt);*/
        /* myInvoice += `
            <tr style="width:20%">
              <td colspan="2">*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
              <td colspan="2">Total Amount to pay</td>
              <td>₹${(order?.totalAmount -( this.totalPayAmt-this.totalpaidAddon)).toLocaleString('en-IN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}</td>
            </tr>`;*/
      }
      else if (order?.amountPaid !== this.totalPayAmt + (this.n * this.order.tour_id.bookPrice)) {
        myInvoice += `
                        <tr style="width:20%">
                        <td>*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
                        <td colspan="2">Amount after Member Cancel</td>
                        <td>₹${((order?.totalAmount)).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td>
                      </tr>
                        <!-- <tr style="width:20%">
                        <td></td>
                        <td colspan="2">Amount Paid</td>
                        <td>₹${((order?.totalAmount)).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td>
                      </tr> -->
                      `;/*
                        <tr style="width:20%">
                        <td colspan="2"></td>
                        <td colspan="2">Remaining Amount</td>
                        <td>₹${((order?.finalPrice - +couponAmount) - ( this.totalPayAmt)).toLocaleString('en-IN', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}</td>
                      </tr>`;
                  /* if ((ref_amt * (this.n - order?.travel_no_of_traveler)) !== (remin_amt - alredy_paid)) {
                      let traveler = Math.round((remin_amt - alredy_paid) / ref_amt);*/
        /*  myInvoice += `
            <tr style="width:20%">
              <td colspan="2">*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
              <td colspan="2">Total Amount to pay</td>
              <td>₹${(order?.totalAmount - ( this.totalPayAmt)).toLocaleString('en-IN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}</td>
            </tr>`;*/
      }
      else {
        myInvoice += `
                        <tr style="width:20%">
                        <td>*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
                        <td colspan="2">Amount after Member Cancel</td>
                        <td>₹${((order?.totalAmount)).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td>
                      </tr>
                        <!-- <tr style="width:20%">
                        <td></td>
                        <td colspan="2">Amount Paid</td>
                        <td>₹${((order?.totalAmount)).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td>
                      </tr> -->
                      `;
        /*   <tr style="width:20%">
            <td colspan="2"></td>
            <td colspan="2">Remaining Amount</td>
            <td>₹${((order?.finalPrice - +couponAmount) - ((this.n * this.order.tour_id.bookPrice) + this.totalPayAmt)).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td>
          </tr>`;
      /* if ((ref_amt * (this.n - order?.travel_no_of_traveler)) !== (remin_amt - alredy_paid)) {
          let traveler = Math.round((remin_amt - alredy_paid) / ref_amt);*/
        /*  myInvoice += `
            <tr style="width:20%">
              <td colspan="2">*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
              <td colspan="2">Total Amount to pay</td>
              <td>₹${(order?.totalAmount - ((this.n * this.order.tour_id.bookPrice) + this.totalPayAmt)).toLocaleString('en-IN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}</td>
            </tr>`;*/

      }
      /* myInvoice += `
              <tr style="width:20%">
                <td colspan="2">*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
                <td colspan="2">Total Amount to pay</td>
                <td>₹${(order?.totalAmount - (order?.amountPaid)).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td>
              </tr>`;*/

    }
    else if (order.payment_status === "Cancelled") {
      if (this.n - (this.n - order?.travel_no_of_traveler) === 1) {
        myInvoice += `<tr style="width:20%">
                                    <td>*Tax Included in Amount</td>
                                    <td style="font-weight: bold" colspan="2">Grand Total</td>
                                    <td style="font-weight: bold">₹${(order?.finalPrice - +couponAmount).toLocaleString(
          'en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
        )}</td>
                                  </tr>
                                  <tr style="width:20%">
                                    <td></td>
                                    <td colspan="2">Total Amount to Pay</td>
                                    <td>₹0</td>
                                  </tr>`;
      } else if (this.n != order?.travel_no_of_traveler) {
        myInvoice += `<tr style="width:20%">
                <td>*Tax Included in Amount</td>
                <td style="font-weight: bold" colspan="2">Grand Total</td>
                <td style="font-weight: bold">₹${(order?.finalPrice - +couponAmount).toLocaleString(
          'en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
        )}</td>
          </tr>

              <tr style="width:20%">
              <td>*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
          <td colspan="2">Amount after Member Cancel</td>
          <td>₹${(order?.totalAmount).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td>
          </tr>`}
      else {
        myInvoice += `<tr style="width:20%">
                                    <td>*Tax Included in Amount</td>
                                    <td style="font-weight: bold" colspan="2">Grand Total</td>
                                    <td style="font-weight: bold">₹${(order?.finalPrice - +couponAmount).toLocaleString(
          'en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
        )}</td>
        </tr>

                                 <!-- <tr style="width:20%">
                                  <td>*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
                          <td colspan="2">Amount after Member Cancel</td>
                          <td>₹${(order?.totalAmount).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td>
                          </tr> -->
                                 <!-- </tr>
                                  <tr style="width:20%">
                                    <td></td>
                                    <td colspan="2">Total Amount to Pay</td>
                                    <td>₹${(order?.totalAmount - order?.amountPaid).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td> 
                                  </tr> -->`;
      }
    }
    //add on after full payment
    else if (((order?.amountPaid - this.totalpaidAddon) > order?.totalAmount || order?.isComplete > 1) && (this.n == order?.travel_no_of_traveler)) {
      if (this.n == order?.travel_no_of_traveler) {
        myInvoice += `<tr style="width:20%">
                      <td>*Tax Included in Amount</td>
                      <td style="font-weight: bold" colspan="2">Grand Total</td>
                      <td style="font-weight: bold">₹${(order?.finalPrice - +couponAmount).toLocaleString(
          'en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
        )}</td>
                    </tr>
                    <!-- <tr style="width:20%">
                      <td></td>
                      <td colspan="2">Total Amount Paid</td>
                      <td>₹${(order?.totalAmount).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td>
                    </tr> -->`;
      }
    }

    //ends

    else if (((order?.amountPaid - this.totalpaidAddon) >= order?.totalAmount || order?.isComplete > 1) && (this.n !== order?.travel_no_of_traveler)) {

      myInvoice += `<tr style="width:20%">
                                      <td>*Tax Included in Amount</td>
                                      <td style="font-weight: bold" colspan="2">Grand Total</td>
                                      <td style="font-weight: bold">₹${(order?.finalPrice - +couponAmount).toLocaleString(
        'en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
      )}</td>
                                    </tr>
                                    <tr style="width:20%">
                                    <td>*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
                        <td colspan="2">Amount after Member Cancel</td>
                        <td>₹${(order?.totalAmount).toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}</td>
                      </tr>
                                    <!-- <tr style="width:20%">
                                    <td></td>
                        <td colspan="2">Amount Paid</td>
                        <td>₹${(order?.totalAmount).toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}</td>
                      </tr> -->
                    `;
    }
    else {
      myInvoice += `<tr style="width:20%">
                    <td>*Tax Included in Amount</td>
                        
                          <td style="font-weight: bold" colspan="2">Grand Total</td>
                          <td style="font-weight: bold">₹${(order?.finalPrice - +couponAmount).toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}</td>
                        </tr>
                        <!-- <tr style="width:20%">
                        <td></td>
                        <td colspan="2">Total Amount Paid</td>
                        <td>₹${(order?.totalAmount).toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}</td>
                      </tr> -->`;

    }

    if (order?.isRefundedOnCancel) {
      //   if(order?.isOuter){
      //     myInvoice += `<tr style="width:20%">
      //     <td colspan="2"></td>
      //     <td colspan="2">Refund Tour Amount</td>
      //     <td>₹${(((order?.refundAmount) / (1 + (order?.igst * 0.01)))).toFixed(
      //       2
      //     )}</td>
      //   </tr>`;
      //   }else{
      //     myInvoice += `
      //     <tr style="width:20%">
      //       <td colspan="2"></td>
      //       <td colspan="2">Tour cost</td>
      //       <td>₹${(((order?.refundAmount) / (1 + (order?.cgst * 0.01) + (order?.sgst * 0.01)))).toFixed(
      //   2
      // )}</td>
      //     </tr>
      //     `;
      //   }
      // if (order?.isOuter) {
      //   myInvoice+= `<tr style="width:20%">
      //     <td colspan="2"></td>
      //     <td colspan="2">IGST  @ ${order?.igst}% </td>
      //     <td>₹${((order?.igst*0.01*(+order?.refundAmount)) /(1+(order?.igst*0.01))).toFixed(2)}</td>
      //   </tr>`;
      // } else {
      //   myInvoice+= `<tr style="width:20%">
      //     <td colspan="2"></td>
      //     <td colspan="2">CGST  @ ${order?.cgst}% </td>
      //     <td>₹${(((order?.cgst*0.01*(+order?.refundAmount)) / (1+(order?.cgst*0.01)+(order?.sgst*0.01)))).toFixed(2)}</td>
      //   </tr>
      //   <tr style="width:20%">
      //     <td colspan="2"></td>
      //     <td colspan="2">SGST  @ ${order?.sgst}%</td>
      //     <td>₹${(((order?.sgst*0.01*(+order?.refundAmount)) / (1+(order?.cgst*0.01)+(order?.sgst*0.01)))).toFixed(2)}</td>
      //   </tr>`;
      // }
      myInvoice += `<tr style="width:20%">
                          <td></td>
                          <td colspan="2">Refund Amount</td>
                          <td>₹${order?.refundAmount.toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}</td>
                        </tr>`;
    }

    myInvoice += `</tfoot>
                    </table>
                    <table border="0" cellspacing="0" cellpadding="0" style="width:100%; margin-bottom: 10px;">
                      <thead style="width:100%">
                        <tr style="color:#dc1b5e; width: 33.33%">
                          <th class="desc">Payment Status</th>
                          <th class="unit">Amount Already Paid</th>
                          <th class="unit">Remaining Amount To Pay</th>
                        </tr>
                      </thead>`;
    //add on after full payment
    if (order?.amountPaid > order?.totalAmount) {
      myInvoice += `
                      <tbody style="width:100%">
                        <tr style="text-align: center; width: 33.33%">
                          <td class="desc"><h6> Complete </h6></td>
                          <td class="unit"><h6> ₹${(order?.totalAmount).toFixed(2)} </h6></td>
                          <td class="unit"><h6> ₹ 0.00 </h6>
                          </td>
                        </tr>
                        
                        </tbody>
          </table>
      <br />
      <div id="thanks" style="text-align: center;">Thank you!</div>
      <br />
      <div id="notices">`;
    }
    //

    else if (order.payment_status === "Cancelled") {
      if (this.n - (this.n - order?.travel_no_of_traveler) === 1) {
        myInvoice += `
        <tbody style="width:100%">
          <tr style="text-align: center; width: 33.33%">
            <td class="desc"><h6> ${order?.payment_status} </h6></td>
            <td class="unit"><h6> ₹${(order?.amountPaid).toFixed(2)} </h6></td>
            <td class="unit"><h6> ₹${(order?.totalAmount - order?.amountPaid).toLocaleString(
          'en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
        )} </h6>
            </td>
          </tr>
          
          </tbody>
          </table>
      <br />
      <div id="thanks" style="text-align: center;">Thank you!</div>
      <br />
      <div id="notices">`;
      }
      else {
        myInvoice += `
        <tbody style="width:100%">
          <tr style="text-align: center; width: 33.33%">
            <td class="desc"><h6> ${order?.payment_status} </h6></td>
            <td class="unit"><h6> ₹${(order?.amountPaid).toFixed(2)} </h6></td>
            <td class="unit"><h6> ₹${(order?.totalAmount - order?.amountPaid).toLocaleString(
          'en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
        )} </h6>
            </td>
          </tr>
          
          </tbody>
          </table>
      <br />
      <div id="thanks" style="text-align: center;">Thank you!</div>
      <br />
      <div id="notices">`;
      }
    }
    else if (order.payment_status === 'Partial') {
      myInvoice += `
              <tbody style="width:100%">
                <tr style="text-align: center; width: 33.33%">
                  <td class="desc"><h6> ${order?.payment_status} </h6></td>
                  <td class="unit"><h6> ₹${(order?.amountPaid).toFixed(2)} </h6></td>
                  <td class="unit"><h6> ₹${(order?.totalAmount - order?.amountPaid).toLocaleString(
        'en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
      )} </h6>
                  </td>
                </tr>
                
                </tbody>
                </table>
            <br />
            <div id="thanks" style="text-align: center;">Thank you!</div>
            <br />
            <div id="notices">`;
    }
    else {
      myInvoice += `
        <tbody style="width:100%">
          <tr style="text-align: center; width: 33.33%">
            <td class="desc"><h6> ${order?.payment_status} </h6></td>
            <td class="unit"><h6> ₹${(order?.amountPaid).toFixed(2)}</h6></td>
            <td class="unit"><h6> ₹${(order?.totalAmount - order?.amountPaid).toLocaleString(
        'en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
      )} </h6>
            </td>
          </tr>
          
          </tbody>
          </table>
      <br />
      <div id="thanks" style="text-align: center;">Thank you!</div>
      <br />
      <div id="notices">`;
    }
    if (order?.bookingNote) {
      myInvoice += `
          <div>NOTE</div>
          <div class="notice" style="white-space: pre-wrap;">${order?.bookingNote}</div>
          <br />`;
    }
    myInvoice += `
        <div>TERMS & CONDITIONS:</div>
          <div class="notice">
            1. All disputes are subjected to Indore Jurisdiction only.
          </div>
        
      </div>`

    myInvoice += `
    <div style = "height:99%; page-break-before: always;border:2px solid white;" > 
      <div style = "border: 2px solid rgb(184, 113, 80); padding: 0 0 10px 0; margin: 1% 4px;" > 
        <div style = "border-bottom: 2px solid rgb(184, 113, 80); " > 
          <h2 style = "margin: 3% 0; padding: 0;text-align: center;"> ITINERARY </h2>
        </div>`;

    let dayCount = 1;
    for (let i = 0; i < this.tour.Itinerary.length; i++) {
      const day = this.tour.Itinerary[i];
      const pageBreak = (i !== 0 && i % 2 === 0) ? 'page-break-before: always; margin-top: 50px;' : '';
      myInvoice += `<div style = " border-bottom:2px solid rgb(184, 113, 80); ">
            <div style=" padding: 10px; margin: 1% 4px; ${pageBreak}">
              <h2 style="color: red; text-decoration: underline;">Day ${dayCount++}: ${day.Title}</h2>
              <p>${day.Description}</p>
              <ul>`;
      if (day.Properties && day.Properties.length > 0) {
        for (const property of day.Properties) {
          myInvoice += `<li>${property}</li>`;
        }
      }
      myInvoice += `</ul>`;
      // htmlContent += `<div>`;
      //   if (day.Itineraryimage && day.Itineraryimage.length > 0) {
      //     for (const image of day.Itineraryimage) {
      //       const imagePath = `https://glabolapi.swapinfotech.com/${image.src}`;
      //       const imageResponse = await axios.get(imagePath, {
      //         responseType: "arraybuffer",
      //       });
      //       const imageBase64 = Buffer.from(imageResponse.data).toString(
      //         "base64"
      //       );
      //       const base64ImageSrc = `data:image/${image.orgName
      //         .split(".")
      //         .pop()};base64,${imageBase64}`;
      //       htmlContent += `<img src="${base64ImageSrc}" alt="${image.orgName}" style="width:175px;height:auto; margin-right: 10px; margin-bottom: 10px;" >`;
      //     }
      //   }
      // htmlContent += `</div>`; 
      myInvoice += `</div>`;
      myInvoice += `</div>`;
    }
    myInvoice += `<div style="border-bottom:2px solid rgb(184, 113, 80);">
          <div style="padding: 10px; margin: 1% 4px; ">
            <h2 style="margin: 10px 0px; padding: 0; color: red;text-decoration: underline; text-align:center;">${this.tour.inculssion}</h2>
            <p style="margin: 30px 0px; padding: 0;">${this.tour.summary}</p>
          </div>
        </div>`;
    myInvoice += `<div style="border-bottom:2px solid rgb(184, 113, 80);">
          <div style="padding: 10px; margin: 1% 4px; ">
            <h2 style="margin: 10px 0px; padding: 0; color: red;text-decoration: underline; text-align:center;">${this.tour.exculssion}</h2>
            <p style="margin: 30px 0px; padding: 0;">${this.tour.summaryExc}</p>
          </div>
        </div>`;
    myInvoice += `<div style="border-bottom:2px solid rgb(184, 113, 80);">
          <div style="padding: 10px; margin: 1% 4px; ">
            <h2 style="margin: 10px 0px; padding: 0; color: red;text-decoration: underline; text-align:center;">Cancellation Policy</h2>
            <p style="margin: 30px 0px; padding: 0;">${this.tour.cancellationPolicy}</p>
          </div>
        </div>`;
    myInvoice += `<div style="max-width: 800px; page-break-before: always;
            margin-top: 50px ; 
            background-color: #fff;
            padding: 20px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);">
          <h1 style="text-align: center;color: #333">Why Glabol?</h1>
          <br>
          <p style="line-height: 1.6;
            color: #666;">Glabol's trips are exclusively for <span class="highlight">18-45 year olds</span>. So you get a chance to travel with a group of young people just like you, who are fun and easy to connect to.</p>
            <br>
            <br>
          <p style="line-height: 1.6;
            color: #666;">We know how hard it can be to convince friends for a trip and make plans which never see the light of day. With us, you can join the trip solo too if others back out. You may be going with a bunch of strangers but you will come back with a family.</p>
            <br>
            <br>
          <p style="line-height: 1.6;
              color: #666;">Our trips are chill and comprise people who like to live in the moment, appreciate nature, and make new friends. The kind of people you will meet and make friends with is what makes it so special.</p>
              <br>
              <br>
          <div style="text-align: center;margin: 20px 0;">
            <p style="line-height: 1.6; color: #666;"><span class="highlight">ISO 1900:2015 CERTIFIED</span></p>
          </div>
          <p style="line-height: 1.6;
              color: #666;"><span style="color: #ff5722;
              font-weight: bold;">Travel Now, Pay Later.</span></p>
              <br>
          <p style="line-height: 1.6;
            color: #666;"><strong>“Work, Travel, Save, Repeat”</strong></p>
            <br>
          <div style="text-align: center;
            padding: 10px;
            background-color: #333;
            position: absolute;
            left:0;
            color: #fff;
            width: 100%;">
            &copy; 2024 Glabol. All rights reserved.
          </div>
        </div>
      </div>
    </main>
  </body>
</html>
    `;
    // console.log(myInvoice);
    //html to pdf format
    var html = htmlToPdfmake(myInvoice, { tableAutoSize: true });
    // console.error(html);
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).open();
  }

  sendDataToGenerateAddonInvoice(order) {
    // console.log(order);
    let myInvoice = `
    <!DOCTYPE html>
<html>
<head>
</head>
<body>
    
    <header class="clearfix" style="content: ''; display: table; clear: both;">
      <div id="logo" style=""float: left; margin-top: 8px;>
      <img src="${this.logoImgInDateURL}" style="width: 300px; height: 75px" />
      </div>
      <div id="company" style="float: right; text-align: right; position:relative;top:0%">
        <h2 class="name" style="color:#dc1b5e;">${order?.payment_status == "Complete" || order?.payment_status == "Cancelled" ? "Tax Invoice" : "Proforma Invoice"}</h2>
        <h6 class="name"  >GLABOL EXPEDITION PVT. LTD.</h6>
        <div>1881 D Sudama Nagar, Indore (m.p) <br/>
         www.glabol.com | info@glabol.com<br/>
        </div>
        <div>GST No.: 23AAJCG2630E1Z3</div>
        <div>SAC Code: 998555</div>
       
      </div>
    </header>
    <br>
    <main>
    <br>
    <table border="0" cellspacing="0" cellpadding="0" style="width: 100%">
     <tbody style="width: 100%">
<tr style=" width: 33.33%">
    		<td rowspan="3">
            
            	<h5>Billed To <br> ${order?.user_id?.firstname} ${order?.user_id?.lastname}</h5>
          <span>${order?.user_id?.mobile}</span>
          <span>${order?.user_id?.email ? order?.user_id?.email : ''}</span>`
    if (order?.cusGSTNo) {
      myInvoice += ` <p>GST No.: ${order?.cusGSTNo}</p>`
    }

    if (order?.cuscompanyName) {
      myInvoice += `<p>Company Name: ${order?.cuscompanyName}</p>`
    }
    if (order?.cuscompanyAddress) {
      myInvoice += `<p>Company Address: ${order?.cuscompanyAddress}</p>`
    }
    myInvoice += `
    <h6> Date of Booking - ${new Date(order?.created_at)
        .toISOString()
        .split('T')[0]
        .split('-')
        .reverse()
        .join('-')}</h6>
    <h6> Date of Invoice - ${new Date(order?.updated_at)
        .toISOString()
        .split('T')[0]
        .split('-')
        .reverse()
        .join('-')}</h6>
            </td>
        	<td style="text-align: center;">
            	<h5> Order ID </h5> ${order?.order_id}
            </td>
            <td rowspan="3" style="text-align: center;"><br><br><br>
            	Total Add-on Amount <h5 style= "color:#dc1b5e;"> ₹${this.totalAddonAmt?.toLocaleString(
          'en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
        )}</h5>
            </td>
        </tr>
    	<tr style=" width: 33.33%">
        	<td style="text-align: center;">
            <h5>	Transaction ID </h5>${order?.transction_Id}
            </td>
        </tr>
        <tr style=" width: 33.33%">
        	<td style="text-align: center;">
          <h5> Invoice ID </h5> ${order?.complete_booking_id ? order?.complete_booking_id : order?.booking_id}
            
            </td>
        </tr>
    </tbody>
</table>
      <br>
      <table border="0" cellspacing="0" cellpadding="0" style="width: 100%">
        <thead style="width: 100%">
          <tr style="color:#dc1b5e; width: 20%">
            <th class="no" style="width: 7%">S.No.</th>
            <th class="desc">Services</th>
            <th class="unit">Basic Price</th>
            <th class="unit" style="width: 13%">Quantity</th>
            <th class="unit">Status</th>
            <th class="total">Total</th>
          </tr>
        </thead>
        <tbody style="width: 100%">`;
    for (let [i, addon] of order?.addons_id?.addons_data.entries()) {
      myInvoice += `<tr style="width: 20%">
              <td class="no" style="width: 7%">${i + 1}</td>
              <td class="desc">${addon.service}</td>
              <td class="unit">₹${addon.price.toFixed(2)}</td>
              <td class="unit" style="width: 13%">${addon.quantity}</td>
              <td class="unit">${addon.status === 'paid' ? 'Paid' : 'Not Paid'}</td>
              <td class="total">₹${(addon.price * addon.quantity).toFixed(2)}</td>
            </tr>`
    }
    myInvoice += `</tbody>
        <tfoot style="width:100%">`;
    myInvoice += `
        <tr style="width:20%">
          <td colspan="3"></td>
          <td colspan="2">Total Add-on Cost</td>
          <td>₹${(order?.addons_id?.addons_data.reduce((total, num) => total + (num.price * num.quantity), 0)).toLocaleString(
      'en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
    )}</td>
        </tr>`;
    // if(order?.isOuter){
    //   myInvoice += `
    //   <tr style="width:20%">
    //     <td colspan="3"></td>
    //     <td colspan="2">Add-on Cost</td>
    //     <td>₹${(order?.addons_id?.addons_data.reduce((total, num) => total + (num.price * num.quantity) / (1+(order?.igst *0.01)), 0)).toLocaleString(
    //       'en-IN'
    //     )}</td>
    //   </tr>`;

    // }else{
    //   myInvoice += `
    //   <tr style="width:20%">
    //     <td colspan="3"></td>
    //     <td colspan="2">Add-on Cost</td>
    //     <td>₹${(order?.addons_id?.addons_data.reduce((total, num) => total + (num.price * num.quantity) /(1+(order?.cgst*0.01)+(order?.sgst*0.01)), 0)).toLocaleString(
    //       'en-IN'
    //     )}</td>
    //   </tr>`;
    // }

    // if (order?.isOuter) {
    //   myInvoice += `<tr style="width:20%">
    //         <td colspan="3"></td>
    //         <td colspan="2">IGST  @ ${order?.igst}% </td>
    //         <td>₹${(
    //           +order?.igst *
    //             0.01 *
    //             (order?.addons_id?.addons_data.reduce((total, num) => total + (num.price * num.quantity) / (1+(order?.igst *
    //               0.01)), 0))
    //         ).toLocaleString('en-IN')}</td>
    //       </tr>`;
    // } else {
    //   myInvoice += `<tr style="width:20%">
    //         <td colspan="3"></td>
    //         <td colspan="2">CGST  @ ${order?.cgst}% </td>
    //         <td>₹${(
    //           +order?.cgst *
    //             0.01 *
    //             (order?.addons_id?.addons_data.reduce((total, num) => total + (num.price * num.quantity) /(1+(order?.cgst *0.01 )+(order?.sgst * 0.01)), 0) )
    //         ).toLocaleString('en-IN')}</td>
    //       </tr>
    //       <tr style="width:20%">
    //         <td colspan="3"></td>
    //         <td colspan="2">SGST  @ ${order?.sgst}%</td>
    //         <td>₹${(
    //           +order?.sgst * 0.01 * (order?.addons_id?.addons_data.reduce((total, num) => total + (num.price * num.quantity) /(1+(order?.cgst *0.01 )+(order?.sgst * 0.01)), 0))
    //         ).toLocaleString('en-IN')}</td>
    //       </tr>`;
    // 
    myInvoice += `<tr style="width:20%">
            <td colspan="3">*Tax Included in Amount</td>
            <td colspan="2" style="font-weight: bold">Grand Total</td>
            <td style="font-weight: bold">₹${this.totalAddonAmt.toLocaleString(
      'en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
    )}</td>
          </tr>
          <!-- <tr style="width:20%">
            <td colspan="3"></td>
            <td colspan="2">Total Amount Paid</td>
            <td>₹${this.totalpaidAddon.toLocaleString('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}</td>
          </tr> -->`;

    // if (order?.isRefundedOnCancel) {
    //   myInvoice += `<tr style="width:20%">
    //       <td colspan="3"></td>
    //       <td colspan="2">Refund Amount</td>
    //       <td>₹${order?.refundAmount.toLocaleString('en-IN')}</td>
    //     </tr>`;
    // }

    myInvoice += `</tfoot>
      </table>
      <br>
       <table border="0" cellspacing="0" cellpadding="0" style="width:100%">
        <thead style="width:100%">
          <tr style="color:#dc1b5e; width: 33.33%">
            <th class="desc">Payment Status</th>
            <th class="unit">Amount Already Paid</th>
            <th class="unit">Remaining Amount To Pay</th>
          </tr>
        </thead>
        <tbody style="width:100%">
          <tr style="text-align: center; width: 33.33%">
            <td class="desc"><h6> ${order?.payment_status} </h6></td>
            <td class="unit"><h6> ₹${(this.totalAddonAmt - this.totalUnpaidAddon).toLocaleString('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })} </h6></td>
            <td class="unit"><h6> ₹${this.totalUnpaidAddon.toLocaleString('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })} </h6>
            </td>
          </tr>
          
          </tbody>
          </table>
      <br />
      <div id="thanks" style="text-align: center;">Thank you!</div>
      <br />
      <div id="notices">`;
    if (order?.bookingNote) {
      myInvoice += `
          <div>NOTE:</div>
          <div class="notice" style="white-space: pre-wrap;">${order?.bookingNote}</div>
          <br />`;
    }
    if (this.addon_notes) {
      myInvoice += `<div> Addon Notes:</div>
        <div class="notice" >
       ${this.addon_notes}
      </div> `
    }
    myInvoice += `
        <div>TERMS & CONDITIONS:</div>
          <div class="notice">
            1. All disputes are subjected to Indore Jurisdiction only.
          </div>


          
        
      </div>`



    myInvoice += `
    <div style = "height:99%; page-break-before: always;border:2px solid white;" > 
      <div style = "border: 2px solid rgb(184, 113, 80); padding: 0 0 10px 0; margin: 1% 4px;" > 
        <div style = "border-bottom: 2px solid rgb(184, 113, 80); " > 
          <h2 style = "margin: 3% 0; padding: 0;text-align: center;"> ITINERARY </h2>
        </div>`;

    let dayCount = 1;
    for (let i = 0; i < this.tour.Itinerary.length; i++) {
      const day = this.tour.Itinerary[i];
      const pageBreak = (i !== 0 && i % 2 === 0) ? 'page-break-before: always; margin-top: 50px;' : '';
      myInvoice += `<div style = " border-bottom:2px solid rgb(184, 113, 80); ">
            <div style=" padding: 10px; margin: 1% 4px; ${pageBreak}">
              <h2 style="color: red; text-decoration: underline;">Day ${dayCount++}: ${day.Title}</h2>
              <p>${day.Description}</p>
              <ul>`;
      if (day.Properties && day.Properties.length > 0) {
        for (const property of day.Properties) {
          myInvoice += `<li>${property}</li>`;
        }
      }
      myInvoice += `</ul>`;
      // htmlContent += `<div>`;
      //   if (day.Itineraryimage && day.Itineraryimage.length > 0) {
      //     for (const image of day.Itineraryimage) {
      //       const imagePath = `https://glabolapi.swapinfotech.com/${image.src}`;
      //       const imageResponse = await axios.get(imagePath, {
      //         responseType: "arraybuffer",
      //       });
      //       const imageBase64 = Buffer.from(imageResponse.data).toString(
      //         "base64"
      //       );
      //       const base64ImageSrc = `data:image/${image.orgName
      //         .split(".")
      //         .pop()};base64,${imageBase64}`;
      //       htmlContent += `<img src="${base64ImageSrc}" alt="${image.orgName}" style="width:175px;height:auto; margin-right: 10px; margin-bottom: 10px;" >`;
      //     }
      //   }
      // htmlContent += `</div>`; 
      myInvoice += `</div>`;
      myInvoice += `</div>`;
    }
    myInvoice += `<div style="border-bottom:2px solid rgb(184, 113, 80);">
          <div style="padding: 10px; margin: 1% 4px; ">
            <h2 style="margin: 10px 0px; padding: 0; color: red;text-decoration: underline; text-align:center;">${this.tour.inculssion}</h2>
            <p style="margin: 30px 0px; padding: 0;">${this.tour.summary}</p>
          </div>
        </div>`;
    myInvoice += `<div style="border-bottom:2px solid rgb(184, 113, 80);">
          <div style="padding: 10px; margin: 1% 4px; ">
            <h2 style="margin: 10px 0px; padding: 0; color: red;text-decoration: underline; text-align:center;">${this.tour.exculssion}</h2>
            <p style="margin: 30px 0px; padding: 0;">${this.tour.summaryExc}</p>
          </div>
        </div>`;
    myInvoice += `<div style="border-bottom:2px solid rgb(184, 113, 80);">
          <div style="padding: 10px; margin: 1% 4px; ">
            <h2 style="margin: 10px 0px; padding: 0; color: red;text-decoration: underline; text-align:center;">Cancellation Policy</h2>
            <p style="margin: 30px 0px; padding: 0;">${this.tour.cancellationPolicy}</p>
          </div>
        </div>`;
    myInvoice += `<div style="max-width: 800px; page-break-before: always;
            margin-top: 50px ; 
            background-color: #fff;
            padding: 20px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);">
          <h1 style="text-align: center;color: #333">Why Glabol?</h1>
          <br>
          <p style="line-height: 1.6;
            color: #666;">Glabol's trips are exclusively for <span class="highlight">18-45 year olds</span>. So you get a chance to travel with a group of young people just like you, who are fun and easy to connect to.</p>
            <br>
            <br>
          <p style="line-height: 1.6;
            color: #666;">We know how hard it can be to convince friends for a trip and make plans which never see the light of day. With us, you can join the trip solo too if others back out. You may be going with a bunch of strangers but you will come back with a family.</p>
            <br>
            <br>
          <p style="line-height: 1.6;
              color: #666;">Our trips are chill and comprise people who like to live in the moment, appreciate nature, and make new friends. The kind of people you will meet and make friends with is what makes it so special.</p>
              <br>
              <br>
          <div style="text-align: center;margin: 20px 0;">
            <p style="line-height: 1.6; color: #666;"><span class="highlight">ISO 1900:2015 CERTIFIED</span></p>
          </div>
          <p style="line-height: 1.6;
              color: #666;"><span style="color: #ff5722;
              font-weight: bold;">Travel Now, Pay Later.</span></p>
              <br>
          <p style="line-height: 1.6;
            color: #666;"><strong>“Work, Travel, Save, Repeat”</strong></p>
            <br>
          <div style="text-align: center;
            padding: 10px;
            background-color: #333;
            position: absolute;
            left:0;
            color: #fff;
            width: 100%;">
            &copy; 2024 Glabol. All rights reserved.
          </div>
        </div>
      </div>
  
    </main>
  </body>
</html>
    `;
    var html = htmlToPdfmake(myInvoice, { tableAutoSize: true });
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).open();
  }

  sendLink() {
    if (!this.linkAmount) {
      this.toast.fire({
        icon: 'error',
        title: 'Please enter amount payable against the link'
      });
      return;
    }
    if (this.linkAmount <= 0) {
      this.toast.fire({
        icon: 'error',
        title: 'Please enter a valid amount'
      });
      return;
    }

    const remainingAmount = (this.order?.totalAmount - this.order?.amountPaid) + this.totalAddonAmt;

    if (this.linkAmount > remainingAmount) {
      this.toast.fire({
        icon: 'error',
        title: 'Payable amount cannot be greater than remaining amount'
      });
      return;
    }

    const dataToSend = {
      amount: this.linkAmount,
      validTill: this.linkValidTill,
      maxPayable: remainingAmount
    };

    console.log(dataToSend, this.orderId);

    let apiCall;


    if (this.tour_type === 'normal') {
      apiCall = this.apiService.generateSendPayLinkNormal(this.orderId, dataToSend);
    } else if (this.tour_type === 'customized') {
      apiCall = this.apiService.generateSendPayLink(this.orderId, dataToSend);
    } else {
      this.toast.fire({
        icon: 'error',
        title: 'Invalid tour type'
      });
      return;
    }

    apiCall.subscribe((res: any) => {
      console.log(res);
      if (res.success) {
        this.linkAmount = undefined;
        this.linkValidTill = 1;
        this.toast.fire({
          icon: 'success',
          title: 'Pay link sent successfully'
        });
        (<any>$('#pay-link')).modal('hide');
        return
        location.reload();
      } else {
        this.toast.fire({
          icon: 'error',
          title: res.message
        });
        console.error(res.message);
      }
    });
  }


  // onlyNumberKey(evt: KeyboardEvent) {

  //   let ASCIICode = (evt.which) ? evt.which : evt.keyCode;
  //   return (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) ? false : true;
  // }

  adminPayAmount() {
    //console.log(this.adminAmount)
    if (!this.adminAmount) {
      this.toast.fire({
        icon: 'error',
        title: 'Please enter amount payable against the remaining amount'
      });
      return;
    }
    if (this.adminAmount <= 0) {
      this.toast.fire({
        icon: 'error',
        title: 'Please enter a valid amount'
      });
      return;
    }
    if (this.adminAmount > (this.order?.totalAmount - this.order?.amountPaid + this.totalAddonAmt)) {
      this.toast.fire({
        icon: 'error',
        title: 'Payable amount cannot be greater than remaining amount'
      });
      return;
    }
  }

  get travelersFormArray() {
    return this.addUserForm.get('travelers') as FormArray;
  }

  addUserFormAccordions(numTravelers: number) {
    this.travelersFormArray.clear(); // Clear existing form controls
    for (let i = 0; i < numTravelers; i++) {
      this.travelersFormArray.push(this.createTravelerForm());
    }
  }


  private createTravelerForm(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      DOB: ['', Validators.required],
      age: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.maxLength(10)]],
      email: ['', [Validators.required, Validators.email]],
      country: [''],
      state: ['', Validators.required],
      pincode: ['', Validators.required],
      city: ['', Validators.required],
      IdNumber: ['', Validators.required],
      Document_Front: ['null'],
      Document_Back: ['null'],
      packageType: [''],
      roomSharing: [''],
      boarding_point: [''],
      Idproof: [''],
    });
  }


  onFileChange(event: any, travelerIndex: number) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let formData = new FormData();
      formData.append('uploadFile', file);

      this.apiService.uploadimages(formData).subscribe(
        (result: any) => {
          console.log('Upload Result:', result);
          if (result && result.file_data && result.file_data.length > 0) {
            const imagePath = result.file_data[0].src;
            console.log('Image Path:', imagePath);

            // Initialize the fornviewID array if not already
            if (!this.fornviewID) {
              this.fornviewID = [];
            }

            // Ensure the index exists in fornviewID
            this.fornviewID[travelerIndex] = imagePath;

            // Ensure the traveler form exists before setting the value
            const travelerFormArray = this.addUserForm.get('travelers') as FormArray;
            if (travelerFormArray && travelerFormArray.at(travelerIndex)) {
              const travelerForm = travelerFormArray.at(travelerIndex);
              travelerForm.get('Document_Front')?.setValue(imagePath);
            } else {
              console.error(`Traveler form at index ${travelerIndex} does not exist.`);
            }
          } else {
            console.error('Unexpected API response format:', result);
          }
        },
        (error) => {
          console.error('Upload error:', error);
        }
      );
    } else {
      console.warn('No file selected for upload.');
    }
  }

  calculateAgeForTraveler(index: number) {
    const travelerForm = this.travelersFormArray.at(index);
    const dobValue = travelerForm.get('DOB').value; // "YYYY-MM-DD" format from input type="date"

    if (dobValue) {
      const dob = new Date(dobValue); // Directly use the date string

      if (!isNaN(dob.getTime())) {
        // Calculate age
        const now = new Date();
        let age = now.getFullYear() - dob.getFullYear();
        const monthDiff = now.getMonth() - dob.getMonth();

        // Adjust age if the birthday hasn't occurred yet this year
        if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < dob.getDate())) {
          age--;
        }

        console.log('Calculated Age:', age);
        travelerForm.patchValue({ age: age });
      } else {
        console.error('Invalid date format');
      }
    } else {
      console.error('Date of Birth is missing');
    }
  }






  onFileChangeback(event, travelerIndex) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let formData = new FormData();
      formData.append('uploadFile', file);
      this.apiService.uploadimages(formData).subscribe((result: any) => {
        console.log(result);
        // this.backviewID = result?.file_data[0].src
        // console.log(this.backviewID)
        if (result && result.file_data && result.file_data.length > 0) {
          const imagePath = result.file_data[0].src;
          this.backviewID[travelerIndex] = imagePath;
          // Set the image path for the specific traveler
          const travelerForm = (this.addUserForm.get('travelers') as FormArray).at(travelerIndex);
          travelerForm.get('Document_Back')?.setValue(imagePath);
        }
        // if (result && result.file_data && result.file_data.length > 0) {
        //   const imagePath = result.file_data[0].src;
        //   this.backviewID = imagePath;
        //   // Ensure that this.addUserForm is not null before accessing form controls
        //   if (this.addUserForm) {
        //     const travelersArray = this.addUserForm.get('travelers') as FormArray;
        //     // Loop through each traveler and set the Document_Front value
        //     travelersArray.controls.forEach((travelerControl: FormGroup) => {
        //       travelerControl.get('Document_Back')?.setValue(imagePath);
        //     });
        //   }
        // }
      });
    }
  }





  User(userid: any, tourids: any, number: any, data: any, roomsharing: any, boardingpoint: any, applyTour: any) {
    console.log(userid, tourids, number, applyTour, roomsharing, boardingpoint);
    this.spinner.show();
    //return
    this.apiService.GetallCountry().subscribe((resp: any) => {
      this.spinner.hide();
      console.log(resp);
      this.country = resp;
      this.addUserFormAccordions(number); // Dynamically add accordions based on number of travelers
      this.myData = data;
      this.roomdata = roomsharing;
      this.boardingdata = boardingpoint;
      this.myUserId = userid;
      this.mytourids = tourids;
      this.applyTourID = applyTour
      this.edituser(this.applyTourID)
    });
  }

  onIdProofChange(selectedValue: string) {
    const inputElement = this.idNumberInput.nativeElement;

    if (selectedValue === 'Adharcard') {
      inputElement.setAttribute('pattern', '\\d*'); // Allow only numbers
    } else {
      inputElement.removeAttribute('pattern'); // Allow alphanumeric characters
    }
  }

  onlyNumberKey(event: KeyboardEvent) {
    const selectedValue = (document.querySelector('select[formControlName="Idproof"]') as HTMLSelectElement).value;
    if (selectedValue === 'Adharcard') {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
        return false;
      }
    }
    return true;
  }

  edituser(tour_id: any) {
    this.spinner.show();
    this.apiService.AlltourUserDetails(tour_id).subscribe((res: any) => {
      console.log(res, ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
      if (res.success == true) {
        this.spinner.hide();
        const travelersData = res?.data[0]?.travelers; // Assuming the traveler data is in the first element of the array
        this.travelersFormArray.clear();
        this.fornviewID = Array(this.travelersFormArray.length).fill('');
        this.backviewID = Array(this.travelersFormArray.length).fill('');
        this.fornviewID = []; // Initialize fornviewID array
        this.backviewID = []; // Initialize backviewID array
        travelersData.forEach(traveler => {
          const travelerFormGroup = this.createTravelerForm();
          travelerFormGroup.patchValue(traveler);
          this.travelersFormArray.push(travelerFormGroup);
          this.fornviewID.push(traveler?.Document_Front[0]); // Set image path for Document_Front
          this.backviewID.push(traveler?.Document_Back[0]); // Set image path for Document_Back
        });
      } else {
        this.spinner.hide();
        console.error("Invalid API response or travelers data is missing.");
      }
    });
  }





  onSubmit() {
    console.log(this.addUserForm.value);
    //return
    this.apiService.updatetourallUser(this.addUserForm.value, this.applyTourID).subscribe((resp: any) => {
      this.toastr.success('User added successfully!');
    },
      (error) => {
        console.error(error);
        this.toastr.error('Failed to add user. Please try again later.');
      }
    );
  }

}
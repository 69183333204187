<div class="login-box">
  <div class="login-logo">
    <a [routerLink]="['/']">
      <img src="../../../assets/img/cropped-glabol-192x192.png" alt="EASYEVALS ADMIN" width="30%">
      <br />
      <!-- <b>Glabol</b> -->
    </a>
  </div>
  <!-- /.login-logo -->
  <div class="card">
    <div class="card-body login-card-body">
      <p class="login-box-msg">Sign in to continue</p>

      <form [formGroup]="loginForm" (ngSubmit)="login()">
        <div class="input-group mb-3">
          <input
            formControlName="email"
            type="email"
            class="form-control"
            placeholder="Email"
          />
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-envelope"></span>
            </div>
          </div>
        </div>
        <div class="input-group mb-3">
          <input
            formControlName="password"
            type="password"
            class="form-control"
            placeholder="Password"
          />
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-lock"></span>
            </div>
          </div>
        </div>
        <!-- <div class="row"> -->
          <!-- <div class="col-7">
            <div class="icheck-primary">
              <input type="checkbox" id="remember" />
              <label for="remember"> Remember Me </label>
            </div>
          </div> -->
          <!-- /.col -->
          <!-- <div class="col-5">
            <app-button
              [type]="'submit'"
              [block]="true"
              [text]="'Sign In'"
              [loading]="isAuthLoading"
            ></app-button>
          </div> -->
          <!-- /.col -->
        <!-- </div> -->
        <div class="row">
          <div class="col-12">
            <app-button
              [type]="'submit'"
              [block]="true"
              [text]="'Sign In'"
              [loading]="isAuthLoading"
              [color]="'info'"
            ></app-button>
          </div>
        </div>
      </form>

      <!-- <div class="social-auth-links text-center mb-3">
        <p>- OR -</p>
        <a href="#" class="btn btn-block btn-primary">
          <i class="fab fa-facebook mr-2"></i> Sign in using Facebook
        </a>
        <a href="#" class="btn btn-block btn-danger">
          <i class="fab fa-google-plus mr-2"></i> Sign in using Google+
        </a>
      </div> -->
      <!-- /.social-auth-links -->

      <!-- <p class="mb-1">
        <a [routerLink]="['/forgot-password']">I forgot my password</a>
      </p> -->
    </div>
    <!-- /.login-card-body -->
  </div>
</div>

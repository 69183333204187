import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ApiService } from '../../utils/services/api.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
//import { Person } from '../person';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import Swal from 'sweetalert2';
class Person {
  data: any[];
  draw: number;
  totalCount: number;
  recordsFiltered: number;
  recordsTotal: number;
}

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  totalCount: any;
  data1: any;
  count: any;
}

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
  
})



export class TestComponent implements OnInit {
 // @ViewChild(DataTableDirective)
 tourForm: FormGroup;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  //dtOptions: any = {};
  persons: Person[];
  public allData: any;
  allDatasearch: any;
  public pagenumber: any;
  selectedSearchDate: any;
  dateObj = undefined;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  //@ViewChild(DataTableDirective) dtElement: DataTableDirective;
  private isDtInitialized: boolean = false;

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  addDateForm: FormGroup;
  name = 'Dynamic Add Fields';
  values = [];
  value = [];
  getData: any[];
  draw: number;
  month: any;
  year: any;
   count:number;
  // dateObj = undefined;


  // recordsFiltered: number;
  // recordsTotal: number;
 // dtOptions: DataTables.Settings = {};
  //allData: any[];
  index: number = 1;
  //dtOptions: DataTables.Settings = {};
  //persons: any[]; 
  //persons: any[] ;
  isRemove: boolean = false;
  addedNew: boolean = false;
  minDate: string = '';
  selectedYear: number;
  years: number[] = [];
  dateDiffer: number = 0;
  initStartDate: string = (new Date()).toISOString().slice(0, 10);
  @ViewChild('addDate', { static: false }) addDate: ElementRef;
  //dtOptions: any = {};
  // dtTrigger: Subject<any> = new Subject<any>();
  //@ViewChild(DataTableDirective) dtElement: DataTableDirective;
  // private isDtInitialized: boolean = false;
  constructor(private calendar: NgbCalendar,
    private spinner: NgxSpinnerService,

    renderer: Renderer2,
    private fb: FormBuilder,
     private http: HttpClient,
      private apiService: ApiService,
    public formatter: NgbDateParserFormatter) { this.selectedYear = new Date().getFullYear();
      console.log(this.selectedYear);
  
      for (let year = this.selectedYear; year >= 2020; year--) {
        this.years.push(year);
      } }

 table=document.getElementsByTagName("table")
 


  ngOnInit(): void {

    this.tourForm = this.fb.group({
      dateRanges: this.fb.array([])
    });
    this.addDateRange();
    
    this.month = ''
    this.year = ''
    let currentDate = new Date;
    this.dateObj = {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate()
    }
    const that = this;
      this.allTour() ;
     
   /* this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'Bfrtip',
      serverSide: true,
      processing: true,
    
            
            ajax: (dataTablesParameters: any, callback) =>
             {
              console.log(dataTablesParameters.draw);
               this.apiService.getAllTour(dataTablesParameters.draw).subscribe((resp: DataTablesResponse) => { 
               this.allData = resp.data1;
               for (let data of this.allData) {
                data.totalAddon = 0;
                if (data.addons_id) {
                  const totalAddon = data.addons_id.addons_data.reduce((total, value) => total + (value.price * value.quantity), 0);
                  data.totalAddon = totalAddon;
                }
              }
               console.log(this.allData,"--",resp.count);
               callback({
                 recordsTotal:resp.count,
                 recordsFiltered: resp.count,
                 data: []
                });
               })
             }
      
    };*/
  
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
    
   
       
 }ngOnDestroy(): void {
  // Do not forget to unsubscribe the event
  this.dtTrigger.unsubscribe();
}

 
  // ngAfterViewInit(): void {
  //   this.dtElement.dtInstance.then((dtInstance: any) => {
  //     console.info("foobar");
  //     dtInstance.columns.adjust()
  //        .responsive.recalc();
  //   });
  // }*/
  date() 
  {
    const alldate = {
      serchmonth: this.month,
      serchyear: this.year
    }
    this.spinner.show();
    this.apiService.searchUserDate(alldate).subscribe((resp: any) => {
      console.log(resp);
      if (resp.success) {
        Swal.fire({
          text: resp.message,
          icon: 'success',
        });
        this.spinner.hide();
        this.allData = resp.getData;
        for (let data of this.allData) {
          data.totalAddon = 0;
          if (data.addons_id) {
            const totalAddon = data.addons_id.addons_data.reduce((total, value) => total + (value.price * value.quantity), 0);
            data.totalAddon = totalAddon;
          }
        }
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.spinner.hide();
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }

      } else {
        Swal.fire({
          text: resp.message,
          icon: 'error',
        });
        this.spinner.hide();
      }
    });
    console.log(alldate);
  }


  get dateRanges() {
    return this.tourForm.get('dateRanges') as FormArray;
  }

  addDateRange() {
    const dateRange = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required]
    });
    this.dateRanges.push(dateRange);
  }

  removeDateRange(index: number) {
    this.dateRanges.removeAt(index);
  }




 allTour() {
  //throw new Error('Function not implemented.');
  this.dtOptions = {
    pagingType: 'full_numbers',
    pageLength: 10,
    dom: 'Brftip',
    language : {
      "zeroRecords": " "             
  },
   
   serverSide: true,
   processing: true,
    buttons: [
      { extend: 'copyHtml5', footer: true, title: 'Glabol Admin - Tax list' },
      { extend: 'excelHtml5', footer: true, title: 'Glabol Admin - Tax list' },
      { extend: 'csvHtml5',
       footer: true, title: 'Glabol Admin - Tax list' },
      { extend: 'pdfHtml5', footer: true, pageSize: 'A3', title: 'Glabol Admin - Tax list' }
    ],
          
          ajax: (dataTablesParameters: any, callback) =>
           {
            
            console.log(dataTablesParameters.draw);
             this.apiService.getAllTour(dataTablesParameters.draw).subscribe((resp: DataTablesResponse) => { 
             this.allData = resp.data1;
             for (let data of this.allData) {
              data.totalAddon = 0;
              if (data.addons_id) {
                const totalAddon = data.addons_id.addons_data.reduce((total, value) => total + (value.price * value.quantity), 0);
                data.totalAddon = totalAddon;
              }
            }

            $('input[type="search"]').on( 'keyup', function(){
              
              //searchBox ($(this).val())
              let v=$(this).val()
              console.log($(this).val());
              
  } );
          /*  if (this.isDtInitialized) {
              this.spinner.hide();
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
               this.dtTrigger.next();
              });}else {
                this.spinner.hide();
                this.isDtInitialized = true;
                this.dtTrigger.next();
              }*/
             console.log(this.allData,"--",resp.count);
             console.log(this.dtOptions)
           
             
             
             callback({
              
               recordsTotal:resp.count,
               recordsFiltered: resp.count,
               data: []
              });

              
              
             })
             
           },
           drawCallback: function (row, data, start, end, display) {
            const api = this.api();
            let nb_cols = api.columns().nodes().length;
            let j = 6;
            console.log(nb_cols,api.column(7).data())
            while (j < nb_cols) {
              let pageTotal = api.column(j, { page: 'current'})
                .data()
                .reduce((a, b) => (Number(a === '-' ? '0' : a) + Number(b === '-' ? '0' : b)), 0);
              $(api.column(j).footer()).html(`₹${pageTotal.toFixed(2)}`);
              j++;
              
                 
                }
            
            
          },
          
  };
}
  


searchBox=(v) =>{
  
//console.log(val[0],val[1])
  //throw new Error('Function not implemented.');
  if (v !== '') {
    // this.ngxSpinnerService.show();
   let firstname: string;
   firstname=v
    this.apiService.getsearchClient(firstname).subscribe((res: any) => {
      console.log(res);
      if (res.success) {
        // this.ngxSpinnerService.hide();
        this.allData = res.getData;
      } else {
       // this.ngxSpinnerService.hide();
        console.error(res.message);
      }
    });
  } 
}
}

<div class="content">
      <div class="container-fluid">
            <div class="row">
                  <div class="col-md-12">
                        <div class="card card-primary">
                              <form [formGroup]="addWebForm" (ngSubmit)="setUser()">
                                    <div class="card-body">
                                          <div class="row">
                                                <div class="form-group col-md-4">
                                                      <label for="title">First Name<span
                                                                  class="required"></span></label>
                                                      <input type="text" class="form-control"
                                                            formControlName="firstname"
                                                            placeholder="Enter First Name"
                                                            [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }"/>
                                                            <div *ngIf="submitted && f.firstname.errors"
                                                            class="invalid-feedback"></div>
                                                      <div *ngIf="f.firstname.errors?.minlength"
                                                            style=" color: #ff0000; font-size: 13px; margin-bottom: 15px; ">
                                                            First name must be at least 3 characters</div>
                                                </div>
                                                <div class="form-group col-md-4">
                                                      <label>Last Name<span class="required"></span></label>
                                                      <input type="text" class="form-control" formControlName="lastname"
                                                            placeholder="Enter Last Name"
                                                            [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }">
                                                            <div *ngIf="submitted && f.lastname.errors"
                                                            class="invalid-feedback"></div>
                                                      <div *ngIf="f.lastname.errors?.minlength"
                                                            style=" color: #ff0000; font-size: 13px; margin-bottom: 15px; ">
                                                            Last name must be at least 3 characters</div>
                                                </div>
                                                <div class="form-group col-md-4">
                                                      <label>Mobile Number<span class="required"></span></label>
                                                      <input type="text" class="form-control"
                                                            (keypress)="onlyNumberKey($event)" formControlName="mobile"
                                                            maxlength="10" placeholder="Mobile Number"
                                                            [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }">
                                                      <div *ngIf="submitted && f.mobile.errors"
                                                            class="invalid-feedback"></div>
                                                      <div *ngIf="f.mobile.errors?.pattern"
                                                            style=" color: #ff0000; font-size: 13px; margin-bottom: 15px; ">
                                                            Mobile number should of 10 digits</div>
                                                </div>
                                                <div class="form-group col-md-4">
                                                      <label>Email<span class="required"></span></label>
                                                      <input type="email" class="form-control" formControlName="email"
                                                            placeholder="Email">
                                                </div>
                                                <div class="form-group col-md-4">
                                                      <label>Address</label>
                                                      <input type="text" class="form-control" formControlName="address" placeholder="Address"/>
                                                </div>
                                                <div class="form-group col-md-4">
                                                      <label>Gender</label>
                                                      <select id="text" class="form-control input-text js-input" placeholder="Gender"
                                                        type="text" formControlName="gender">
                                                        <option value="">Select Option</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                        <option value="other">Other</option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-md-4">
                                                      <label>DOB</label>
                                                      <input id="text" class="form-control input-text js-input"
                                                        placeholder="Date oF Birth" [max]="maxDate" type="date"
                                                        formControlName="DOB">
                                                </div>
                                                <div class="form-group col-md-4">
                                                      <label>City</label>
                                                      <input type="text" class="form-control" formControlName="city" placeholder="City"/>
                                                </div>
                                                <div class="form-group col-md-4">
                                                      <div *ngIf="allStates.length !== 0">
                                                            <label for="select-state">State<span class="required"></span></label>
                                                            <select class="form-control" id="select-state" formControlName="state">
                                                                  <option value="" selected="selected">Select State</option>
                                                                  <option *ngFor="let state of allStates" value="{{ state.iso2 }}">{{ state.name }}</option>
                                                            </select>
                                                      </div>
                                                </div>

                                                <div class="card-body" style="margin-top: -42px;">
                                                      <div class="row">
                                                            <div class="col-md-4">

                                                                  <div class="custom-control custom-checkbox"
                                                                        style="margin-top: 18px; margin-left: -15px;">
                                                                        <input id="male" type="checkbox"
                                                                              class="custom-control-input"
                                                                              formControlName="status"
                                                                              (change)="addWebForm.get('status').setValue($event.target.checked ? 'active' : 'inactive')"
                                                                              [checked]="addWebForm.value.status == 'active'" />
                                                                        <label class="custom-control-label"
                                                                              for="male">Status</label>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>


                                          </div>

                                          <!-- <div *ngIf="addWebForm.value.role==='1'" class="row"
                                                style="border-top: 1px solid #565656;">

                                                <div class="col-md-3" style="margin-top: 25px;"
                                                      *ngFor="let web of userRoleList">

                                                      <div class="custom-control custom-checkbox">
                                                            <label>

                                                                  <input type="checkbox" [value]="web.value"
                                                                        (change)="web.check = web.check === true ? false : true"
                                                                        [checked]="web.check" />

                                                                  {{web.name}}

                                                            </label>


                                                      </div>
                                                </div>

                                          </div> -->

                                    </div>
                                    <div class="card-footer text-right">
                                          <app-button [type]="'submit'" [block]="false" [text]="'Submit'"
                                                [loading]="isAuthLoading" [color]="'info'" [disabled]="isAuthDisabled">
                                          </app-button>
                                          <button type="button" [disabled]="isAuthDisabled"
                                                class="btn btn-secondary ml-2"
                                                [routerLink]="['/add-user-list']">Cancel</button>
                                    </div>
                              </form>
                        </div>
                  </div>
            </div>
      </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/utils/services/api.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
// import { Socket } from 'ngx-socket-io';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';

@Component({
  selector: 'app-notification-view',
  templateUrl: './notification-view.component.html',
  styleUrls: ['./notification-view.component.scss']
})
export class NotificationViewComponent implements OnInit {
  public allNotifications: any = [];
  allFirstName: any = [];
  allLastName: any = [];
  allMobile: any = [];
  allTourName: any = [];
  dtOptions: DataTables.Settings = {};
  private isDtInitialized: boolean = false;
  public timeArray: Array<any> = [];
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  constructor(private apiService: ApiService,  private spinner: NgxSpinnerService ) { }

  ngOnInit(): void {
    // this.socket.on("message", (event: any) => {
    //   console.log(event);
    // })
    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: true,
      pageLength: 10,
      //dom: 'Bfrtip',
      //buttons: ['excel']
    };
    this.notificationList()
  }

  notificationList(){
    this.spinner.show();
    this.apiService.allNotification().subscribe((res: any) => {
      console.log(res, "Notification view");
      // this.allNotifications = res.data;
      this.spinner.hide();
      for(let x = 0; x < res.data.length; x++){
        if(res?.data[x]?.applyTour_id?.payment_status != "Cancelled"){
          this.allNotifications.push({msg: `${res?.data[x]?.applyTour_id?.user_id?.firstname} paid ${res?.data[x]?.payment_status.toLowerCase()} ₹${res?.data[x]?.last_amount_paid} for ${res?.data[x]?.applyTour_id?.tour_id?.title} tour date ${res?.data[x]?.applyTour_id?.tourDate2}.`, id: res?.data[x]?.applyTour_id?._id, notification_id: res?.data[x]?._id, date: res?.data[x]?.created_at})
        } else {
          this.allNotifications.push({msg: `${res?.data[x]?.applyTour_id?.user_id?.firstname} ${res?.data[x]?.applyTour_id?.payment_status.toLowerCase()} ${res?.data[x]?.applyTour_id?.tour_id?.title} tour date ${res?.data[x]?.applyTour_id?.tourDate2}.`, id: res?.data[x]?.applyTour_id?._id, notification_id: res?.data[x]?._id, date: res?.data[x]?.created_at})
        }
      }

      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
      for(let x = 0; x < res.data.length; x++){
        this.apiService.adminId({id : res?.data[x]?.applyTour_id?.user_id}).subscribe((resp: any) => {
          // console.log(resp);
          // console.log(resp.getData.firstname, resp.getData.lastname);
          this.allFirstName.push(resp?.getData?.firstname);
          this.allLastName.push(resp?.getData?.lastname);
          this.allMobile.push(resp?.getData?.mobile);
        })
        this.apiService.getTourId({id : res?.data[x]?.applyTour_id?.tour_id}).subscribe((response: any) => {
          // console.log(response.getData)
          this.allTourName.push(response?.getData?.title)
        })
        // this.apiService.TourId({id : res.data[x].applyTour_id.user_id}).subscribe((response: any) => {
          // console.log(response)
          // this.allTourName.push(response.getData.title)
        // })
        // this.apiService.getBoardById({id: res.data[x].applyTour_id.travel_boarding_point}).subscribe((response: any) => {
        //   console.log(response.getData);
        //   this.allTourName.push(response.getData.boarding)
        // })
      }
    })
  }

}

import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  totalCount: any;
  data1: any;
  count: any;
}

@Component({
  selector: 'app-tax-list',
  templateUrl: './tax-list.component.html',
  styleUrls: ['./tax-list.component.scss']
})
export class TaxListComponent implements OnInit, OnDestroy {
  datesSelected: NgbDateStruct[] = [];
  change(value: NgbDateStruct[]) {
    this.datesSelected = value;
  }
  public allData: any;
  public month: any;
  public year: any;
  selectedYear: number;
  years: number[] = [];
  allDatasearch: any;
  public pagenumber: any;
  selectedSearchDate: any;
  dateObj = undefined;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  private isDtInitialized: boolean = false;
  value: boolean = false;
  public getFinancialYear: any[] = [];
  public selectFinancialyear: any;

count:number;
  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  )
  { this.selectedYear = new Date().getFullYear();
    console.log(this.selectedYear);

    for (let year = this.selectedYear; year >= 2020; year--) {
      this.years.push(year);
    }
  }



  ngOnInit(): void {
    // financial year problem - if year change then currentYear will be wrong.
    var yearsLength = 5;
    this.selectFinancialyear = "Select Year"
var currentYear = new Date().getFullYear();
for(var i = 0; i < 5; i++){
  var next = currentYear+1;
  var year = currentYear + '-' + next.toString().slice(-2);
  // $('#financialYear').append(new Option(year, year));
  this.getFinancialYear.unshift(year)
  currentYear--;
}
    setTimeout(() => {
      console.log(this.getFinancialYear)
    }, 1000)
    this.month = ''
    this.year = ''
    let currentDate = new Date;
    this.dateObj = {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate()
    }
    console.log('Heloo ');
    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: true,
      pageLength: 10,
      dom: 'Brftip',
      //serverSide: true,
      //processing: true,
      recordsTotal:this.pagenumber,
      recordsFiltered: this.pagenumber,







      buttons: [
        { extend: 'copyHtml5', footer: true, title: 'Glabol Admin - Tax list' },
        { extend: 'excelHtml5', footer: true, title: 'Glabol Admin - Tax list' },
        { extend: 'csvHtml5', footer: true, title: 'Glabol Admin - Tax list' },
        { extend: 'pdfHtml5', footer: true, pageSize: 'A3', title: 'Glabol Admin - Tax list' }
      ],



     footerCallback: function (row, data, start, end, display) {let api = this.api();



      let nb_cols = api.columns().nodes().length;
        let j = 6;
        while (j < nb_cols) {
          let pageTotal = api
            .column(j)
            .data()
            .reduce((a, b) => (Number(a === '-' ? '0' : a) + Number(b === '-' ? '0' : b)), 0);
          $(api.column(j).footer()).html(`₹${pageTotal.toFixed(2)}`);
          j++;

        }

      }

    };


    this.allTour();
   // var table = $('#example').DataTable();

   //var table=document.getElementById('#example')
   // console.log(table.page())

  }


  date() {
    console.log(this.year)
    const alldate = {
      serchmonth: this.month,
      serchyear: this.year
    }
    this.spinner.show();
    this.apiService.searchUserDate(alldate).subscribe((resp: any) => {
      console.log(resp);
      if (resp.success) {
        Swal.fire({
          text: resp.message,
          icon: 'success',
        });
        this.spinner.hide();
        this.allData = resp.getData;
        for (let data of this.allData) {
          data.totalAddon = 0;
          if (data.addons_id) {
            const totalAddon = data.addons_id.addons_data.reduce((total, value) => total + (value.price * value.quantity), 0);
            data.totalAddon = totalAddon;
          }
        }
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.spinner.hide();
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }

      } else {
        Swal.fire({
          text: resp.message,
          icon: 'error',
        });
        this.spinner.hide();
      }
    });
    console.log(alldate);
  }

  financialList(){
    console.log(this.value, this.selectFinancialyear)
    if(this.selectFinancialyear == 'Select Year'){
      Swal.fire({
        text: "Please select financial year",
        icon: 'warning',
      });
      return
    }
    this.spinner.show();
    this.apiService.financialYearList({name: this.selectFinancialyear}).subscribe((res: any) => {
      this.spinner.hide();
      console.log(res)
      if(res.success){
        this.allData = res.getData;
        for (let data of this.allData) {
          data.totalAddon = 0;
          if (data.addons_id) {
            const totalAddon = data.addons_id.addons_data.reduce((total, value) => total + (value.price * value.quantity), 0);
            data.totalAddon = totalAddon;
          }
        }
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.spinner.hide();
          this.isDtInitialized = true;
          this.dtTrigger.next();
        }
      } else {
        Swal.fire({
          text: res.message,
          icon: 'error',
        });
        this.spinner.hide();
      }
    })
  }

 allTour() {
    this.spinner.show();

    this.apiService.getAllTour(2).subscribe((resp: any) => {
      console.log(resp,"****");

      if (resp.success)  {
        this.spinner.hide();
        this.allData = resp.data1;
        this.count=resp.count;

         this.pagenumber = resp.count;
         console.log(this.pagenumber);
         const pages = Math.ceil(this.pagenumber / 10);
        console.log(pages);

         console.log(this.allData[0].user_id._id
         );
        for (let data of this.allData) {
          data.totalAddon = 0;
          if (data.addons_id) {
            const totalAddon = data.addons_id.addons_data.reduce((total, value) => total + (value.price * value.quantity), 0);
            data.totalAddon = totalAddon;
          }
        }
        if (this.isDtInitialized) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        } else {
          this.spinner.hide();
          this.isDtInitialized = true;
          this.dtTrigger.next();

        }
      } else {
        this.spinner.hide();
        this.toastr.error(resp.message);
      }
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}

import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
declare const window: any;
@Component({
  selector: 'app-make-trip-itinerary',
  templateUrl: './make-trip-itinerary.component.html',
  styleUrls: ['./make-trip-itinerary.component.scss'],
})
export class MakeTripItineraryComponent implements OnInit {
  addItineraryForm: FormGroup;
  id: any;
  object: any;
  @ViewChild('div', { static: false }) div: ElementRef;
  //allDates: any;
  date: any;
  allData: any;
  allPackData: any;
  allDateData: any;
  public isAuthLoading = false;
  public isAuthDisabled = false;
  public i: number = 1;
  public dynamicDesc: any = [];
  //private i : any;
  pck: any;
  TripData: any;
  start_date: any;
  public minDate = undefined;
  public allDates: any;
  tripId: any;
  propertyLists: any = {};
  allProps = ['breakfast', 'lunch', 'dinner', 'evening_snacks', 'monument_fee', 'bonfire', 'dJ_night', 'live_music', 'movie_screening', 'bike_rent', 'riverrafting', 'paragliding', 'water_activities', 'jeep_safari', 'camel_ride'];

  config_ck = {
    uiColor: '#ffffff',
    toolbarGroups: [
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
      { name: 'links' },
      { name: 'insert' },
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
      { name: 'styles' },
      { name: 'colors' },
    ],
    // skin: 'kama',
    resize_enabled: false,
    removePlugins: 'elementspath,save,magicline',
    //extraPlugins: 'justify',
    colorButton_foreStyle: {
      element: 'font',
      attributes: { color: '#(color)' },
    },
    height: 188,
    removeDialogTabs: 'image:advanced;link:advanced',
    removeButtons: 'Subscript,Superscript,Anchor,Source,Table',
    format_tags: 'p;h1;h2;h3;pre;div',
    extraPlugins: 'uploadimage,justify',
    uploadUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json',

    // Configure your file manager integration. This example uses CKFinder 3 for PHP.
    filebrowserBrowseUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html',
    filebrowserImageBrowseUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html?type=Images',
    filebrowserUploadUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files',
    filebrowserImageUploadUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Images',
  };

  constructor(
    private formBuilder: FormBuilder,
    private config: NgbDatepickerConfig,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.object = this.route.snapshot.paramMap.get('objId');
    if (this.object != undefined && this.object != null && this.object != '') {
      this.edittripthree(this.object);
    }
    this.addItineraryForm = new FormGroup({
      days: new FormControl(),
      allDateData: new FormControl(),
      Itinerary: new FormControl(),
      // blog_image: new FormControl(),
      // status: new FormControl(),
    });
    this.addItineraryForm = this.formBuilder.group({
      days: ['', [Validators.required]],
      allDateData: ['', [Validators.required]],
      Itinerary: ['', [Validators.required]],
      user_role: this.formBuilder.array([], [Validators.required]),
      // blog_image: [''],
      // status: ['', []],
    });
    this.getdate(this.object);
  }

  getdate(id) {
    let dataForForm = {
      id: id,
    };
    this.apiService.dateByID(dataForForm).subscribe((result: any) => {
      console.log(result.getData, 'kalu');
      // console.log(result.getData,this.start_date)
      this.allData = result.getData;
      this.date = this.allData.start_date;
      //console.log(this.allData.start_date, 'devvvvvvvvvvvvvv');
      this.allDates = this.dateRange(
        JSON.parse(result.getData.start_date)[0],
        JSON.parse(result.getData.end_date)[0]
      );
      console.log(JSON.parse(result.getData.start_date)[0], 'lalu');
      this.allDates.forEach((data, i) =>
        this.addItineraryForm.addControl(
          'title' + i,
          new FormControl('', Validators.required)
        )
      );
      this.allDates.forEach((data, i) =>
        this.addItineraryForm.addControl(
          'desc' + i,
          new FormControl('', Validators.required)
        )
      );
      this.allDates.forEach((data, i) => {
        let properties_list = [
          { id: i + '1', name: 'Breakfast', value: 'breakfast', check: false },
          { id: i + '2', name: 'Lunch', value: 'lunch', check: false },
          { id: i + '3', name: 'Dinner', value: 'dinner', check: false },
          { id: i + '4', name: 'Evening Snacks', value: 'evening_snacks', check: false },
          { id: i + '5', name: 'Monument Fee', value: 'monument_fee', check: false },
          { id: i + '6', name: 'Bonfire', value: 'bonfire', check: false },
          { id: i + '7', name: 'DJ Night', value: 'dJ_night', check: false },
          { id: i + '8', name: 'Live Music', value: 'live_music', check: false },
          { id: i + '9', name: 'Movie Screening', value: 'movie_screening', check: false },
          { id: i + '10', name: 'Bike Rent', value: 'bike_rent', check: false },
          { id: i + '11', name: 'Riverrafting', value: 'riverrafting', check: false },
          { id: i + '12', name: 'Paragliding', value: 'paragliding', check: false },
          { id: i + '13', name: 'Water Activities', value: 'water_activities', check: false },
          { id: i + '14', name: 'Jeep Safari', value: 'jeep_safari', check: false },
          { id: i + '15', name: 'Camel Ride', value: 'camel_ride', check: false },
        ];
        this.propertyLists['properties_list_' + i] = properties_list;
        let item = 0;
        do {
          this.addItineraryForm.addControl(
            'properties' + i + (+item),
            new FormControl(false, Validators.required)
          )
          item++;
        } while (item < properties_list.length);
      });
      // console.error(this.propertyLists, "list");
      // console.error(this.addItineraryForm.value, "control");
    });
  }

  addElement() {
    const p: HTMLParagraphElement = this.renderer.createElement('div');
    let dynamicHTML = '';
    dynamicHTML = `
      <tr id="row${this.i}"><td><label class="col-md-3 control-label">Description</label><textarea class="ckeditor form-control" formControlName="descEx${this.i}" id="descEx${this.i}" rows="6" style="width:50%;" value=""></textarea></td></tr>

      <div class="row" style="margin-bottom: 35px;">
    `;

    // let properties_list = [
    //   { id: '1', name: 'Breakfast', value: 'breakfast', check: false },
    //   { id: '2', name: 'Lunch', value: 'lunch', check: false },
    //   { id: '3', name: 'Dinner', value: 'dinner', check: false },
    //   { id: '4', name: 'Evening Snacks', value: 'evening_snacks', check: false },
    //   { id: '5', name: 'Monument Fee', value: 'monument_fee', check: false },
    //   { id: '6', name: 'Bonfire', value: 'bonfire', check: false },
    //   { id: '7', name: 'DJ Night', value: 'dJ_night', check: false },
    //   { id: '8', name: 'Live Music', value: 'live_music', check: false },
    //   { id: '9', name: 'Movie Screening', value: 'movie_screening', check: false },
    //   { id: '10', name: 'Bike Rent', value: 'bike_rent', check: false },
    //   { id: '11', name: 'Riverrafting', value: 'riverrafting', check: false },
    //   { id: '12', name: 'Paragliding', value: 'paragliding', check: false },
    //   { id: '13', name: 'Water Activities', value: 'water_activities', check: false },
    //   { id: '14', name: 'Jeep Safari', value: 'jeep_safari', check: false },
    //   { id: '15', name: 'Camel Ride', value: 'camel_ride', check: false },
    // ];
    // let item = 0;
    // do {
    //   dynamicHTML += `
    //   <div class="col-md-3" id="row-prop-${this.i + (+item)}" style="margin-top: 25px;">
    //     <div class="custom-control custom-checkbox">
    //       <label>
    //         <input type="checkbox" formControlName="${'properties_extra' + this.i + (+item)}" value="${properties_list[item].value}" checked="${properties_list[item].check}" />
    //         ${properties_list[item].name}
    //       </label>
    //     </div>
    //   </div>
    // `;
    //   this.addItineraryForm.addControl('properties_extra' + this.i + (+item),
    //     new FormControl(false, Validators.required));
    //   item++;
    // } while (item < 15);
    // console.error(item, "item");
    // dynamicHTML += `</div>`;

    p.innerHTML = dynamicHTML;

    this.renderer.appendChild(this.div.nativeElement, p);
    window.CKEDITOR.replace('descEx' + this.i);
    this.addItineraryForm.addControl(
      `descEx${this.i}`,
      new FormControl('', Validators.required)
    );
    this.i++;
  }

  dateRange(startDate, endDate, steps = 1) {
    const dateArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      dateArray.push(new Date(currentDate));
      // Use UTC date to prevent problems with time zones and DST
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }
    return dateArray;
  }

  edittripthree(id) {
    let dataForForm = {
      id: id,
    };
    //console.log(this.object);
    this.apiService.tourUpdate(dataForForm).subscribe((result: any) => {
      console.log(result.getData);
      if (result.success) {
        let TripData: any = result.getData;
        this.tripId = TripData._id;
        // console.log(TripData, 'hiiiiiiiiiiiiiiiiii');
        setTimeout(() => {
          //<<<---using ()=> syntax
          TripData.Itinerary.forEach((key, i) => {
            this.addItineraryForm.controls['title' + i].setValue(key.Title);
            this.addItineraryForm.controls['desc' + i].setValue(
              key.Description
            );
          });
        }, 1000);
         TripData.Itinerary1.forEach((key, i) => {
             this.addElement();
            // console.log(TripData.Itinerary1);
            //this.addMakeTripTwoForm.controls['desc' + this.i]?.setValue(key.desc);
          });

          setTimeout(() => {
         // CKEDITOR.instances[desc${this.i}].setData(TripData.Overwiew1)
          TripData.Itinerary1.forEach((key, x) => {
            console.log(key.descEx, 'descEx' + (x+1));
            window.CKEDITOR.instances[`descEx${x+1}`].setData(key.descEx)
            this.addItineraryForm.controls[`descEx${x+1}`].setValue(key.descEx);
            // console.log(this.addItineraryForm.value, "ctrl");
            // console.log(window.CKEDITOR.instances, 'ck');
          });
        }, 2000);

        setTimeout(() => {
          console.log(TripData.Itinerary[1].Properties,'hello');
          for (let i = 0; i < TripData.Itinerary.length; i++) {
            let item = 0;
            do {
              console.log(this.propertyLists['properties_list_'+i][item]);
              if (TripData.Itinerary[i].Properties.includes(this.propertyLists['properties_list_'+i][item].value)) {
                this.addItineraryForm.controls['properties' + i + (+item)].setValue(true);
              }
              item++;
            } while (item < 15);
            console.log(this.propertyLists);
            if (TripData.Itinerary[i].Properties.length !== 0) {}
          }
        }, 1000);
      }
    });
  }

  addMakeTourThree(id) {

    // const userRole = [];
    // this.userRoleList.forEach(ele => {
    //   if (ele.check === true) {
    //     userRole.push(ele.value);
    //   }
    // });

    // console.log(userRole);
    // this.addItineraryForm.value.user_role = userRole;
    //return;

    for (let i=0; i<this.allDates.length; i++) {
      if (this.addItineraryForm.value[`title${i}`] == '' || 
        this.addItineraryForm.value[`title${i}`] == undefined
      ) {
        Swal.fire({
          text: 'Please enter all itinerary titles',
          icon: 'warning',
        });
        return false;
      }
      if (this.addItineraryForm.value[`desc${i}`] == '' || 
        this.addItineraryForm.value[`desc${i}`] == undefined
      ) {
        Swal.fire({
          text: 'Please enter all itinerary descriptions',
          icon: 'warning',
        });
        return false;
      }
    }

    let allDateData = [];
    for (let i = 0; i < this.allDates.length; i++) {
      let item = 0;
      let propArr = [];
      do {
        if (this.addItineraryForm.value['properties' + i + (+item)]) {
          propArr.push(this.allProps[item]);
        }
        item++;
      } while (item < 15);
      allDateData.push({
        Title: this.addItineraryForm.value['title' + i],
        Description: this.addItineraryForm.value['desc' + i],
        Properties: propArr
      });
    }

    this.dynamicDesc = [];
    for (let i in window.CKEDITOR.instances) {
      if (i.substring(0, 6) !== 'editor') {
        let item = {};
        item[i.slice(0, -1)] = window.CKEDITOR.instances[i].getData();
        // list.push(item);
        this.dynamicDesc.push(item);
      }
    }

    //console.log(this.dynamicDesc);
    this.addItineraryForm.value.Itinerary = allDateData;
    this.addItineraryForm.value.Itinerary1 = this.dynamicDesc;

    console.log(this.addItineraryForm.value);
    // return;
    //console.log(addItineraryForm, 'hiiiiiiiiiiiiiiiillop');
    //this.allDateData.value.Itinerary1 = this.dynamicDesc;
    // console.log(window.CKEDITOR.instances);
    // return;
    for (let i in window.CKEDITOR.instances) {
      window.CKEDITOR.instances[i].destroy();
    }
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    this.apiService
      .updateTourThree(this.addItineraryForm.value, this.object)
      .subscribe((res: any) => {
        //console.log(this.tripInfo._id)
        //return;
        console.log(res);
        if (res.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          //this.toastr.success(res.message);
          // Swal.fire({
          //   text: res.message,
          //   icon: 'success',
          // });
          this.router.navigateByUrl('/make-trip-inclusion/' + this.object);
          console.log(this.object);
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          //this.toastr.error(res.message);
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
        }
      });
  }
}

<div class="content">
      <div class="container-fluid">
            <div class="row">
                  <div class="col-md-12">
                        <div class="card card-primary">
                              <!-- form start -->
                              <form [formGroup]="addItineraryForm" (ngSubmit)="addMakeTourThree(id)">
                                    <div class="card-body">

                                          <div class="row" *ngIf="allDates?.length != 0">
                                                <div class="col-md-12" *ngFor="let data of allDates; let i = index">
                                                      <div class="form-group">
                                                            <label for="class_name"> Day : {{ i + 1 }}
                                                                  <span class="required"></span></label>

                                                            <input type="text" class="form-control"
                                                                  [formControlName]="'title' + i" placeholder="Title">


                                                      </div>
                                                      <div class="form-group">
                                                            <label for="class_name">Itinerary<span
                                                                        class="required"></span></label>
                                                            <ckeditor [config]="config_ck"
                                                                  [formControlName]="'desc' + i"
                                                                  placeholder="Description">

                                                            </ckeditor>
                                                      </div>
                                                      <div class="row" style="margin-bottom: 35px;">
                                                            <div class="col-md-3" style="margin-top: 25px;"
                                                                  *ngFor="let prop of propertyLists['properties_list_'+i]; let x = index">
                                                                  <div class="custom-control custom-checkbox">
                                                                        <label>
                                                                              <input type="checkbox"
                                                                                    [formControlName]="'properties' + i + (+x)"
                                                                                    [value]="prop.value"
                                                                                    (change)="prop.value = prop.check === true ? false : true"
                                                                                    [checked]="prop.check" />
                                                                              {{prop.name}}
                                                                        </label>
                                                                  </div>
                                                            </div>

                                                      </div>
                                                </div>
                                          </div>
                                          <br>
                                          <br>

                                          <div class="form-group col-md-12" #div>
                                          </div>
                                          <div id="dynamic_fields"></div>

                                          <div class="row" style="margin-left: 1px;">
                                                <div class="form-group col-md-12">
                                                      <button type="button" (click)="addElement()"
                                                            class="btn btn-info">Add</button>
                                                </div>
                                          </div>
                                    </div>

                                    <div class="card-footer text-right">
                                          <app-button [type]="'submit'" [block]="false" [text]="'Next'" [loading]="isAuthLoading" [color]="'info'"
                                          [disabled]="isAuthDisabled"></app-button>   
                                          <button type="submit" class="btn btn-info"
                                                [routerLink]="['/make-trip-overview', tripId]" style="float:left;">Previous</button>
                                    </div>
                              </form>
                        </div>
                  </div>
            </div>
      </div>
</div>
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
//import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-make-trip-list',
  templateUrl: './make-trip-list.component.html',
  styleUrls: ['./make-trip-list.component.scss']
})
export class MakeTripListComponent implements OnInit {
   dtOptions: DataTables.Settings = {};
  private category: string;
  public allData: any;
  public editCatRoute: string;
  public addLanName: string;
  public addLanRoute: string;
  private userData = JSON.parse(localStorage.getItem("userInfo"));
  id: any;

  private isDtInitialized: boolean = false;

  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  constructor(
     private router: Router,
    private apiService: ApiService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.category = this.router.url.split('/')[1];
    if (this.category === 'destination-city-list') {
      this.addLanName = 'Add Destination ';
      this.addLanRoute = '/destination-city';
      // this.editCatRoute = '/news/edit-news';
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };

    this.trip();
  }

   trip() {
    // console.log(this.addLanguageListForm.value)
    this.apiService.getTrip().subscribe((resp: any) => {
      console.log(resp);
      this.allData = resp.data;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }

  async Triplist(id, status) {
    if (id == undefined || id == '') {
      //this.toastr.error(' Id is Invalid');
      Swal.fire({
        text: 'Id is Invalid',
        icon: 'error',
      });
      return false;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure, you want to change status?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        let getStatus = {};
        getStatus =
          status === 'active' ? { status: 'inactive' } : { status: 'active' };
        this.apiService
          .tripStatus(id, JSON.stringify(getStatus))
          .subscribe((result: any) => {
            if (result.success) {
              Swal.fire({
                text: result.message,
                icon: 'success',
              });
              //this.toastr.success(result.message);
              this.trip();
            } else {
              Swal.fire({
                text: result.message,
                icon: 'error',
              });
              //this.toastr.error(result.message);
            }
          });
      }
    });
  }

  cloneTourData(tour: any) {
    // console.log(tour);
    // return;
    if (!tour) {
      Swal.fire({
        text: 'Invalid tour data',
        icon: 'error',
      });
      return false;
    }
    if (!this.userData.id) {
      Swal.fire({
        text: 'User Id not found',
        icon: 'error',
      });
      return false;
    }
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure, you want to clone tour?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result: any) => {
      if (result.isConfirmed) {
        let cloneData = {
          tour_id: tour._id,
          user_id: this.userData.id
        }
        this.apiService.copyAndSaveTour(cloneData).subscribe((res: any) => {
          console.log(res);
          if (res.success) {
            Swal.fire({
              text: res.message,
              icon: 'success',
            });
            this.trip();
          } else {
            Swal.fire({
              text: res.message,
              icon: 'error',
            });
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}

<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary">
                    <!-- form start -->
                    <form [formGroup]="addPricingForm" (ngSubmit)="addPrice(id)">
                        <div class="card-body">

                            <!-- <h3>Pricing List</h3> -->
                            <div class="row">

                                <!-- <div class="col-md-4">
                                    <div class="form-group">

                                        <label for="class_name">Basic Price<span class="required"></span></label>
                                        <input type="text" class="form-control" placeholder="Basic Price"
                                            (keypress)="onlyNumberKey($event)" formControlName="basicAmount">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">

                                        <label for="class_name">Striking Price <span class="required"></span></label>
                                        <input type="text" class="form-control" placeholder="Striking Price"
                                            (keypress)="onlyNumberKey($event)" formControlName="strikingAmount">
                                    </div>
                                </div> -->

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <!-- <h3>Pricing List</h3> -->
                                        <label for="class_name">Booking Price <span class="required"></span></label>
                                        <input type="text" class="form-control" placeholder="Booking Price"
                                            (keypress)="onlyNumberKey($event)" formControlName="bookPrice">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Pricing Image 1</label>
                                        <div class="input-group">
                                            <div class="custom-file">
                                                <input type="file" accept="image/*" (change)="onFileChange($event)"
                                                    class="custom-file-input" aria-describedby="imageFile"
                                                    name="pricingImage1" />
                                                <label class="custom-file-label" #importFestImg for="pricingImage1">
                                                    <span>Upload Image</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Pricing Image 2</label>
                                        <div class="input-group">
                                            <div class="custom-file">
                                                <input type="file" accept="image/*" (change)="onFileChangeTwo($event)"
                                                    class="custom-file-input" aria-describedby="imageFile"
                                                    name="pricingImage2" />
                                                <label class="custom-file-label" #importFestImgTwo for="pricingImage2">
                                                    <span>Upload Image</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Coupon</label>
                                        <select class="form-control" formControlName="coupon_code">
                                            <option value="" selected>Select Coupon
                                            </option>
                                            <option *ngFor="let item of allcoupon; let i = index" value="{{item._id}}">
                                                {{item.coupon_code}} </option>
                                        </select>

                                    </div>
                                </div> -->

                            </div>
                            <div class="row">
                                <div class="col-md-6 mb-4" *ngIf="pck?.length != 0">
                                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                        class="row-border hover table-bordered w-100">
                                        <thead class="text-center">
                                            <tr>
                                                <th>S. No.</th>
                                                <th>Package Type</th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-center">
                                            <tr *ngFor="let data of pck; let i = index ">
                                                <td>{{ i + 1 }}</td>
                                                <td>
                                                    <div class="form-group">
                                                        <label for="class_name">{{ data }}<span class="required"></span></label>
                                                        <!-- <input type="text" class="form-control board"
                                                        (keypress)="onlyNumberKey($event)" [formControlName]="pck_strike[i]"
                                                        placeholder="Enter Striking Price"> -->
                                                        <input type="text" class="form-control board"
                                                        (keypress)="onlyNumberKey($event)" [formControlName]="data"
                                                        placeholder="Enter Price">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                                <div class="col-md-6 mb-4" *ngIf="pck_strike?.length != 0">
                                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                        class="row-border hover table-bordered w-100">
                                        <thead class="text-center">
                                            <tr>
                                                <th>S. No.</th>
                                                <th>Striker Package Type</th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-center">
                                            <tr *ngFor="let strikeData of pck_strike; let i = index ">
                                                <td>{{ i + 1 }}</td>
                                                <td>
                                                    <div class="form-group">
                                                        <label for="class">{{ strikeData }}<span class="required"></span></label>
                                                        <!-- <input type="text" class="form-control board"
                                                        (keypress)="onlyNumberKey($event)" [formControlName]=""
                                                        placeholder="Enter Striking Price"> -->
                                                        <input type="text" class="form-control board"
                                                        (keypress)="onlyNumberKey($event)" [formControlName]="strikeData"
                                                        placeholder="Enter Striking Price">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                                <div class="col-md-6 mb-4" *ngIf="rooms?.length != 0">
                                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                        class="row-border hover table-bordered w-100">
                                        <thead class="text-center">
                                            <tr>
                                                <th>S. No.</th>
                                                <th>Room Sharing </th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-center">
                                            <tr *ngFor="let data of rooms; let i = index ">
                                                <td>{{ i + 1 }}</td>
                                                <td>
                                                    <div class="form-group">
                                                        <label for="class_name">{{ data }}<span class="required"></span></label>
                                                        <input type="text" class="form-control board"
                                                        (keypress)="onlyNumberKey($event)" [formControlName]="data"
                                                        placeholder="Enter Price">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            <!-- </div> -->
                            <!-- <div class="row mb-4"> -->
                                <!-- <div class="col-md-6 mb-4" *ngIf="share?.length != 0">
                                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                        class="row-border hover table-bordered w-100">
                                        <thead class="text-center">
                                            <tr>
                                                <th>S. No.</th>
                                                <th>Shared bikes</th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-center">
                                            <tr *ngFor="let data of share; let i = index ">
                                                <td>{{ i + 1 }}</td>
                                                <td>
                                                    <div class="form-group">
                                                        <label for="class_name">{{ data }}<span class="required"></span></label>
                                                        <input type="text" class="form-control board"
                                                        (keypress)="onlyNumberKey($event)" [formControlName]="data+'_share'"
                                                        placeholder="Enter Price">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                                <div class="col-md-6 mb-4" *ngIf="solo?.length != 0">
                                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                        class="row-border hover table-bordered w-100">
                                        <thead class="text-center">
                                            <tr>
                                                <th>S. No.</th>
                                                <th>Solo bikes</th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-center">
                                            <tr *ngFor="let data of solo; let i = index ">
                                                <td>{{ i + 1 }}</td>
                                                <td>
                                                    <div class="form-group">
                                                        <label for="class_name">{{ data }}<span class="required"></span></label>
                                                        <input type="text" class="form-control board"
                                                        (keypress)="onlyNumberKey($event)" [formControlName]="data+'_solo'"
                                                        placeholder="Enter Price">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div> -->
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                        class="row-border hover table-bordered w-100" >
                                        <thead>
                                            <tr>
                                                <th style="text-align: center;">S. No.</th>
                                             <th style=" text-align: center;">Boarding Point</th>
                                                <th style="text-align: center;">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="board?.length != 0">
                                            <tr *ngFor="let boardData of board; let i = index ">
                                                <td style="text-align: center;">{{ i + 1 }}</td>
                                                <td style="text-align: center;">{{ boardData }}</td>
                                                <td style="text-align: center;"><input class="form-check-input" type="radio"
                                                        [formControlName]="boardData"
                                                        (change)="toggleShow('single'+i, i)" value="single{{i}}"
                                                        style=" margin-left:5px;margin-top: 28px;">
                                                    <label class="form-check-label"
                                                        style=" margin-left:18px; margin-top: 20px;">
                                                        Single Boarding Point
                                                    </label>
                                                    <div *ngIf="isShown.includes('single'+i)" class="row" id="divshow">
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label for="class_name">Board
                                                                    Place<span class="required"></span></label>
                                                                <input type="text" class="form-control board"
                                                                    [formControlName]="'boardplace'+i"
                                                                    (keypress)="onlyNumberKey($event)"
                                                                    placeholder="Enter Price">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td>

                                                </td> -->

                                                <td style="text-align: center;"><input class="form-check-input" type="radio"
                                                        [formControlName]="boardData" value="multi{{i}}"
                                                        (change)="toggleShow('multi'+i, i)"
                                                        style=" margin-left:17px;margin-top: 28px;">
                                                    <label class="form-check-label"
                                                        style=" margin-left:35px;margin-top: 20px;">
                                                        Multiple Boarding Point
                                                    </label>
                                                    <div *ngIf="isShown.includes('multi'+i)" class="row" id="divshow">
                                                        <div class="col-md-4">
                                                            <div class="form-group" style="text-align: center;">
                                                                <label for="class_name">
                                                                    Sleeper <span class="required"></span></label>
                                                                <input type="text" [formControlName]="'Sleeper'+i"
                                                                    class="form-control board"
                                                                    (keypress)="onlyNumberKey($event)"
                                                                    placeholder="Enter Price">
                                                            </div>
                                                        </div>

                                                        <div class="col-md-4">
                                                            <div class="form-group" style="text-align: center;">
                                                                <label for="class_name">
                                                                    3 AC<span class="required"></span></label>
                                                                <input type="text" class="form-control board"
                                                                    [formControlName]="'3AC'+i"
                                                                    (keypress)="onlyNumberKey($event)"
                                                                    placeholder="Enter Price">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="form-group" style="text-align: center;">
                                                                <label for="class_name" style="text-align: center;">
                                                                    2 AC<span class="required"></span></label>
                                                                <input type="text" class="form-control board"
                                                                    [formControlName]="'2AC'+i"
                                                                    (keypress)="onlyNumberKey($event)"
                                                                    placeholder="Enter Price">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="form-group" style="text-align: center;">
                                                                <label for="class_name">
                                                                    1 AC<span class="required"></span></label>
                                                                <input type="text" class="form-control board"
                                                                    [formControlName]="'1AC'+i"
                                                                    (keypress)="onlyNumberKey($event)"
                                                                    placeholder="Enter Price">
                                                            </div>
                                                        </div>

                                                        <div class="col-md-4">
                                                            <div class="form-group" style="text-align: center;">
                                                                <label for="class_name">
                                                                    Flight<span class="required"></span></label>
                                                                <input type="text" class="form-control board"
                                                                    [formControlName]="'Flight'+i"
                                                                    (keypress)="onlyNumberKey($event)"
                                                                    placeholder="Enter Price">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <!-- <td>

                                                </td> -->

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>



                        </div>
                        <div class="card-body" style="margin-top: -42px;">
                            <div class="row">
                                <div class="col-md-4">

                                    <div class="custom-control custom-checkbox">
                                        <input id="male" type="checkbox" class="custom-control-input"
                                            formControlName="status"
                                            (change)="addPricingForm.get('status').setValue($event.target.checked ? 'active' : 'inactive')"
                                            [checked]="addPricingForm.value.status == 'active'" />
                                        <label class="custom-control-label" for="male">Status</label>
                                    </div>
                                </div>
                            </div>
                        </div>
        <!-- /.card-body -->
                        <div class="card-footer text-right">

                            <app-button [type]="'submit'" [block]="false" [text]="'Submit'" [loading]="isAuthLoading" [color]="'info'"
                            [disabled]="isAuthDisabled"></app-button>
                            <button type="submit" class="btn btn-info"
                            [routerLink]="['/make-trip-inclusion', tripId]" style="float:left;">Previous</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

import { Component, OnInit, ElementRef, Renderer2, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  
  Validators,
  FormControl,
} from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ck-editor',
  templateUrl: './ck-editor.component.html',
  styleUrls: ['./ck-editor.component.scss']
})
export class CkEditorComponent implements OnInit {
   @ViewChild('div', { static: false }) div: ElementRef;
   config_ck = {
    uiColor: '#ffffff',
    toolbarGroups: [
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
      { name: 'links' },
      { name: 'insert' },
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
      { name: 'styles' },
      { name: 'colors' },
    ],
    // skin: 'kama',
    resize_enabled: false,
    removePlugins: 'elementspath,save,magicline',
    //extraPlugins: 'justify',
    colorButton_foreStyle: {
      element: 'font',
      attributes: { color: '#(color)' },
    },
    height: 188,
    removeDialogTabs: 'image:advanced;link:advanced',
    removeButtons: 'Subscript,Superscript,Anchor,Source,Table',
    format_tags: 'p;h1;h2;h3;pre;div',
    extraPlugins: 'uploadimage,justify',
    uploadUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json',

    // Configure your file manager integration. This example uses CKFinder 3 for PHP.
    filebrowserBrowseUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html',
    filebrowserImageBrowseUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/ckfinder.html?type=Images',
    filebrowserUploadUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Files',
    filebrowserImageUploadUrl:
      'https://ckeditor.com/apps/ckfinder/3.4.5/core/connector/php/connector.php?command=QuickUpload&type=Images',
  };

  constructor(private formBuilder: FormBuilder,
    private config: NgbDatepickerConfig,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private apiService: ApiService) { }

  ngOnInit(): void {
  }

   addElement() {
    const p: HTMLParagraphElement = this.renderer.createElement('div');
    p.innerHTML = `
      let i = 1;
document.getElementById("add").click(function() { 
 document.getElementById("dynamic_fields").append('<tr id="row' + i + '"><td><label class="col-md-3 control-label">Title</label><input type="text" name="title[]" id="title" placeholder="Enter Name" class="form-control name_list"><label class="col-md-3 control-label">Description</label><textarea class="ckeditor form-control" name="desc'+i+'" id="desc'+i+'" rows="6" style="width:50%;" value=""></textarea><input type="hidden" name="imageid" value="">  <label class="col-md-3 control-label">Product Image</label><div class="form-group"><input type="file" class="form-control" name="userfile[]" multiple="multiple"></div></td><td><button name="remove" id="' + i + '" class="btn btn-danger btn-remove" >X</button></td></tr>');CKEDITOR.replace('desc'+i);  i++;});document.on('click', '.btn-remove', function() {  let button_id = this.attr("id");  $('#row' + button_id + '').remove();  });
    `;

    this.renderer.appendChild(this.div.nativeElement, p);
  }



}

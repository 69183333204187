<section class="content">
      <div class="row">
            <div class="col-12">
                  <div class="card">
                        <div class="row">
                              <div class="container-fluid">
                                    <a
                                    [routerLink]="['/user-history/'+this.webID]"
                                    class="btn btn-info float-right mt-3" style="margin-right: 40px;"
                                    >Add Order</a>
                              </div>

                        </div>
                        <!-- <h2 style="padding: 1.25rem 1.25rem 0rem 1.25rem; margin: 0%;">ORDER</h2> -->
                        <ul class="nav nav-pills mb-3 nav-tabs justify-content-around" id="pills-tab" role="tablist">
                              <li class="nav-item col-4 p-0 text-center" role="presentation">
                                    <a class="nav-link active" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">User Details</a>
                              </li>
                              <li class="nav-item col-4 p-0 text-center" role="presentation">
                                <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Voucher</a>
                              </li>
                              <li class="nav-item col-4 p-0 text-center" role="presentation">
                                    <a class="nav-link" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Order History</a>
                              </li>
                            </ul>
                            <div class="tab-content" id="pills-tabContent">
                              <div class="tab-pane fade" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <div class="card-body"  *ngIf="allOrders.length !== 0;else noOrder">
                                          <div class="card rounded-card" *ngFor="let order of allOrders; let i = index">
                                                <div class="card-header card-head-rounded card-bottom-rounded"
                                                      [ngClass]="[order?.payment_status==='Complete' ? 'complete-green' : '', order?.payment_status==='Partial' ? 'progress-blue' : '', order?.payment_status==='Cancelled' ? 'cancel-violet' : '']">
                                                      <div class="d-md-flex justify-content-around align-items-center text-center cursor-pointer">
                                                            <div>{{ order?.tour_id?.title }}</div>
                                                            <div>{{ dateFormatter(order?.travel_date) }}</div>
                                                            <div>{{ order?.payment_status }}</div>
                                                            <a class="btn fw-bolder"
                                                                  [ngClass]="[order?.payment_status==='Complete' ? 'btn-green' : '', order?.payment_status==='Partial' ? 'btn-blue' : '', order?.payment_status==='Cancelled' ? 'btn-violet' : '']"routerLink="/user-order-history/particular-order/{{ order?._id }}">View more</a>
                                                      </div>
                                                </div>
                                          </div>

                                    </div>
                                    <ng-template #noOrder>
                                          <div class="container my-md-5 text-center">
                                              <h2>No Order</h2>
                                          </div>
                                      </ng-template>
                              </div>
                              <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                    <div class="card-body d-flex flex-wrap"  *ngIf="assignedVoucher.length !== 0;else noVoucher">
                                          <div class="rounded-card col-4 p-2" *ngFor="let voucher of assignedVoucher; let i = index">
                                                <div class="card card-header card-head-rounded card-bottom-rounded card-color h-100">
                                                      <div class="d-md-flex justify-content-around align-items-start cursor-pointer flex-column blackclr">
                                                            <div>Name: {{voucher?.coupon_code}}</div>
                                                            <div *ngIf="voucher.discount_type == 'Fixed Amount'; else percentage">Amount: ₹{{voucher?.coupon_amount}}</div>
                                                            <ng-template #percentage>
                                                              <div>
                                                                Discount: {{voucher?.coupon_amount}}% OFF (Upto - ₹{{voucher?.percentage_fix_amount}})
                                                              </div>
                                                            </ng-template>
                                                            <div>Expire: {{voucher?.coupon_expiry_date | date: 'dd/MM/yyyy'}}</div>
                                                            <div>Description: {{voucher?.coupon_description}}</div>
                                                            <!-- <a class="btn fw-bolder" routerLink="/coupan-code/edit-coupan/{{voucher._id}}" [ngClass]="'btn-blue'">View more</a> -->
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    <ng-template #noVoucher>
                                      <div class="container my-md-5 text-center">
                                          <h2>No Voucher</h2>
                                      </div>
                                  </ng-template>
                              </div>
                              <div class="tab-pane fade show active" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                    <div class="card card-primary">
                                                <div class="card-body">
                                                      <div class="row">
                                                            <div class="form-group col-md-4">
                                                                  <div>First Name</div>
                                                                  <!-- <input type="text" class="form-control"
                                                                  formControlName="firstname"
                                                                  placeholder="Enter First Name"
                                                                  /> -->
                                                                  <div class="form-control">{{getUserById.firstname}}</div>

                                                                        <!-- <div *ngIf="submitted && f.firstname.errors"
                                                                        class="invalid-feedback"></div>
                                                                  <div *ngIf="f.firstname.errors?.minlength"
                                                                        style=" color: #ff0000; font-size: 13px; margin-bottom: 15px; ">
                                                                        First name must be at least 3 characters</div> -->
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                  <div>Last Name</div>
                                                                  <div class="form-control">{{getUserById.lastname}}</div>
                                                                  <!-- <input type="text" class="form-control" formControlName="lastname"
                                                                        placeholder="Enter Last Name"
                                                                        > -->
                                                                        <!-- <div *ngIf="submitted && f.lastname.errors"
                                                                        class="invalid-feedback"></div>
                                                                  <div *ngIf="f.lastname.errors?.minlength"
                                                                        style=" color: #ff0000; font-size: 13px; margin-bottom: 15px; ">
                                                                        Last name must be at least 3 characters</div> -->
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                  <div>Mobile Number</div>
                                                                  <div class="form-control">{{getUserById.mobile}}</div>
                                                                  <!-- <input type="text" class="form-control"
                                                                        formControlName="mobile"
                                                                        maxlength="10" placeholder="Mobile Number"
                                                                        > -->
                                                                  <!-- <div *ngIf="submitted && f.mobile.errors"
                                                                        class="invalid-feedback"></div>
                                                                  <div *ngIf="f.mobile.errors?.pattern"
                                                                        style=" color: #ff0000; font-size: 13px; margin-bottom: 15px; ">
                                                                        Mobile number should of 10 digits</div> -->
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                  <div>Email</div>
                                                                  <div class="form-control">{{getUserById.email}}</div>
                                                                  <!-- <input type="email" class="form-control" formControlName="email"
                                                                        placeholder="Email"> -->
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                  <div>Address</div>
                                                                  <div class="form-control">{{getUserById.address || "N/A"}}</div>
                                                                  <!-- <input type="text" class="form-control" formControlName="address" placeholder="Address"/> -->
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                  <div>Gender</div>
                                                                  <div class="form-control">{{getUserById.gender || "N/A"}}</div>
                                                                  <!-- <select id="text" class="form-control input-text js-input" placeholder="Gender"
                                                                    type="text" formControlName="gender">
                                                                    <option value="">Select Option</option>
                                                                    <option value="male">Male</option>
                                                                    <option value="female">Female</option>
                                                                    <option value="other">Other</option>
                                                                </select> -->
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                  <div>DOB</div>
                                                                  <div class="form-control">{{getUserById.DOB || "N/A"}}</div>
                                                                  <!-- <input id="text" class="form-control input-text js-input"
                                                                    placeholder="Date oF Birth" type="date"
                                                                    formControlName="DOB"> -->
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                  <div>City</div>
                                                                  <div class="form-control">{{getUserById.city || "N/A"}}</div>
                                                                  <!-- <input type="text" class="form-control" formControlName="city" placeholder="City"/> -->
                                                            </div>
                                                            <div class="form-group col-md-4">
                                                                  <div>
                                                                        <div>State</div>
                                                                        <div class="form-control">{{getUserById.state}}</div>
                                                                        <!-- <select class="form-control" id="select-state" formControlName="state">
                                                                              <option value="" selected="selected">Select State</option>
                                                                              <option *ngFor="let state of allStates" value="{{ state.iso2 }}">{{ state.name }}</option>
                                                                        </select> -->
                                                                  </div>
                                                            </div>

                                                            <!-- <div class="card-body" style="margin-top: -42px;">
                                                                  <div class="row">
                                                                        <div class="col-md-4">

                                                                              <div class="custom-control custom-checkbox"
                                                                                    style="margin-top: 18px; margin-left: -15px;">
                                                                                    <input id="male" type="checkbox"
                                                                                          class="custom-control-input"
                                                                                          formControlName="status"
                                                                                          (change)="addWebForm.get('status').setValue($event.target.checked ? 'active' : 'inactive')"
                                                                                          [checked]="addWebForm.value.status == 'active'" />
                                                                                    <label class="custom-control-label"
                                                                                          for="male">Status</label>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            </div> -->


                                                      </div>

                                                      <!-- <div *ngIf="addWebForm.value.role==='1'" class="row"
                                                            style="border-top: 1px solid #565656;">

                                                            <div class="col-md-3" style="margin-top: 25px;"
                                                                  *ngFor="let web of userRoleList">

                                                                  <div class="custom-control custom-checkbox">
                                                                        <label>

                                                                              <input type="checkbox" [value]="web.value"
                                                                                    (change)="web.check = web.check === true ? false : true"
                                                                                    [checked]="web.check" />

                                                                              {{web.name}}

                                                                        </label>


                                                                  </div>
                                                            </div>

                                                      </div> -->

                                                </div>
                                                <!-- <div class="card-footer text-right">
                                                      <app-button [type]="'submit'" [block]="false" [text]="'Submit'"
                                                            [loading]="isAuthLoading" [color]="'info'" [disabled]="isAuthDisabled">
                                                      </app-button>
                                                      <button type="button" [disabled]="isAuthDisabled"
                                                            class="btn btn-secondary ml-2"
                                                            [routerLink]="['/add-user-list']">Cancel</button>
                                                </div> -->
                                    </div>
                              </div>
                            </div>
                        <!-- <div class="card-body"  *ngIf="allOrders.length !== 0;else noOrder">
                              <div class="card rounded-card" *ngFor="let order of allOrders; let i = index">
                                    <div class="card-header card-head-rounded card-bottom-rounded"
                                          [ngClass]="[order?.payment_status==='Complete' ? 'complete-green' : '', order?.payment_status==='Partial' ? 'progress-blue' : '', order?.payment_status==='Cancelled' ? 'cancel-violet' : '']">
                                          <div class="d-md-flex justify-content-around align-items-center text-center cursor-pointer">
                                                <div>{{ order?.tour_id?.title }}</div>
                                                <div>{{ dateFormatter(order?.travel_date) }}</div>
                                                <div>{{ order?.payment_status }}</div>
                                                <a class="btn fw-bolder"
                                                      [ngClass]="[order?.payment_status==='Complete' ? 'btn-green' : '', order?.payment_status==='Partial' ? 'btn-blue' : '', order?.payment_status==='Cancelled' ? 'btn-violet' : '']"routerLink="/user-order-history/particular-order/{{ order?._id }}">View more</a>
                                          </div>
                                    </div>
                                    <div class="card-body row">
                                          <div class="col-md-3">
                                                <div class="py-1">
                                                      <small class="fw-lighter">First Name</small>
                                                      <h6 class="fw-bolder">{{ order?.user_id?.firstname }}</h6>
                                                </div>
                                                <div class="py-1">
                                                      <small class="fw-lighter">Boarding Point</small>
                                                      <h6 class="fw-bolder">{{
                                                            order?.travel_boarding_point?.boarding }}</h6>
                                                </div>
                                          </div>
                                          <div class="col-md-3">
                                                <div class="py-1">
                                                      <small class="fw-lighter">Last Name</small>
                                                      <h6 class="fw-bolder">{{ order?.user_id?.lastname }}</h6>
                                                </div>
                                                <div class="py-1" *ngIf="getPackage(order?.travel_packageType) !== 'Solo bike' && getPackage(order?.travel_packageType) !== 'Shared bike'; else bikeOpt">
                                                      <small class="fw-lighter">Package Type</small>
                                                      <h6 class="fw-bolder">{{
                                                            getPackage(order?.travel_packageType) }}</h6>
                                                </div>
                                                <ng-template #bikeOpt>
                                                      <div class="py-1">
                                                          <small class="fw-lighter">Package Type</small>
                                                          <h6 class="fw-bolder">{{ getBikePackage(order?.travel_soloPackageType ? order?.travel_soloPackageType : order?.travel_sharedPackageType) }} ({{ getPackage(order?.travel_packageType) }})</h6>
                                                      </div>
                                                </ng-template>
                                          </div>
                                          <div class="col-md-3">
                                                <div class="py-1">
                                                      <small class="fw-lighter">Order ID</small>
                                                      <h6 class="fw-bolder">{{ order?.order_id }}</h6>
                                                </div>
                                                <div class="py-1">
                                                      <small class="fw-lighter">Date</small>
                                                      <h6 class="fw-bolder">{{ order?.travel_date }}</h6>
                                                </div>
                                          </div>
                                          <div class="col-md-3">
                                                <div class="py-1">
                                                      <small class="fw-lighter">Members</small>
                                                      <h6 class="fw-bolder">{{ order?.travel_no_of_traveler }}
                                                            Members</h6>
                                                </div>
                                                <div class="py-1">
                                                      <small class="fw-lighter">Payment Status</small>
                                                      <h6 class="fw-bolder"
                                                            [ngClass]="[order?.payment_status==='Complete' ? 'text-success' : '', order?.payment_status==='Partial' ? 'text-warning' : '', order?.payment_status==='Cancelled' ? 'text-danger' : '']">
                                                            {{ order?.payment_status }}</h6>
                                                </div>
                                          </div>
                                          <div class="col-md-12">
                                                <hr class=""
                                                      [ngClass]="[order?.payment_status==='Complete' ? 'complete-green-hr' : '', order?.payment_status==='Partial' ? 'progress-blue-hr' : '', order?.payment_status==='Cancelled' ? 'cancel-violet-hr' : '']" />
                                          </div>
                                          <div class="col-md-3">
                                                <div class="py-1">
                                                      <small class="fw-lighter">Total Amount</small>
                                                      <h6 class="fw-bolder">₹{{ order?.totalAmount?.toLocaleString('en-IN') }}
                                                      </h6>
                                                </div>
                                          </div>
                                          <div class="col-md-3">
                                                <div class="py-1">
                                                      <small class="fw-lighter">Amount Paid</small>
                                                      <h6 class="fw-bolder">₹{{ order?.amountPaid?.toLocaleString('en-IN') }}
                                                      </h6>
                                                </div>
                                          </div>
                                          <div class="col-md-3">
                                                <div class="py-1">
                                                      <small class="fw-lighter">Pending Amount</small>
                                                      <h6 class="fw-bolder">₹{{ (order?.totalAmount -
                                                            order?.amountPaid)?.toLocaleString('en-IN') }}
                                                      </h6>
                                                </div>
                                          </div>
                                          <div class="col-md-3 d-flex align-items-center justify-content-around">
                                              <button class="btn p-1 btn-curve px-3"
                                                  [ngClass]="[order?.payment_status==='Complete' ? 'complete-green' : '', order?.payment_status==='Partial' ? 'progress-blue' : '', order?.payment_status==='Cancelled' ? 'cancel-violet' : '']"
                                                  *ngIf="order?.payment_status!=='Cancelled'"
                                                  (click)="cancelOrder(order?._id)">CANCEL</button>
                                              <button class="btn p-1 btn-curve px-3"
                                                  [ngClass]="[order?.payment_status==='Complete' ? 'complete-green' : '', order?.payment_status==='Partial' ? 'progress-blue' : '', order?.payment_status==='Cancelled' ? 'cancel-violet' : '']"
                                                  *ngIf="order?.payment_status==='Partial'"
                                                  (click)="completeOrder(order?._id, order?.totalAmount, order?.appliedCoupon, order?.cgst, order?.sgst, order?.igst, order?.isOuter, order?.transctionCharge, order?.cusGSTNo, order?.bookingNote)">PAY
                                                  NOW</button>
                                                  <button class="btn btn-curve m-1"
                                                [ngClass]="[order?.payment_status==='Complete' ? 'complete-green' : '', order?.payment_status==='Partial' ? 'progress-blue' : '', order?.payment_status==='Cancelled' ? 'cancel-violet' : '']"
                                                (click)="sendDataToGenerateInvoice(order)" data-bs-toggle="tooltip"
                                                data-bs-placement="top" title="INVOICE"><i class="far fa-file-pdf"
                                                    aria-hidden="true"></i></button>
                                          </div>
                                          <small *ngIf="order?.tour_id?.orderNote" style="color: #ff0000;" class="mb-0 innerNote d-flex"

                                          innerHTML="<span class='pr-1'>Note:</span>{{ order?.tour_id?.orderNote }}"></small>
                                    </div>
                                    </div>



                              </div>

                        </div>
                        <ng-template #noOrder>
                              <div class="container my-md-5 text-center">
                                  <h2>No Order</h2>
                              </div>
                          </ng-template> -->
                  </div>
            </div>
      </div>
</section>

import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
//import { Router } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2'; 

@Component({
  selector: 'app-add-menu',
  templateUrl: './add-menu.component.html',
  styleUrls: ['./add-menu.component.scss']
})
export class AddMenuComponent implements OnInit {
  addMenuForm: FormGroup;
  public cancelCatRoute: string;
  private menuid: any;
  getData: any;
  public isAuthLoading = false;
  public isAuthDisabled = false;
  private _userId: string = localStorage.getItem('id');

  constructor(
    private formBuilder: FormBuilder,
    //private router: Router,
    private apiService: ApiService,
    //private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.addMenuForm = new FormGroup({
      menu_type: new FormControl(),
      status: new FormControl(),
      // user_id: new FormControl(),
      //display_sequence: new FormControl()
    });
     this.menuid = this.route.snapshot.paramMap.get('menu_id');
    // console.log(this.news_id, this.news_type);
    if (
      this.menuid != undefined &&
      this.menuid != null &&
      this.menuid != ''
    ) {
      this.editmenu(this.menuid);
    } else {
      this.addMenuForm.get('status').setValue('active');
    }
  }

  editmenu(id) {
    let dataForForm = {
      id: id,
    };
    this.apiService.getMenuById(dataForForm).subscribe((result: any) => {
      console.log(result.getData);
      if (result.success) {
        this.addMenuForm.patchValue({
          menu_type: result.getData.menu_type,
        });
        if (result.getData.status == 'active') {
          this.addMenuForm.get('status').setValue('active');
        }
        //  if(blogData.status == "active") {
        //   this.addBlogForm.get('status').setValue('active');
        // }
      }
    });
  }

  addmenu() {
    if (
      this.addMenuForm.value.menu_type== undefined ||
      this.addMenuForm.value.menu_type.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter menu type',
        icon: 'warning',
      });
      return false;
      // this.toastr.error("Please Enter the Language");
      // return false;
    }
    // if (
    //   this.addMenuForm.value.this._userId == undefined ||
    //   this.addMenuForm.value.boarding.trim() == ''
    // )
    if (this.addMenuForm.status == 'active') {
      this.addMenuForm.get('status').setValue('active');
    }
    if (this.menuid != undefined && this.menuid != null && this.menuid != '') {
      this.updatemenu(this.menuid);
    } else {
      this.addMenuForm.value.user_id = this._userId;
      this.isAuthLoading = true;
      this.isAuthDisabled = true;
      this.apiService
        .addMenus(this.addMenuForm.value)
        .subscribe((res: any) => {
          console.log(res);
          if (res.success) {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.success(res.message);
            Swal.fire({
              text: res.message,
              icon: 'success',
            });
            this.router.navigateByUrl('/add-menu-list');
          } else {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.error(res.message);
            Swal.fire({
              text: res.message,
              icon: 'error',
            });
          }
        });
    }
  }
    updatemenu(id) {
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    this.apiService
      .updateMenu(this.addMenuForm.value, id)
      .subscribe((res: any) => {
        if (res.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          //this.toastr.success(res.message);
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          this.router.navigateByUrl('/add-menu-list');
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
          //this.toastr.error(res.message);
        }
      });
  }

}

<div class="content">
      <div class="container-fluid">
            <div class="row">
                  <div class="col-md-12">
                        <div class="card card-primary">
                              <!-- form start -->
                              <form [formGroup]="addCoupanForm" (ngSubmit)="setCoupan()">
                                    <div class="card-body">
                                          <div class="row">
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Tour</label>
                                                            <select class="form-control" formControlName="tour">
                                                                  <option value="" selected>Select Tour
                                                                  </option>
                                                                  <option *ngFor="let item of alldesti; let i = index"
                                                                        value="{{item._id}}">
                                                                        {{item.title}} </option>
                                                            </select>

                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Add New Coupon Code<span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control"
                                                                  placeholder="Add New Coupon"
                                                                  formControlName="coupon_code">
                                                      </div>
                                                </div>


                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name"> Coupon Description<span
                                                                        class="required"></span></label>
                                                            <textarea class="form-control" rows="3"
                                                                  placeholder="Coupon Description"
                                                                  formControlName="coupon_description"></textarea>

                                                      </div>
                                                </div>

                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Discount Type <span
                                                                        class="required"></span></label>
                                                            <select class="form-control"
                                                                  formControlName="discount_type">
                                                                  <option value="" selected>Select Discount</option>
                                                                  <option value="Percentage Discount">Percentage
                                                                        Discount</option>
                                                                  <option value="Fixed Amount">Fixed Amount</option>
                                                                  <!-- <option value="Festival">Fixed product discount
                                                                  </option> -->
                                                                  <!-- <option value="Business">Business Category</option> -->
                                                            </select>


                                                      </div>
                                                </div>

                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name"> Coupon Amount/ Percentage<span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control"
                                                                  (keypress)="onlyNumberKey($event)"
                                                                  placeholder="Coupon Amount/ Percentage"
                                                                  formControlName="coupon_amount">

                                                      </div>
                                                </div>

                                                <div class="form-group col-md-4">
                                                      <label>Coupon Expiry Date<span class="required"></span></label>
                                                      <input type="date" class="form-control" ngbDatepicker
                                                            (click)="datePicker.toggle()" #datePicker="ngbDatepicker"
                                                            [minDate]="minDate" placeholder="dd-mm-yyyy"
                                                            formControlName="coupon_expiry_date">
                                                </div>

                                                <!-- <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name"> Maximum spend</label>
                                                            <input type="text" class="form-control"
                                                                  (keypress)="onlyNumberKey($event)"
                                                                  placeholder="Maximum Spend"
                                                                  formControlName="maximum_spend">

                                                      </div>
                                                </div> -->

                                                <div *ngIf="addCoupanForm.value.discount_type==='Percentage Discount'"
                                                      class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name"> Percentage Fix Amount </label>
                                                            <input type="text" class="form-control"
                                                                  (keypress)="onlyNumberKey($event)"
                                                                  placeholder="Maximum Percentage Amount"
                                                                  formControlName="percentage_fix_amount">

                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name"> Minimum Spend <span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control"
                                                                  (keypress)="onlyNumberKey($event)"
                                                                  placeholder="Minimum Spend"
                                                                  formControlName="minimum_spend">

                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name"> Usage limit Per Coupon </label>
                                                            <input type="text" class="form-control"
                                                                  (keypress)="onlyNumberKey($event)"
                                                                  placeholder="Usage Limit Per Coupon"
                                                                  formControlName="usage_limit_per_coupon">

                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name"> Usage limit Per User</label>
                                                            <input type="text" class="form-control"
                                                                  (keypress)="onlyNumberKey($event)"
                                                                  placeholder="Usage Limit Per User"
                                                                  formControlName="usage_limit_per_user">

                                                      </div>
                                                </div>

                                          </div>
                                          <div class="row" style="margin-left: -30px;">
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <input class="form-check-input" type="radio" value="Public"
                                                                  name="isShow" id="Public" checked
                                                                  style="margin-left:30px; margin-top:35px; "
                                                                  formControlName="isShow">
                                                            <label class="form-check-label"
                                                                  style="margin-left:50px; margin-top:25px;">
                                                                  Public
                                                            </label>

                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <input class="form-check-input" type="radio" value="Private"
                                                                  name="isShow" id="Private" checked
                                                                  style="margin-left:30px; margin-top:35px; "
                                                                  formControlName="isShow">
                                                            <label class="form-check-label"
                                                                  style="margin-left:50px; margin-top:25px;">
                                                                  Private
                                                            </label>

                                                      </div>
                                                </div>
                                                <!-- <div class="col-md-4">
                                                      <div class="form-group">
                                                            <input class="form-check-input" type="radio" value="Voucher"
                                                                  name="isShow" id="Voucher" checked
                                                                  style="margin-left:30px; margin-top:35px; "
                                                                  formControlName="isShow">
                                                            <label class="form-check-label"
                                                                  style="margin-left:50px; margin-top:25px;">
                                                                  Voucher
                                                            </label>

                                                      </div>
                                                </div> -->
                                          </div>
                                          <!-- <div class="row" *ngIf="this.addCoupanForm.value.isShow == 'Voucher'">
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label>Users</label>
                                                            <ng-select *ngIf="allUsers.length > 0" class="w-75" [multiple]="true" placeholder="Select Users" formControlName="user_assign">
                                                              <ng-option *ngFor="let user of allUsers; index as i" [value]="user._id">{{user.firstname}} {{user.lastname}}</ng-option>
                                                            </ng-select>
                                                      </div>
                                                </div>
                                          </div> -->
                                    </div>

                                    <div class="card-body" style="margin-top: -42px;">
                                          <div class="row">
                                                <div class="col-md-4">

                                                      <div class="custom-control custom-checkbox">
                                                            <input id="male" type="checkbox"
                                                                  class="custom-control-input" formControlName="status"
                                                                  (change)="addCoupanForm.get('status').setValue($event.target.checked ? 'active' : 'inactive')"
                                                                  [checked]="addCoupanForm.value.status == 'active'" />
                                                            <label class="custom-control-label"
                                                                  for="male">Status</label>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>

                                    <!-- /.card-body -->
                                    <div class="card-footer text-right">
                                          <button type="submit" class="btn btn-info">Submit</button>

                                          <button type="button" class="btn btn-secondary ml-2"
                                                [routerLink]="['/coupan-code-list']">Cancel</button>
                                    </div>
                              </form>
                        </div>
                  </div>
            </div>
      </div>
</div>

<div class="content">
      <div class="container-fluid">
            <div class="row">
                  <div class="col-md-12">
                        <div class="card card-primary">
                              <!-- form start -->
                              <form [formGroup]="addInculssionForm" (ngSubmit)="addMakeTourFour(id)">
                                    <div class="card-body">

                                          <div class="row">
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Inclusion<span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control"
                                                                  placeholder="Inclusion" formControlName="inculssion">
                                                      </div>
                                                </div>
                                                <div class="col-md-12">
                                                      <div class="form-group">
                                                            <label for="class_name">Summary<span
                                                                        class="required"></span></label>
                                                            <ckeditor [config]="config_ck" formControlName="summary">

                                                            </ckeditor>
                                                      </div>
                                                </div>
                                                <div class="col-md-4">
                                                      <div class="form-group">
                                                            <label for="class_name">Exclusion<span
                                                                        class="required"></span></label>
                                                            <input type="text" class="form-control"
                                                                  placeholder="Exclusion" formControlName="exculssion">
                                                      </div>
                                                </div>

                                                <div class="col-md-12">
                                                      <div class="form-group">
                                                            <label for="class_name">Summary<span
                                                                        class="required"></span></label>
                                                            <ckeditor [config]="config_ck" formControlName="summaryExc">

                                                            </ckeditor>
                                                      </div>
                                                </div>

                                                <div class="form-group col-md-12" #div>
                                                </div>
                                                <div id="dynamic_fields"></div>
                                                <div class="row" style="margin-left: 1px;">
                                                      <div class="form-group col-md-12">
                                                            <button type="button" (click)="addElement()"
                                                                  class="btn btn-info">Add</button>
                                                      </div>
                                                </div>
                                          </div>

                                    </div>
                                    <!-- <div class="card-body" style="margin-top: -42px;">
                                          <div class="row">
                                                <div class="col-md-4">

                                                      <div class="custom-control custom-checkbox">
                                                            <input id="male" type="checkbox"
                                                                  class="custom-control-input">
                                                            <label class="custom-control-label"
                                                                  for="male">Status</label>
                                                      </div>
                                                </div>
                                          </div>
                                    </div> -->



                                    <!-- /.card-body -->
                                    <div class="card-footer text-right">
                                          <app-button [type]="'submit'" [block]="false" [text]="'Next'" [loading]="isAuthLoading" [color]="'info'"
                                          [disabled]="isAuthDisabled"></app-button>
                                          <button type="submit" class="btn btn-info"
                                          [routerLink]="['/make-trip-itinerary', tripId]" style="float:left;">Previous</button>
                                    </div>
                              </form>
                        </div>
                  </div>
            </div>
      </div>
</div>
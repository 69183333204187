<div class="content">
      <div class="container-fluid">
            <div class="row">
                  <div class="col-md-12">
                        <div class="card card-primary">
                              <div class="card-body">
                                    <div class="row" *ngIf="!value; else financialSearch">
                                          <div class="form-group col-md-5">
                                                <label for="class_name">Tour Name <span class="required"></span></label>
                                                <select class="form-control" #cat
                                                      (change)="onCategorySelect($event.target.value);">
                                                      <option value="" selected>Plan Name</option>
                                                      <option *ngFor="let item of allTour; let i = index"
                                                            value="{{item._id }}">
                                                            {{ item.title }}</option>
                                                </select>
                                          </div>

                                          <div class="form-group col-md-3" *ngIf="allOrder?.start_date">
                                                <div class="form-group">
                                                      <label class="title-label">Date <span
                                                                  class="required"></span></label>
                                                      <select class="form-control" id="slct" #dateSchedule>
                                                            <option value="" selected="selected">Select Date
                                                            </option>
                                                            <option
                                                                  value="{{ date.startDate | date: 'dd/MM/yyyy' }}-{{ date.endDate | date: 'dd/MM/yyyy' }}"
                                                                  *ngFor="let date of allOrder?.dateArr; index as i">
                                                                  {{ date.startDate
                                                                  | date:
                                                                  'dd/MM/yy' }} - {{ date.endDate | date: 'dd/MM/yy' }}
                                                                  &nbsp;&nbsp;

                                                            </option>
                                                      </select>
                                                </div>
                                          </div>

                                          <div class="col-md-3 form-group">
                                                <div class="form-group">
                                                      <label for="class_name">Status<span
                                                                  class="required"></span></label>
                                                      <select class="form-control" #status>
                                                            <option value="" selected>All</option>

                                                            <option value="Complete">Complete</option>
                                                            <option value="Partial">Partial</option>
                                                            <option value="Cancelled">Cancel</option>
                                                            <!-- <option value="3">Three</option> -->
                                                      </select>

                                                </div>
                                          </div>
                                          <div class="col-md-1 d-flex align-items-center justify-content-center">
                                                <button type="submit" class="btn btn-info" (click)="userreport()">Report</button>
                                          </div>
                                    </div>
                                    <ng-template #financialSearch>
                                          <div class="row">
                                                <div class="form-group col-md-5">
                                                      <label for="class_name">Select Financial Year <span class="required"></span></label>
                                                      <select class="form-control"
                                                      [(ngModel)]="selectFinancialyear">
                                                            <option value="Select Year" [selected]="true" [disabled]="true">Select Year</option>
                                                            <option value={{year}} *ngFor="let year of getFinancialYear; let i = index">{{year}}</option>
                                                      </select>
                                                </div>
                                                <div class="col-md-2 d-flex align-items-center justify-content-start">
                                                      <button type="submit" class="btn btn-info mt-3" (click)="financialList()">Financial Search</button>
                                                </div>
                                          </div>
                                    </ng-template>
                                    <div>
                                          <button (click)="value = !value" class="btn btn-info mb-3">{{value? 'Plan Search':'Financial Search'}}</button>
                                    </div>
                                    <br>
                                    <div class="row">
                                          <div class="col-12">
                                                <div class="card">
                                                      <div class="card-body">

                                                            <table datatable [dtOptions]="dtOptions"
                                                                  [dtTrigger]="dtTrigger" class="row-border hover">
                                                                  <thead>
                                                                        <tr>
                                                                              <th>S. No.</th>
                                                                              <th>Name</th>
                                                                              <th>Mobile</th>
                                                                              <th>Tour Name</th>
                                                                              <!-- <th>Package Type</th> -->
                                                                              <th>Payment Status</th>
                                                                              <th>Date & Time</th>
                                                                              <th>Total Amount</th>
                                                                              <th>Amount Paid</th>
                                                                              <th>Remaining Amount</th>
                                                                              <th>Order History</th>
                                                                        </tr>
                                                                  </thead>
                                                                  <tbody *ngIf="allUser?.length != 0">
                                                                        <tr *ngFor="let user of allUser; let i = index">
                                                                              <td>{{ i + 1 }}</td>
                                                                              <td>{{ user.user_id.firstname }} {{
                                                                                    user.user_id.lastname }} </td>
                                                                              <td>{{user.user_id.mobile}}</td>
                                                                              <!-- <td>{{user.travel_boarding_point.boarding}}
                                                                              </td> -->
                                                                              <td>{{user.tour_id.title}}
                                                                              </td>
                                                                              <!-- <td>{{this.getPackage(user.travel_packageType)}}
                                                                              </td> -->
                                                                              <td>{{user.payment_status}}</td>
                                                                              <td>{{user.payment_time | date:"d/M/yy, h:mm a"}}</td>
                                                                              <!-- <td>{{user.travel_no_of_traveler}}</td> -->
                                                                              <td>
                                                                                {{(user.totalAmount + user.addons_id).toLocaleString('en-IN', {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2
                                                                                  })}}
                                                                              </td>
                                                                              <td>{{(+user?.amountPaid).toLocaleString('en-IN', {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2
                                                                                  })}}</td>

                                                                              <td *ngIf="(this.user.finalPrice/(this.user.partial_payment))-((this.user.finalPrice/(this.user.partial_payment))-user?.travel_no_of_traveler)===1 && user?.payment_status==='Cancelled';else Amt">0</td>
                                                                              <ng-template #Amt>
                                                                              <td >{{(+user?.totalAmount + +user?.addons_id - +user?.amountPaid).toLocaleString('en-IN', {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2
                                                                                  })}} </td>
                                                                              <!-- <td >{{user.addons_id.addons_data[i].price? user.addons_id.addons_data[i].price : user.totalAmount - user.amountPaid}}</td> -->
                                                                             </ng-template>
                                                                              <td>
                                                                                    <div class="btn-group">
                                                                                          <button type="button" class="btn btn-info" [routerLink]="['/user-order-history/particular-order/'+user._id]">
                                                                                                Order History
                                                                                          </button>
                                                                                    </div>
                                                                              </td>
                                                                        </tr>
                                                                  </tbody>
                                                                  <tbody *ngIf="allUser?.length == 0">
                                                                        <tr>
                                                                              <td></td>
                                                                              <td></td>
                                                                              <td></td>
                                                                              <td></td>
                                                                              <!-- <td></td> -->
                                                                              <td>No data!</td>
                                                                              <td></td>
                                                                              <td></td>
                                                                              <td></td>
                                                                              <td></td>
                                                                              <td></td>
                                                                        </tr>
                                                                  </tbody>
                                                            </table>

                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpEventType } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiHost: string;
  login: string;
  checkEmail: string;
  checkOTP: string;
  resetPsw: string;
  logout: string;
  register: string;
  //dashboardCount: string;
  getAdminList: string;
  getAdminById: string;
  updateAdmin: string;
  getUserList: string;
  // getUserDetail: string;
  searchByLoginTime : string;
  updateMembershipDate: string;
  getMembershipDate: string;
  status: string;

  getBlogList: string;
  addblog: string;
  getBlogID: string;
  addBoarding: string;
  getBoardingList: string;
  getBoardingID: string;
  updateBoarding: string;
  bordingStatus: string;
  addPackage: string;
  getAllPackage: string;
  getPackageID: string;
  updatePackage: string;
  packageStatus: string;
  addRoom: string;
  getAllRooms: string;
  getRoomById: string;
  updateRoom: string;
  roomStatus: string;
  addBike: string;
  getAllBikes: string;
  getBikeById: string;
  updateBike: string;
  bikeStatus: string;
  addAdventure: string;
  getAdventure: string;
  updateAdventure: string;
  getAdventureId: string;
  changeAdven: string;
  addDestination: string;
  getDestination: string;
  getDestinationId: string;
  updateDestination: string;
  chnageDestination: string;
  addTour: string;
  updateaddTour: string;
  addTourtwo: string;
  addTourthree:string;
  addTourfour: string;
  addTourfive: string;
  addTourUpdate: string;
  activeBoardingPoint: string;
  activeAdanturePoint: string;
  activeDestinationPoint: string;
  activePackagePoint:string;
  activeRoomSharing: string;
  activeBikeSharing: string;
  addAdmin: string;
  getAdminListRole: string;
  adminById: string;
  adminStatus: string;
  updateSuperAdmin: string;
  addGallery: string;
  getGallery: string;
  changeGalleryStatus: string;
  deleteGallery : string;
  galleryById: string;
  getWebUser: string;
  webStatus: string;
  addWebUser: string;
  getTourByID: string;
  addVideoAdmin: string;
  addVideoList: string;
  addVideoStatus: string;
  addVideoByID: string;
  addUpdateVideo: string;
  addDate: string;
  addTripList: string;
  addTourStatus: string;
  addNotes: string;
  addNotesList: string;
  updateNotes: string;
  addNotesStatus: string;
  addNotesById: string;
  addCoupanCode: string;
  addCoupanCodeList: string;
  addvoucherlist: string;
  addCopanCodeStatus: string;
  updateCoupanCode: string;
  addCoupanCodeId: string;
  getAllActiveTour: string;
  searchClient:string;
  addActiveTourAdmin: string;
  addWebsiteUserBYID: string;
  addCompleteActiveTour: string;
  addorderByID: string;
  addSpecialImage: string;
  addSpecialImageList: string;
  addSpecialImageByID: string;
  addSpecialImageStatus: string;
  addSpecialImageUpdate: string;
  addOrderCharge: string;
  addOrderChargeList: string;
  addOrderChargeUpdate:string;
  addOrderChargeStatus: string;
  addOrderChargeByID: string;
  dashboard: string;
  addcheckoutTour:string;
  tourByID: string;
  webUserOrderList: string;
  WeborderList: string;
  checkoutStatusUpdate: string;
  allActiveCoupan: string;
  orderBooking: string;
  getOrderId: string;
  applyCoupon: string;
  addReport: string;
  landing: string;
  cloneTour: string;
  addons: string;
  tourOfUser: string;
  refundAmt: string;
  stateName: string;
  addmenu: string;
  addmenulist: string;
  addmenuByID: string;
  addmenuByStatus: string;
  updateaddmenu: string;
  getallactivemenu: string;
  refundlist: string;
  addaddonnotes: string;
  getaddonnotes: string;
  addDateSearchUser: string;
  cancelMember:string;
  cancelMemberByName:string;
  generatePayLink:string;
  allOrderWithLink: string;
  reviveLink: string;

  getNotification: string;
  notificationCount: string;
  notificationSeen: string;
  notificationService: string;
  getreminder: string;
  reminder: string;
  travelDateUpdate: string;
  financialYear: string;
  financialYearReport: string;
  deletecoupon: string;

  constructor(private http: HttpClient) {
    this.apiHost = environment.API_HOST;
    this.login = this.apiHost + `login`;
    this.checkEmail = this.apiHost + `admin/forgetUserPassword`;
    this.checkOTP = this.apiHost + `admin/verifyOTPForgetUserPassword`;
    this.addCompleteActiveTour = this.apiHost + 'admin/get-all-final-active-tour';
    this.dashboard = this.apiHost + 'admin/dashboard';
    this.getOrderId = this.apiHost + 'order';
    this.addReport = this.apiHost + 'report';
    this.applyCoupon = this.apiHost +  'apply-coupon';
    this.orderBooking = this.apiHost + 'admin/post-checkout-tour';
    this.webUserOrderList = this.apiHost + 'admin/get-orderById';
    this.WeborderList = this.apiHost + 'user-all-checkout-tour';
    this.addorderByID = this.apiHost + 'admin/get-tourById';
    this.addcheckoutTour = this.apiHost + 'checkout-tour-admin';
    this.tourByID = this.apiHost + 'admin/get-appliedTourById';
    this.resetPsw = this.apiHost + `admin/forgetUserPasswordUpdate`;
    this.addOrderCharge = this.apiHost + 'admin/post-other-charge';
    this.allActiveCoupan = this.apiHost + 'admin/get-all-active-coupon';
    this.addOrderChargeList = this.apiHost + 'admin/get-all-other-charge';
    this.checkoutStatusUpdate = this.apiHost + 'checkout-status-update';
    this.addOrderChargeUpdate = this.apiHost + 'admin/put-other-charge';
    this.addOrderChargeStatus = this.apiHost + 'admin/put-other-charge-status';
    this.addOrderChargeByID = this.apiHost + 'admin/get-other-chargeById';
    this.addSpecialImage = this.apiHost + 'admin/post-special-tour';
    this.addSpecialImageList = this.apiHost + 'admin/get-all-special-tour';
    this.addSpecialImageByID = this.apiHost + 'admin/get-special-tourById';
    this.addSpecialImageStatus = this.apiHost + 'admin/put-special-tour-status';
    this.addSpecialImageUpdate = this.apiHost + 'admin/post-special-tour';
    this.addNotes = this.apiHost + 'admin/post-notes';
    this.addWebsiteUserBYID = this.apiHost + 'admin/get-website-userById';
    this.addActiveTourAdmin = this.apiHost + 'admin/post-admin-tour';
    this.getAllActiveTour = this.apiHost + 'admin/get-all-active-applied-tour';
    this.searchClient = this.apiHost + 'admin/search-client';
    this.addNotesList = this.apiHost + 'admin/get-all-notes';
    this.updateNotes = this.apiHost + 'admin/put-notes';
    this.addNotesStatus = this.apiHost + 'admin/put-notes-status';
    this.addNotesById = this.apiHost + 'admin/get-notesById';
    this.logout = this.apiHost + `admin/logout`;
    this.addCoupanCode = this.apiHost + 'admin/post-coupon';
    this.addCoupanCodeList = this.apiHost + 'admin/get-all-coupon';
    this.addvoucherlist = this.apiHost + 'admin/get-all-voucher';
    this.addCopanCodeStatus = this.apiHost + 'admin/put-coupon-status';
    this.updateCoupanCode = this.apiHost + 'admin/put-coupon';
    this.addCoupanCodeId = this.apiHost + 'admin/get-couponById';
    this.register = this.apiHost + `user`;
    this.addWebUser = this.apiHost + 'admin/website-user';
    //this.dashboardCount = this.apiHost + `admin/getAllDashboardCount`;
    this.activeBoardingPoint = this.apiHost + 'get-all-active-boarding';
    this.activeAdanturePoint = this.apiHost + 'get-all-active-adventure';
    this.activeDestinationPoint = this.apiHost + 'get-all-active-destination';
    this.activePackagePoint = this.apiHost + 'get-all-active-package';
    this.activeRoomSharing = this.apiHost + 'get-all-active-rooms';
    this.activeBikeSharing = this.apiHost + 'get-all-active-bikes-by-bike-options';
    this.addGallery = this.apiHost + 'admin/post-gallery';
    this.addVideoAdmin = this.apiHost + 'admin/post-video';
    this.addVideoList = this.apiHost + 'admin/get-all-video';
    this.addTripList = this.apiHost + 'admin/get-all-tour';
    this.addTourStatus = this.apiHost + 'admin/put-tour-status';
    this.addVideoStatus = this.apiHost + 'admin/put-video-status';
    this.addVideoByID = this.apiHost + 'admin/get-videoById';
    this.addUpdateVideo = this.apiHost + 'admin/put-video';
    this.getGallery = this.apiHost + 'admin/get-all-gallery';
    this.getTourByID = this.apiHost + 'admin/get-tourById';
    this.webStatus = this.apiHost + 'admin/put-website-user-status';
    this.updateSuperAdmin = this.apiHost + 'admin/put-admin';
    this.deleteGallery = this.apiHost + 'admin/delete-gallery';
    this.changeGalleryStatus = this.apiHost + 'admin/put-gallery-status';
    this.galleryById = this.apiHost + 'admin/get-galleryById';
    this.addAdmin = this.apiHost+ 'admin-user';
    this.addDate = this.apiHost + 'admin/get-tourDateById';
    this.adminById = this.apiHost + 'admin/get-adminById';
    this.adminStatus = this.apiHost + 'admin/put-admin-status';
    this.getWebUser = this.apiHost+ 'admin/get-all-website-user';
    this.addblog = this.apiHost + `admin/post-blog`;
    this.status = this.apiHost + `admin/put-blog-status`;
    this.getBlogID = this.apiHost + `admin/get-blogById`;
    this.getBlogList = this.apiHost + `admin/get-all-blog`;
    this.addBoarding = this.apiHost + `admin/post-boarding`;
    this.getBoardingList = this.apiHost + 'admin/get-all-boarding';
    this.getBoardingID = this.apiHost + 'admin/get-boardingById';
    this.updateBoarding = this.apiHost + 'admin/put-boarding';
    this.bordingStatus = this.apiHost + 'admin/put-boarding-status';
    this.addPackage = this.apiHost + 'admin/post-package';
    this.getAllPackage = this.apiHost + 'admin/get-all-package';
    this.getPackageID = this.apiHost + 'admin/get-packageById';
    this.updatePackage = this.apiHost + 'admin/put-package';
    this.packageStatus = this.apiHost + 'admin/put-package-status';
    this.addRoom = this.apiHost + 'admin/post-roomShare';
    this.getAllRooms = this.apiHost + 'admin/get-all-roomShare';
    this.getRoomById = this.apiHost + 'admin/get-roomShareById';
    this.updateRoom = this.apiHost + 'admin/put-roomShare';
    this.roomStatus = this.apiHost + 'admin/put-roomShareStatus';
    this.addBike = this.apiHost + 'admin/post-bikeOption';
    this.getAllBikes = this.apiHost + 'admin/get-all-bikeOption';
    this.getBikeById = this.apiHost + 'admin/get-bikeOptionById';
    this.updateBike = this.apiHost + 'admin/put-bikeOption';
    this.bikeStatus = this.apiHost + 'admin/put-bikeOptionStatus';
    this.addAdventure = this.apiHost + 'admin/post-adventure';
    this.getAdventure = this.apiHost + 'admin/get-all-adventure';
    this.updateAdventure = this.apiHost + 'admin/put-adventure';
    this.getAdventureId = this.apiHost + 'admin/get-adventureById';
    this.changeAdven = this.apiHost + 'admin/put-adventure-status';
    this.addDestination = this.apiHost + 'admin/post-destination';
    this.getDestination = this.apiHost + 'admin/get-all-destination';
    this.getDestinationId = this.apiHost + 'admin/get-destinationById';
    this.updateDestination = this.apiHost + 'admin/put-destination';
    this.chnageDestination = this.apiHost + 'admin/put-destination-status';
    //this.getWebUser = this.apiHost + 'getWebUser';
    this.addTour = this.apiHost + 'admin/post-tour';
    this.updateaddTour = this.apiHost + 'admin/put-tour'
    this.addTourtwo = this.apiHost + 'admin/put-tour-2';
    this.addTourthree = this.apiHost + 'admin/put-tour-3';
    this.addTourfour = this.apiHost + 'admin/put-tour-4';
    this.addTourfive = this.apiHost + 'admin/put-tour-5';
    this.addTourUpdate = this.apiHost + 'admin/get-tourById';
    this.getAdminList = this.apiHost + `admin/all-admin`;
    this.getAdminById = this.apiHost + `getUserById`;
    this.updateAdmin = this.apiHost + `user`;
    this.getUserList = this.apiHost + `all-user-website`;
    this.getAdminListRole = this.apiHost + `admin/get-all-admin-user`;
    // this.getUserDetail = this.apiHost + `getLoginLogoutTime`;
    this.searchByLoginTime = this.apiHost + `loginLogoutTimeSearch`;
    this.updateMembershipDate = this.apiHost + `admin/addUserMemberDate`;
    this.getMembershipDate = this.apiHost + `admin/all-UserMemberDate`;
    this.landing = this.apiHost + 'landing-page';
    this.cloneTour = this.apiHost + 'clone-tour';
    this.addons = this.apiHost + 'addon-details';
    this.tourOfUser = this.apiHost + 'admin/get-all-applied-tour-by-user';
    this.refundAmt = this.apiHost + 'admin/put-refund-applied-tour';
    this.addmenu = this.apiHost + 'admin/post-menu';
    this.addmenulist = this.apiHost + 'admin/get-all-menu';
    this.addmenuByID = this.apiHost + 'admin/get-menuById';
    this.addmenuByStatus = this.apiHost + 'admin/put-menu-status';
    this.updateaddmenu = this.apiHost + 'admin/put-menu';
    this.getallactivemenu = this.apiHost + 'admin/get-all-activemenu';
    this.refundlist = this.apiHost + 'admin/get-all-isRefundedOnCancel';
    this.addaddonnotes = this.apiHost + 'admin/add-addon-notes';
    this.addDateSearchUser = this.apiHost + 'dateSearchAdminList';
    this.cancelMember=this.apiHost+'cancel-members';
    this.cancelMemberByName=this.apiHost+'cancel-members-by-name';
    this.getaddonnotes = this.apiHost + `admin/get-all-add-addon-notes`;
    this.generatePayLink = this.apiHost + 'admin/send-pay-link';
    this.allOrderWithLink = this.apiHost + 'admin/get-all-orders-with-pay-link';
    this.reviveLink = this.apiHost + 'admin/revive-pay-link';
    this.deletecoupon = this.apiHost + 'admin/delete-coupon';

    this.stateName = 'https://api.countrystatecity.in/v1/countries/IN/states';

    //this.getCategory = this.apiHost + `admin/all-category`;

    // Delete all category names - `admin/all-category`

    this.getNotification = this.apiHost + 'admin/get-all-notification';
    this.notificationCount = this.apiHost + 'admin/get-notificationCount';
    this.notificationSeen = this.apiHost + 'admin/put-notification';
    this.notificationService = this.apiHost + 'admin/add-notification';

    this.reminder = this.apiHost + 'admin/reminder';

    this.travelDateUpdate = this.apiHost + 'admin/travel-date-update';

    this.financialYear = this.apiHost + 'admin/financial-search';

    this.financialYearReport = this.apiHost + 'admin/financial-search-report';
  }

//////////////////////////////////////////////////////////////////////////////////////////////////////////

addImage(requestParameters) {
    return this.http.post(`${this.addGallery}`, requestParameters, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event))
    );
  }

  private getEventMessage(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);
      case HttpEventType.Response:
        return event.body;
      default:
        return `Upload event: ${event.type}.`;
    }
  }

  private fileUploadProgress(event: any) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { progress: percentDone, files: [] };
  }

  addBlogs(requestParameters) {
    return this.http.post(`${this.addblog}`, requestParameters, {});
  }

  addnotesaddon(requestParameters) {
    return this.http.post(`${this.addaddonnotes}`, requestParameters, {});
  }

  addMenus(requestParameters) {
    return this.http.post(`${this.addmenu}`, requestParameters, {});
  }

  MenuStatus(id, requestParameters) {
    return this.http.put(`${this.addmenuByStatus}?id=${id}`, JSON.parse(requestParameters), {});
  }

  updateMenu(requestParameters, id) {
    return this.http.put(`${this.updateaddmenu}?id=${id}`, requestParameters, {});
  }

  getMenuById(requestParameters) {
    return this.http.post(`${this.addmenuByID}`, requestParameters, {});
  }

   report(requestParameters) {
    return this.http.post(`${this.addReport}`, requestParameters, {});
  }

   Coupan(requestParameters) {
    return this.http.post(`${this.applyCoupon}`, requestParameters, {});
  }

  createOrder(requestParameter: any) {
    return this.http.post(`${this.getOrderId}`, requestParameter, {});
  }

  booking(requestParameters) {
    return this.http.put(`${this.orderBooking}`, requestParameters, {});
  }

   webOrder(id) {
    return this.http.post(`${this.webUserOrderList}?id=${id}`,  {});
  }

   TourId(requestParameters) {
    return this.http.post(`${this.tourByID}`, requestParameters, {});
  }

  webUserOrder(requestParameters) {
    return this.http.post(`${this.WeborderList}`, requestParameters, {});
  }

  AdminTour(requestParameters) {
    return this.http.post(`${this.addcheckoutTour}`, requestParameters, {});
  }

   dashboardCount(requestParameters) {
    return this.http.post(`${this.dashboard}`, requestParameters, {});
  }

  addorder(requestParameters) {
    return this.http.post(`${this.addOrderCharge}`, requestParameters, {});
  }

  specialImage(requestParameters) {
    return this.http.post(`${this.addSpecialImage}`, requestParameters, {});
  }

  addWebSiteID(requestParameters) {
    return this.http.post(`${this.addWebsiteUserBYID}`, requestParameters, {});
  }

  addAdminTour(requestParameters) {
    return this.http.post(`${this.addActiveTourAdmin}`, requestParameters, {});
  }

   addCoupan(requestParameters) {
    return this.http.post(`${this.addCoupanCode}`, requestParameters, {});
  }

   videoAdd(requestParameters) {
    return this.http.post(`${this.addVideoAdmin}`, requestParameters, {});
  }

   Notes(requestParameters) {
    return this.http.post(`${this.addNotes}`, requestParameters, {});
  }

  addRole(requestParameters) {
    return this.http.post(`${this.addAdmin}`, requestParameters, {});
  }

   adminId(requestParameters) {
    return this.http.post(`${this.adminById}`, requestParameters, {});
  }

  orderChargeID(requestParameters) {
    return this.http.post(`${this.addOrderChargeByID}`, requestParameters, {});
  }

   webSite(requestParameters) {
    return this.http.post(`${this.addWebUser}`, requestParameters, {});
  }


  AdminStatus(id, requestParameters) {
    return this.http.put(`${this.adminStatus}?id=${id}`, JSON.parse(requestParameters), {});
  }

  OrderStatus(id, requestParameters) {
    return this.http.put(`${this.addOrderChargeStatus}?id=${id}`, JSON.parse(requestParameters), {});
  }

  SpecialImageStatus(id, requestParameters) {
    return this.http.put(`${this.addSpecialImageStatus}?id=${id}`, JSON.parse(requestParameters), {});
  }

  putCheckoutStatusUpdate(requestParameter: any, id: any) {
    return this.http.put(`${this.checkoutStatusUpdate}?id=${id}`, requestParameter, {});
  }

  VideoStatus(id, requestParameters) {
    return this.http.put(`${this.addVideoStatus}?id=${id}`, JSON.parse(requestParameters), {});
  }

  upAdmin(requestParameters, id) {
    return this.http.put(`${this.updateSuperAdmin}?id=${id}`, requestParameters, {});
  }

  // addImage(requestParameters) {
  //   return this.http.post(`${this.addGallery}`, requestParameters, {});
  // }

  changeStausById(id, requestParameters) {
    return this.http.put(`${this.status}?id=${id}`, JSON.parse(requestParameters), {});
  }

  galleryStatus(id, requestParameters) {
    return this.http.put(`${this.changeGalleryStatus}?id=${id}`, JSON.parse(requestParameters), {});
  }

   WebsiteUserStatus(id, requestParameters) {
    return this.http.put(`${this.webStatus}?id=${id}`, JSON.parse(requestParameters), {});
  }

  getIDGallery(requestParameters) {
    return this.http.post(`${this.galleryById}`, requestParameters, {});
  }

  SpecialImageById(requestParameters) {
    return this.http.post(`${this.addSpecialImageByID}`, requestParameters, {});
  }

   coupanBYID(requestParameters) {
    return this.http.post(`${this.addCoupanCodeId}`, requestParameters, {});
  }

  getBlogsById(requestParameters) {
    return this.http.post(`${this.getBlogID}`, requestParameters, {});
  }

  videoById(requestParameters) {
    return this.http.post(`${this.addVideoByID}`, requestParameters, {});
  }

  orderID(requestParameters) {
    return this.http.post(`${this.addorderByID}`, requestParameters, {});
  }


  getTourId(requestParameters) {
    return this.http.post(`${this.getTourByID}`, requestParameters, {});
  }

    NotesId(requestParameters) {
    return this.http.post(`${this.addNotesById}`, requestParameters, {});
  }

  dateByID(requestParameters) {
    return this.http.post(`${this.addDate}`, requestParameters, {});
  }

  updateBlogs(requestParameters, id) {
    return this.http.put(`${this.addblog}?id=${id}`, requestParameters, {});
  }

   updateFirstTour(requestParameters, id) {
    return this.http.put(`${this.updateaddTour}?id=${id}`, requestParameters, {});
  }

  addcompleteActive() {
    return this.http.get(`${this.addCompleteActiveTour}`);
  }

  allRefund() {
    return this.http.get(`${this.refundlist}`);
  }

  Activemenu() {
    return this.http.get(`${this.getallactivemenu}`);
  }

  addMenulist() {
    return this.http.get(`${this.addmenulist}`);
  }

  orderList() {
    return this.http.get(`${this.addOrderChargeList}`);
  }

  SpecialImageList() {
    return this.http.get(`${this.addSpecialImageList}`);
  }

  activeCoupanList() {
    return this.http.get(`${this.allActiveCoupan}`);
  }

  getAllBlogs() {
    return this.http.get(`${this.getBlogList}`);
  }

  getAllTour(requestParameter:number) {
    return this.http.post(`${this.getAllActiveTour}`,[requestParameter]);
  }
  getsearchClient(requestParameter:string){
    return this.http.post(`${this.searchClient}`,[requestParameter]);
  }

  coupanList() {
    return this.http.get(`${this.addCoupanCodeList}`);
  }

  voucherList() {
    return this.http.get(`${this.addvoucherlist}`);
  }


  NoteList() {
    return this.http.get(`${this.addNotesList}`);
  }

  getTrip() {
    return this.http.get(`${this.addTripList}`);
  }

   videoList() {
    return this.http.get(`${this.addVideoList}`);
  }

  allWebUser() {
    return this.http.get(`${this.getWebUser}`);
  }

  Allgellary() {
    return this.http.get(`${this.getGallery}`);
  }

  addBoard(requestParameters) {
    return this.http.post(`${this.addBoarding}`, requestParameters, {});
  }

  getBoardById(requestParameters) {
    return this.http.post(`${this.getBoardingID}`, requestParameters, {});
  }

  searchUserDate(requestParameters) {
    return this.http.post(`${this.addDateSearchUser}`, requestParameters, {});
  }

  getAllBoarding() {
    return this.http.get(`${this.getBoardingList}`);
  }

   getAll() {
    return this.http.get(`${this.getAdminList}`);
  }

  updateBoard(requestParameters, id) {
    return this.http.put(`${this.updateBoarding}?id=${id}`, requestParameters, {});
  }

  orderUpdate(requestParameters, id) {
    return this.http.put(`${this.addOrderChargeUpdate}?id=${id}`, requestParameters, {});
  }

  deleteMediaById(id) {
    return this.http.delete(`${this.deleteGallery}?id=${id}`);
  }

  deleteCouponById(id) {
    return this.http.delete(`${this.deletecoupon}?id=${id}`);
  }

  updateSpecialImage(requestParameters, id) {
    return this.http.put(`${this.addSpecialImageUpdate}?id=${id}`, requestParameters, {});
  }

   upVideo(requestParameters, id) {
    return this.http.put(`${this.addUpdateVideo}?id=${id}`, requestParameters, {});
  }

  changeBoarding(id, requestParameters) {
    return this.http.put(`${this.bordingStatus}?id=${id}`, JSON.parse(requestParameters), {});
  }

  tripStatus(id, requestParameters) {
    return this.http.put(`${this.addTourStatus}?id=${id}`, JSON.parse(requestParameters), {});
  }

  coupanStatus(id, requestParameters) {
    return this.http.put(`${this.addCopanCodeStatus}?id=${id}`, JSON.parse(requestParameters), {});
  }


   NoteStatus(id, requestParameters) {
    return this.http.put(`${this.addNotesStatus}?id=${id}`, JSON.parse(requestParameters), {});
  }

  addPack(requestParameters) {
    return this.http.post(`${this.addPackage}`, requestParameters, {});
  }

  getAllPack() {
    return this.http.get(`${this.getAllPackage}`);
  }

   getPackId(requestParameters) {
    return this.http.post(`${this.getPackageID}`, requestParameters, {});
  }

  updatePack(requestParameters, id) {
    return this.http.put(`${this.updatePackage}?id=${id}`, requestParameters, {});
  }

  changePack(id, requestParameters) {
    return this.http.put(`${this.packageStatus}?id=${id}`, JSON.parse(requestParameters), {});
  }

  postAddRoom(requestParameters) {
    return this.http.post(`${this.addRoom}`, requestParameters, {});
  }

  AllRooms() {
    return this.http.get(`${this.getAllRooms}`);
  }

  getRoomId(requestParameters) {
    return this.http.post(`${this.getRoomById}`, requestParameters, {});
  }

  updateRoomById(requestParameters, id) {
    return this.http.put(`${this.updateRoom}?id=${id}`, requestParameters, {});
  }

  changeRoomStatus(id, requestParameters) {
    return this.http.put(`${this.roomStatus}?id=${id}`, JSON.parse(requestParameters), {});
  }

  postAddBike(requestParameters) {
    return this.http.post(`${this.addBike}`, requestParameters, {});
  }

  AllBikes() {
    return this.http.get(`${this.getAllBikes}`);
  }

  getBikeId(requestParameters) {
    return this.http.post(`${this.getBikeById}`, requestParameters, {});
  }

  updateBikeById(requestParameters, id) {
    return this.http.put(`${this.updateBike}?id=${id}`, requestParameters, {});
  }

  changeBikeStatus(id, requestParameters) {
    return this.http.put(`${this.bikeStatus}?id=${id}`, JSON.parse(requestParameters), {});
  }

  addAdven(requestParameters) {
    return this.http.post(`${this.addAdventure}`, requestParameters, {});
  }

  getAdven() {
    return this.http.get(`${this.getAdventure}`);
  }

  updateAdven(requestParameters, id) {
    return this.http.put(`${this.updateAdventure}?id=${id}`, requestParameters, {});
  }

   updatecoupan(requestParameters, id) {
    return this.http.put(`${this.updateCoupanCode}?id=${id}`, requestParameters, {});
  }

  getAdvenId(requestParameters) {
    return this.http.post(`${this.getAdventureId}`, requestParameters, {});
  }

  changeAdvenStatus(id, requestParameters) {
    return this.http.put(`${this.changeAdven}?id=${id}`, JSON.parse(requestParameters), {});
  }

  addDestni(requestParameters) {
    return this.http.post(`${this.addDestination}`, requestParameters, {});
  }

  getDesti() {
    return this.http.get(`${this.getDestination}`);
  }

   tourUpdate(requestParameters) {
    return this.http.post(`${this.addTourUpdate}`, (requestParameters), {});
  }

  getDestiId(requestParameters) {
    return this.http.post(`${this.getDestinationId}`, requestParameters, {});
  }

   updateDesti(requestParameters, id) {
    return this.http.put(`${this.updateDestination}?id=${id}`, requestParameters, {});
  }

   changeDestiStatus(id, requestParameters) {
    return this.http.put(`${this.chnageDestination}?id=${id}`, JSON.parse(requestParameters), {});
  }

   activeAdvent() {
    return this.http.get(`${this.activeAdanturePoint}`);
  }

  activeBording() {
    return this.http.get(`${this.activeBoardingPoint}`);
  }

  activeDestination() {
    return this.http.get(`${this.activeDestinationPoint}`);
  }

   activePackage() {
    return this.http.get(`${this.activePackagePoint}`);
  }

  activeRooms() {
    return this.http.get(`${this.activeRoomSharing}`);
  }

  activeBikesByOpt(requestParameters: string) {
    return this.http.get(`${this.activeBikeSharing}/${requestParameters}`);
  }

 addSubscription(subscribeData: any) {
    localStorage.setItem('subscriptionInfo', JSON.stringify(subscribeData));
  }

  addTrip(requestParameters) {
    return this.http.post(`${this.addTour}`, requestParameters, {});
  }

  updateTour(requestParameters,id) {
    return this.http.put(`${this.addTourtwo}?id=${id}`, requestParameters, {});
  }

  updateTourThree(requestParameters,id) {
    return this.http.put(`${this.addTourthree}?id=${id}`, requestParameters, {});
  }

  updateTourFour(requestParameters,id) {
    return this.http.put(`${this.addTourfour}?id=${id}`, requestParameters, {});
  }

  upNotes(requestParameters,id) {
    return this.http.put(`${this.updateNotes}?id=${id}`, requestParameters, {});
  }

  updateTourFive(requestParameters,id) {
    return this.http.put(`${this.addTourfive}?id=${id}`, requestParameters, {});
  }


 onLogin(requestParameters) {
    return this.http.post(`${this.login}`, JSON.parse(requestParameters), {});
  }

  checkEmailForgotPsw(requestParameters) {
    return this.http.post(`${this.checkEmail}`, JSON.parse(requestParameters), {});
  }

  checkOTPForgotPsw(requestParameters) {
    return this.http.post(`${this.checkOTP}`, JSON.parse(requestParameters), {});
  }

  resetForgotPsw(requestParameters) {
    return this.http.post(`${this.resetPsw}`, JSON.parse(requestParameters), {});
  }

  onLogout() {
    return this.http.get(`${this.logout}`);
  }

  onRegister(requestParameters) {
    return this.http.post(`${this.register}`, JSON.parse(requestParameters), {});
  }

  getAllAdmin() {
    return this.http.get(`${this.getAdminListRole}`);
  }

  getAdmin(requestParameters) {
    return this.http.post(`${this.getAdminById}`, JSON.parse(requestParameters), {});
  }

  updateAdminById(id, requestParameters) {
    return this.http.put(`${this.updateAdmin}?id=${id}`, JSON.parse(requestParameters), {});
  }

  getAllUsers() {
    return this.http.get(`${this.getUserList}`);
  }

  getaddon(id) {
    return this.http.get(`${this.getaddonnotes}?applyTour_id=${id}`);
  }



  getUserLoginDetails(requestParameters) {
    return this.http.post(`${this.searchByLoginTime}`, requestParameters, {});
  }


  // getAllCategory() {
  //   return this.http.get(`${this.getCategory}`);
  // }



  // getAllDashboardCount() {
  //   return this.http.get(`${this.dashboardCount}`);
  // }

  getHost(url) {
    return this.apiHost + url;
  }

  imgToData64(imguRL: string) {
    return this.http.get(imguRL, { responseType: 'blob' });
  }

  getAllLandingData() {
    return this.http.get(`${this.landing}`);
  }

  getLandingData(id: any) {
    return this.http.get(`${this.landing}/${id}`);
  }

  copyAndSaveTour(requestParameter: any) {
    return this.http.post(`${this.cloneTour}`, requestParameter, {});
  }

  setAddonsData(requestParameter: any) {
    return this.http.post(`${this.addons}`, requestParameter, {});
  }

  getAppliedTourByUser(requestParameter: any) {
    return this.http.post(`${this.tourOfUser}`, requestParameter, {});
  }

  putAppliedTourRefundAmt(id: string, requestParameter: any) {
    return this.http.put(`${this.refundAmt}/${id}`, requestParameter, {});
  }

  getStates() {
    const headerDict = { "X-CSCAPI-KEY": "cDkybWFzVWJFck0yODdxMFZDTjBzSHUyWnpUVHlWd010VGZSdUhoeQ==" }
    return this.http.get(`${this.stateName}`, { headers: new HttpHeaders(headerDict) });
  }
  updatecancelMembers(requestParameters){
    return this.http.put(`${this.cancelMember}`,requestParameters,{} );
  }
  updatecancelMembersByName(requestParameters){
    return this.http.put(`${this.cancelMemberByName}`,requestParameters,{} );
  }

  generateSendPayLink(bookId: string, requestParameter: any) {
    return this.http.patch(`${this.generatePayLink}/${bookId}`, requestParameter);
  }

  getAllOrderWithLink() {
    return this.http.get(`${this.allOrderWithLink}`);
  }

  reviveSendPayLink(bookId: string, requestParameter: any) {
    return this.http.patch(`${this.reviveLink}/${bookId}`, requestParameter);
  }

  postNotification(requestParameters){
    return this.http.post(`${this.notificationService}`, requestParameters, {})
  }

  allNotification(){
    return this.http.get(`${this.getNotification}`);
  }

  getNotificationCount(){
    return this.http.get(`${this.notificationCount}`);
  }

  seenNotification(requestParameter){
    return this.http.put(`${this.notificationSeen}`, requestParameter, {});
  }

  getReminder(){
    return this.http.get(`${this.reminder}`)
  }

  updateTravelDate(id, requestParameters){
    return this.http.put(`${this.travelDateUpdate}?id=${id}`, JSON.parse(requestParameters), {})
  }

  financialYearList(requestParameters: any){
    return this.http.post(`${this.financialYear}`, requestParameters, {})
  }

  financialYearReportList(requestParameters: any){
    return this.http.post(`${this.financialYearReport}`, requestParameters, {})
  }
}

import { Component, OnInit , ViewChild, OnDestroy} from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-media-list',
  templateUrl: './add-media-list.component.html',
  styleUrls: ['./add-media-list.component.scss']
})
export class AddMediaListComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  imageGallery: any;
  //private catego: string;
  public editCatRoute: string;
  gallery_image: any;
  //public addLanName: string;
  //public addLanRoute: string;
  galleryImage: any;
  public allData: any;
  id: any;
  
  NoData: any;
  //public editCatRoute: string;
  private isDtInitialized: boolean = false;
  baseURLofAPi: string;

  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private toastr: ToastrService
  ) {this.baseURLofAPi = environment.API_HOST; }

  ngOnInit(): void {
     this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };
    this.getAllMedia();
  }

  getAllMedia() {
    this.apiService.Allgellary().subscribe((res: any) => {
      this.imageGallery = res.data;
      // let galleryImage = []; 
      
      //this.galleryImage = res.imageGallery.gallery_image;
       //console.log(this.imageGallery);
       //console.log(this.galleryImage)
    });
  }

  deleteMedia(id) {
   Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure, you want to change status?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
       if (result.isConfirmed) {
       this.apiService.deleteMediaById(id).subscribe((res: any) => {
        // console.log(res);
        if (res.success) {
          Swal.fire({
              text: res.message,
              icon: 'success',
            });
          //this.toastr.success(res.message);
          this.getAllMedia();
        } else {
           Swal.fire({
              text: res.message,
              icon: 'error',
            });
          //this.toastr.error(res.message);
        }
      });
      // else {
      //   return;
      // }
    } 
    });
    
  }

  async gallerylist(id, status) {
    if (id == undefined || id == '') {
      //this.toastr.error(' Id is Invalid');
      Swal.fire({
        text: 'Id is Invalid',
        icon: 'error',
      });
      return false;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure, you want to change status?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        let getStatus = {};
        getStatus =
          status === 'active' ? { status: 'inactive' } : { status: 'active' };
        this.apiService
          .galleryStatus(id, JSON.stringify(getStatus))
          .subscribe((result: any) => {
            if (result.success) {
              Swal.fire({
                text: result.message,
                icon: 'success',
              });
              //this.toastr.success(result.message);
              this.getAllMedia();
            } else {
              Swal.fire({
                text: result.message,
                icon: 'error',
              });
              this.getAllMedia();
              //this.toastr.error(result.message);
            }
          });
      } else {
        this.getAllMedia();
      }
    });
  }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  getImg(imgSrc) {
    return this.apiService.getHost(imgSrc);
  }

}

import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
//import { Router } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-destination-city',
  templateUrl: './destination-city.component.html',
  styleUrls: ['./destination-city.component.scss']
})
export class DestinationCityComponent implements OnInit {
  addDestinationForm: FormGroup;
  public cancelCatRoute: string;
  private destiid: any;
  getData: any;
  public isAuthLoading = false;
  public isAuthDisabled = false;
  private _userId: string = localStorage.getItem('id');

  constructor(
    private formBuilder: FormBuilder,
    //private router: Router,
    private apiService: ApiService,
    //private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.addDestinationForm = new FormGroup({
      destination: new FormControl(),
      status: new FormControl(),
      // user_id: new FormControl(),
      //display_sequence: new FormControl()
    });
     this.destiid = this.route.snapshot.paramMap.get('destination_id');
    // console.log(this.news_id, this.news_type);
    if (
      this.destiid != undefined &&
      this.destiid != null &&
      this.destiid != ''
    ) {
      this.editpack(this.destiid);
    } else {
      this.addDestinationForm.get('status').setValue('active');
    }
  }

  editpack(id) {
    let dataForForm = {
      id: id,
    };
    this.apiService.getDestiId(dataForForm).subscribe((result: any) => {
      console.log(result.getData);
      if (result.success) {
        this.addDestinationForm.patchValue({
          destination: result.getData.destination,
        });
        if (result.getData.status == 'active') {
          this.addDestinationForm.get('status').setValue('active');
        }
        //  if(blogData.status == "active") {
        //   this.addBlogForm.get('status').setValue('active');
        // }
      }
    });
  }

  addDesti() {
    if (
      this.addDestinationForm.value.destination== undefined ||
      this.addDestinationForm.value.destination.trim() == ''
    ) {
      Swal.fire({
        text: 'Please enter destination',
        icon: 'warning',
      });
      return false;
      // this.toastr.error("Please Enter the Language");
      // return false;
    }
    // if (
    //   this.addDestinationForm.value.this._userId == undefined ||
    //   this.addDestinationForm.value.boarding.trim() == ''
    // )
    if (this.addDestinationForm.status == 'active') {
      this.addDestinationForm.get('status').setValue('active');
    }
    if (this.destiid != undefined && this.destiid != null && this.destiid != '') {
      this.updatedesti(this.destiid);
    } else {
      this.addDestinationForm.value.user_id = this._userId;
      this.isAuthLoading = true;
      this.isAuthDisabled = true;
      this.apiService
        .addDestni(this.addDestinationForm.value)
        .subscribe((res: any) => {
          console.log(res);
          if (res.success) {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.success(res.message);
            Swal.fire({
              text: res.message,
              icon: 'success',
            });
            this.router.navigateByUrl('/destination-city-list');
          } else {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            //this.toastr.error(res.message);
            Swal.fire({
              text: res.message,
              icon: 'error',
            });
          }
        });
    }
  }
    updatedesti(id) {
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    this.apiService
      .updateDesti(this.addDestinationForm.value, id)
      .subscribe((res: any) => {
        if (res.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          //this.toastr.success(res.message);
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          this.router.navigateByUrl('/destination-city-list');
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
          //this.toastr.error(res.message);
        }
      });
  }

}

import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/utils/services/api.service';
import { AppService } from 'src/app/utils/services/app.service';
import Swal from 'sweetalert2';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
//import { log } from 'console';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-user-particular-order',
  templateUrl: './user-particular-order.component.html',
  styleUrls: ['./user-particular-order.component.scss']
})
export class UserParticularOrderComponent implements OnInit {
  private userData = JSON.parse(localStorage.getItem("userToken") || '{}');
  // @ViewChild('appendAddOn') appendAddOn:ElementRef;
  public addOnForm: FormGroup;
  private orderId: any;
  public order: any;
  addon_notes: any;
  aboutData: any;
  public travellerArr: any = [];
  public allPackageData: any;
  public allBikeData: any;
  public allRoomData: any;
  private logoImgInDateURL: string = '';
  public openProfile: boolean = true;
  public maxDate = new Date().toISOString().slice(0, 10);
  public submitted: boolean = false;
  public totalUnpaidAddon: number = 0;
  public totalAddonAmt: number = 0;
  private _userId: string = localStorage.getItem('id');
  addondata: any;
  count: number = 0;
  addOnData1: any = [];
  validity: number[] = Array.from({ length: 24 }, (_, i) => i + 1);
  linkAmount: number | undefined;
  linkValidTill: number = 1;
  dateEdit: any = false;
  dateDropDown: any = [];
  selectedDate: any;
  adminAmount: number | undefined;

  private toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  amt: number;
  n: any;
  totalpaidAddon: number = 0;
  totalPayAmt: any;
  status: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private apiService: ApiService,
    private appService: AppService,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService
  ) {
    this.addOnForm = new FormGroup({
      addOns: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.selectedDate = '';
    this.route.params.subscribe(data => {
      this.orderId = data?.orderId;
      this.getAllPackageData();
      this.getAllBikeData();
      this.getAllRoomData();
      this.getAllOrder();
      this.invoiceLogoImgToDataURL();
      setTimeout(() => {                           //<<<---using ()=> syntax
        this.getalladdonnote(this.order._id);
      }, 2000);
    });
    setTimeout(() => {
      this.spinner.show();
      JSON.parse(this.order?.tour_id.start_date).filter((elem, i) => {
        if (new Date(elem).getTime() > Date.now()) {
          // console.log(elem.split('/')[1], elem.split('/')[0], elem.split('/')[2], JSON.parse(this.order.tour_id.end_date)[i].split('/')[1], JSON.parse(this.order.tour_id.end_date)[i].split('/')[0], JSON.parse(this.order.tour_id.end_date)[i].split('/')[2])
          let startDatePart = `${elem.split('/')[1]}/${elem.split('/')[0]}/${elem.split('/')[2]}`
          let endDatePart = `${JSON.parse(this.order.tour_id.end_date)[i].split('/')[1]}/${JSON.parse(this.order.tour_id.end_date)[i].split('/')[0]}/${JSON.parse(this.order.tour_id.end_date)[i].split('/')[2]}`
          // console.log(startDatePart, endDatePart)
          this.dateDropDown.push(`${startDatePart} - ${endDatePart}`)
          this.spinner.hide();
        }
      })
    }, 2000)
  }

  getalladdonnote(id) {
    this.spinner.show();
    this.apiService.getaddon(id).subscribe((res: any) => {
      //console.log(res, 'devvv', this.totalUnpaidAddon);
      this.addOnData1 = res.data;
      if (res.success) {
        this.addon_notes = res.data[0]?.addon_notes;
        this.spinner.hide();
        //console.log(this.addon_notes)
      } else {
        this.spinner.hide();
      }
    });
  }

  addaddonnotes() {
    const notes = {
      //user_id: this._userId,
      applyTour_id: this.order._id,
      addon_notes: this.addon_notes,
      addon_id: this.order.addons_id ? this.order.addons_id._id : ''
    };
    //console.log(notes);
    //return;
    this.spinner.show();
    this.apiService.addnotesaddon(notes).subscribe((res: any) => {
      if (res.success) {

        Swal.fire({
          title: 'Success',
          text: res.message,
          icon: 'success',
        });
        this.spinner.hide();
        setTimeout(() => {                           //<<<---using ()=> syntax
          this.getalladdonnote(this.order._id);
        }, 2000);
        //this.router.navigateByUrl('/add-user-list');
      } else {
        Swal.fire({
          text: res.message,
          icon: 'error',
        });
        this.spinner.hide();
      }
    });
  }
  handleClick() {
    this.selectedDate = '';
    // console.log("WORKED", JSON.parse(this.order.tour_id.start_date), JSON.parse(this.order.tour_id.end_date), Date.now(), new Date(),new Date().toLocaleDateString() )
    // JSON.parse(this.order.tour_id.start_date).filter((elem, i) => {
    //   if(new Date(elem).getTime() > Date.now()){
    //     console.log(elem, JSON.parse(this.order.tour_id.end_date)[i])
    //     this.dateDropDown.push(`${elem} - ${JSON.parse(this.order.tour_id.end_date)[i]}`)
    //   }
    // })
    this.dateEdit = true;
  }
  handleSaveDate() {
    this.dateEdit = false;
    if (this.selectedDate) {
      // console.log(this.selectedDate, this.orderId, this.selectedDate.split(" ")[0].split("/")[2], new Date(`${this.selectedDate.split(" ")[0].split("/")[2]}/${this.selectedDate.split(" ")[0].split("/")[1]}/${this.selectedDate.split(" ")[0].split("/")[0]}`).getTime(), new Date(new Date(`${this.selectedDate.split(" ")[0].split("/")[2]}/${this.selectedDate.split(" ")[0].split("/")[1]}/${this.selectedDate.split(" ")[0].split("/")[0]}`).getTime()).toISOString())
      let fewDaysAgoDate = new Date(`${this.selectedDate.split(" ")[0].split("/")[2]}/${this.selectedDate.split(" ")[0].split("/")[1]}/${this.selectedDate.split(" ")[0].split("/")[0]}`);
      new Date(fewDaysAgoDate.setDate(fewDaysAgoDate.getDate() - +this.order.tour_id.lastReminderDay))
      let fiveDaysAgoDate = new Date(fewDaysAgoDate)
      new Date(fiveDaysAgoDate.setDate(fiveDaysAgoDate.getDate() - 5))
      // console.log(+this.order.tour_id.lastReminderDay, this.selectedDate, fewDaysAgoDate, fiveDaysAgoDate, new Date(`${this.selectedDate.split(" ")[0].split("/")[2]}/${this.selectedDate.split(" ")[0].split("/")[1]}/${this.selectedDate.split(" ")[0].split("/")[0]}`))

      let travelDate = { travel_date: this.selectedDate, tourDate2: `${this.selectedDate.split(" ")[0].split("/")[1]}/${this.selectedDate.split(" ")[0].split("/")[0]}/${this.selectedDate.split(" ")[0].split("/")[2]}`, tourDate: new Date(`${this.selectedDate.split(" ")[0].split("/")[2]}/${this.selectedDate.split(" ")[0].split("/")[1]}/${this.selectedDate.split(" ")[0].split("/")[0]}`).getTime(), tourReminderStartDate: fiveDaysAgoDate, tourReminderEndDate: fewDaysAgoDate }
      // return
      this.apiService.updateTravelDate(this.orderId, JSON.stringify(travelDate)).subscribe((res: any) => {
        // console.log(res)
        location.reload();
      })
    } else if (this.selectedDate == "No date available") {
      this.dateEdit = false;
    } else {
      Swal.fire({
        text: 'Please select a tour date.',
        icon: 'warning',
      });
      this.dateEdit = true;
      return;
    }
  }
  // compareDates = (d1, d2) => {
  //   let date1 = new Date(d1).getTime();
  //   let date2 = new Date(d2).getTime();
  //   console.log(date1, date2)
  //   if (date1 < date2) {
  //     console.log(`${d1} is less than ${d2}`);
  //   } else if (date1 > date2) {
  //     console.log(`${d1} is greater than ${d2}`);
  //   } else {
  //     console.log(`Both dates are equal`);
  //   }
  // };
  getAllOrder() {
    const orderId = {
      id: this.orderId
    };
    this.spinner.show();
    this.apiService.TourId(orderId).subscribe((res: any) => {
      if (res.success) {
        this.order = res.getData;
        console.log(this.order, 'Devvvv')
        this.selectedDate = this.order.travel_date
        this.apiService.getaddon(this.order._id).subscribe((res: any) => {
          //console.log(res, 'devvv', this.totalUnpaidAddon);
          this.addOnData1 = res.data;
          this.spinner.hide();
          //console.log(this.addOnData1, "dataaa")
        })

        //
        this.n = 0;
        //console.log(this.order, 'ordeeer', this.totalUnpaidAddon, "Add-on");
        const totalPerson = this.order?.travel_packageType_data;
        for (let person of totalPerson) {
          this.n = this.n + person.persons;
        }
        this.totalPayAmt = 0
        const totalPaylinkamt = this.order?.payLinkTotalAmount;
        for (let person of totalPaylinkamt) {
          this.totalPayAmt = this.totalPayAmt + person;
        }
        // this.n= this.order?.travel_packageType_data[0].persons
        //console.log(this.n, "nnn", this.totalPayAmt)
        this.addondata = this.order?.addons_id?.addon_notes
        //console.log(this.order.addons_id?.addon_notes, 'addoon');
        //console.log(this.addOnData1, 'addoon Data');
        this.travellerArr = Array(this.order?.travel_no_of_traveler).fill('travel');
        if (this.order?.addons_id && this.order?.addons_id?.addons_data.length) {
          this.totalUnpaidAddon = 0;
          this.totalpaidAddon = 0;
          this.totalAddonAmt = 0;
          const addonData = this.order?.addons_id?.addons_data;
          for (let addon of addonData) {
            if (addon.status === 'unpaid') {
              this.totalUnpaidAddon += (addon.price * addon.quantity);
            }
            if (addon.status === 'paid') {
              this.totalpaidAddon += (addon.price * addon.quantity);
            }

            this.totalAddonAmt += (addon.price * addon.quantity);
            this.insertAddOn(addon.service, addon.price, addon.quantity, addon.status)
          }
          if (!this.order?.isOuter) {
            //this.totalUnpaidAddon += (+this.order?.cgst + +this.order?.sgst) * this.totalUnpaidAddon * 0.01;
            //this.totalAddonAmt += (+this.order?.cgst + +this.order?.sgst) * this.totalAddonAmt * 0.01;
          } else {
            //this.totalUnpaidAddon += +this.order?.igst * this.totalUnpaidAddon * 0.01;
            //this.totalAddonAmt += +this.order?.igst * this.totalAddonAmt * 0.01;
          }
          this.addOnForm.disable();
        }
        this.openProfile = true;
        //console.log(this.order, "hala");
        // this.n= this.order.finalPrice/(this.order.partial_payment);
        //console.log(this.n, this.totalUnpaidAddon, this.totalpaidAddon, "paidAdd")

      } else {
        this.toast.fire({
          icon: 'error',
          title: res.message
        });
        this.spinner.hide();
      }
    });
  }

  getAllPackageData() {
    this.spinner.show();
    this.apiService.getAllPack().subscribe((res: any) => {
      if (res.success) {
        // console.log(res, "pack");
        this.allPackageData = res.data;
        this.spinner.hide();
      } else {
        console.error(res);
        this.spinner.hide();
      }
    });
  }

  getAllBikeData() {
    this.spinner.show();
    this.apiService.AllBikes().subscribe((res: any) => {
      if (res.success) {
        // console.log(res, "bike");
        this.allBikeData = res.data;
        this.spinner.hide();
        // this.tourAllData.packageType_price = data;
      } else {
        console.error(res);
        this.spinner.hide();
      }
    });
  }

  getAllRoomData() {
    this.spinner.show();
    this.apiService.AllRooms().subscribe((res: any) => {
      if (res.success) {
        //console.error(res, "room");
        this.allRoomData = res.data;
        this.spinner.hide();
        // this.tourAllData.packageType_price = data;
      } else {
        console.error(res);
        this.spinner.hide();
      }
    });
  }

  getPackage(id: string) {
    if (this.allPackageData?.length > 0 && id !== "") {
      return this.allPackageData.filter((pack: any) => pack._id === id)[0]?.package_type;
    } else {
      return "-";
    }
  }

  getBikePackage(id: string) {
    if (this.allBikeData?.length > 0 && id !== "") {
      return this.allBikeData.filter((pack: any) => pack._id === id)[0].bike_name;
    } else {
      return "-";
    }
  }

  get addOns() {
    return this.addOnForm.controls['addOns'] as FormArray;
  }

  insertAddOn(serviceEdit = '', priceEdit = undefined, quantityEdit = undefined, statusEdit = 'unpaid') {
    let addOnData = this.fb.group({
      service: [serviceEdit, Validators.required],
      price: [priceEdit, Validators.required],
      quantity: [quantityEdit, Validators.required],
      status: [statusEdit, Validators.required]
    });
    this.addOns.push(addOnData);
  }

  removeAddOn(removeId: number) {
    this.addOns.removeAt(removeId);
  }

  clearAddOn() {
    while (this.addOns.length !== 0) {
      this.addOns.removeAt(0)
    }
  }

  submitAddOn() {
    for (let addOn of this.addOns.controls) {
      if (!addOn.value.service.trim() || !addOn.value.price || !addOn.value.quantity) {
        this.toast.fire({
          icon: 'error',
          title: 'Please enter valid data'
        });
        return;
      }
    }
    this.addOnForm.enable();
    this.addOnForm.value.addOns = this.addOnForm.value?.addOns.map(addon => {
      addon.price = +addon.price;
      addon.quantity = +addon.quantity;
      return addon;
    });
    // console.log(this.addOnForm.value.addOns);
    // return;
    const allData = {
      addons_data: this.addOnForm.value.addOns,
      applyTour_id: this.order._id,
      id: this.order.addons_id ? this.order.addons_id._id : ''
    };
    this.spinner.show();
    this.apiService.setAddonsData(allData).subscribe((res: any) => {
      //console.log(res);
      if (res.success) {
        this.clearAddOn();
        this.getAllOrder();
        this.spinner.hide();
      } else {
        this.clearAddOn();
        this.getAllOrder();
        this.toast.fire({
          icon: 'error',
          title: res.message
        });
        this.spinner.hide();
      }
    });
  }

  isCancelRemove(tourStartDate: string) {
    if (tourStartDate) {
      const [date, month, year] = tourStartDate.split('/');
      return (new Date(`${month}/${date}/${year}`).getTime() >= new Date().getTime());
    }
  }

  cancelOrder(tourId: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure want to cancel your tour?',
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Cancel Tour',
      cancelButtonText: 'Cancel Members'
    }).then((result) => {
      if (result.isConfirmed) {
        const cancelStatus = {
          payment_status: 'Cancelled',
          username: this.order?.user_id?.firstname,
          useremail: this.order?.user_id?.email
        };
        // console.log(cancelStatus);
        // return;
        this.spinner.hide();
        this.apiService
          .putCheckoutStatusUpdate(cancelStatus, tourId)
          .subscribe((res: any) => {
            if (res.success) {
              Swal.fire({
                title: 'Success',
                text: 'Tour cancelled successfully',
                icon: 'success',
              });
              this.spinner.hide();
              //this.router.navigateByUrl(`/user-order-history/particular-order/${this.orderId}`);
              this.router.navigateByUrl('/add-user-list');
            } else {
              Swal.fire({
                text: res.message,
                icon: 'error',
              });
              this.spinner.hide();
            }
          });
      }
      if (result.isDismissed) {

        (<any>$('#cancel-members')).modal('show');

      }
    });
  }

  get isCancelled(): boolean {
    return this.n - (this.n - this.order?.travel_no_of_traveler) === 1 && this.status === 'Cancelled';
  }
  
  cancelMembers(id: string) {
    let e = id
    this.orderId = e;
    //console.log(this.order)
    if (this.order.travel_no_of_traveler !== 1) {

      //.log(this.order.travel_no_of_traveler)
      // console.log(e, "valuee")

      let pending_amt = this.order.totalAmount - this.order.amountPaid;
      //console.log(pending_amt)
      this.amt = pending_amt / this.order.travel_no_of_traveler;
      this.order.totalAmount = this.order.totalAmount - this.amt;
      this.order.travel_no_of_traveler = this.order.travel_no_of_traveler - 1;
      this.count = this.count + 1;
      var data1 = {
        orderId: this.orderId,
        noOfTraveler: this.order.travel_no_of_traveler,
        totalAmt: this.order.totalAmount
      }
      //console.log(data1);
      this.spinner.show();
      this.apiService.updatecancelMembers(data1).subscribe((res: any) => {
        if (res.success) {
          console.log("successfully")
          if (res.success) {
            Swal.fire({
              title: 'Success',
              text: 'Member cancelled successfully',
              icon: 'success',
            });
            this.spinner.hide();
            this.router.navigateByUrl(`/user-order-history/particular-order/${this.orderId}`);
            (<any>$('#cancel-members')).modal('hide');
          } else {
            Swal.fire({
              text: res.message,
              icon: 'error',
            });
            this.spinner.hide();
          }

        }
      })
    }
    else {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to cancel the Tour?',
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.isConfirmed) {
          const cancelStatus = {
            payment_status: 'Cancelled',
            username: this.order?.user_id?.firstname,
            useremail: this.order?.user_id?.email
          };
          // console.log(cancelStatus);
          // return;
          this.spinner.show();
          this.apiService
            .putCheckoutStatusUpdate(cancelStatus, id)
            .subscribe((res: any) => {
              if (res.success) {
                Swal.fire({
                  title: 'Success',
                  text: 'Tour cancelled successfully',
                  icon: 'success',
                });
                this.spinner.hide();
                //this.router.navigateByUrl(`/user-order-history/particular-order/${this.orderId}`);
                this.router.navigateByUrl('/add-user-list');
              } else {
                Swal.fire({
                  text: res.message,
                  icon: 'error',
                });
                this.spinner.hide();
              }
            });
        }
      })
    }


  }
  cancelMembersByName(record, id, num: number) {
    //console.log(record.name, id.applyTour_id)
    if (this.order.travel_no_of_traveler !== 1) {

      let pending_amt = this.order.totalAmount - this.order.amountPaid;
      //console.log(pending_amt)
      this.amt = pending_amt / this.order.travel_no_of_traveler;
      this.order.totalAmount = this.order.totalAmount - this.amt;
      this.order.travel_no_of_traveler = this.order.travel_no_of_traveler - 1;
      //console.log(this.order.totalAmount)
      var data1 = {
        applyTour_id: id.applyTour_id,
        name: record.name,
        noOfTraveler: num - 1,
        totalAmt: this.order.totalAmount
      }
      console.log(record,
        this.order?.travellers_id?.traveller_data)
      //this.order.travellers_id.traveller_data =this.order.travellers_id.traveller_data.filter(this.order?.travellers_id?.traveller_data[record.name])
      this.order.travellers_id.traveller_data.splice(this.order.travellers_id.traveller_data.indexOf(record), 1)

      //  user.splice(user.indexOf(recToRemove),1)
      console.log(
        this.order?.travellers_id?.traveller_data)
      this.apiService.updatecancelMembersByName(data1).subscribe((res: any) => {
        if (res.success) {
          Swal.fire({
            title: 'Success',
            text: 'Member cancelled successfully',
            icon: 'success',
          });
          this.router.navigateByUrl(`/user-order-history/particular-order/${this.orderId}`);

          (<any>$('#cancel-members')).modal('hide');
        } else {
          Swal.fire({
            text: res.message,
            icon: 'error',
          });
        }

      })
    } else {
      console.log(this.orderId)
      Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to cancel the Tour?',
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.isConfirmed) {
          const cancelStatus = {
            payment_status: 'Cancelled',
            username: this.order?.user_id?.firstname,
            useremail: this.order?.user_id?.email
          };
          // console.log(cancelStatus);
          // return;
          this.apiService
            .putCheckoutStatusUpdate(cancelStatus, this.orderId)
            .subscribe((res: any) => {
              if (res.success) {
                Swal.fire({
                  title: 'Success',
                  text: 'Tour cancelled successfully',
                  icon: 'success',
                });
                this.router.navigateByUrl(`/user-order-history/particular-order/${this.orderId}`);
                //this.router.navigateByUrl('/add-user-list');
              } else {
                Swal.fire({
                  text: res.message,
                  icon: 'error',
                });
              }
            });
        }
      })
    }

  }
  completeOrder(id, totalAmount, appliedCoupon, cgst, sgst, igst, isOuter, transctionCharge, cusGSTNo, cuscompanyName, cuscompanyAddress, bookingNote, payText, adminPay) {
    console.log(totalAmount, appliedCoupon, "coupon", this.order.isComplete, payText)
    console.log(totalAmount, this.order?.totalAmount, this.order?.amountPaid, this.totalAddonAmt, adminPay)
    console.log(this.order?.totalAmount - this.order?.amountPaid + this.totalAddonAmt - adminPay)
    if (payText == 'payAdmin') {
      if (!this.adminAmount || !adminPay) {
        this.toast.fire({
          icon: 'error',
          title: 'Please enter amount payable against the remaining amount'
        });
        return;
      }
      if (this.adminAmount <= 0 || adminPay <= 0) {
        this.toast.fire({
          icon: 'error',
          title: 'Please enter a valid amount'
        });
        return;
      }
      if (this.adminAmount > Math.ceil((this.order?.totalAmount - this.order?.amountPaid + this.totalAddonAmt)) || adminPay > Math.ceil((this.order?.totalAmount - this.order?.amountPaid + this.totalAddonAmt))) {
        // if (this.adminAmount > (this.order?.totalAmount - this.order?.amountPaid + this.totalAddonAmt) || adminPay > (this.order?.totalAmount - this.order?.amountPaid + this.totalAddonAmt)) {
        this.toast.fire({
          icon: 'error',
          title: 'Payable amount cannot be greater than remaining amount'
        });
        return;
      }
      const orderDetails = {
        username: this.order?.user_id?.firstname,
        useremail: this.order?.user_id?.email,
        user_id: this.order?.user_id?._id,
        id: this.order?._id,
        payment_status: (this.order?.totalAmount - this.order?.amountPaid + this.totalAddonAmt - adminPay) == 0 ? 'Complete' : 'Partial',
        // payment_status: 'Complete',
        total_amount: totalAmount,
        //addon_ammount: this.totalAddonAmt,
        amount_paid: payText == 'payAdmin' ? adminPay + this.order?.amountPaid : totalAmount + this.totalAddonAmt,
        order_id: 'OI' + (Math.floor(Math.random() * 1000000000000) + 1),
        transction_Id: 'TI' + (Math.floor(Math.random() * 1000000000000) + 1),
        receipt: 'Receipt #' + (Math.floor(Math.random() * 10000000) + 1),
        cgst: cgst,
        sgst: sgst,
        igst: igst,
        isOuter: isOuter,
        transctionCharge: transctionCharge,
        appliedCoupon: appliedCoupon,
        cusGSTNo: cusGSTNo,
        cuscompanyName: cuscompanyName,
        cuscompanyAddress: cuscompanyAddress,
        bookingNote: bookingNote,
        partial_payment: this.order.partial_payment + adminPay,
        bookingPrice: this.order.tour_id.bookPrice,
        isComplete: this.order.isComplete + 1,
      };
      //console.log(this.order, orderDetails);
      //return;
      this.spinner.show();
      this.apiService.booking(orderDetails).subscribe((res: any) => {
        if (res.success) {
          //console.log(res);
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          this.spinner.hide();
          let notificationData = {
            applyTour_id: this.order?._id,
            last_amount_paid: adminPay,
            is_new: true,
            status: "",
            payment_status: orderDetails?.payment_status
          };
          this.apiService.postNotification(notificationData).subscribe((res: any) => {
            if (res) {
              //console.log(res);
            };
          });
          (<any>$('#admin-pay-link')).modal('hide');
          this.router.navigateByUrl('/add-user-list');
          //this.router.navigateByUrl('/user-order-history/' + this.webID);
        } else {
          this.spinner.hide();
          console.log(res);
        }
      });
    } else if (payText == 'payNow') {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Did the amount fully paid?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          const orderDetails = {
            username: this.order?.user_id?.firstname,
            useremail: this.order?.user_id?.email,
            user_id: this.order?.user_id?._id,
            id: this.order?._id,
            payment_status: 'Complete',
            // payment_status: 'Complete',
            total_amount: totalAmount,
            //addon_ammount: this.totalAddonAmt,
            amount_paid: totalAmount + this.totalAddonAmt,
            order_id: 'OI' + (Math.floor(Math.random() * 1000000000000) + 1),
            transction_Id: 'TI' + (Math.floor(Math.random() * 1000000000000) + 1),
            receipt: 'Receipt #' + (Math.floor(Math.random() * 10000000) + 1),
            cgst: cgst,
            sgst: sgst,
            igst: igst,
            isOuter: isOuter,
            transctionCharge: transctionCharge,
            appliedCoupon: appliedCoupon,
            cusGSTNo: cusGSTNo,
            cuscompanyName: cuscompanyName,
            cuscompanyAddress: cuscompanyAddress,
            bookingNote: bookingNote,
            partial_payment: this.order.partial_payment,
            bookingPrice: this.order.tour_id.bookPrice,
            isComplete: this.order.isComplete + 1,
          };
          //console.log(this.order, orderDetails);
          //return;
          this.spinner.show();
          this.apiService.booking(orderDetails).subscribe((res: any) => {
            if (res.success) {
              //console.log(res);
              Swal.fire({
                text: res.message,
                icon: 'success',
              });
              this.spinner.hide();
              let notificationData = {
                applyTour_id: this.order?._id,
                last_amount_paid: this.order?.totalAmount - this.order?.amountPaid,
                is_new: true,
                status: "",
                payment_status: orderDetails?.payment_status
              }
              this.apiService.postNotification(notificationData).subscribe((res: any) => {
                if (res) {
                  console.log(res);
                }
              })
              this.router.navigateByUrl('/add-user-list');
              //this.router.navigateByUrl('/user-order-history/' + this.webID);
            } else {
              this.spinner.hide();
              console.log(res);
            }
          });
        }
      });
    }
  }

  getRoom(id: string) {
    if (this.allRoomData?.length > 0 && id !== "") {
      return this.allRoomData.filter((room: any) => room._id === id)[0].room_name;
    } else {
      return "-"
    }
  }

  getPackageDataPrice(packageTypePrice: string, packageType: string) {
    let packageType_price = JSON.parse(packageTypePrice);
    return packageType_price.filter(
      (pack: any) => pack.packType === packageType
    )[0]?.packPrice;
  }

  getRoomDataPrice(roomTypePrice: string, roomType: string) {
    let roomType_price = JSON.parse(roomTypePrice);
    return roomType_price.filter((room: any) => room.roomType === roomType)[0]?.roomPrice;
  }

  getBikeDataPrice(bikeTypePrice: string, bikeName: string, bikeType: string) {
    //console.log(bikeTypePrice, bikeName, bikeType);
    let bikeType_price = JSON.parse(bikeTypePrice);
    if (bikeType === "Solo bike") {
      return bikeType_price.filter((bike: any) => bike.soloType === bikeName)[0]?.soloPrice;
    } else {
      return bikeType_price.filter((bike: any) => bike.sharedType === bikeName)[0]?.sharedPrice;
    }
  }

  getBoardingPrice(getBoardingData: any, order: any) {
    const allBoadingDataWithPrices = JSON.parse(getBoardingData);
    const BoardPrice = allBoadingDataWithPrices.filter(
      (board: any) => board.bp === order?.travel_boarding_point?.boarding
    )[0]?.bp_price;
    return BoardPrice?.length === 1
      ? BoardPrice[0].singleBoardPrice
      : Object.values(
        BoardPrice.filter(
          (boardPrice: any) =>
            Object.keys(boardPrice)[0] === order?.boardingType
        )[0]
      )[0];
  }

  invoiceLogoImgToDataURL() {
    this.apiService
      .imgToData64('../../../assets/img/fdf-01 (1).png')
      .subscribe((blob) => {
        const reader = new FileReader();
        const binaryString = reader.readAsDataURL(blob);
        reader.onload = (event: any) => {
          this.logoImgInDateURL = event.target.result;
        };
        reader.onerror = (event: any) => {
          //console.error('File could not be read: ' + event.target.error.code);
        };
      });
  }

  sendDataToGenerateInvoicerefund(order) {
    console.log(order)
    var couponAmount = (+order?.appliedCoupon) ? order?.appliedCoupon : 0;
    var bpPriceArr = JSON.parse(order?.tour_id.boarding_point_price[0]);
    let boardingPoint = bpPriceArr.find(o => o.bp === order?.travel_boarding_point.boarding);
    if (boardingPoint.bp_type == 'single') {
      var boardingPointPrice = +boardingPoint.bp_price[0].singleBoardPrice;
    }
    else {
      //let boardingPointPriceArr = boardingPoint.bp_price.find(o => order?.boarding_point_type in o);
      let boardingPointPriceArr = boardingPoint.bp_price.find(o => Object.keys(o)[0] == order?.boardingType);
      //console.log(boardingPoint, order, boardingPointPriceArr)
      //console.log(boardingPointPriceArr,Object.keys(boardingPointPriceArr)[0]);
      var boardingPointPrice = Number(boardingPointPriceArr[Object.keys(boardingPointPriceArr)[0]]);
    }
    let ref_amt = (order?.finalPrice - (this.n * this.order.tour_id.bookPrice)) / this.n;
    let remin_amt = (order?.finalPrice - (this.n * this.order.tour_id.bookPrice));
    let alredy_paid = ((order?.amountPaid) - (this.n * this.order.tour_id.bookPrice));
    //console.log(this.order.tour_id.bookPrice, this.order.partial_payment)
    let myInvoice = `
    <!DOCTYPE html>
<html>
<head>
<style>
  thead tr
  {
      background:#eee!important;
  }
  thead tr th
  {
    background: #eee;
    font-size: 16px
  }
  h5,h6
  {
    font-weight: bold;
    font-size: 16px;
    padding-left: 5px;
  }
  p,span,td
  {
    font-size:16px;
    margin-top:2px!important;
    margin-bottom: 2px!important;
    padding-left: 5px;
  }
  </style>
</head>
<body>

    <table style="border: none;" border="0" cellspacing="0" cellpadding="0">
      <tbody style="border: none;">
        <tr style="width: 50.5%;border: none;">
          <td>
          <div id="logo" style="margin-top: 8px;">
            <img src="${this.logoImgInDateURL}" style="width: 225px; height: 60px;"/>
          </div>
          </td>
          <td>
            <div id="company" style="text-align: right;margin-top:-100px;position: absolute;right: 0%;top: 0%;">
              <h3 class="name" style="color:#dc1b5e;">${order?.payment_status == "Complete" || order?.payment_status == "Cancelled" ? "Sales Return Tax Invoice" : "Proforma Invoice"}</h3>
              <h6 class="name"  >GLABOL EXPEDITION PVT. LTD.</h6>
              <div>1881 D Sudama Nagar, Indore (m.p)<br/>
               www.glabol.com | info@glabol.com<br/>
              </div>
              <div>GST No.: 23AAJCG2630E1Z3</div>
              <div>SAC Code: 998555</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <header style="display: flex; justify-content: center; align-items: center">
      <img src="${this.logoImgInDateURL}" style="width: 125px; height: 30px;"/>
      <span>THIS</span>
    </header> -->
    <main>
    <table border="0" cellspacing="0" cellpadding="0" style="width: 100%; margin-bottom: 10px;">
     <tbody style="width: 100%">
<tr style=" width: 33.33%">
    		<td rowspan="3">
            
          <h5>Billed To <br> ${order?.user_id?.firstname} ${order?.user_id?.lastname}</h5>
          <span>${order?.user_id?.mobile}</span>
          <span>${order?.user_id?.email ? order?.user_id?.email : ''}</span>`;

    if (order?.cusGSTNo) {
      myInvoice += ` <p>GST No.: ${order?.cusGSTNo}</p>`
    }

    if (order?.cuscompanyName) {
      myInvoice += `<p>Company Name: ${order?.cuscompanyName}</p>`
    }
    if (order?.cuscompanyAddress) {
      myInvoice += `<p>Company Address: ${order?.cuscompanyAddress}</p>`
    }

    let reminderDate = order?.tourReminderEndDate
    //console.log(new Date(reminderDate).toISOString().split('T')[0], reminderDate)

    myInvoice += `
    <h6> Date of Booking - ${new Date(order?.created_at)
        .toISOString()
        .split('T')[0]
        .split('-')
        .reverse()
        .join('-')}</h6>
    <h6> Date of Invoice - ${new Date(order?.updated_at)
        .toISOString()
        .split('T')[0]
        .split('-')
        .reverse()
        .join('-')}</h6></td>
        	<td style="text-align: center;">
            	<h5> Order ID </h5> ${order?.order_id}
            </td>
            <td rowspan="3" style="text-align: center;"><br><br><br>
            	Total Amount <h5 style= "color:#dc1b5e;"> ₹${order?.refundAmount.toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</h5>
            </td>
        </tr>
    	<tr style=" width: 33.33%">
        	<td style="text-align: center;">
            <h5>	Transaction ID </h5>${order?.transction_Id}
            </td>
        </tr>
        <tr style=" width: 33.33%">
        	<td style="text-align: center;">
          <h5> ${order?.payment_status == "Complete" || order?.payment_status == "Cancelled" ? "Tax Invoice ID" : "Proforma Invoice ID"} </h5>`

    if (order?.payment_status == "Complete") {
      myInvoice += `${order?.complete_booking_id ? order?.complete_booking_id : order?._id}`
    } else if (order?.payment_status == "Cancelled") {
      myInvoice += `${order?.cancel_booking_id ? order?.cancel_booking_id : ''}<br><span>(Reference ID: ${order?.complete_booking_id ? order?.complete_booking_id : order?.booking_id})</span>`
    } else {
      myInvoice += `${order?.booking_id ? order?.booking_id : order?._id}`
    }

    myInvoice += `<!-- ${order?.payment_status == "Complete" || order?.payment_status == "Cancelled" ? order?.complete_booking_id ? order?.complete_booking_id : order?._id : order?.booking_id ? order?.booking_id : order?._id} 
          ${order?.booking_id ? order?.booking_id : order?._id} -->  
            </td>
        </tr>
    </tbody>
</table>
      
      <table border="0" cellspacing="0" cellpadding="0" style="width: 100%; margin-bottom: 10px;">
        <thead style="width: 100%">
          <tr style="color:#dc1b5e; width: 31%">
          <th class="desc" style="width: ${order?.payment_status == "Partial" ? '45%' : '100%'}">Trip Details</th>
         `

    myInvoice += `</tr>
        </thead>
        <tbody style="width: 100%">
          <tr>
            <td><h5> ${order?.tour_id?.title} Trip</h5>
            <p>Batch: ${order?.travel_date} </p></td>
          `
  
    myInvoice += `
      </td>
  </tr>
</tbody>
</table>

<table border="0" cellspacing="0" cellpadding="0" style="width: 100%; margin-bottom: 10px;">
  <thead style="width: 100%">
    <tr style="color:#dc1b5e; width: 30%">
    <th class="desc" style="width:70%">Descrption</th>
    <th style="text-align: center;" class="unit" style="width:25%">Total</th>`

    myInvoice += `</tr>
  </thead>
  <tbody style="width: 100%">
    <tr>
      <td>  Refund Amount
      </td>
      <td style="text-align: center;">₹${order?.refundAmount.toLocaleString('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}</td>
    <tr>
      <td style="font-weight: bold" > Total Amount Refund (*Tax Included in Amount)
      </td>
      <td style="text-align: center;">₹${order?.refundAmount.toLocaleString('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}</td>`

    myInvoice += `
        </h6>
            </td>
          </tr>
          
          </tbody>
          </table>
      <br />
      <div id="thanks" style="text-align: center;">Thank you!</div>
      <br />
      <div id="notices">`;

    if (order?.bookingNote) {
      myInvoice += `
          <div>NOTE</div>
          <div class="notice" style="white-space: pre-wrap;">${order?.bookingNote}</div>
          <br />`;
    }
    myInvoice += `
        <div>TERMS & CONDITIONS:</div>
          <div class="notice">
            1. All disputes are subjected to Indore Jurisdiction only.
          </div>
        
      </div>
    </main>
  </body>
</html>
    `;
    // console.log(myInvoice);
    //html to pdf format
    var html = htmlToPdfmake(myInvoice, { tableAutoSize: true });
    // console.error(html);
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).open();

  }

  sendDataToGenerateInvoice(order) {
    console.log(order)
    var couponAmount = (+order?.appliedCoupon) ? order?.appliedCoupon : 0;
    var bpPriceArr = JSON.parse(order?.tour_id.boarding_point_price[0]);
    let boardingPoint = bpPriceArr.find(o => o.bp === order?.travel_boarding_point.boarding);
    if (boardingPoint.bp_type == 'single') {
      var boardingPointPrice = +boardingPoint.bp_price[0].singleBoardPrice;
    }
    else {
      //let boardingPointPriceArr = boardingPoint.bp_price.find(o => order?.boarding_point_type in o);
      let boardingPointPriceArr = boardingPoint.bp_price.find(o => Object.keys(o)[0] == order?.boardingType);
      //console.log(boardingPoint, order, boardingPointPriceArr)
      //console.log(boardingPointPriceArr,Object.keys(boardingPointPriceArr)[0]);
      var boardingPointPrice = Number(boardingPointPriceArr[Object.keys(boardingPointPriceArr)[0]]);
    }

    // for new format
    // <br>
    // <table border="0" cellspacing="0" cellpadding="0" style="width: 100%">
    // <thead style="width: 100%">
    //       <tr style="color:#dc1b5e; width: 20%">
    //         <th class="desc" style="width: 33.33%">Descripiton</th>
    //         <th class="unit" style="width: 33.33%">No. Of Travellers</th>
    //         <th class="total" style="width: 33.33%">Reminder Date</th>
    //       </tr>
    //     </thead>
    //  <tbody>
    //   <tr style=" width: 33.33%">
    //     <td>
    //       <h5>
    //        ${order?.tour_id?.title}&nbsp;
    //       </h5>
    //       <p>Date: ${order?.travel_date}</p>
    //     </td>
    //     <td>
    //     <br>
    //       <h5 style="text-align: center;">
    //        ${order?.travel_no_of_traveler}
    //       </h5>
    //     </td>
    //     <td>
    //       <h3>
    //        ${order?.tourReminderEndDate}
    //       </h3>
    //     </td>
    //   </tr>
    //  </tbody>
    // </table>



    let ref_amt = (order?.finalPrice - (this.n * this.order.tour_id.bookPrice)) / this.n;
    let remin_amt = (order?.finalPrice - (this.n * this.order.tour_id.bookPrice));
    let alredy_paid = ((order?.amountPaid) - (this.n * this.order.tour_id.bookPrice));
    //console.log(this.order.tour_id.bookPrice, this.order.partial_payment)
    let myInvoice = `
    <!DOCTYPE html>
<html>
<head>
<style>
  thead tr
  {
      background:#eee!important;
  }
  thead tr th
  {
    background: #eee;
    font-size: 16px
  }
  h5,h6
  {
    font-weight: bold;
    font-size: 16px;
    padding-left: 5px;
  }
  p,span,td
  {
    font-size:16px;
    margin-top:2px!important;
    margin-bottom: 2px!important;
    padding-left: 5px;
  }
  </style>
</head>
<body>
    
    <!-- <header class="clearfix" style="display: flex; align-items: flex-start; justify-content: center; position: relative;">
      <div id="logo" style="margin-top: 8px;">
        <img src="${this.logoImgInDateURL}" style="width: 280px; height: 75px;"/>
      </div>
      <div id="company" style="text-align: right;margin-top:-100px;position: absolute;right: 0%;top: 0%;">
        <h2 class="name" style="color:#dc1b5e;">${order?.payment_status == "Complete" || order?.payment_status == "Cancelled" ? "Tax Invoice" : "Proforma Invoice"}</h2>
        <h6 class="name"  >GLABOL EXPEDITION PVT. LTD.</h6>
        <div>1881 D Sudama Nagar, Indore (m.p) <br/>
        +91 89622 45021 | info@glabol.com<br/>
        </div>
        <div>GST No.: 23AAJCG2630E1Z3</div>
        <div>SAC Code: 998555</div>
      </div>
    </header> -->
    <table style="border: none;" border="0" cellspacing="0" cellpadding="0">
      <tbody style="border: none;">
        <tr style="width: 50.5%;border: none;">
          <td>
          <div id="logo" style="margin-top: 8px;">
            <img src="${this.logoImgInDateURL}" style="width: 225px; height: 60px;"/>
          </div>
          </td>
          <td>
            <div id="company" style="text-align: right;margin-top:-100px;position: absolute;right: 0%;top: 0%;">
              <h2 class="name" style="color:#dc1b5e;">${order?.payment_status == "Complete" || order?.payment_status == "Cancelled" ? "Tax Invoice" : "Proforma Invoice"}</h2>
              <h6 class="name"  >GLABOL EXPEDITION PVT. LTD.</h6>
              <div>1881 D Sudama Nagar, Indore (m.p)<br/>
               www.glabol.com | info@glabol.com<br/>
              </div>
              <div>GST No.: 23AAJCG2630E1Z3</div>
              <div>SAC Code: 998555</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <header style="display: flex; justify-content: center; align-items: center">
      <img src="${this.logoImgInDateURL}" style="width: 125px; height: 30px;"/>
      <span>THIS</span>
    </header> -->
    <main>
    <table border="0" cellspacing="0" cellpadding="0" style="width: 100%; margin-bottom: 10px;">
     <tbody style="width: 100%">
<tr style=" width: 33.33%">
    		<td rowspan="3">
            
          <h5>Billed To <br> ${order?.user_id?.firstname} ${order?.user_id?.lastname}</h5>
          <span>${order?.user_id?.mobile}</span>
          <span>${order?.user_id?.email ? order?.user_id?.email : ''}</span>`;

    if (order?.cusGSTNo) {
      myInvoice += ` <p>GST No.: ${order?.cusGSTNo}</p>`
    }

    if (order?.cuscompanyName) {
      myInvoice += `<p>Company Name: ${order?.cuscompanyName}</p>`
    }
    if (order?.cuscompanyAddress) {
      myInvoice += `<p>Company Address: ${order?.cuscompanyAddress}</p>`
    }

    let reminderDate = order?.tourReminderEndDate
    //console.log(new Date(reminderDate).toISOString().split('T')[0], reminderDate)

    myInvoice += `
    <h6> Date of Booking - ${new Date(order?.created_at)
        .toISOString()
        .split('T')[0]
        .split('-')
        .reverse()
        .join('-')}</h6>
    <h6> Date of Invoice - ${new Date(order?.updated_at)
        .toISOString()
        .split('T')[0]
        .split('-')
        .reverse()
        .join('-')}</h6></td>
        	<td style="text-align: center;">
            	<h5> Order ID </h5> ${order?.order_id}
            </td>
            <td rowspan="3" style="text-align: center;"><br><br><br>
            	Total Amount <h5 style= "color:#dc1b5e;"> ₹${(+order?.finalPrice - +couponAmount).toLocaleString(
          'en-IN'
        )}</h5>
            </td>
        </tr>
    	<tr style=" width: 33.33%">
        	<td style="text-align: center;">
            <h5>	Transaction ID </h5>${order?.transction_Id}
            </td>
        </tr>
        <tr style=" width: 33.33%">
        	<td style="text-align: center;">
          <h5> ${order?.payment_status == "Complete" || order?.payment_status == "Cancelled" ? "Tax Invoice ID" : "Proforma Invoice ID"} </h5>`

    if (order?.payment_status == "Complete") {
      myInvoice += `${order?.complete_booking_id ? order?.complete_booking_id : order?._id}`
    } else if (order?.payment_status == "Cancelled") {
      myInvoice += `${order?.cancel_booking_id ? order?.cancel_booking_id : ''}<br><span>(Reference ID: ${order?.complete_booking_id ? order?.complete_booking_id : order?.booking_id})</span>`
    } else {
      myInvoice += `${order?.booking_id ? order?.booking_id : order?._id}`
    }

    myInvoice += `<!-- ${order?.payment_status == "Complete" || order?.payment_status == "Cancelled" ? order?.complete_booking_id ? order?.complete_booking_id : order?._id : order?.booking_id ? order?.booking_id : order?._id} 
          ${order?.booking_id ? order?.booking_id : order?._id} -->  
            </td>
        </tr>
    </tbody>
</table>
      
      <table border="0" cellspacing="0" cellpadding="0" style="width: 100%; margin-bottom: 10px;">
        <thead style="width: 100%">
          <tr style="color:#dc1b5e; width: 31%">
          <th class="desc" style="width: ${order?.payment_status == "Partial" ? '45%' : '67.5%'}">Trip Details</th>
          <th class="unit" style="width: ${order?.payment_status == "Partial" ? '25%' : '32.5%'};">No. Of Travellers</th>`
    if (order?.payment_status == "Partial") {
      myInvoice += `<th class="total" style="width: 30%">Due Date</th>`
    }

    myInvoice += `</tr>
        </thead>
        <tbody style="width: 100%">
          <tr>
            <td><h5> ${order?.tour_id?.title} Trip</h5>
            <p>Batch: ${order?.travel_date} </p></td>
            <td style="text-align: center;"><br>${order?.travel_no_of_traveler}</td>`
    if (order?.payment_status == "Partial") {
      myInvoice += `<td style="text-align: center;"><br><span>${new Date(order?.tourReminderEndDate).toLocaleDateString().split("/")[1]}/${new Date(order?.tourReminderEndDate).toLocaleDateString().split("/")[0]}/${new Date(order?.tourReminderEndDate).toLocaleDateString().split("/")[2]}</span></td>`
    }

    myInvoice += `</tr>
        </tbody>
      </table>
      <table border="0" cellspacing="0" cellpadding="0" style="width: 100%; margin-bottom: 10px;">
                    <thead style="width: 100%">
                        <tr style="color:#dc1b5e; width: 31%">
                          <!-- <th class="no" style="width: 7%">S.No.</th> -->
                          <th class="desc" style="width: 40%">Descripiton</th>
                          <!-- <th class="unit" style="width: 15%"></th> -->
                          <th class="unit" style="width: 15%;">Quantity</th>
                          <th class="total" style="width: 15%">Price</th>
                          <th class="total" style="width: 30%">Total</th>
                        </tr>
                      </thead>
                      <tbody style="width: 100%">
                        <!-- <tr>
                          <td><h5> ${order?.tour_id?.title} Trip</h5>
                          <p>Batch: ${order?.travel_date} </p></td>
                          <td style="text-align: center;"><br>${order?.travel_no_of_traveler}</td>
                          <td></td>
                        </tr>
                        <tr style="color:#dc1b5e; width: 31%">
                          <td class="desc" style="width: 45%"></td>
                          <td class="unit" style="width: 25%; font-weight: bold;">Quantity</td>
                          <td class="total" style="width: 30%; font-weight: bold;">Price</td>
                        </tr> -->
                        
                        <tr style="width: 20%">
                          <!-- <td class="no" style="width: 7%">01</td> -->
                          <td class="desc" style="width: 38%;line-spacing:0.5px;">
                            <!-- <h5> ${order?.tour_id?.title} Trip</h5>
                            <p>Batch: ${order?.travel_date} </p> -->`
    if (order?.travel_packageType_data?.length !== 0) {
      myInvoice += `<h6 style="margin: 0px;"><u>Package Type</u></h6>`

      for (let packData of order?.travel_packageType_data) {
        if (packData.persons !== 0) {

          myInvoice += `<span>`;
          if (packData?.type !== 'normal') {
            myInvoice += `${packData.type} X `;
          }
          myInvoice += `${packData.packType}`;
          myInvoice += `</span>`;
        }
      }
    }
    if (order?.travel_roomSharing_data?.length !== 0) {
      // myInvoice += `<h6 style="margin: 0px; margin-top: 5px;">Room Sharing Type</h6>`

      function RoomSharing() {
        for (let roomData of order?.travel_roomSharing_data) {
          if (roomData.persons !== 0) {
            return myInvoice += `<h6 style="margin: 0px; margin-top: 5px;"><u>Room Sharing Type</u></h6>`;
          }
        }
      }
      RoomSharing();
      for (let roomData of order?.travel_roomSharing_data) {
        if (roomData.persons !== 0) {
          myInvoice += `<span>`;
          myInvoice += `${roomData.roomType}`;
          myInvoice += `</span>`;
        }
      }
    }
    myInvoice += `
                          <h6 style="margin: 0px; margin-top: 5px;"><u>Boarding Point</u></h6>
                            <span>${order?.travel_boarding_point.boarding} </span>`

    if (order?.boardingType) {
      myInvoice += `
                            <h6 style="margin: 0px; margin-top: 5px;"><u>Boarding Point Type</u></h6>
                              <span>${order?.boardingType}</span>`
    }

    myInvoice += `
                          </td>
                          <!-- <td style="text-align: center;margin-top:5px">${order?.travel_no_of_traveler}</td> -->
                          <td class="unit" style="text-align: center;">
                          <!-- <p>&nbsp;</p>
                          <p>&nbsp;</p> -->
                          <br>
                          `;

    if (order?.travel_packageType_data?.length !== 0) {
      // myInvoice += `<br>`

      for (let packData of order?.travel_packageType_data) {
        if (packData.persons !== 0) {

          // myInvoice += `₹${packData.packPrice} x ${packData.persons}`;
          myInvoice += `<div>${packData.persons}<div>`;
          // myInvoice += `<br>`;
        }
      }
    }
    let forStyle = false;
    if (order?.travel_roomSharing_data?.length !== 0) {
      myInvoice += `<br>`
      for (let roomData of order?.travel_roomSharing_data) {
        if (roomData.persons !== 0) {

          // myInvoice += `₹${roomData.roomPrice} x ${roomData.persons}`;
          myInvoice += `<div>${roomData.persons}</div>`;
          // myInvoice += `<br> <br>`;
        }
      }
      function forBr() {
        for (let roomData of order?.travel_roomSharing_data) {
          if (roomData.persons !== 0) {
            return myInvoice += `<div>&nbsp;</div>`;
          }
        }
      }
      forBr()
      // myInvoice += `<br>`;
    }
    myInvoice += `         
                        <!-- <br>
                        <span>₹${+boardingPointPrice} x ${order?.travel_no_of_traveler}<br> -->
                        <span>${order?.old_travel_no_of_traveler}<br>
                        </td>
                        <td>
                        <br>
                        <!-- <p>&nbsp;</p>
                        <p>&nbsp;</p> -->`;
    if (order?.travel_packageType_data?.length !== 0) {
      for (let packData of order?.travel_packageType_data) {
        if (packData.persons !== 0) {

          // myInvoice += `₹${Number(packData.packPrice) * Number(packData.persons)}`;
          myInvoice += `<div>₹${packData.packPrice} x ${packData.persons}</div>`;
          // myInvoice += `<br>`;
        }
      }
    }
    if (order?.travel_roomSharing_data?.length !== 0) {
      myInvoice += `<br>`

      for (let roomData of order?.travel_roomSharing_data) {
        if (roomData.persons !== 0) {

          // myInvoice += `₹${Number(roomData.roomPrice) * Number(roomData.persons)}`;
          myInvoice += `<div>₹${roomData.roomPrice} x ${roomData.persons}</div>`;
          // myInvoice += `<br>`;
        }
      }
      function forBr() {
        for (let roomData of order?.travel_roomSharing_data) {
          if (roomData.persons !== 0) {
            return myInvoice += `<br><div>&nbsp;</div>`;
          }
        }
      }
      forBr()
      // myInvoice += `<br><br>`
    }
    // myInvoice += `<br><span>₹${Number(boardingPointPrice) * Number(order?.travel_no_of_traveler)}<br>`;
    myInvoice += `<div>₹${+boardingPointPrice} x ${order?.old_travel_no_of_traveler}</div>
                          </td>`;

    myInvoice += `
                          <td>
                          <br>
                          <!-- <p>&nbsp;</p>
                          <p>&nbsp;</p> -->`;

    if (order?.travel_packageType_data?.length !== 0) {
      for (let packData of order?.travel_packageType_data) {
        if (packData.persons !== 0) {

          // myInvoice += `₹${Number(packData.packPrice) * Number(packData.persons)}`;
          myInvoice += `<div>₹${+packData.packPrice * +packData.persons}</div>`;
          // myInvoice += `<br>`;
        }
      }
    }
    if (order?.travel_roomSharing_data?.length !== 0) {
      myInvoice += `<br>`

      for (let roomData of order?.travel_roomSharing_data) {
        if (roomData.persons !== 0) {

          // myInvoice += `₹${Number(roomData.roomPrice) * Number(roomData.persons)}`;
          myInvoice += `<div>₹${+roomData.roomPrice * +roomData.persons}</div>`;
        }
      }
      function forBr() {
        for (let roomData of order?.travel_roomSharing_data) {
          if (roomData.persons !== 0) {
            return myInvoice += `<br><div>&nbsp;</div>`;
          }
        }
      }
      forBr()
      // myInvoice += `<br><br>`;
    }
    // myInvoice += `<br><span>₹${Number(boardingPointPrice) * Number(order?.travel_no_of_traveler)}<br>`;
    myInvoice += `<div>₹${+boardingPointPrice * +order?.old_travel_no_of_traveler}</div>
                          </td>`;

    myInvoice += `
                      </tr>
                    </tbody>
                    <tfoot style="width:100%">
                    
                    <tr style="width:20%">
                      <td></td>
                      <td colspan="2">Final Price</td>
                      <td class="total">₹${order?.finalPrice.toLocaleString('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}</td>
                    </tr>
                    <tr style="width:20%">
                      <td></td>
                      <td colspan="2">Coupon Discount</td>
                      <td>₹${+couponAmount}</td>
                    </tr>
                  
                      <tr style="width:20%">
                        <td></td>
                        <td colspan="2">Total Package Cost</td>
                        <td>₹${(+order?.finalPrice - +couponAmount).toFixed(2)}</td>
                      </tr>`;


    //   if (order?.isOuter){
    //     myInvoice += `
    //     <tr style="width:20%">
    //       <td colspan="2"></td>
    //       <td colspan="2">Tour cost</td>
    //       <td>₹${((order?.finalPrice - +order?.appliedCoupon) / (1 + (order?.igst * 0.01))).toFixed(
    //   2
    // )}</td>
    //     </tr>
    //     `;

    //   }else{
    //     myInvoice += `
    //     <tr style="width:20%">
    //       <td colspan="2"></td>
    //       <td colspan="2">Tour cost</td>
    //       <td>₹${((order?.finalPrice - +order?.appliedCoupon) / (1 + (order?.cgst * 0.01) + (order?.sgst * 0.01))).toFixed(
    //   2
    // )}</td>
    //     </tr>
    //     `;

    //   }

    // `<tr style="width:20%">
    //   <td colspan="2"></td>
    //   <td colspan="2">Transction Charge (${
    //     order?.transctionCharge
    //   }%)</td>
    //   <td>₹${(
    //     +order?.transctionCharge *
    //     0.01 *
    //     (order?.finalPrice - +order?.appliedCoupon)
    //   ).toLocaleString('en-IN')}</td>
    // </tr>`;

    // if (order?.isOuter) {
    //   myInvoice += `<tr style="width:20%">
    //         <td colspan="2"></td>
    //         <td colspan="2">IGST  @ ${order?.igst}% </td>
    //         <td>₹${(
    //           +order?.igst *
    //           0.01 *
    //           (order?.finalPrice - +order?.appliedCoupon) / (1 + (order?.igst *
    //             0.01))
    //         ).toFixed(2)}</td>
    //       </tr>`;
    // } else {
    //   myInvoice += `<tr style="width:20%">
    //         <td colspan="2"></td>
    //         <td colspan="2">CGST  @ ${order?.cgst}% </td>
    //         <td>₹${(
    //           order?.cgst * 0.01 * ((+order?.finalPrice - +order?.appliedCoupon) / (1 + (order?.cgst * 0.01) + (order?.sgst * 0.01)))
    //         ).toFixed(2)}</td>
    //       </tr>
    //       <tr style="width:20%">
    //         <td colspan="2"></td>
    //         <td colspan="2">SGST  @ ${order?.sgst}%</td>
    //         <td>₹${(
    //           order?.sgst * 0.01 * ((+order?.finalPrice - +order?.appliedCoupon) / (1 + (order?.cgst * 0.01) + (order?.sgst * 0.01)))
    //         ).toFixed(2)}</td>
    //       </tr>`;
    // }



    console.log(ref_amt * (this.n - order?.travel_no_of_traveler), remin_amt - alredy_paid)
    // ref_amt!==(remin_amt-alredy_paid)){
    let traveler = Math.round((remin_amt - alredy_paid) / ref_amt);
    console.log(traveler, this.n - traveler, "---", this.n - order?.travel_no_of_traveler)
    console.log(this.n, this.order.tour_id.bookPrice, this.order.finalPrice, this.totalPayAmt)
    console.log(order?.travel_no_of_traveler !== this.n, "&&", order?.amountPaid, this.totalpaidAddon, "&& ", order?.isComplete <= 1)
    if ((order?.travel_no_of_traveler !== this.n) && ((order?.amountPaid - this.totalpaidAddon) < order?.totalAmount) && (order?.isComplete <= 1)) {

      myInvoice += `<tr style="width:20%">
                          <td>*Tax Included in Amount</td>
                        
                          <td style="font-weight: bold" colspan="2">Grand Total</td>
                          <td style="font-weight: bold">₹${(order?.finalPrice - +couponAmount).toLocaleString(
        'en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
      )}</td>
                        </tr>`
      if (order?.amountPaid === this.totalPayAmt) {
        myInvoice += `
                          <tr style="width:20%">
                          <td>*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
                          <td colspan="2">Amount after Member Cancel</td>
                          <td>₹${(order?.totalAmount).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td>
                        </tr>
                          <!-- <tr style="width:20%">
                          <td></td>
                          <td colspan="2">Amount Paid</td>
                          <td>₹${(order?.totalAmount).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td>
                        </tr> -->
                        `;
        /*
          <tr style="width:20%">
          <td colspan="2"></td>
          <td colspan="2">Remaining Amount</td>
          <td>₹${((order?.finalPrice - +couponAmount) - ( this.totalPayAmt-this.totalpaidAddon)).toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}</td>
        </tr>`;
    /* if ((ref_amt * (this.n - order?.travel_no_of_traveler)) !== (remin_amt - alredy_paid)) {
        let traveler = Math.round((remin_amt - alredy_paid) / ref_amt);*/
        /* myInvoice += `
            <tr style="width:20%">
              <td colspan="2">*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
              <td colspan="2">Total Amount to pay</td>
              <td>₹${(order?.totalAmount -( this.totalPayAmt-this.totalpaidAddon)).toLocaleString('en-IN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}</td>
            </tr>`;*/
      }
      else if (order?.amountPaid !== this.totalPayAmt + (this.n * this.order.tour_id.bookPrice)) {
        myInvoice += `
                        <tr style="width:20%">
                        <td>*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
                        <td colspan="2">Amount after Member Cancel</td>
                        <td>₹${((order?.totalAmount)).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td>
                      </tr>
                        <!-- <tr style="width:20%">
                        <td></td>
                        <td colspan="2">Amount Paid</td>
                        <td>₹${((order?.totalAmount)).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td>
                      </tr> -->
                      `;/*
                        <tr style="width:20%">
                        <td colspan="2"></td>
                        <td colspan="2">Remaining Amount</td>
                        <td>₹${((order?.finalPrice - +couponAmount) - ( this.totalPayAmt)).toLocaleString('en-IN', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}</td>
                      </tr>`;
                  /* if ((ref_amt * (this.n - order?.travel_no_of_traveler)) !== (remin_amt - alredy_paid)) {
                      let traveler = Math.round((remin_amt - alredy_paid) / ref_amt);*/
        /*  myInvoice += `
            <tr style="width:20%">
              <td colspan="2">*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
              <td colspan="2">Total Amount to pay</td>
              <td>₹${(order?.totalAmount - ( this.totalPayAmt)).toLocaleString('en-IN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}</td>
            </tr>`;*/
      }
      else {
        myInvoice += `
                        <tr style="width:20%">
                        <td>*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
                        <td colspan="2">Amount after Member Cancel</td>
                        <td>₹${((order?.totalAmount)).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td>
                      </tr>
                        <!-- <tr style="width:20%">
                        <td></td>
                        <td colspan="2">Amount Paid</td>
                        <td>₹${((order?.totalAmount)).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td>
                      </tr> -->
                      `;
        /*   <tr style="width:20%">
            <td colspan="2"></td>
            <td colspan="2">Remaining Amount</td>
            <td>₹${((order?.finalPrice - +couponAmount) - ((this.n * this.order.tour_id.bookPrice) + this.totalPayAmt)).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td>
          </tr>`;
      /* if ((ref_amt * (this.n - order?.travel_no_of_traveler)) !== (remin_amt - alredy_paid)) {
          let traveler = Math.round((remin_amt - alredy_paid) / ref_amt);*/
        /*  myInvoice += `
            <tr style="width:20%">
              <td colspan="2">*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
              <td colspan="2">Total Amount to pay</td>
              <td>₹${(order?.totalAmount - ((this.n * this.order.tour_id.bookPrice) + this.totalPayAmt)).toLocaleString('en-IN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}</td>
            </tr>`;*/

      }
      /* myInvoice += `
              <tr style="width:20%">
                <td colspan="2">*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
                <td colspan="2">Total Amount to pay</td>
                <td>₹${(order?.totalAmount - (order?.amountPaid)).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td>
              </tr>`;*/

    }
    else if (order.payment_status === "Cancelled") {
      if (this.n - (this.n - order?.travel_no_of_traveler) === 1) {
        myInvoice += `<tr style="width:20%">
                                    <td>*Tax Included in Amount</td>
                                    <td style="font-weight: bold" colspan="2">Grand Total</td>
                                    <td style="font-weight: bold">₹${(order?.finalPrice - +couponAmount).toLocaleString(
          'en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
        )}</td>
                                  </tr>
                                  <tr style="width:20%">
                                    <td></td>
                                    <td colspan="2">Total Amount to Pay</td>
                                    <td>₹0</td>
                                  </tr>`;
      } else if (this.n != order?.travel_no_of_traveler) {
        myInvoice += `<tr style="width:20%">
                <td>*Tax Included in Amount</td>
                <td style="font-weight: bold" colspan="2">Grand Total</td>
                <td style="font-weight: bold">₹${(order?.finalPrice - +couponAmount).toLocaleString(
          'en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
        )}</td>
          </tr>

              <tr style="width:20%">
              <td>*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
          <td colspan="2">Amount after Member Cancel</td>
          <td>₹${(order?.totalAmount).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td>
          </tr>`}
      else {
        myInvoice += `<tr style="width:20%">
                                    <td>*Tax Included in Amount</td>
                                    <td style="font-weight: bold" colspan="2">Grand Total</td>
                                    <td style="font-weight: bold">₹${(order?.finalPrice - +couponAmount).toLocaleString(
          'en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
        )}</td>
        </tr>

                                 <!-- <tr style="width:20%">
                                  <td>*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
                          <td colspan="2">Amount after Member Cancel</td>
                          <td>₹${(order?.totalAmount).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td>
                          </tr> -->
                                 <!-- </tr>
                                  <tr style="width:20%">
                                    <td></td>
                                    <td colspan="2">Total Amount to Pay</td>
                                    <td>₹${(order?.totalAmount - order?.amountPaid).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td> 
                                  </tr> -->`;
      }
    }
    //add on after full payment
    else if (((order?.amountPaid - this.totalpaidAddon) > order?.totalAmount || order?.isComplete > 1) && (this.n == order?.travel_no_of_traveler)) {
      if (this.n == order?.travel_no_of_traveler) {
        myInvoice += `<tr style="width:20%">
                      <td>*Tax Included in Amount</td>
                      <td style="font-weight: bold" colspan="2">Grand Total</td>
                      <td style="font-weight: bold">₹${(order?.finalPrice - +couponAmount).toLocaleString(
          'en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
        )}</td>
                    </tr>
                    <!-- <tr style="width:20%">
                      <td></td>
                      <td colspan="2">Total Amount Paid</td>
                      <td>₹${(order?.totalAmount).toLocaleString('en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</td>
                    </tr> -->`;
      }
    }

    //ends

    else if (((order?.amountPaid - this.totalpaidAddon) >= order?.totalAmount || order?.isComplete > 1) && (this.n !== order?.travel_no_of_traveler)) {

      myInvoice += `<tr style="width:20%">
                                      <td>*Tax Included in Amount</td>
                                      <td style="font-weight: bold" colspan="2">Grand Total</td>
                                      <td style="font-weight: bold">₹${(order?.finalPrice - +couponAmount).toLocaleString(
        'en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
      )}</td>
                                    </tr>
                                    <tr style="width:20%">
                                    <td>*After cancel ${(this.n - order?.travel_no_of_traveler)} member </td>
                        <td colspan="2">Amount after Member Cancel</td>
                        <td>₹${(order?.totalAmount).toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}</td>
                      </tr>
                                    <!-- <tr style="width:20%">
                                    <td></td>
                        <td colspan="2">Amount Paid</td>
                        <td>₹${(order?.totalAmount).toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}</td>
                      </tr> -->
                    `;
    }
    else {
      myInvoice += `<tr style="width:20%">
                    <td>*Tax Included in Amount</td>
                        
                          <td style="font-weight: bold" colspan="2">Grand Total</td>
                          <td style="font-weight: bold">₹${(order?.finalPrice - +couponAmount).toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}</td>
                        </tr>
                        <!-- <tr style="width:20%">
                        <td></td>
                        <td colspan="2">Total Amount Paid</td>
                        <td>₹${(order?.totalAmount).toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}</td>
                      </tr> -->`;

    }

    if (order?.isRefundedOnCancel) {
      //   if(order?.isOuter){
      //     myInvoice += `<tr style="width:20%">
      //     <td colspan="2"></td>
      //     <td colspan="2">Refund Tour Amount</td>
      //     <td>₹${(((order?.refundAmount) / (1 + (order?.igst * 0.01)))).toFixed(
      //       2
      //     )}</td>
      //   </tr>`;
      //   }else{
      //     myInvoice += `
      //     <tr style="width:20%">
      //       <td colspan="2"></td>
      //       <td colspan="2">Tour cost</td>
      //       <td>₹${(((order?.refundAmount) / (1 + (order?.cgst * 0.01) + (order?.sgst * 0.01)))).toFixed(
      //   2
      // )}</td>
      //     </tr>
      //     `;
      //   }
      // if (order?.isOuter) {
      //   myInvoice+= `<tr style="width:20%">
      //     <td colspan="2"></td>
      //     <td colspan="2">IGST  @ ${order?.igst}% </td>
      //     <td>₹${((order?.igst*0.01*(+order?.refundAmount)) /(1+(order?.igst*0.01))).toFixed(2)}</td>
      //   </tr>`;
      // } else {
      //   myInvoice+= `<tr style="width:20%">
      //     <td colspan="2"></td>
      //     <td colspan="2">CGST  @ ${order?.cgst}% </td>
      //     <td>₹${(((order?.cgst*0.01*(+order?.refundAmount)) / (1+(order?.cgst*0.01)+(order?.sgst*0.01)))).toFixed(2)}</td>
      //   </tr>
      //   <tr style="width:20%">
      //     <td colspan="2"></td>
      //     <td colspan="2">SGST  @ ${order?.sgst}%</td>
      //     <td>₹${(((order?.sgst*0.01*(+order?.refundAmount)) / (1+(order?.cgst*0.01)+(order?.sgst*0.01)))).toFixed(2)}</td>
      //   </tr>`;
      // }
      myInvoice += `<tr style="width:20%">
                          <td></td>
                          <td colspan="2">Refund Amount</td>
                          <td>₹${order?.refundAmount.toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}</td>
                        </tr>`;
    }

    myInvoice += `</tfoot>
                    </table>
                    <table border="0" cellspacing="0" cellpadding="0" style="width:100%; margin-bottom: 10px;">
                      <thead style="width:100%">
                        <tr style="color:#dc1b5e; width: 33.33%">
                          <th class="desc">Payment Status</th>
                          <th class="unit">Amount Already Paid</th>
                          <th class="unit">Remaining Amount To Pay</th>
                        </tr>
                      </thead>`;
    //add on after full payment
    if (order?.amountPaid > order?.totalAmount) {
      myInvoice += `
                      <tbody style="width:100%">
                        <tr style="text-align: center; width: 33.33%">
                          <td class="desc"><h6> Complete </h6></td>
                          <td class="unit"><h6> ₹${(order?.totalAmount).toFixed(2)} </h6></td>
                          <td class="unit"><h6> ₹ 0.00 </h6>
                          </td>
                        </tr>
                        
                        </tbody>
          </table>
      <br />
      <div id="thanks" style="text-align: center;">Thank you!</div>
      <br />
      <div id="notices">`;
    }
    //

    else if (order.payment_status === "Cancelled") {
      if (this.n - (this.n - order?.travel_no_of_traveler) === 1) {
        myInvoice += `
        <tbody style="width:100%">
          <tr style="text-align: center; width: 33.33%">
            <td class="desc"><h6> ${order?.payment_status} </h6></td>
            <td class="unit"><h6> ₹${(order?.amountPaid).toFixed(2)} </h6></td>
            <td class="unit"><h6> ₹${(order?.totalAmount - order?.amountPaid).toLocaleString(
          'en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
        )} </h6>
            </td>
          </tr>
          
          </tbody>
          </table>
      <br />
      <div id="thanks" style="text-align: center;">Thank you!</div>
      <br />
      <div id="notices">`;
      }
      else {
        myInvoice += `
        <tbody style="width:100%">
          <tr style="text-align: center; width: 33.33%">
            <td class="desc"><h6> ${order?.payment_status} </h6></td>
            <td class="unit"><h6> ₹${(order?.amountPaid).toFixed(2)} </h6></td>
            <td class="unit"><h6> ₹${(order?.totalAmount - order?.amountPaid).toLocaleString(
          'en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
        )} </h6>
            </td>
          </tr>
          
          </tbody>
          </table>
      <br />
      <div id="thanks" style="text-align: center;">Thank you!</div>
      <br />
      <div id="notices">`;
      }
    }
    else if (order.payment_status === 'Partial') {
      myInvoice += `
              <tbody style="width:100%">
                <tr style="text-align: center; width: 33.33%">
                  <td class="desc"><h6> ${order?.payment_status} </h6></td>
                  <td class="unit"><h6> ₹${(order?.amountPaid).toFixed(2)} </h6></td>
                  <td class="unit"><h6> ₹${(order?.totalAmount - order?.amountPaid).toLocaleString(
        'en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
      )} </h6>
                  </td>
                </tr>
                
                </tbody>
                </table>
            <br />
            <div id="thanks" style="text-align: center;">Thank you!</div>
            <br />
            <div id="notices">`;
    }
    else {
      myInvoice += `
        <tbody style="width:100%">
          <tr style="text-align: center; width: 33.33%">
            <td class="desc"><h6> ${order?.payment_status} </h6></td>
            <td class="unit"><h6> ₹${(order?.amountPaid).toFixed(2)}</h6></td>
            <td class="unit"><h6> ₹${(order?.totalAmount - order?.amountPaid).toLocaleString(
        'en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
      )} </h6>
            </td>
          </tr>
          
          </tbody>
          </table>
      <br />
      <div id="thanks" style="text-align: center;">Thank you!</div>
      <br />
      <div id="notices">`;
    }
    if (order?.bookingNote) {
      myInvoice += `
          <div>NOTE</div>
          <div class="notice" style="white-space: pre-wrap;">${order?.bookingNote}</div>
          <br />`;
    }
    myInvoice += `
        <div>TERMS & CONDITIONS:</div>
          <div class="notice">
            1. All disputes are subjected to Indore Jurisdiction only.
          </div>
        
      </div>
    </main>
  </body>
</html>
    `;
    // console.log(myInvoice);
    //html to pdf format
    var html = htmlToPdfmake(myInvoice, { tableAutoSize: true });
    // console.error(html);
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).open();
  }

  sendDataToGenerateAddonInvoice(order) {
    // console.log(order);
    let myInvoice = `
    <!DOCTYPE html>
<html>
<head>
</head>
<body>
    
    <header class="clearfix" style="content: ''; display: table; clear: both;">
      <div id="logo" style=""float: left; margin-top: 8px;>
      <img src="${this.logoImgInDateURL}" style="width: 300px; height: 75px" />
      </div>
      <div id="company" style="float: right; text-align: right; position:relative;top:0%">
        <h2 class="name" style="color:#dc1b5e;">${order?.payment_status == "Complete" || order?.payment_status == "Cancelled" ? "Tax Invoice" : "Proforma Invoice"}</h2>
        <h6 class="name"  >GLABOL EXPEDITION PVT. LTD.</h6>
        <div>1881 D Sudama Nagar, Indore (m.p) <br/>
         www.glabol.com | info@glabol.com<br/>
        </div>
        <div>GST No.: 23AAJCG2630E1Z3</div>
        <div>SAC Code: 998555</div>
       
      </div>
    </header>
    <br>
    <main>
    <br>
    <table border="0" cellspacing="0" cellpadding="0" style="width: 100%">
     <tbody style="width: 100%">
<tr style=" width: 33.33%">
    		<td rowspan="3">
            
            	<h5>Billed To <br> ${order?.user_id?.firstname} ${order?.user_id?.lastname}</h5>
          <span>${order?.user_id?.mobile}</span>
          <span>${order?.user_id?.email ? order?.user_id?.email : ''}</span>`
    if (order?.cusGSTNo) {
      myInvoice += ` <p>GST No.: ${order?.cusGSTNo}</p>`
    }

    if (order?.cuscompanyName) {
      myInvoice += `<p>Company Name: ${order?.cuscompanyName}</p>`
    }
    if (order?.cuscompanyAddress) {
      myInvoice += `<p>Company Address: ${order?.cuscompanyAddress}</p>`
    }
    myInvoice += `
    <h6> Date of Booking - ${new Date(order?.created_at)
        .toISOString()
        .split('T')[0]
        .split('-')
        .reverse()
        .join('-')}</h6>
    <h6> Date of Invoice - ${new Date(order?.updated_at)
        .toISOString()
        .split('T')[0]
        .split('-')
        .reverse()
        .join('-')}</h6>
            </td>
        	<td style="text-align: center;">
            	<h5> Order ID </h5> ${order?.order_id}
            </td>
            <td rowspan="3" style="text-align: center;"><br><br><br>
            	Total Add-on Amount <h5 style= "color:#dc1b5e;"> ₹${this.totalAddonAmt?.toLocaleString(
          'en-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
        )}</h5>
            </td>
        </tr>
    	<tr style=" width: 33.33%">
        	<td style="text-align: center;">
            <h5>	Transaction ID </h5>${order?.transction_Id}
            </td>
        </tr>
        <tr style=" width: 33.33%">
        	<td style="text-align: center;">
          <h5> Invoice ID </h5> ${order?.complete_booking_id ? order?.complete_booking_id : order?.booking_id}
            
            </td>
        </tr>
    </tbody>
</table>
      <br>
      <table border="0" cellspacing="0" cellpadding="0" style="width: 100%">
        <thead style="width: 100%">
          <tr style="color:#dc1b5e; width: 20%">
            <th class="no" style="width: 7%">S.No.</th>
            <th class="desc">Services</th>
            <th class="unit">Basic Price</th>
            <th class="unit" style="width: 13%">Quantity</th>
            <th class="unit">Status</th>
            <th class="total">Total</th>
          </tr>
        </thead>
        <tbody style="width: 100%">`;
    for (let [i, addon] of order?.addons_id?.addons_data.entries()) {
      myInvoice += `<tr style="width: 20%">
              <td class="no" style="width: 7%">${i + 1}</td>
              <td class="desc">${addon.service}</td>
              <td class="unit">₹${addon.price.toFixed(2)}</td>
              <td class="unit" style="width: 13%">${addon.quantity}</td>
              <td class="unit">${addon.status === 'paid' ? 'Paid' : 'Not Paid'}</td>
              <td class="total">₹${(addon.price * addon.quantity).toFixed(2)}</td>
            </tr>`
    }
    myInvoice += `</tbody>
        <tfoot style="width:100%">`;
    myInvoice += `
        <tr style="width:20%">
          <td colspan="3"></td>
          <td colspan="2">Total Add-on Cost</td>
          <td>₹${(order?.addons_id?.addons_data.reduce((total, num) => total + (num.price * num.quantity), 0)).toLocaleString(
      'en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
    )}</td>
        </tr>`;
    // if(order?.isOuter){
    //   myInvoice += `
    //   <tr style="width:20%">
    //     <td colspan="3"></td>
    //     <td colspan="2">Add-on Cost</td>
    //     <td>₹${(order?.addons_id?.addons_data.reduce((total, num) => total + (num.price * num.quantity) / (1+(order?.igst *0.01)), 0)).toLocaleString(
    //       'en-IN'
    //     )}</td>
    //   </tr>`;

    // }else{
    //   myInvoice += `
    //   <tr style="width:20%">
    //     <td colspan="3"></td>
    //     <td colspan="2">Add-on Cost</td>
    //     <td>₹${(order?.addons_id?.addons_data.reduce((total, num) => total + (num.price * num.quantity) /(1+(order?.cgst*0.01)+(order?.sgst*0.01)), 0)).toLocaleString(
    //       'en-IN'
    //     )}</td>
    //   </tr>`;
    // }

    // if (order?.isOuter) {
    //   myInvoice += `<tr style="width:20%">
    //         <td colspan="3"></td>
    //         <td colspan="2">IGST  @ ${order?.igst}% </td>
    //         <td>₹${(
    //           +order?.igst *
    //             0.01 *
    //             (order?.addons_id?.addons_data.reduce((total, num) => total + (num.price * num.quantity) / (1+(order?.igst *
    //               0.01)), 0))
    //         ).toLocaleString('en-IN')}</td>
    //       </tr>`;
    // } else {
    //   myInvoice += `<tr style="width:20%">
    //         <td colspan="3"></td>
    //         <td colspan="2">CGST  @ ${order?.cgst}% </td>
    //         <td>₹${(
    //           +order?.cgst *
    //             0.01 *
    //             (order?.addons_id?.addons_data.reduce((total, num) => total + (num.price * num.quantity) /(1+(order?.cgst *0.01 )+(order?.sgst * 0.01)), 0) )
    //         ).toLocaleString('en-IN')}</td>
    //       </tr>
    //       <tr style="width:20%">
    //         <td colspan="3"></td>
    //         <td colspan="2">SGST  @ ${order?.sgst}%</td>
    //         <td>₹${(
    //           +order?.sgst * 0.01 * (order?.addons_id?.addons_data.reduce((total, num) => total + (num.price * num.quantity) /(1+(order?.cgst *0.01 )+(order?.sgst * 0.01)), 0))
    //         ).toLocaleString('en-IN')}</td>
    //       </tr>`;
    // 
    myInvoice += `<tr style="width:20%">
            <td colspan="3">*Tax Included in Amount</td>
            <td colspan="2" style="font-weight: bold">Grand Total</td>
            <td style="font-weight: bold">₹${this.totalAddonAmt.toLocaleString(
      'en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
    )}</td>
          </tr>
          <!-- <tr style="width:20%">
            <td colspan="3"></td>
            <td colspan="2">Total Amount Paid</td>
            <td>₹${this.totalpaidAddon.toLocaleString('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}</td>
          </tr> -->`;

    // if (order?.isRefundedOnCancel) {
    //   myInvoice += `<tr style="width:20%">
    //       <td colspan="3"></td>
    //       <td colspan="2">Refund Amount</td>
    //       <td>₹${order?.refundAmount.toLocaleString('en-IN')}</td>
    //     </tr>`;
    // }

    myInvoice += `</tfoot>
      </table>
      <br>
       <table border="0" cellspacing="0" cellpadding="0" style="width:100%">
        <thead style="width:100%">
          <tr style="color:#dc1b5e; width: 33.33%">
            <th class="desc">Payment Status</th>
            <th class="unit">Amount Already Paid</th>
            <th class="unit">Remaining Amount To Pay</th>
          </tr>
        </thead>
        <tbody style="width:100%">
          <tr style="text-align: center; width: 33.33%">
            <td class="desc"><h6> ${order?.payment_status} </h6></td>
            <td class="unit"><h6> ₹${(this.totalAddonAmt - this.totalUnpaidAddon).toLocaleString('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })} </h6></td>
            <td class="unit"><h6> ₹${this.totalUnpaidAddon.toLocaleString('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })} </h6>
            </td>
          </tr>
          
          </tbody>
          </table>
      <br />
      <div id="thanks" style="text-align: center;">Thank you!</div>
      <br />
      <div id="notices">`;
    if (order?.bookingNote) {
      myInvoice += `
          <div>NOTE:</div>
          <div class="notice" style="white-space: pre-wrap;">${order?.bookingNote}</div>
          <br />`;
    }
    if (this.addon_notes) {
      myInvoice += `<div> Addon Notes:</div>
        <div class="notice" >
       ${this.addon_notes}
      </div> `
    }
    myInvoice += `
        <div>TERMS & CONDITIONS:</div>
          <div class="notice">
            1. All disputes are subjected to Indore Jurisdiction only.
          </div>
        
      </div>
    </main>
  </body>
</html>
    `;
    var html = htmlToPdfmake(myInvoice, { tableAutoSize: true });
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).open();
  }

  sendLink() {
    if (!this.linkAmount) {
      this.toast.fire({
        icon: 'error',
        title: 'Please enter amount payable against the link'
      });
      return;
    }
    if (this.linkAmount <= 0) {
      this.toast.fire({
        icon: 'error',
        title: 'Please enter a valid amount'
      });
      return;
    }
    console.log((this.order?.totalAmount - this.order?.amountPaid) + this.totalAddonAmt, this.linkAmount);
    if (this.linkAmount > ((this.order?.totalAmount - this.order?.amountPaid) + this.totalAddonAmt)) {
      this.toast.fire({
        icon: 'error',
        title: 'Payable amount cannot be greater than remaining amount'
      });
      return;
    }
    const dataToSend = {
      amount: this.linkAmount,
      validTill: this.linkValidTill,
      maxPayable: (this.order?.totalAmount - this.order?.amountPaid) + this.totalAddonAmt
    }
    console.log(dataToSend, this.orderId)
    //return;
    this.apiService.generateSendPayLink(this.orderId, dataToSend).subscribe((res: any) => {
      console.log(res);
      if (res.success) {
        this.linkAmount = undefined;
        this.linkValidTill = 1;
        this.toast.fire({
          icon: 'success',
          title: 'Pay link sent successfully'
        });
        (<any>$('#pay-link')).modal('hide');
        return
        location.reload();
      } else {
        this.toast.fire({
          icon: 'error',
          title: res.message
        });
        console.error(res.message);
      }
    });
  }

  onlyNumberKey(evt: KeyboardEvent) {
    // Only ASCII character in that range allowed
    let ASCIICode = (evt.which) ? evt.which : evt.keyCode;
    return (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) ? false : true;
  }

  adminPayAmount() {
    //console.log(this.adminAmount)
    if (!this.adminAmount) {
      this.toast.fire({
        icon: 'error',
        title: 'Please enter amount payable against the remaining amount'
      });
      return;
    }
    if (this.adminAmount <= 0) {
      this.toast.fire({
        icon: 'error',
        title: 'Please enter a valid amount'
      });
      return;
    }
    if (this.adminAmount > (this.order?.totalAmount - this.order?.amountPaid + this.totalAddonAmt)) {
      this.toast.fire({
        icon: 'error',
        title: 'Payable amount cannot be greater than remaining amount'
      });
      return;
    }
  }

}
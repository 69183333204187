import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';

@Component({
  selector: 'app-coupan-assign',
  templateUrl: './coupan-assign.component.html',
  styleUrls: ['./coupan-assign.component.scss']
})
export class CoupanAssignComponent implements OnInit {
  addCoupanToUsers: FormGroup;
  public allData: Array<any> = [];
  public allVoucher: Array<any> = [];
  public filteredVoucher: any;
  private swalWithCustomButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn primary-grd',
      cancelButton: 'btn btn-danger',
    },
    buttonsStyling: false,
  });

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.addCoupanToUsers = new FormGroup({
      userList: new FormControl(),
      voucher: new FormControl()
    })
    this.addCoupanToUsers = this.formBuilder.group({
      userList: ['', [Validators.required]],
      voucher: [null, [Validators.required]]
    })
    this.User()
    this.VoucherList()
    // console.log(this.addCoupanToUsers)
  }

  User() {
    this.spinner.show();
    this.apiService.allWebUser().subscribe((resp: any) => {
      this.spinner.hide();
      let sortedUsers = resp.getData.sort((a, b) => {
        // console.log(a, b)
        return a.firstname.localeCompare(b.firstname)
      })
      // console.log(resp, sortedUsers);
      this.allData = sortedUsers;
    });
  }

  VoucherList(){
    this.apiService.voucherList().subscribe((resp: any) => {
      console.log(resp,"responnnnnn");
      let sortedVoucher = resp.data.sort((a, b) => {
        // console.log(a, b)
        return a.coupon_code.localeCompare(b.coupon_code)
      })
      sortedVoucher.filter((elem) => {
        if(elem.isShow == "Voucher" && elem.status == "active"){
          this.allVoucher.push(elem)
        }
      })
      // this.allVoucher = sortedVoucher;
    });
  }

  setCoupon(){
    console.log(this.addCoupanToUsers.value)
    this.allVoucher.filter((elem) => {
      if(elem._id == this.addCoupanToUsers.value.voucher){
        console.log(elem);
        this.filteredVoucher = elem;
      }
    })
    let body = {...this.filteredVoucher, user_assign: this.addCoupanToUsers.value.userList}
    console.log(body)
    // return
    this.apiService.updatecoupan(body, this.addCoupanToUsers.value.voucher).subscribe((res: any) => {
      console.log(res)
      if(res.success){
        this.swalWithCustomButtons.fire({
          title: 'Success',
          width: 400,
          text: 'Voucher assigned successfully.',
          icon: 'success',
          confirmButtonText: 'Ok',
        });
        this.router.navigate(['/add-voucher-list']);
      } else {
        this.swalWithCustomButtons.fire({
          title: 'Error!',
          width: 400,
          text: "Please select a user and a voucher.",
          icon: 'error',
          confirmButtonText: 'Ok',
        });
      }
    })
  }

  onChangeVoucherDetail(e: any){
    // console.log("THIS IS WORKING", e)
    this.apiService.coupanBYID({id: e}).subscribe((res: any) => {
      if(res.success){
        this.addCoupanToUsers.patchValue({
          userList: res?.getData?.user_assign
        })
      }
      // console.log(res)
    })
  }

  onChangeUserDetail(e: any){
    console.log(e)
    this.addCoupanToUsers.patchValue({
      userList: e
    })
    // this.allVoucher.filter((elem) => {
    //   if(elem._id == this.addCoupanToUsers.value.voucher){
    //     console.log(elem);
    //     this.filteredVoucher = elem;
    //   }
    // })
    // let body = {...this.filteredVoucher, user_assign: e}
    // this.apiService.updatecoupan(body, this.addCoupanToUsers.value.voucher).subscribe((res: any) => {
    //   console.log(res)
    // })
  }

}

<div class="container img-scroll">
  <div class="row" *ngIf="imageGallery?.length > 0; else NoData">
    <div class="col-md-2" *ngFor="let data of imageGallery; index as i">
      <div class="card">
        <img class="card-img-top" height="171px" [src]="getImg(data.gallery_image[0].src)" alt="Card image">
        <div class="card-body p-1">
          <!-- <div class="text-center">
                <small class="card-text">Type - {{ data.category_type }}</small><br />
                <small class="card-text">Category - {{ data.category_name }}</small><br />
                <small class="card-text">Language - {{ data.language_name }}</small><br />
              </div> -->
          <div class="text-center p-1">
             <p style="margin-bottom:0px;">{{data.destination.destination}}</p> 
            <p style="margin-bottom:0px;" *ngIf="data.is_Both === true">Both</p>
            <p style="margin-bottom:0px;" *ngIf="data.is_Gallery === true">Gallery</p>
            <p style="margin-bottom:0px;" *ngIf="data.is_Tour === true">Tour</p>
            <div class="btn-group">
              <small class="form-check">
                <input type="checkbox" class="form-check-input" [checked]="data.status === 'active' ? true : false"
                  (change)="gallerylist(data._id, data.status)" id="{{ 'checkStatus-' + i }}">
                <label class="form-check-label" for="{{ 'checkStatus-' + i }}">Active</label>
              </small>
            </div>
            <button (click)="deleteMedia(data._id)" class="btn btn-danger btn-sm btn-block mt-1">Remove</button>
          </div>

        </div>
      </div>
    </div>
    <ng-template #NoData>
      <div class="text-center">
        <h3>No Media Not Found</h3>
      </div>
    </ng-template>
  </div>
<div class="content">
    <div class="container-fluid">

        <div class="row">

            <div class="col-md-12">
                <div class="card card-primary">

                    <form [formGroup]="addMakeTripForm" (ngSubmit)="addtrip()">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Title <span class="required"></span></label>
                                        <input type="text" class="form-control" formControlName="title"
                                            placeholder="Title">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Image (Only 1920x1080)<span class="required"></span></label>
                                        <div class="input-group">
                                            <div class="custom-file">
                                                <input type="file" accept="image/*" (change)="onFileChange($event)"
                                                    class="custom-file-input" aria-describedby="imageFile"
                                                    name="tour_image" />
                                                <label class="custom-file-label" #importFestImg for="tour_image">
                                                    <span>Upload Image</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">PDF Upload</label>
                                        <div class="input-group">
                                            <div class="custom-file">
                                                <input type="file" accept="application/pdf" class="custom-file-input"
                                                    (change)="onFileChangepdf($event)" aria-describedby="imageFile"
                                                    name="tour_pdf" />
                                                <label class="custom-file-label" #importPDFImg for="tour_pdf">
                                                    <span>Upload Pdf</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Boarding Point<span class="required"></span></label>
                                        <ng-select placeholder="Select Boarding Point" [multiple]="true"
                                            formControlName="boardingArr">
                                            <ng-option *ngFor="let subCat of SubCats" [value]="subCat.boarding">{{
                                                subCat.boarding }}
                                            </ng-option>
                                        </ng-select>

                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Adventure Type<span class="required"></span></label>
                                        <select class="form-control" formControlName="adventure_type">
                                            <option value="" selected>Select Adventure Type
                                            </option>
                                            <option *ngFor="let item of allAdve; let i = index" value="{{item._id}}">
                                                {{item.adventure_type}} </option>
                                        </select>

                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Destination<span class="required"></span></label>
                                        <select class="form-control" formControlName="destination">
                                            <option value="" selected>Select Destination
                                            </option>
                                            <option *ngFor="let item of alldesti; let i = index" value="{{item._id}}">
                                                {{item.destination}} </option>
                                        </select>

                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Package Type</label>
                                        <ng-select placeholder="Select Package Type" [multiple]="true"
                                            formControlName="packageArr" (change)="selectedPackage($event)">
                                            <ng-option *ngFor="let subPac of allpackag" [value]="subPac.package_type">{{
                                                subPac.package_type }}
                                            </ng-option>
                                        </ng-select>

                                    </div>
                                </div>

                                <!-- <div class="col-md-4" *ngIf="packageSelected.includes('Solo bike')">
                                    <div class="form-group">
                                        <label for="class_name">Solo Bikes</label>
                                        <ng-select placeholder="Select Package Type" [multiple]="true"
                                            formControlName="soloBikeArr">
                                            <ng-option *ngFor="let solobike of allsoloBike" [value]="solobike.bike_name">{{
                                                solobike.bike_name }}
                                            </ng-option>
                                        </ng-select>

                                    </div>
                                </div>

                                <div class="col-md-4" *ngIf="packageSelected.includes('Shared bike')">
                                    <div class="form-group">
                                        <label for="class_name">Shared Bikes</label>
                                        <ng-select placeholder="Select Package Type" [multiple]="true"
                                            formControlName="sharedBikeArr">
                                            <ng-option *ngFor="let sharebike of allsharedBike" [value]="sharebike.bike_name">{{
                                                sharebike.bike_name }}
                                            </ng-option>
                                        </ng-select>

                                    </div>
                                </div> -->

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Room Sharing</label>
                                        <ng-select placeholder="Select Rooms" [multiple]="true"
                                            formControlName="roomArr">
                                            <ng-option *ngFor="let room of allrooms" [value]="room.room_name">{{
                                                room.room_name }}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>

                                <!-- <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Number Of Travellar<span
                                                class="required"></span></label>
                                        <select class="form-control" formControlName="no_of_traveler">
                                            <option value="" selected>Select Travellar</option>

                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>

                                    </div>
                                </div> -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="class_name">Trip/Trek<span class="required"></span></label>
                                        <select class="form-control" formControlName="tripType">
                                            <option value="" selected>Select Trip/Trek</option>

                                            <option value="1">Trip</option>
                                            <option value="2">Trek</option>
                                            <option *ngFor="let item of allmenu; let i = index" value="3">
                                                {{item.menu_type}} </option>
                                            <!-- <option value="3">Three</option> -->
                                        </select>

                                      

                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="remainder-day">Last Reminder Day<span class="required"></span></label>
                                        <select class="form-control" name="lastReminderDay" formControlName="lastReminderDay" id="remainder-day">
                                            <option *ngFor="let rd of reminderDays" [selected]="rd===1" value="{{rd}}">{{rd}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Start Date <span class="required"></span></label>
                                                <input type="date" id="start_date1" [readonly]="addedNew"
                                                    (change)="minDate=addMinDate($event.target.value, 1)"
                                                    [min]="initStartDate" formControlName="start_date1"
                                                    class="form-control">
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>End Date<span class="required"></span></label>
                                                <input type="date" id="end_date1" [readonly]="addedNew" [min]="minDate"
                                                    (change)="getAddButton()" formControlName="end_date1"
                                                    class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Show Date</label>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" id="show_date1"
                                                        formControlName="show_date1">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- 
                                                      <div class="col-md-6">
                                                            <div class="form-group">
                                                                  <label>Start Date <span
                                                                              class="required"></span></label>
                                                                  <input type="date"
                                                                        (change)="minDate=addMinDate($event.target.value, 1)"
                                                                        [min]="initStartDate"
                                                                        formControlName="start_date1">
                                                            </div>
                                                      </div>
                                                      <div class="col-md-6">
                                                            <label>End Date<span class="required"></span></label>
                                                            <div class="form-group">
                                                                  <input type="date" [readonly]="addedNew"
                                                                        [min]="minDate" formControlName="end_date1">
                                                            </div>
                                                      </div> -->

                                <div #addDate class="col-md-12"></div>

                                <div class="row" style="margin-left: 1px;">
                                    <div class="form-group col-md-12">
                                        <button type="button" (click)="addvalue()" *ngIf="isAdd"
                                            class="btn btn-info">Add</button>
                                        <button type="button" (click)="removevalue(index)" *ngIf="isRemove"
                                            class="btn btn-secondary ml-2">Remove</button>
                                    </div>
                                </div>

                                <!-- <div class="form-group col-md-4">
                                                      <label>Start Date <span class="required"></span></label>
                                                      <input type="date" class="form-control" ngbDatepicker
                                                            (click)="datePicker1.toggle()" #datePicker1="ngbDatepicker"
                                                            formControlName="start_date" [minDate]="minDate"
                                                            placeholder="dd-mm-yyyy">
                                                </div> -->

                                <!-- <div *ngIf="addMakeTripForm.value.start_date!==null"
                                                      class="form-group col-md-4">
                                                      <a (click)="toggleShow()"> <i class="fas fa-plus-square"></i></a>
                                                </div> -->
                                <!-- <div class="form-group col-md-4" >
                                                      <label>End Date<span class="required"></span></label>
                                                      <input type="date" class="form-control" ngbDatepicker
                                                            (click)="datePicker2.toggle()" #datePicker2="ngbDatepicker"
                                                            formControlName="end_date" [minDate]="minDate"
                                                            placeholder="dd-mm-yyyy">
                                                </div> -->

                                <div class="form-group col-md-4">

                                </div>

                                <div class="form-group col-md-12">

                                    <label for="class_name">Order Note</label>
                                    <ckeditor [config]="config_ck" formControlName="orderNote">

                                    </ckeditor>

                                </div>

                                <div class="col-md-4">

                                    <div class="custom-control custom-checkbox">
                                        <input id="male" type="checkbox" class="custom-control-input"
                                            formControlName="isHome"
                                            (change)="addMakeTripForm.get('isHome').setValue($event.target.checked ? 'true' : 'false')"
                                            [checked]="addMakeTripForm .value.isHome == 'true'" />
                                        <label class="custom-control-label" for="male">Is
                                            Home</label>
                                    </div>
                                </div>

                            </div>

                        </div>





                        <div class="card-footer text-right">
                            <app-button [type]="'submit'" [block]="false" [text]="'Next'" [loading]="isAuthLoading"
                                [color]="'info'" [disabled]="isAuthDisabled"></app-button>

                            <button type="button" class="btn btn-secondary ml-2"
                                [routerLink]="['/make-trip-list']">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>


    </div>
</div>